export const ActiveStepIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'>
    <rect
      width='16'
      height='16'
      transform='translate(0.000976562 0.59375)'
      fill='white'
      style={{ mixBlendMode: 'multiply' }}
    />
    <path
      d='M11.8831 4.0234L12.5256 3.25765C11.9049 2.73228 11.1973 2.31928 10.4346 2.03725L10.0928 2.97605C10.7459 3.21834 11.3518 3.57277 11.8831 4.0234Z'
      fill='#4589FF'
    />
    <path
      d='M13.906 7.59375L14.8898 7.38735C14.7515 6.58843 14.4744 5.81991 14.071 5.1166L13.2053 5.59375C13.5508 6.21575 13.7878 6.89211 13.906 7.59375Z'
      fill='#4589FF'
    />
    <path
      d='M10.0928 14.2114L10.4346 15.1503C11.1973 14.8682 11.9049 14.4552 12.5256 13.9298L11.8831 13.1641C11.3518 13.6147 10.7459 13.9692 10.0928 14.2114Z'
      fill='#4589FF'
    />
    <path
      d='M13.2053 11.5938L14.071 12.0938C14.4746 11.3824 14.7518 10.6063 14.8901 9.80015L13.906 9.6267C13.7877 10.3179 13.5506 10.9834 13.2053 11.5938Z'
      fill='#4589FF'
    />
    <path
      d='M8.00098 15.5938V1.59375C6.14446 1.59375 4.36398 2.33125 3.05123 3.644C1.73847 4.95676 1.00098 6.73723 1.00098 8.59375C1.00098 10.4503 1.73847 12.2307 3.05123 13.5435C4.36398 14.8563 6.14446 15.5938 8.00098 15.5938Z'
      fill='#4589FF'
    />
  </svg>
);

export const CompletedStepIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'>
    <rect
      width='16'
      height='16'
      transform='translate(0.000976562 0.59375)'
      fill='white'
      style={{ mixBlendMode: 'multiply' }}
    />
    <path
      d='M7.00098 11.3007L4.50098 8.80025L5.20748 8.09375L7.00098 9.88675L10.7935 6.09375L11.501 6.80125L7.00098 11.3007Z'
      fill='#4589FF'
    />
    <path
      d='M8.00098 1.59375C6.61651 1.59375 5.26313 2.00429 4.11199 2.77346C2.96084 3.54263 2.06364 4.63588 1.53382 5.91497C1.00401 7.19405 0.865386 8.60151 1.13548 9.95938C1.40558 11.3172 2.07226 12.5645 3.05123 13.5435C4.0302 14.5225 5.27748 15.1891 6.63535 15.4592C7.99321 15.7293 9.40068 15.5907 10.6798 15.0609C11.9588 14.5311 13.0521 13.6339 13.8213 12.4827C14.5904 11.3316 15.001 9.97822 15.001 8.59375C15.001 6.73723 14.2635 4.95676 12.9507 3.644C11.638 2.33125 9.8575 1.59375 8.00098 1.59375ZM8.00098 14.5938C6.81429 14.5938 5.65425 14.2419 4.66756 13.5826C3.68086 12.9233 2.91183 11.9862 2.4577 10.8899C2.00358 9.79349 1.88476 8.58709 2.11627 7.42321C2.34778 6.25932 2.91922 5.19022 3.75834 4.35111C4.59745 3.51199 5.66655 2.94055 6.83044 2.70904C7.99432 2.47753 9.20072 2.59635 10.2971 3.05047C11.3934 3.5046 12.3305 4.27363 12.9898 5.26033C13.6491 6.24702 14.001 7.40706 14.001 8.59375C14.001 10.185 13.3688 11.7112 12.2436 12.8364C11.1184 13.9616 9.59228 14.5938 8.00098 14.5938Z'
      fill='#4589FF'
    />
  </svg>
);
