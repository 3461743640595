import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 16px 24px;
  border-radius: 8px;
  background: #fefefe;
  overflow-y: auto; /* Allows scrolling */
  max-height: calc(3 * 82px + 2 * 12px); /* Height for 3 cards plus gap */
`;

const Title = styled.h3`
  color: var(--Hover-Gray-60, #5e5e5e);
  font-size: 18px;
  font-weight: 600;
  line-height: 125%;
`;

const Card = styled.div`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--neutral-400, #a0a0a0); /* Default style */
  background: var(--neutral-200, #d8d8d8); /* Default style */
  cursor: pointer;
  div {
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;

export { Container, Title, Card };
