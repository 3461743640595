import { ApplicationBusinessList } from 'components/ApplicationBusinessList';
import { ApplicationInvestorList } from 'components/ApplicationInvestorList';
import { useAppContext } from 'contexts/AppContext';

const ApplicationScreen = () => {
  const { userDataLight } = useAppContext();

  return (
    <>
      {userDataLight?.organizationRole === 'BUSINESS' && <ApplicationBusinessList />}
      {userDataLight?.organizationRole === 'INVESTOR' && <ApplicationInvestorList />}
    </>
  );
};

export default ApplicationScreen;
