import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  HeaderBox,
  BreadCrumbsBox,
} from 'components/RequestDetailsDraft/RequestDetails.style';
import { NewButton } from 'components/common/Button';
import { TitleBox, ContentBox } from './InfoEGZ.style';
import { Indicator, ScoringInfoProps } from './InfoEGZ.interface';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

const InfoEGZ: React.FC<ScoringInfoProps> = ({ id, num, closeModal }) => {
  const [indicators, setIndicators] = useState<Indicator[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}cabinet/investor/request/creditor/indicators/${id}`,
          { withCredentials: true },
        );
        setIndicators(response.data.indicators);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const renderIndicator = (indicator: Indicator) => {
    const isInRange =
      indicator.minValue !== null &&
      indicator.maxValue !== null &&
      indicator.value !== null &&
      indicator.value >= indicator.minValue &&
      indicator.value <= indicator.maxValue;

    const valueStyle = {
      color: isInRange ? 'green' : 'red',
    };

    switch (indicator.type) {
      case 'b':
        return (
          <p key={indicator.code} style={{ color: indicator.boolValue ? 'green' : 'red' }}>
            <strong>{indicator.name}:</strong> {indicator.boolValue ? 'Да' : 'Нет'}
          </p>
        );
      case 'v':
        return (
          <p
            key={indicator.code}
            style={
              indicator.minValue !== null && indicator.maxValue !== null ? valueStyle : undefined
            }>
            <strong>{indicator.name}:</strong> {indicator.value?.toLocaleString()}
          </p>
        );
      case 'm':
        return (
          <p key={indicator.code} style={indicator.value !== null ? valueStyle : undefined}>
            <strong>{indicator.name}:</strong>{' '}
            {indicator.value !== null
              ? indicator.value
              : `${indicator.minValue} - ${indicator.maxValue}`}
          </p>
        );
      default:
        return null;
    }
  };

  return (
    <Container>
      <HeaderBox>
        <BreadCrumbsBox>
          <p>
            <a href='/applications'>Заявки</a>
          </p>
          <p>{'>'}</p>
          <span onClick={closeModal}>Заявка {num}</span>
          <p>{'>'}</p>
          <p className='select'>Данные ЭГЗ</p>
        </BreadCrumbsBox>
        <TitleBox>
          <h2>Данные ЭГЗ</h2>
          <NewButton onClick={closeModal} text='Назад к заявке' className='white' />
        </TitleBox>
      </HeaderBox>
      <ContentBox>
        {loading ? (
          <p>Loading...</p>
        ) : indicators.length === 0 ? (
          <p>Данные не найдены</p>
        ) : (
          indicators.map((indicator) => renderIndicator(indicator))
        )}
      </ContentBox>
    </Container>
  );
};

export default InfoEGZ;
