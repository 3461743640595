import React, { useState } from 'react';
import {
  ModalWrapper,
  Title,
  BigBoxInputTitle,
  Box,
  BoxInput,
  BoxInputTitle,
} from './ModuleAddModalStyle';
import { Button } from 'components/common/Button';
import { ModuleAddDetails } from '../../AdminSettingsInterface';
import * as api from '../../admin-settings.api';
import { Input } from '../../../common/Input/InputText/InputText.style';

interface ModuleProps {
  onClose: () => void;
}

export const ModuleAddModal: React.FC<ModuleProps> = ({ onClose }) => {
  const [isModal, setIsModal] = useState(true);
  const [editingData, setEditingData] = useState<ModuleAddDetails>();

  const closeModal = () => {
    setIsModal(false);
    onClose();
  };

  const handleSaveClick = () => {
    if (editingData) {
      api
        .addModule(editingData)
        .then(() => {
          // Обработка успешного сохранения
        })
        .catch((error: any) => {
          console.error('Error saving document group:', error);
        });
      closeModal();
    }
  };

  return (
    <>
      {isModal && (
        <ModalWrapper>
          <div className='modal'>
            <Title>Добавить модуль</Title>
            <div className='modal-content'>
              <form>
                <BoxInput>
                  <BoxInputTitle>Путь:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData?.path}
                    onChange={(e) => setEditingData({ ...editingData, path: e.target.value })}
                  />
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>Статус:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData?.status}
                    onChange={(e) => setEditingData({ ...editingData, status: e.target.value })}
                  />
                </BoxInput>
                <div>
                  <div style={{ textAlign: 'center', paddingBottom: '10px' }}>
                    <BigBoxInputTitle>Название модуля:</BigBoxInputTitle>
                  </div>
                  <BoxInput>
                    <BoxInputTitle>English:</BoxInputTitle>
                    <Input
                      type='text'
                      value={editingData?.text_en}
                      onChange={(e) => setEditingData({ ...editingData, text_en: e.target.value })}
                    />
                  </BoxInput>
                  <BoxInput>
                    <BoxInputTitle>Русский:</BoxInputTitle>
                    <Input
                      type='text'
                      value={editingData?.text_ru}
                      onChange={(e) => setEditingData({ ...editingData, text_ru: e.target.value })}
                    />
                  </BoxInput>
                  <BoxInput>
                    <BoxInputTitle>Қазақша:</BoxInputTitle>
                    <Input
                      type='text'
                      value={editingData?.text_kk}
                      onChange={(e) => setEditingData({ ...editingData, text_kk: e.target.value })}
                    />
                  </BoxInput>
                </div>
                <Box>
                  <Button onClick={handleSaveClick} text={'Сохранить'} />
                  <Button onClick={closeModal} text={'Закрыть'} />
                </Box>
              </form>
            </div>
          </div>
        </ModalWrapper>
      )}
    </>
  );
};
