import React from 'react';
import {
  SuccessContainer,
  SuccessIcon,
  SuccessTitle,
  SuccessMessage,
  SuccessButton,
} from './SuccessMessageComponent.style';
import AproveGIF from 'assets/img/AproveGIF.gif';

interface SuccessMessageComponentlProps {
  onClose: () => void;
}

const SuccessModal: React.FC<SuccessMessageComponentlProps> = ({ onClose }) => (
  <SuccessContainer>
    <SuccessIcon>
      <img src={AproveGIF} alt='GIFAprove' />
    </SuccessIcon>
    <SuccessTitle>Успех!</SuccessTitle>
    <SuccessMessage>Аккаунт вашей организации создан, осталось только войти</SuccessMessage>
    <SuccessButton onClick={onClose}>Войти в аккаунт</SuccessButton>
  </SuccessContainer>
);

export default SuccessModal;
