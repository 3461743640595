import axios from 'axios';
import {
  DocumentGroupDetails,
  ModuleAddDetails,
  OrganizationEditDetails,
  UserEditDetails,
} from './AdminSettingsInterface';

/*********** Document ***********/

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

export const addDocumentGroupApi = (documentGroupDetails: DocumentGroupDetails, file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('doc', JSON.stringify(documentGroupDetails));

  return axios.post(`${BASE_URL}administration/documents/add/document`, formData, {
    withCredentials: true,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

/*********** Module ***********/
export const addModule = (moduleAddDetails: ModuleAddDetails) => {
  return axios.post(`${BASE_URL}administration/modules/add`, moduleAddDetails, {
    withCredentials: true,
  });
};

/*********** Organization ***********/
export const organizationList = () => {
  return axios.get(`${BASE_URL}administration/organizations/`, {
    withCredentials: true,
  });
};

export const editOrganization = (id: number, organizationEditDetails: OrganizationEditDetails) => {
  return axios.put(`${BASE_URL}administration/organizations/edit/${id}`, organizationEditDetails, {
    withCredentials: true,
  });
};

export const blockOrganization = (id: number, block: boolean) => {
  return axios.put(`${BASE_URL}administration/organizations/block/${id}?block=${block}`, {
    withCredentials: true,
  });
};

/*********** User ***********/
export const userList = () => {
  return axios.get(`${BASE_URL}administration/users/list`, {
    withCredentials: true,
  });
};

export const editUser = (id: number, userEditDetails: UserEditDetails) => {
  return axios.put(`${BASE_URL}administration/users/edit/${id}`, userEditDetails, {
    withCredentials: true,
  });
};

export const blockUser = (id: number, block: boolean) => {
  return axios.get(`${BASE_URL}administration/users/block/${id}?block=${block}`, {
    withCredentials: true,
  });
};

export const roleList = () => {
  return axios.get(`${BASE_URL}administration/roles/list`, {
    withCredentials: true,
  });
};
