import {
  HeaderMenu,
  HeaderNav,
  UserInitialsCircle,
  StyledNavLink,
  IconWithBadge,
  NotificationBadge,
} from './Header.style';
import { ORGANIZATION_URL } from 'constants/urls';
import { useAppContext } from 'contexts/AppContext';
import { NotificationsDropdown } from './NotificationsDropdown';
import { useState, useEffect } from 'react';
import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

export const Header = () => {
  const { userDataLight } = useAppContext();
  const [notificationsCount, setNotificationsCount] = useState(userDataLight?.notification || 0);
  const [avatarUrl, setAvatarUrl] = useState('');

  const handleNotificationsClick = () => {
    setNotificationsCount(0);
  };

  const fetchAvatar = async (avatarId: string | undefined) => {
    if (!avatarId) {
      setAvatarUrl(''); // Handle the case where avatarId is undefined
      return;
    }

    try {
      const response = await axios.get(`${BASE_URL}/api/v1/ui/file/${avatarId}`, {
        responseType: 'blob',
        withCredentials: true,
      });
      const url = URL.createObjectURL(response.data);
      setAvatarUrl(url);
    } catch (error) {
      console.error('Failed to fetch avatar:', error);
    }
  };

  useEffect(() => {
    if (userDataLight?.avatar) {
      fetchAvatar(userDataLight.avatar);
    }
  }, [userDataLight?.avatar]);

  const fio = userDataLight?.fio || '';
  const initials = fio.split(' ').reduce((acc: string, word: string, index: number) => {
    if (index < 2) {
      acc += word.charAt(0);
    }
    return acc;
  }, '');

  return (
    <HeaderMenu>
      <HeaderNav>
        <IconWithBadge onClick={handleNotificationsClick}>
          <NotificationsDropdown />
          {notificationsCount > 0 && <NotificationBadge>{notificationsCount}</NotificationBadge>}
        </IconWithBadge>
        <StyledNavLink to={ORGANIZATION_URL}>
          {avatarUrl ? (
            <img
              src={avatarUrl}
              alt='User Avatar'
              style={{ borderRadius: '50%', width: '50px', height: '50px' }}
            />
          ) : (
            <UserInitialsCircle>{initials}</UserInitialsCircle>
          )}
        </StyledNavLink>
      </HeaderNav>
    </HeaderMenu>
  );
};
