import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styled from 'styled-components';

const Info = styled.p`
  color: var(--grayscale-600, #757575);
  font-size: 14px;
  font-weight: 400;
  line-height: 126%;
`;

interface Props {
  value: number; // current value
  maxValue: number; // maximum value
}

const ScoringIndicator: React.FC<Props> = ({ value, maxValue }) => {
  const percentage = (value / maxValue) * 100;

  const getColor = (percentage: number): string => {
    const colors: { stop: number; color: [number, number, number] }[] = [
      { stop: 0, color: [255, 0, 0] }, // Bright red
      { stop: 50, color: [255, 255, 0] }, // Bright yellow
      { stop: 100, color: [0, 255, 0] }, // Bright green
    ];

    const lowerIndex = colors.findIndex((c) => percentage <= c.stop) - 1;
    const upperIndex = lowerIndex + 1 < colors.length ? lowerIndex + 1 : lowerIndex;

    const lowerColorStop = colors[lowerIndex];
    const upperColorStop = colors[upperIndex];

    const range = upperColorStop.stop - lowerColorStop.stop;
    const rangePct = (percentage - lowerColorStop.stop) / range;

    const interpolate = (start: number, end: number) => start + (end - start) * rangePct;

    const color = `rgb(${Math.floor(
      interpolate(lowerColorStop.color[0], upperColorStop.color[0]),
    )},${Math.floor(interpolate(lowerColorStop.color[1], upperColorStop.color[1]))},${Math.floor(
      interpolate(lowerColorStop.color[2], upperColorStop.color[2]),
    )})`;

    return color;
  };

  const getScoringStatus = (percentage: number): string => {
    if (percentage < 33.33) {
      return 'Низкая';
    } else if (percentage >= 33.33 && percentage < 66.66) {
      return 'Средняя';
    } else {
      return 'Высокая';
    }
  };

  return (
    <div style={{ width: '300px', height: '300px', margin: '0 auto' }}>
      <CircularProgressbar
        value={percentage}
        text={`${value}`}
        strokeWidth={5}
        styles={buildStyles({
          pathColor: getColor(percentage),
          textColor: 'black',
          trailColor: '#F5F5F5',
          textSize: '24px',
          pathTransitionDuration: 0.5,
        })}
      />
      <div style={{ textAlign: 'center', marginTop: '-34%', fontSize: '16px' }}>
        <Info>{getScoringStatus(percentage)} степень надежности</Info>
      </div>
    </div>
  );
};

export default ScoringIndicator;
