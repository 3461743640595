import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  width: 100%;
`;

const BoxContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

const BoxHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;

  h1 {
    color: #0d0d0d;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 115%;
  }
`;

const BoxSearch = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 20px;
  width: 100%;
  max-width: 764px;
`;

const BoxSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  div {
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }
  p {
    color: var(--Black-Main, #292d32);
    font-size: 18px;
    font-weight: 500;
    line-height: 16px;
  }
`;

const SwitcherBox = styled.div<{ activeSection?: string }>`
  display: flex;
  gap: 16px;
  div {
    color: var(--Primary, #5784f7);
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #5784f7;
    padding: 10px 43px;
    border-radius: 500px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    &.active {
      color: var(--White, #fff);
      background: var(--Primary, #5784f7);
    }
  }
`;

const BoxCheck = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const BoxChecks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  p {
    color: var(--Text-Input, #292d32);
    font-size: 14px;
    font-weight: 400;
    line-height: 125%;
  }
`;

const CustomCalendarWrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 52%;
  .react-calendar {
    width: 350px;

    max-width: 100%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    line-height: 1.125em;
  }
  .react-calendar__navigation {
    height: 44px;
    margin-bottom: 10px;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    abbr {
      text-decoration: none;
      font-weight: bold;
      color: #222;
    }
  }
  .react-calendar__month-view__days__day {
    color: #222;
  }
  .react-calendar__tile {
    max-width: initial !important;
    padding: 10px 0;
    background: none;
    color: #000;
    border-radius: 8px;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6f7ff;
    color: #0050b3;
  }
  .react-calendar__tile--now {
    background: #ffffe6;
    font-weight: bold;
    color: #0050b3;
  }
  .react-calendar__tile--active {
    background: #0050b3;
    color: white;
  }
  .react-calendar__tile--range {
    background: #0050b3;
    color: white;
  }
  .react-calendar__tile--rangeStart {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .react-calendar__tile--rangeEnd {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .react-calendar__navigation__label {
    font-weight: bold;
    font-size: 18px;
  }
  .react-calendar__navigation__arrow {
    font-size: 24px;
  }
`;

const ButtonCalendar = styled.button`
  background: #5784f7;
  border: none;
  border-radius: 4px;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  &:hover {
    background-color: #5784f7;
  }
`;

const ClearButton = styled(ButtonCalendar)`
  background: #ccc;
  &:hover {
    background-color: #bbb;
  }
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding: 8px 10px;
  gap: 18px;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
`;

const CustomSelectWrapper = styled.div`
  position: relative;
`;

const CustomSelect = styled.div`
  background: var(--Field-field-01, #f4f4f4);
  padding: 8px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  user-select: none;
  z-index: 1;
  max-width: 120px;
  &.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.paid {
    border-bottom: 1px solid #4caf50; /* Green */
  }

  &.unpaid {
    border-bottom: 1px solid #f44336; /* Red */
  }

  &.awaiting {
    border-bottom: 1px solid #ff9800; /* Orange */
  }
`;

const CustomOptions = styled.div`
  background: var(--neutral-50, #fefefe);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  overflow-y: auto;
  z-index: 2;
  display: flex;
  flex-direction: column;
  max-width: 120px;
`;

const CustomOption = styled.div`
  padding: 8px;
  font-size: 14px;
  cursor: pointer;
  max-width: 120px;
  width: 100%;
  &:hover {
    background: #f4f4f4;
  }

  &.paid {
    border-bottom: 1px solid #4caf50; /* Green */
  }

  &.unpaid {
    border-bottom: 1px solid #f44336; /* Red */
  }

  &.awaiting {
    border-bottom: 1px solid #ff9800; /* Orange */
  }
`;

const ArrowIcon = styled.div<{ expanded: boolean }>`
  display: inline-block;
  transform: rotate(${(props) => (props.expanded ? '180deg' : '0deg')});
  transition: transform 0.3s ease;
`;

const ButtonBoxDowland = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  button {
    width: 250px;
  }
`;

export {
  Container,
  BoxContent,
  BoxHeader,
  BoxSearch,
  BoxSelect,
  SwitcherBox,
  BoxCheck,
  BoxChecks,
  CustomCalendarWrapper,
  ButtonCalendar,
  ClearButton,
  ButtonBox,
  CustomSelectWrapper,
  CustomSelect,
  CustomOptions,
  CustomOption,
  ArrowIcon,
  ButtonBoxDowland,
};
