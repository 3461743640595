import styled from 'styled-components';
import { Button } from 'components/common/Button';

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  justify-content: center;
  margin-top: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
`;

const Box = styled.div`
  flex: 1;
  background-color: #fff;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  p {
    text-align: center;
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: bold;
  }
`;

const ServiceButton = styled.button<{ $isActive: boolean }>`
  border: none;
  width: 100%;
  text-align: left;
  background-color: ${({ $isActive }) => ($isActive ? '#F0EBE8' : '#fff')};
  font-size: 16px;
  padding: 10px 40px;
`;

const BoxInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
`;

const BoxInputTitle = styled.span`
  font-size: 16px;
  width: 100%;
`;

const BoxButton = styled(Button)`
  margin-top: 20px;
`;

const TextDiv = styled.div`
  font-size: 15px;
`;

const ButtonSmall = styled.button`
  font-size: 15px;
`;

const ButtonBig = styled.button`
  padding: 10px;
  font-size: 15px;
  background: #01aeb7;
  color: white;
  display: block;
  margin: 0 auto;
  border-radius: 10px;
`;

export {
  Container,
  Box,
  ServiceButton,
  BoxInput,
  BoxInputTitle,
  BoxButton,
  TextDiv,
  ButtonSmall,
  ButtonBig,
};
