import styled from 'styled-components';

interface TabProps {
  active: boolean;
}

const Container = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  background: #f4f4f4;
  @media (max-height: 790px) {
    gap: 18px;
  }
`;

const Header = styled.div`
  color: #0d0d0d;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 115%; /* 36.8px */
  @media (max-height: 790px) {
    font-size: 28px;
  }
`;

const TabsContainer = styled.div`
  display: flex;
  width: 100%;
`;

const Tab = styled.div<TabProps>`
  width: 100%;
  padding: 14px;
  cursor: pointer;
  background: ${(props) => (props.active ? '#5784F7' : '#FEFEFE')};
  color: ${(props) => (props.active ? '#FEFEFE' : '#848484')};
  border: 1px solid #5784f7;
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.16px;
  border-radius: 2px;
  &:not(:last-child) {
    border-right: none;
  }
`;

export { Container, Header, TabsContainer, Tab };
