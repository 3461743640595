import axios from 'axios';

export interface Filters {
  requestNum?: string;
  dateCreateStart?: any;
  dateCreateEnd?: any;
  whiteLabels?: string[];
  products?: string[];
  statuses?: string[];
  minSum?: number;
  maxSum?: number;
  minAvailSum?: number;
  maxAvailSum?: number;
  minRate?: string;
  maxRate?: string;
  minPeriod?: string;
  maxPeriod?: string;
  isReceivingOffers?: boolean;
  hasInvestorOffers?: boolean;
}

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/cabinet/creditor`;

export const fetchTableBusinessList = async (
  page = 0,
  filters: Filters = {},
  sortConfig: { field: string; direction: 'asc' | 'desc' | null },
) => {
  try {
    const params = new URLSearchParams({ page: page.toString() });

    if (sortConfig.field && sortConfig.direction) {
      params.append('sort', `${sortConfig.field},${sortConfig.direction}`);
    }

    const response = await axios.post(
      `${BASE_URL}/request?${params.toString()}`, // page и sort остаются в строке запроса
      filters, // фильтры передаются в теле запроса
      {
        withCredentials: true,
      },
    );
    return response.data;
  } catch (error) {
    console.error('Ошибка загрузки данных:', error);
    throw error;
  }
};
