import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 42px;
  width: 100%;
`;

const BoxCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  h3 {
    color: #0d0d0d;

    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
  }
`;

const ListCard = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  .aqua {
    background: var(--12, linear-gradient(180deg, #1fc8d1 0%, #0ab7c0 100%));
  }
  .blue {
    background: linear-gradient(180deg, #89aff8 0%, #7fa8f7 100%);
  }
  .red {
    background: var(--15, linear-gradient(180deg, #fe6c6c 0%, #fe464b 100%));
  }
  .orange {
    background: var(--14, linear-gradient(180deg, #ff9364 0%, #f25f33 100%));
  }
`;

const Card = styled.div`
  display: flex;
  width: 100%;
  height: 184px;
  padding: 16px;
  max-width: 182px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 6px;
  background: var(--13, linear-gradient(180deg, #f8b801 0%, #eaad00 100%));

  p {
    color: var(--White, #fff);
    font-size: 16px;
    font-weight: 600;
    line-height: 125%;
    margin-top: auto;
  }

  span {
    color: #f4f4f4;
    max-width: 136px;
    font-size: 12px;
    font-weight: 500;
    line-height: 112%;
  }

  img {
    width: 16px;
    height: 16px;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: #f4f4f4;
    font-size: 10px;
    font-weight: 400;
    line-height: 125%;
    margin-top: auto;
  }
`;

const ListScoring = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-top: 24px;
  width: 100%;
`;

const CardScoring = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  h3 {
    color: #0d0d0d;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
  }
`;

const CardInfo = styled.div`
  display: flex;
  padding: 32px;
  min-width: 461px;
  height: 268px;
  align-items: center;
  gap: 54px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Background-Grey, #f6f8fa);
`;

const CardDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  div {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  p {
    color: #5e5e5e;
    font-size: 14px;
    font-weight: 400;
    line-height: 125%;
  }
`;

const Circle = styled.span<{ color: string }>`
  height: 20px;
  width: 20px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  display: inline-block;
`;

const ChartContainer = styled.div`
  position: relative;
  width: 202px;
  height: 202px;
`;

const CenterText = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h4 {
    color: #0d0d0d;

    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
  }
  p {
    color: #5e5e5e;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
  }
`;

const BarChartBox = styled.div`
  background: var(--Background-Grey, #f6f8fa);
  border-radius: 8px;
  max-width: 960px;
  padding: 32px;
`;

const BarChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-top: 20px;
  h3 {
    color: #0d0d0d;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
  }
  div {
    display: flex;
    gap: 16px;
    color: var(--Black-Main, #292d32);
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
  }
`;

export {
  Container,
  BoxCard,
  ListCard,
  Card,
  ListScoring,
  CardScoring,
  CardInfo,
  CardDescription,
  Circle,
  ChartContainer,
  CenterText,
  BarChartBox,
  BarChartContainer,
};
