import styled from 'styled-components';

const Box = styled.div`
  display: flex;
  flex-direction: column;
  background: #f6f8fa;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 52px;
  max-width: 500px;
  div {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    button {
      width: 100%;
      max-width: 258px;
    }
  }
`;

const Title = styled.h2`
  color: var(--Black-Main, #292d32);
  text-align: center;
  font-size: 48px;
  font-weight: 600;
  line-height: 115%;
`;

const Description = styled.p`
  color: rgba(67, 67, 67, 0.4);
  text-align: center;
  font-size: 20px;
  font-weight: 400;
`;

export { Box, Content, Title, Description };
