import styled from 'styled-components';

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: white;
  border-radius: 8px;
  text-align: center;
  max-width: 510px;
  width: 100%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  margin-top: 28px;
`;

const SuccessIcon = styled.div`
  margin: 0 auto 20px;
  img {
    width: 139px;
    height: 150px;
  }
`;

const SuccessTitle = styled.h2`
  color: #000;
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  line-height: 39.902px; /* 142.507% */
  margin-bottom: 10px;
`;

const SuccessMessage = styled.p`
  color: var(--fordevs-gray-900, #242527);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  margin-bottom: 20px;
`;

const SuccessButton = styled.button`
  background: var(--primary-300, #5784f7);
  color: #fff;

  box-shadow: 0px 4px 10.8px 0px rgba(33, 0, 236, 0.25);
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background: #0f62fe;
  }
  &:active {
    background: #08329e;
  }
`;

export { SuccessContainer, SuccessIcon, SuccessTitle, SuccessMessage, SuccessButton };
