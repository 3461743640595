import styled from 'styled-components';

const TitleBox = styled.div`
  display: flex;
  margin-top: 30px;
  gap: 40px;
  h2 {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    max-width: 478px;
    a {
      color: var(--Primary, #5784f7);
    }
  }
`;

export { TitleBox };
