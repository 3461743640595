import styled from 'styled-components';
import colors from 'global_styling/colors';

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow-y: auto;
`;

const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
  max-width: 620px;
  border-radius: 20px;
  box-shadow: 0px 21.98182px 43.96363px 0px rgba(30, 34, 72, 0.16);
  max-height: 96vh; // 90% от высоты видимой части экрана
  overflow-y: auto;
  overflow-x: hidden;
`;

const CloseButton = styled.img`
  cursor: pointer;
`;

const Title = styled.h2`
  color: #292929;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
`;
const HeaderBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 24px 42px;
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px;
  height: 100%;
  gap: 12px;
  overflow-y: auto;
  border-top: 1px solid #dee2e6;
`;

const BoxInput = styled.div`
  display: flex;
  gap: 18px;
`;

const MoreInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  padding: 4px 0px;
  p {
    color: var(--Control, #9aa4b0);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
`;

const MoreInfo = styled.textarea`
  width: 100%;
  padding: 12px 16px;
  border-radius: 6px;
  background: var(--White, #fff);
  border: 1px solid var(--Control, #9aa4b0);
  color: var(--Control, #9aa4b0);
  font-size: 16px;
  font-weight: 400;
  height: 66px;
  resize: none;
  font-family: 'Jost';

  /* Chrome, Safari, Edge, Opera Убираем стрелочки больше и меньше */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  & {
    -moz-appearance: textfield;
  }

  &:focus {
    outline: none; /* Убираем стандартный фокус */
    border: 1px solid #5784f7;
    color: #292d32;
  }

  &:not(:placeholder-shown) {
    border: 1px solid #5784f7;
    color: #292d32;
  }
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;
  gap: 12px;
  margin-bottom: 10px;
`;

const BoxSubmitted = styled.div`
  display: flex;
  flex-direction: column;
  gap: 38px;
  align-items: center;
  p {
    color: #292929;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    line-height: 36px;
    max-width: 484px;
  }
  div {
    max-width: 200px;
  }
`;

const BoxCheck = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export {
  Modal,
  ModalContent,
  CloseButton,
  Title,
  HeaderBox,
  ContentBox,
  BoxInput,
  MoreInfoBox,
  MoreInfo,
  ButtonBox,
  BoxSubmitted,
  BoxCheck,
};
