import React from 'react';
import { ModalBackground, ErrorDialog } from './SuccessModal.styled';
import AproveGIF from 'assets/img/AproveGIF.gif';
import { NewButton } from '../Button';

interface SuccessModalProps {
  onClose: () => void;
  title?: string;
  message?: any;
  buttonText?: any;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ onClose, title, message, buttonText }) => (
  <ModalBackground>
    <ErrorDialog>
      <img src={AproveGIF} alt={'AproveGIF'} />
      <h2>{title}</h2>
      <p>{message}</p>
      <NewButton text={buttonText} onClick={() => onClose()} />
    </ErrorDialog>
  </ModalBackground>
);

export default SuccessModal;
