import React, { useEffect, useState } from 'react';
import { Container, Box, ServiceButton, TextDiv, ButtonSmall, ButtonBig } from './TarifStyle';
import { TarifDetails } from './TarifInterface';
import { useTranslation } from 'react-i18next';
import TarifModal from '../../modal/tarif/TarifModal';
import TarifCreateModal from '../../modal/tarif/TarifCreateModal';
import * as api from '../../api/investor-settings.api';

interface TarifProps {
  code: string;
  name: string;
}

export const Tarif = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState<TarifProps[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<TarifProps | null>(null);
  const [tarifDetails, setTarifDetails] = useState<TarifDetails[] | null>(null);
  const [isTarifModal, setIsTarifModal] = useState(false);
  const [isTarifCreateModal, setIsTarifCreateModal] = useState(false);

  useEffect(() => {
    api.getUiProducts().then((response) => setProducts(response.data));
  }, []);

  const fetchProductDetails = (tarifCode: string) => {
    api.getInvestorTarif(tarifCode).then((response) => setTarifDetails(response.data));
  };

  const handleProductClick = (tarif: TarifProps) => {
    setSelectedProduct(tarif);
    fetchProductDetails(tarif.code);
  };

  const handleEditClick = () => {
    setIsTarifModal(true);
  };

  const handleAddTarifClick = () => {
    setIsTarifCreateModal(true);
  };

  return (
    <Container>
      <Box>
        <p>Список тарифов</p>
        {products.map((tarif) => (
          <ServiceButton
            key={tarif.code}
            $isActive={selectedProduct === tarif}
            onClick={() => handleProductClick(tarif)}>
            {tarif.name}
          </ServiceButton>
        ))}
      </Box>
      {isTarifCreateModal && <TarifCreateModal onClose={() => setIsTarifCreateModal(false)} />}
      <Box>
        {tarifDetails && (
          <div>
            <p>{t('tarif.title')}</p>
            {tarifDetails?.map((tarif, index) => (
              <div key={index}>
                <TextDiv>
                  {t('tarif.activity')}: {tarif.activityName}
                </TextDiv>
                <TextDiv>
                  {t('tarif.category')}: {tarif.categoryName}
                </TextDiv>
                <TextDiv>
                  {t('tarif.experience')}: {tarif.experience}
                </TextDiv>
                <TextDiv>
                  {t('tarif.productName')}: {tarif.productName}
                </TextDiv>
                <ButtonSmall onClick={handleEditClick}>Редактировать</ButtonSmall>
                {isTarifModal && tarifDetails && (
                  <TarifModal tarifDetails={tarif} onClose={() => setIsTarifModal(false)} />
                )}
              </div>
            ))}
            <div style={{ paddingTop: '10px' }}>
              <ButtonBig onClick={handleAddTarifClick}>{t('tarif.add')}</ButtonBig>
            </div>
          </div>
        )}
      </Box>
    </Container>
  );
};
