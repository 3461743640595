import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {
  Container,
  BoxContent,
  BoxHeader,
  BoxSearch,
  BoxSelect,
  SwitcherBox,
  BoxCheck,
  BoxChecks,
  CustomCalendarWrapper,
  ButtonCalendar,
  ClearButton,
  ButtonBox,
  CustomSelectWrapper,
  CustomSelect,
  CustomOptions,
  CustomOption,
  ArrowIcon,
  ButtonBoxDowland,
} from './Payments.style';
import {
  StyledLink,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableRowHeader,
} from 'components/ApplicationBusinessList/ApplicationBusinessList.style';
import { SelectNew } from 'components/common/Input/SelectNew';
import { useAppContext } from 'contexts/AppContext';
import { NewButton } from 'components/common/Button';
import { Checkbox } from 'components/common/Checkbox';
import { ExpandedDates, GroupedPayments, PaymentData } from './Payments.interface';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/`;

export const Payments = () => {
  const { userDataLight } = useAppContext();
  const [selectedValue, setSelectedValue] = useState('');
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);
  const [dateState, setDateState] = useState<[Date | null, Date | null] | null>([new Date(), null]);
  const [active, setActive] = useState('all');
  const [isShowPaymentsStatus, setIsShowPaymentsStatus] = useState(false);
  const [payments, setPayments] = useState<PaymentData[]>([]);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [tempDateState, setTempDateState] = useState<[Date | null, Date | null] | Date | null>(
    null,
  );
  const [expandedDates, setExpandedDates] = useState<ExpandedDates>({});
  const [groupedPayments, setGroupedPayments] = useState<GroupedPayments>({});
  const [openSelectId, setOpenSelectId] = useState<number | null>(null);
  const [selectedPayments, setSelectedPayments] = useState<Set<number>>(new Set());
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);
  const [isLoadingOneC, setIsLoadingOneC] = useState(false);
  const endpoint =
    userDataLight.organizationRole === 'INVESTOR'
      ? `api/v1/cabinet/investor/payment/calendar/`
      : `api/v1/cabinet/creditor/payment/calendar/`;

  useEffect(() => {
    const fetchData = async () => {
      const url = `${BASE_URL}${endpoint}list`;
      try {
        const response = await axios.get(url, { withCredentials: true });
        setPayments(response.data.items || []);
      } catch (error) {
        console.error('Ошибка при загрузке данных о платежах:', error);
      }
    };
    clearData();
    fetchData();
  }, []);

  useEffect(() => {
    const filteredPayments =
      active === 'all'
        ? payments
        : payments.filter((payment) => {
            if (active === 'reward') {
              return payment.paymentTypeName === 'Процент';
            } else if (active === 'body') {
              return payment.paymentTypeName === 'Тело';
            }
            return false;
          });

    const newGroupedPayments = filteredPayments
      .sort((a, b) => new Date(b.paymentDate).getTime() - new Date(a.paymentDate).getTime())
      .reduce<GroupedPayments>((acc, payment) => {
        const paymentDate = formatDate(payment.paymentDate);
        if (!acc[paymentDate]) {
          acc[paymentDate] = [];
        }
        acc[paymentDate].push(payment);
        return acc;
      }, {});

    setGroupedPayments(newGroupedPayments);
  }, [payments, active]);

  useEffect(() => {
    const filteredPayments = payments.filter((payment) => {
      return isShowPaymentsStatus ? payment.signed === false : payment.signed === true;
    });

    const newGroupedPayments = filteredPayments
      .sort((a, b) => new Date(b.paymentDate).getTime() - new Date(a.paymentDate).getTime())
      .reduce<GroupedPayments>((acc, payment) => {
        const paymentDate = formatDate(payment.paymentDate);
        if (!acc[paymentDate]) {
          acc[paymentDate] = [];
        }
        acc[paymentDate].push(payment);
        return acc;
      }, {});

    setGroupedPayments(newGroupedPayments);
  }, [payments, isShowPaymentsStatus]);

  const clearData = () => {
    setPayments([]);
  };

  const toggleDate = (date: string) => {
    setExpandedDates((prev) => ({
      ...prev,
      [date]: !prev[date],
    }));
  };

  const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    };
    return new Date(dateString).toLocaleDateString('ru-RU', options).replace(/\//g, '.');
  };

  const getTotalsByDate = (payments: PaymentData[]) => {
    return payments.reduce(
      (totals, payment) => {
        totals.debit += payment.debit;
        totals.credit += payment.credit;
        totals.balance += payment.balance;
        return totals;
      },
      { debit: 0, credit: 0, balance: 0 },
    );
  };

  const applyDateSelection = async () => {
    let newDateState: [Date | null, Date | null] | null = null;
    if (Array.isArray(tempDateState)) {
      newDateState = tempDateState;
    } else if (tempDateState instanceof Date) {
      newDateState = [tempDateState, tempDateState];
    }

    setDateState(newDateState);

    if (newDateState && newDateState[0] && newDateState[1]) {
      const startDate = newDateState[0].toISOString().split('T')[0];
      const endDate = newDateState[1].toISOString().split('T')[0];
      setSelectedValue(`${startDate} - ${endDate}`);

      const url = `${BASE_URL}${endpoint}list?dateFrom=${startDate}&dateTo=${endDate}`;

      try {
        const response = await axios.get(url, { withCredentials: true });
        setPayments(response.data.items);
      } catch (error) {
        console.error('Ошибка при загрузке данных о платежах:', error);
      }
    } else {
      setSelectedValue('');
    }

    setIsDatePickerOpen(false);
  };

  const getDateRange = (period: any) => {
    const today = new Date();
    const dateFrom = new Date();

    switch (period) {
      case 'week':
        dateFrom.setDate(today.getDate() - 7);
        break;
      case 'month':
        dateFrom.setMonth(today.getMonth() - 1);
        break;
      case 'quarter':
        dateFrom.setMonth(today.getMonth() - 3);
        break;
      case 'year':
        dateFrom.setFullYear(today.getFullYear() - 1);
        break;
      default:
        break;
    }

    const formatDate = (date: Date) => date.toISOString().split('T')[0];

    return { dateFrom: formatDate(dateFrom), dateTo: formatDate(today) };
  };

  const handlePaymentsStatus = () => {
    setIsShowPaymentsStatus(!isShowPaymentsStatus);
  };

  const changeDate = (value: Date | [Date, Date] | null) => {
    if (value instanceof Date) {
      setTempDateState([value, value]);
    } else {
      setTempDateState(value);
    }
  };

  const options = [
    { value: 'week', label: 'За неделю' },
    { value: 'month', label: 'За месяц' },
    { value: 'quarter', label: 'За квартал' },
    { value: 'year', label: 'За 1 год' },
    { value: 'custom', label: 'За период' },
  ];

  const handleOptionChange = async (label: string) => {
    const option = options.find((option) => option.label === label);
    if (option) {
      setSelectedValue(option.label);
      let url = `${BASE_URL}${endpoint}`;

      if (option.value === 'custom') {
        setIsDatePickerOpen(true);
        setIsSelectOpen(false);
      } else {
        setIsDatePickerOpen(false);
        const { dateFrom, dateTo } = getDateRange(option.value);
        url += `?dateFrom=${dateFrom}&dateTo=${dateTo}`;

        try {
          const response = await axios.get(url, { withCredentials: true });
          setPayments(response.data.items);
        } catch (error) {
          console.error('Ошибка при загрузке данных о платежах:', error);
        }
      }
    }
  };

  const toggleSelect = () => {
    const newState = !isSelectOpen;
    setIsSelectOpen(newState);
    if (newState) {
      setIsDatePickerOpen(false);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      setIsSelectOpen(false);
      setIsDatePickerOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (selectedValue === 'За период') {
      setIsDatePickerOpen(true);
    }
  }, [selectedValue]);

  const handleStatusChange = async (paymentCalendarId: number, status: string) => {
    const url = `${BASE_URL}${endpoint}payment_calendar/status/${paymentCalendarId}?paymentStatus=${status}`;
    try {
      await axios.get(url, { withCredentials: true });
      const updatedPayments = payments.map((payment) =>
        payment.paymentCalendarId === paymentCalendarId
          ? { ...payment, paymentStatus: status }
          : payment,
      );
      setPayments(updatedPayments);
      setOpenSelectId(null); // Close the select after status change
    } catch (error) {
      console.error('Ошибка при обновлении статуса:', error);
    }
  };

  const getStatusClassName = (status: string) => {
    switch (status) {
      case 'PAID':
        return 'paid';
      case 'UNPAID':
        return 'unpaid';
      case 'AWAITING_PAYMENT':
        return 'awaiting';
      default:
        return '';
    }
  };

  const handlePaymentSelection = (paymentId: number) => {
    setSelectedPayments((prev) => {
      const updated = new Set(prev);
      if (updated.has(paymentId)) {
        updated.delete(paymentId);
      } else {
        updated.add(paymentId);
      }
      return updated;
    });
  };

  const handleSelectAllForDate = (date: string) => {
    setExpandedDates((prev) => ({
      ...prev,
      [date]: true, // Ensure the date stays expanded when selecting all
    }));

    setSelectedPayments((prev) => {
      const updated = new Set(prev);
      const datePayments = groupedPayments[date] || [];
      const allSelected = datePayments.every((payment) => updated.has(payment.paymentCalendarId));
      datePayments.forEach((payment) => {
        if (allSelected) {
          updated.delete(payment.paymentCalendarId);
        } else {
          updated.add(payment.paymentCalendarId);
        }
      });
      return updated;
    });
  };

  const handleGenerateInvoice = async () => {
    const selectedIds = Array.from(selectedPayments);
    const url = `${BASE_URL}${endpoint}payment_ordinary`;
    setIsLoadingPdf(true); // Set loading state to true
    try {
      const response = await axios.post(url, selectedIds, {
        withCredentials: true,
        responseType: 'blob',
      });

      // Create a link to download the file
      const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlBlob;
      link.setAttribute('download', 'invoice.zip'); // Set the file name with .zip extension
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.error('Error generating invoice:', error);
    } finally {
      setIsLoadingPdf(false); // Set loading state to false
    }
  };

  const handleGenerateOneCInvoice = async () => {
    const selectedIds = Array.from(selectedPayments);
    const url = `${BASE_URL}${endpoint}export_onec`;
    setIsLoadingOneC(true); // Set loading state to true
    try {
      const response = await axios.post(url, selectedIds, {
        withCredentials: true,
        responseType: 'blob',
      });

      // Create a link to download the file
      const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlBlob;
      link.setAttribute('download', 'invoice_onec.txt'); // Set the file name with .txt extension
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.error('Error generating 1C invoice:', error);
    } finally {
      setIsLoadingOneC(false); // Set loading state to false
    }
  };

  return (
    <Container>
      <BoxContent>
        <BoxHeader>
          <h1>Все платежи</h1>
          <BoxSearch ref={selectRef}>
            <SelectNew
              options={options}
              onOptionChange={handleOptionChange}
              selectedValue={selectedValue}
              isOpen={isSelectOpen}
              onToggleOpen={toggleSelect}
            />
            {isDatePickerOpen && (
              <CustomCalendarWrapper>
                <Calendar selectRange={true} value={tempDateState} onChange={changeDate as any} />
                <ButtonBox>
                  <ClearButton onClick={() => changeDate(null)}>Очистить</ClearButton>
                  <ButtonCalendar onClick={applyDateSelection}>Применить</ButtonCalendar>
                </ButtonBox>
              </CustomCalendarWrapper>
            )}
          </BoxSearch>
        </BoxHeader>
        <BoxSelect>
          <p>Тип операции</p>
          <SwitcherBox>
            <div className={active === 'all' ? 'active' : ''} onClick={() => setActive('all')}>
              Все
            </div>
            <div
              className={active === 'reward' ? 'active' : ''}
              onClick={() => setActive('reward')}>
              Вознаграждение
            </div>
            <div className={active === 'body' ? 'active' : ''} onClick={() => setActive('body')}>
              Тело
            </div>
          </SwitcherBox>
        </BoxSelect>
        <BoxCheck>
          <BoxChecks>
            <Checkbox checked={isShowPaymentsStatus} onChange={handlePaymentsStatus} />
            <p>Отражать платежи по договорам со статусом «На согласовании»</p>
          </BoxChecks>
        </BoxCheck>
        <ButtonBoxDowland>
          <NewButton
            text={isLoadingPdf ? 'Загрузка...' : 'Сгенерировать счет .pdf'}
            loading={isLoadingPdf}
            onClick={handleGenerateInvoice}
            disabled={selectedPayments.size === 0 || isLoadingOneC} // Disable button if loading, no selections, or other button is loading
          />
          <NewButton
            text={isLoadingOneC ? 'Загрузка...' : 'Выгрузка в формате 1С'}
            loading={isLoadingOneC}
            onClick={handleGenerateOneCInvoice}
            disabled={selectedPayments.size === 0 || isLoadingPdf} // Disable button if loading, no selections, or other button is loading
          />
        </ButtonBoxDowland>
      </BoxContent>
      <Table>
        <thead>
          <TableRowHeader>
            <TableHeader>
              <Checkbox
                checked={false} // Logic to handle if all are checked
                onChange={() => {
                  // Logic to handle select all for all dates
                }}
              />
            </TableHeader>
            <TableHeader>Дата</TableHeader>
            <TableHeader>Номер контракта</TableHeader>
            <TableHeader>Тип операции</TableHeader>
            <TableHeader>Приход</TableHeader>
            <TableHeader>Расход</TableHeader>
            <TableHeader>Сальдо</TableHeader>
            <TableHeader className='fixStatus'>Статус</TableHeader>
          </TableRowHeader>
        </thead>
        <tbody>
          {Object.entries(groupedPayments).map(([date, payments]) => {
            const { debit, credit, balance } = getTotalsByDate(payments);
            const allPaymentsSelected = payments.every((payment) =>
              selectedPayments.has(payment.paymentCalendarId),
            );
            return (
              <React.Fragment key={date}>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      checked={allPaymentsSelected}
                      onChange={() => handleSelectAllForDate(date)}
                    />
                  </TableCell>
                  <TableCell>
                    <div onClick={() => toggleDate(date)}>
                      <ArrowIcon expanded={expandedDates[date]}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='17'
                          viewBox='0 0 16 17'
                          fill='none'>
                          <rect y='0.5' width='16' height='16' />
                          <path
                            d='M4 6.5L8 10.5L12 6.5'
                            stroke='black'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </ArrowIcon>
                      {date}
                    </div>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>

                  <TableCell className='right'>
                    {payments.length > 1
                      ? debit.toLocaleString('ru-KZ', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : payments[0].debit.toLocaleString('ru-KZ', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{' '}
                    ₸
                  </TableCell>
                  <TableCell className='right'>
                    {payments.length > 1
                      ? credit.toLocaleString('ru-KZ', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : payments[0].credit.toLocaleString('ru-KZ', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{' '}
                    ₸
                  </TableCell>
                  <TableCell className='right' style={{ color: balance > 0 ? '#5784F7' : 'red' }}>
                    {payments.length > 1
                      ? balance > 0
                        ? '+ '
                        : ''
                      : payments[0].balance > 0
                        ? '+ '
                        : ''}
                    {payments.length > 1
                      ? balance.toLocaleString('ru-KZ', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : payments[0].balance.toLocaleString('ru-KZ', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{' '}
                    ₸
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                {expandedDates[date] &&
                  payments.map((payment) => (
                    <TableRow key={uuidv4()} style={{ backgroundColor: '#f2f2f2' }}>
                      <TableCell>
                        <Checkbox
                          checked={selectedPayments.has(payment.paymentCalendarId)}
                          onChange={() => handlePaymentSelection(payment.paymentCalendarId)}
                        />
                      </TableCell>
                      <TableCell>{formatDate(payment.paymentDate)}</TableCell>
                      <TableCell>
                        <StyledLink to={`/contracts/${payment.contractId}`}>
                          {payment.contractNum}
                        </StyledLink>
                      </TableCell>
                      <TableCell>{payment.paymentTypeName}</TableCell>
                      <TableCell className='right'>
                        {payment.debit.toLocaleString('ru-KZ', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{' '}
                        ₸
                      </TableCell>
                      <TableCell className='right'>
                        {payment.credit.toLocaleString('ru-KZ', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{' '}
                        ₸
                      </TableCell>
                      <TableCell
                        className='right'
                        style={{ color: payment.balance > 0 ? '#5784F7' : 'red' }}>
                        {payment.balance > 0 ? '+ ' : ''}
                        {payment.balance.toLocaleString('ru-KZ', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{' '}
                        ₸
                      </TableCell>
                      <TableCell>
                        <CustomSelectWrapper>
                          <CustomSelect
                            className={`${getStatusClassName(payment.paymentStatus)} ${
                              openSelectId === payment.paymentCalendarId ? 'open' : ''
                            }`}
                            onClick={() =>
                              setOpenSelectId(
                                openSelectId === payment.paymentCalendarId
                                  ? null
                                  : payment.paymentCalendarId,
                              )
                            }>
                            {payment.paymentStatus === 'PAID'
                              ? 'Оплачено'
                              : payment.paymentStatus === 'UNPAID'
                                ? 'Не оплачено'
                                : 'Ожидание'}
                            <span>▾</span>
                          </CustomSelect>
                          {openSelectId === payment.paymentCalendarId && (
                            <CustomOptions>
                              <CustomOption
                                className='paid'
                                onClick={() =>
                                  handleStatusChange(payment.paymentCalendarId, 'PAID')
                                }>
                                Оплачено
                              </CustomOption>
                              <CustomOption
                                className='awaiting'
                                onClick={() =>
                                  handleStatusChange(payment.paymentCalendarId, 'AWAITING_PAYMENT')
                                }>
                                Ожидание
                              </CustomOption>
                              <CustomOption
                                className='unpaid'
                                onClick={() =>
                                  handleStatusChange(payment.paymentCalendarId, 'UNPAID')
                                }>
                                Не оплачено
                              </CustomOption>
                            </CustomOptions>
                          )}
                        </CustomSelectWrapper>
                      </TableCell>
                    </TableRow>
                  ))}
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};
