import React, { useEffect, useState } from 'react';
import {
  Container,
  BoxHeader,
  ButtonBox,
  Title,
  Table,
  TableHeader,
  TableRowHeader,
  TableRow,
  TableCell,
} from './ModuleListStyle';
import { NewButton } from 'components/common/Button';
import axios from 'axios';
import { ModuleDetails } from '../../AdminSettingsInterface';
import { ModuleAddModal } from '../../Modal/moduleModal/ModuleAddModal';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/administration/modules/`;

export const ModuleList = () => {
  const [selectedRows, setSelectedRows] = useState<Record<number, boolean>>({});
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState<ModuleDetails[]>([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  useEffect(() => {
    axios
      .get(BASE_URL, {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data);
        setTableData(response.data);
      })
      .catch((error) => {
        console.error('Ошибка загрузки данных:', error);
      });
  }, []);

  return (
    <Container>
      <Title>Список модулей платформы</Title>
      <BoxHeader>
        <ButtonBox>
          <NewButton
            text='Добавить модуль'
            className='small'
            onClick={() => setIsAddModalOpen(true)}
          />
        </ButtonBox>
      </BoxHeader>
      <Table>
        <thead>
          <TableRowHeader>
            <TableHeader>Название модуля</TableHeader>
            <TableHeader>Путь модуля</TableHeader>
            <TableHeader>Статус модуля</TableHeader>
            <TableHeader></TableHeader>
          </TableRowHeader>
        </thead>
        <tbody>
          {tableData.map((row) => (
            <TableRow key={row.id} $isSelected={!!selectedRows[row.id]}>
              <TableCell>{row.name.text_ru}</TableCell>
              <TableCell>{row.path}</TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
      {isAddModalOpen && <ModuleAddModal onClose={() => setIsAddModalOpen(false)} />}
      {/*<Pagination*/}
      {/*  pageCount={Math.ceil(filteredData.length / rowsPerPage)}*/}
      {/*  currentPage={currentPage}*/}
      {/*  onPageChange={handlePageChange}*/}
      {/*/>*/}
      {/*{isCreateModalOpen && <CreateApplication closeModal={closeModal} />}*/}
    </Container>
  );
};
