import React, { useState } from 'react';
import styled from 'styled-components';
import IconCopy from 'assets/img/IconCopy.svg'; // Ensure you have this icon

interface CopyableTextProps {
  text: string;
  label: string;
}

const CopyableText: React.FC<CopyableTextProps> = ({ text, label }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(text).then(
      () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      },
      (err) => {
        console.error('Could not copy text: ', err);
      },
    );
  };

  return (
    <Wrapper>
      <Text>{label}</Text>
      <Icon src={IconCopy} alt='copy icon' onClick={handleCopyToClipboard} />
      {copied && <CopiedMessage>Скопировано!</CopiedMessage>}
    </Wrapper>
  );
};

export default CopyableText;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

const Text = styled.span``;

const Icon = styled.img`
  cursor: pointer;
  width: 18px;
  height: 18px;
`;

const CopiedMessage = styled.div`
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: #29e584;
  color: #fff;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 16px;
  z-index: 1000;
  transition: opacity 0.5s ease-in-out;
`;
