import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import ScoringIndicator from './ScoringIndicator';
import {
  Container,
  HeaderBox,
  BreadCrumbsBox,
} from 'components/RequestDetailsDraft/RequestDetails.style';
import { TitleBox, Content, Card, Info, Box } from './ScoringInfoInvestor.style';
import { NewButton } from 'components/common/Button';
import RiskGauge from './RiskGauge';
import axios from 'axios';

interface ScoringInfoProps {
  closeModal?: () => void;
  num: string;
  id: string | number;
}

interface ApiResponse {
  badRate: string;
  ball: number;
  riskClass: string;
  defaultRate: string;
}

const data = {
  labels: ['Некредитоспособных субъектов', 'Остальные'],
  datasets: [
    {
      data: [15.7, 100],
      backgroundColor: ['#F8B801', '#01AEB7'],
      borderColor: ['#F8B801', '#01AEB7'],
      borderWidth: 1,
    },
  ],
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
};

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/`;

const ScoringInfoInvestor: React.FC<ScoringInfoProps> = ({ id, num, closeModal }) => {
  const [scoringData, setScoringData] = useState<ApiResponse | null>(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<ApiResponse>(
          `${BASE_URL}api/v1/cabinet/investor/request/creditor/scoring?request=${id}`,
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );

        setScoringData(response.data);
        setIsError(false);
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setIsError(true);
      }
    };

    fetchData();
  }, [id]);

  if (isError) {
    return (
      <Container>
        <HeaderBox>
          <BreadCrumbsBox>
            <p>
              <a href='/applications'>Заявки</a>
            </p>
            <p>{'>'}</p>
            <span onClick={closeModal}>Заявка {num}</span>
            <p>{'>'}</p>
            <p className='select'>Скоринг</p>
          </BreadCrumbsBox>
          <div style={{ marginTop: '20px' }}>Данные отсутствуют</div>
        </HeaderBox>
      </Container>
    );
  }

  if (!scoringData) {
    return <div>Loading...</div>;
  }

  const badRateValue = parseFloat(scoringData.badRate);
  const creditScore = scoringData.ball;
  const riskClassValue = parseInt(scoringData.riskClass.split('_')[1]);
  const defaultRateRange = scoringData.defaultRate.split(' - ').map((rate) => parseFloat(rate));
  const defaultRateAverage = (defaultRateRange[0] + defaultRateRange[1]) / 2 / 100;

  // Update the data for the Pie chart
  const updatedData = {
    ...data,
    datasets: [
      {
        ...data.datasets[0],
        data: [badRateValue, 100 - badRateValue],
      },
    ],
  };

  return (
    <Container>
      <HeaderBox>
        <BreadCrumbsBox>
          <p>
            <a href='/applications'>Заявки</a>
          </p>
          <p>{'>'}</p>
          <span onClick={closeModal}>Заявка {num}</span>
          <p>{'>'}</p>
          <p className='select'>Скоринг</p>
        </BreadCrumbsBox>
        <TitleBox>
          <h2>Скоринг</h2>
          <NewButton onClick={closeModal} text='Назад к заявке' className='white' />
        </TitleBox>
      </HeaderBox>
      <Content>
        <Card>
          <h3>Доля некредитоспособных субъектов</h3>
          <Box>
            <div>
              <div className='right'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'>
                  <circle cx='8' cy='8' r='8' fill='#F8B801' />
                </svg>
                <p>{badRateValue.toFixed(1)} %</p>
              </div>
              <Info>доля некредитоспособных субъектов</Info>
            </div>
            <div style={{ width: '300px', height: '300px' }}>
              <Pie data={updatedData} options={options} />
            </div>
          </Box>
        </Card>
        <Card>
          <h3>Кредитный балл</h3>
          <ScoringIndicator value={creditScore} maxValue={1200} />
        </Card>
        <Card>
          <h3>Класс риска</h3>
          <RiskGauge value={riskClassValue} />
        </Card>
        <Card>
          <h3>Вероятность дефолта</h3>
          <RiskGauge value={defaultRateAverage} isPercentage={true} />
        </Card>
      </Content>
    </Container>
  );
};

export default ScoringInfoInvestor;
