import { Route, Routes } from 'react-router-dom';
import './App.css';
import AdminScreen from 'screens/AdminScreen/AdminScreen';
import EnterSiteScreen from 'screens/EnterSiteScreen/EnterSiteScreen';

const App = () => {
  return (
    <div>
      <Routes>
        <Route path='/*' element={<AdminScreen />} />
        <Route path='login' element={<EnterSiteScreen />} />
      </Routes>
    </div>
  );
};
export default App;
