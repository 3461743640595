import React, { useEffect, useState } from 'react';
import {
  ModalWrapper,
  Title,
  Box,
  BoxInput,
  BoxInputTitle,
  SelectWrapper,
  StyledSelect,
} from './EditModalStyle';
import { Button } from 'components/common/Button';
import {
  OrganizationDetails,
  OrganizationEditDetails,
  OrganizationType,
  WhiteLabel,
} from '../../AdminSettingsInterface';
import * as api from '../../admin-settings.api';
import { Input } from '../../../common/Input/InputText/InputText.style';
import { axiosInstanceJSON } from '../../../ContractBusiness/PenaltyCalculatorModal/axios-instance';

interface EditOrgProps {
  organizationEditDetails: OrganizationDetails | null; // Adjust the type to accept null
  onClose: () => void;
  id: number;
  onUpdateOrganization: (updateOrganization: OrganizationDetails) => void;
}

export const EditOrgModal: React.FC<EditOrgProps> = ({
  organizationEditDetails,
  onClose,
  id,
  onUpdateOrganization,
}) => {
  const [isModal, setIsModal] = useState(true);
  const [editingData, setEditingData] = useState<OrganizationEditDetails | null>(null);
  const [whiteLabels, setWhiteLabels] = useState<WhiteLabel[]>([]);
  const [selectedWhiteLabelIds, setSelectedWhiteLabelIds] = useState<string[]>([]);
  const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

  useEffect(() => {
    if (organizationEditDetails) {
      const updatedEditDetails = {
        ...organizationEditDetails,
        whiteLabels: organizationEditDetails.whiteLabels.map((label) => label.id),
      };
      setEditingData(updatedEditDetails);
    }
  }, [organizationEditDetails]);

  const closeModal = () => {
    setIsModal(false);
    onClose();
  };

  const handleSaveClick = () => {
    if (editingData) {
      const changes: Partial<OrganizationEditDetails> = {};

      if (editingData.iinbin !== organizationEditDetails?.iinbin) {
        changes.iinbin = editingData.iinbin;
      }
      if (editingData.name !== organizationEditDetails?.name) {
        changes.name = editingData.name;
      }
      if (editingData.address !== organizationEditDetails?.address) {
        changes.address = editingData.address;
      }
      if (editingData.email !== organizationEditDetails?.email) {
        changes.email = editingData.email;
      }
      if (editingData.ceoName !== organizationEditDetails?.ceoName) {
        changes.ceoName = editingData.ceoName;
      }
      if (editingData.phone !== organizationEditDetails?.phone) {
        changes.phone = editingData.phone;
      }
      if (editingData.organizationType !== organizationEditDetails?.organizationType) {
        changes.organizationType = editingData.organizationType;
      }
      if (editingData.organizationRole !== organizationEditDetails?.organizationRole) {
        changes.organizationRole = editingData.organizationRole || '';
      }
      if (editingData.whiteLabels !== selectedWhiteLabelIds) {
        changes.whiteLabels = selectedWhiteLabelIds || [];
      }
      api
        .editOrganization(id, changes)
        .then((response) => {
          onUpdateOrganization(response?.data);
        })
        .catch((error: any) => {
          console.error('Error editing organization:', error);
        });
      closeModal();
    }
  };

  const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setEditingData({
      ...editingData,
      organizationRole: e.target.value,
    });
  };

  const handleTypeChange = (value: string): OrganizationType | undefined => {
    switch (value) {
      case 'ИП':
        return OrganizationType.SL;
      case 'ТОО':
        return OrganizationType.LLC;
      default:
        return undefined;
    }
  };

  const getWhiteLabels = async () => {
    try {
      const response = await axiosInstanceJSON.get(
        `${BASE_URL}administration/organizations/white_label`,
      );
      setWhiteLabels(response.data);
    } catch (error) {
      console.error('Ошибка загрузки white labels:', error);
    }
  };

  useEffect(() => {
    getWhiteLabels();
  }, []);

  // Обработка выбора элементов
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOptions = Array.from(event.target.selectedOptions).map((option) => option.value);
    setSelectedWhiteLabelIds(selectedOptions);
  };

  return (
    <>
      {isModal && (
        <ModalWrapper>
          <div className='modal'>
            <Title>Редактирование организации</Title>
            <div className='modal-content'>
              <form>
                <BoxInput>
                  <BoxInputTitle>ИИН/БИН:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData?.iinbin}
                    onChange={(e) => setEditingData({ ...editingData, iinbin: e.target.value })}
                  />
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>Название:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData?.name}
                    onChange={(e) => setEditingData({ ...editingData, name: e.target.value })}
                  />
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>Адрес:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData?.address}
                    onChange={(e) => setEditingData({ ...editingData, address: e.target.value })}
                  />
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>Email:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData?.email}
                    onChange={(e) => setEditingData({ ...editingData, email: e.target.value })}
                  />
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>Телефон:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData?.phone}
                    onChange={(e) => setEditingData({ ...editingData, phone: e.target.value })}
                  />
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>ФИО руководителя:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData?.ceoName}
                    onChange={(e) => setEditingData({ ...editingData, ceoName: e.target.value })}
                  />
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>Роль организации:</BoxInputTitle>
                  <SelectWrapper>
                    <StyledSelect
                      value={editingData?.organizationRole || ''}
                      onChange={handleRoleChange}>
                      <option value='BUSINESS'>Бизнес</option>
                      <option value='INVESTOR'>Инвестор</option>
                      <option value='AGENT'>Агент</option>
                    </StyledSelect>
                  </SelectWrapper>
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>Тип организации:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData?.organizationType === OrganizationType.SL ? 'ИП' : 'ТОО'}
                    onChange={(e) =>
                      setEditingData({
                        ...editingData,
                        organizationType: handleTypeChange(e.target.value),
                      })
                    }
                  />
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>White Labels:</BoxInputTitle>
                  <select
                    multiple
                    onChange={handleSelectChange}
                    style={{
                      width: '100%',
                      height: '200px',
                    }}>
                    {whiteLabels.map((whiteLabel) => (
                      <option key={whiteLabel.id} value={whiteLabel.id}>
                        {whiteLabel.name.text_ru}
                      </option>
                    ))}
                  </select>
                </BoxInput>
                <Box>
                  <Button onClick={handleSaveClick} text={'Сохранить'} />
                  <Button onClick={closeModal} text={'Закрыть'} />
                </Box>
              </form>
            </div>
          </div>
        </ModalWrapper>
      )}
    </>
  );
};
