import React, { useEffect, useState } from 'react';
import { PkbResponseDetails } from 'components/InvestorSettings/pkb/response/PkbResponseInterface';
import * as api from 'components/api/investor-settings.api';
import { PkbResponseNew } from 'components/InvestorSettings/pkb/response/PkbResponseNew';
import { ErrorBox } from './PKBInfo.style';

interface PKBInfoProps {
  closeModal?: () => void;
  num: string;
  id: string | number;
}

export const PKBInfo: React.FC<PKBInfoProps> = ({ id }) => {
  const [responseModal, setResponseModal] = useState<PkbResponseDetails | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getAnswerPkb = async () => {
      try {
        const response = await api.getRequestPkb(id);
        if (response && response.data) {
          setResponseModal(response.data);
          setError(null); // Clear any previous error
        } else {
          setError('Данные отсутствуют');
        }
      } catch (err) {
        setError('Данные отсутствуют');
      }
    };
    getAnswerPkb();
  }, [id]);

  return (
    <>
      {error ? (
        <ErrorBox>{error}</ErrorBox>
      ) : (
        <PkbResponseNew
          status={responseModal?.data?.status ?? 'Ожидается'}
          enterprise={responseModal?.data?.enterprise}
          sources={responseModal?.data?.sources}
        />
      )}
    </>
  );
};
