import React, { useEffect, useState } from 'react';
import { Container, Box, ServiceButton, TextDiv, TextDivBold } from './ProductSettingStyle';
import { Button } from '../../common/Button';
import { ProductSettingDetails } from './ProductSettingInterface';
import ProductModal from '../../modal/product/ProductModal';
import { useTranslation } from 'react-i18next';
import * as api from '../../api/investor-settings.api';

interface ProductProps {
  code: string;
  name: string;
}

export const ProductSettings = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState<ProductProps[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<ProductProps | null>(null);
  const [productDetails, setProductDetails] = useState<ProductSettingDetails | null>(null);
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);

  useEffect(() => {
    api.getUiProducts().then((response) => setProducts(response.data));
  }, []);

  const fetchProductDetails = (productCode: string) => {
    api.getInvestorAttr(productCode).then((response) => setProductDetails(response.data));
  };

  const handleProductClick = (product: ProductProps) => {
    setSelectedProduct(product);
    fetchProductDetails(product.code);
  };

  const handleEditClick = () => {
    setIsProductModalOpen(true);
  };

  return (
    <Container>
      <Box>
        <p>{t('productSetting.title')}</p>
        {products.map((product) => (
          <ServiceButton
            key={product.code}
            $isActive={selectedProduct === product}
            onClick={() => handleProductClick(product)}>
            {product.name}
          </ServiceButton>
        ))}
        {isProductModalOpen && productDetails && (
          <ProductModal
            productDetails={productDetails}
            onClose={() => setIsProductModalOpen(false)}
            fetchProductDetails={fetchProductDetails}
          />
        )}
        {productDetails && (
          <div style={{ paddingTop: '30px', alignSelf: 'center' }}>
            <Button text={'Редактировать'} onClick={handleEditClick} />
          </div>
        )}
      </Box>
      <Box>
        {productDetails && (
          <div>
            <TextDiv>{t('productSetting.details')}:</TextDiv>
            <TextDiv>
              {t('productSetting.name')}: {productDetails.product}
            </TextDiv>
            <TextDiv>
              {t('productSetting.scoring')}: {productDetails.useScoring}
            </TextDiv>
            <TextDiv>
              {t('productSetting.totalLimit')}: {productDetails.totalLimit}
            </TextDiv>
            <TextDiv>
              {t('productSetting.minSum')}: {productDetails.minSum}
            </TextDiv>
            <TextDiv>
              {t('productSetting.maxSum')}: {productDetails.maxSum}
            </TextDiv>
            <TextDiv>
              {t('productSetting.borrowerSum')}: {productDetails.borrowerLimit}
            </TextDiv>
            <TextDiv>
              {t('productSetting.degreeOfRisk')}: {productDetails.degreeOfRisk}
            </TextDiv>
            <TextDiv>
              {t('productSetting.minTerm')}: {productDetails.minTerm}
            </TextDiv>
            <TextDiv>
              {t('productSetting.maxTerm')}: {productDetails.maxTerm}
            </TextDiv>
            <TextDiv>
              {t('productSetting.transactions')}: {productDetails.transactions}
            </TextDiv>
            <div style={{ paddingTop: '10px' }}>
              <TextDivBold>{t('productSetting.truLimit')}:</TextDivBold>
              <ul>
                {productDetails?.truLimit?.map((limit, index) => (
                  <li
                    key={index}
                    style={{ display: 'flex', justifyContent: 'space-between', width: '150px' }}>
                    <TextDiv>
                      {t('productSetting.code')}: {limit.code}
                    </TextDiv>
                    <TextDiv>
                      {t('productSetting.value')}: {limit.value}
                    </TextDiv>
                  </li>
                ))}
              </ul>
            </div>
            <div style={{ paddingTop: '10px' }}>
              <TextDivBold>{t('productSetting.activityLimit')}:</TextDivBold>
              <ul>
                {productDetails?.activityLimit?.map((limit, index) => (
                  <li
                    key={index}
                    style={{ display: 'flex', justifyContent: 'space-between', width: '150px' }}>
                    <TextDiv>
                      {t('productSetting.code')}: {limit.code}
                    </TextDiv>
                    <TextDiv>
                      {t('productSetting.value')}: {limit.value}
                    </TextDiv>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </Box>
    </Container>
  );
};
