import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  padding: 16px;
  background: #f4f4f4;
  flex-direction: column;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
`;
const Header = styled.h4`
  color: var(--primary-300, var(--primary-300, #5784f7));
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;
`;

const ContentBox = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  justify-content: space-between;
`;

export { Container, Header, ContentBox };
