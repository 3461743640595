import styled from 'styled-components';
import EnterBanner from 'assets/img/EnterBanner1.webp';

const Container = styled.div`
  display: flex;
  min-height: 100vh;
`;

const LeftBlock = styled.div`
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

const RightBlock = styled.div`
  flex: 1;
  background-image: url(${EnterBanner});
  background-size: cover;
  background-position: center;
  position: relative;
  padding-left: 5%;
  padding-right: 5%;
  align-items: center;
  display: flex;
`;

export const LogoBox = styled.a`
  margin: 0 auto 54px auto;
`;

const LogoImg = styled.img`
  width: 182px;
  height: 50px;
`;

const WelcomeText = styled.h1`
  color: #343434;
  font-size: 42px;
  font-weight: 700;
  line-height: 121%;
  max-width: 440px;
  margin-bottom: 16px;
`;

const SubText = styled.p`
  color: rgba(67, 67, 67, 0.4);
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 32px;
  max-width: 440px;
`;

const CustomButton = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: #5784f7;
  color: #fff;
  border: none;
  padding: 20px 20px;
  cursor: pointer;
  box-shadow: 0px 4px 10.8px 0px rgba(33, 0, 236, 0.25);
  outline: none;
  border: 0;
  font-size: 20px;
  font-weight: 600;
  transition: background-color 0.3s;
  max-width: 440px;
  position: relative;

  &:hover {
    background: #0f62fe;
  }
  &:active {
    background: #08329e;
  }
  svg {
    position: absolute;
    left: 20px;
  }
`;

const Divider = styled.hr`
  width: 100%;
  border-bottom: 1px dashed #d9d9d9;
  border-top: none;
  border-right: none;
  border-left: none;
  margin: 32px 0;
`;

const DividerBox = styled.div`
  display: flex;
  gap: 26px;
  max-width: 440px;
  color: #d9d9d9;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  align-items: center;
`;

const LinkText = styled.button`
  color: var(--Primary-Button-Hover, #5784f7);
  font-size: 20px;
  cursor: pointer;
  font-weight: 500;
  transition: 0.3s;
  border: none;
  background: none;
  max-width: 440px;

  &:hover {
    color: var(--Primary, #5784f7);
  }
`;

const FAQSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-radius: 8px;
  width: 100%;
`;

const StyledFAQItem = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px 0px rgba(7, 17, 43, 0.07);
  overflow: hidden;
`;

const FAQTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  cursor: pointer;
  color: var(--neutral-900, #141414);
  font-family: Jost;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%; /* 23.193px */
  div {
    display: flex;
    gap: 20px;
    align-items: center;
    img {
      width: 16px;
      height: 16px;
    }
  }
`;

const FAQIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FAQContent = styled.div`
  padding: 0px 24px 24px 24px;
  background-color: #fff;
  display: flex;
  gap: 20px;
  flex-direction: column;
  p {
    color: #5e5e5e;
    font-size: 16px;
    font-weight: 600;
    line-height: 125%; /* 23.193px */
  }
  span {
    color: #0d0d0d;
    font-size: 20px;
    font-weight: 500;
    line-height: 39px; /* 195% */
  }
`;

const StyledLink = styled.a`
  color: var(--primary-300, #5784f7);
  font-size: 18px;
  font-weight: 600;
  line-height: 125%; /* 23.193px */
  text-decoration-line: underline;
`;

export {
  CustomButton,
  Container,
  LeftBlock,
  RightBlock,
  LogoImg,
  WelcomeText,
  Divider,
  DividerBox,
  LinkText,
  SubText,
  ContentLeft,
  FAQSection,
  StyledFAQItem,
  FAQTitle,
  FAQContent,
  FAQIcon,
  StyledLink,
};
