import React from 'react';
import { ModalBackground, ErrorDialog, CloseButton } from './WarningModal.style';
import WarningIcon from 'assets/img/WarningIcon.webp';
import { NewButton } from '../Button';

interface WarningModalProps {
  headerWarning: string;
  textWarning: string;
  buttonClose: string;
  onClick: () => void;
  onClose: () => void;
}

export const WarningModal = ({
  headerWarning,
  textWarning,
  buttonClose,
  onClick,
  onClose,
}: WarningModalProps) => {
  return (
    <ModalBackground>
      <ErrorDialog>
        <CloseButton onClick={onClose}>&times;</CloseButton> {/* Close button */}
        <img src={WarningIcon} alt='icon' />
        <h2>{headerWarning}</h2>
        <p>{textWarning}</p>
        <NewButton text={buttonClose} onClick={onClick} />
      </ErrorDialog>
    </ModalBackground>
  );
};
