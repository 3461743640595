import axios from 'axios';
import { ProductSettingDetails } from '../InvestorSettings/productSettings/ProductSettingInterface';
import { TarifCreateDetails, TarifDetails } from '../InvestorSettings/tarif/TarifInterface';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/`;

// api of products
export const getListProducts = () =>
  axios.get(`${BASE_URL}v1/dictionary/get?name=product`, {
    withCredentials: true,
  });

export const getUiProducts = () =>
  axios.get(`${BASE_URL}v1/ui/products`, {
    withCredentials: true,
  });

export const addProduct = (productCode: string) =>
  axios.get(`${BASE_URL}v1/ui/products/add?code=${productCode}`, {
    withCredentials: true,
  });

//api of product settings
export const getInvestorAttr = (productCode: string) =>
  axios.get(`${BASE_URL}v1/cabinet/investor/attrs?product=${productCode}`, {
    withCredentials: true,
  });

export const postInvestorAttr = (productSettingDetails: ProductSettingDetails) => {
  return axios.post(`${BASE_URL}v1/cabinet/investor/attrs`, productSettingDetails, {
    withCredentials: true,
  });
};

//api of tarif
export const getInvestorTarif = (tarifCode: string) =>
  axios.get(`${BASE_URL}v1/cabinet/investor/tariff?product=${tarifCode}`, {
    withCredentials: true,
  });

export const editInvestorTarif = (tarifDetails: TarifDetails) =>
  axios.post(`${BASE_URL}v1/cabinet/investor/tariff/edit`, tarifDetails, {
    withCredentials: true,
  });

export const addInvestorTarif = (tarifCreateDetails: TarifCreateDetails) =>
  axios.post(`${BASE_URL}v1/cabinet/investor/tariff/add`, tarifCreateDetails, {
    withCredentials: true,
  });

export const getListActivity = () =>
  axios.get(`${BASE_URL}v1/dictionary/get?name=activity`, {
    withCredentials: true,
  });

export const getListCategory = () =>
  axios.get(`${BASE_URL}v1/dictionary/get?name=category`, {
    withCredentials: true,
  });

export const getReqisterPkb = (num: number) =>
  axios.get(`${BASE_URL}v1/cabinet/investor/request/creditor/register?request=${num}`, {
    withCredentials: true,
  });

export const getRequestPkb = (num: number | string) =>
  axios.get(`${BASE_URL}v1/cabinet/investor/request/creditor/info?request=${num}`, {
    withCredentials: true,
  });
