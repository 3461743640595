import { EnterSite } from 'components/EnterSite';
import React from 'react';

const EnterSiteScreen = () => {
  return (
    <div>
      <EnterSite />
    </div>
  );
};

export default EnterSiteScreen;
