import axios from 'axios';
import Cookies from 'js-cookie';

const _axiosInstanceJSON = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + '/api/v1',
  timeout: 5000,
  withCredentials: true,
  headers: {
    'Accept-Language': 'ru',
    'Content-Type': 'application/json;charset=UTF-8',
  },
});

_axiosInstanceJSON.interceptors.request.use((config) => {
  config.headers['Accept-Language'] = Cookies.get('language') || 'ru';
  return config;
});

export const axiosInstanceJSON = _axiosInstanceJSON;
