import styled from 'styled-components';
import colors from 'global_styling/colors';
import DatePicker from 'react-date-picker';

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow-y: auto;
`;

const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
  max-width: 600px;
  border-radius: 20px;
  box-shadow: 0px 21.98182px 43.96363px 0px rgba(30, 34, 72, 0.16);
  max-height: 96vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

const CloseButton = styled.span`
  font-size: 34px;
  cursor: pointer;
  color: ${colors.TEAL};
`;

const Title = styled.h2`
  color: #292929;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px;
`;
const HeaderBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 24px 42px;
`;
const SelectBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
  }
  span {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: var(--Control, #9aa4b0);
  }
`;

const InfoContact = styled.h4`
  color: var(--Control, #9aa4b0);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  height: 100%;
  gap: 18px;
  overflow-y: auto;
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  gap: 12px;
  margin-bottom: 10px;
`;
const ProgressBarContainer = styled.div`
  width: 100%;
  height: 2px;
  background: #dee2e6;
  border-radius: 2px;
  align-items: center;
  display: flex;
`;

const ProgressFill = styled.div<{ percentage: number }>`
  height: 4px;
  width: ${(props) => props.percentage}%;
  background: #01aeb7;
  border-radius: 2px;
`;

const AddNewAccount = styled.p`
  color: #01aeb7;
  font-size: 14px;
  font-weight: 600;
  margin-left: auto;
  cursor: pointer;
`;

const LoadingFileBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const LoadingTitle = styled.label`
  color: var(--Black-Main, #292d32);
  font-size: 20px;
`;

const LoadingButton = styled.label`
  border-radius: 6px;
  background: var(--Primary, #01aeb7);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 194px;
  color: var(--White, #fff);
  font-size: 16px;
  font-weight: 600;
  padding: 12px 12px;
  cursor: pointer;
  gap: 10px;
  &:hover {
    background: #07bec8;
  }
`;

const FilesLength = styled.div`
  color: var(--Black-Main, #292d32);
  font-size: 16px;
  font-weight: 500;
  margin-top: 14px;
  display: flex;
  align-items: center;
  gap: 20px;
  img {
    cursor: pointer;
  }
`;

const LoadingFiles = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  color: var(--Control, #9aa4b0);
  font-size: 14px;
  font-weight: 400;
  gap: 8px;
  li {
    display: flex;
    align-items: center;
  }
`;

const Line = styled.div`
  border: 1px solid #d9d9d9;
  width: 100%;
  margin-top: 20px;
`;

const RequestSuccessfulBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  p {
    color: #292929;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    max-width: 400px;
  }
  img {
    width: 110px;
    height: 110px;
    margin-top: 14px;
  }
`;

const ErrorMessage = styled.h5`
  font-size: 14px;
  font-weight: 400;
  color: #fb4753;
  margin-top: -10px;
`;

const BoxChecks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  p {
    color: var(--Text-Input, #292d32);
    font-size: 14px;
    font-weight: 400;
    line-height: 125%;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  display: none;
  .react-date-picker__wrapper {
    border: none;
    background-color: #f3f3f3;
    padding: 10px;
    border-radius: 8px;
  }

  .react-date-picker__inputGroup {
    input {
      color: #333;
      width: 100%;
    }
  }

  .react-calendar__tile--active {
  }
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 11px 16px;
  border-radius: 6px;
  background: var(--White, #fff);
  border: 1px solid var(--Control, #9aa4b0);
  font-family: 'Jost', sans-serif;
  color: var(--Control, #9aa4b0);
  font-size: 14px;
  line-height: 125%;
  font-weight: 400;
  &:focus {
    outline: none;
    border: 1px solid #01a5ad;
    color: #292d32;
  }
`;

const DataPickerBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  p {
    color: var(--Control, #9aa4b0);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
`;

const DateBox = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
  gap: 12px;
  span {
    color: var(--Control, #9aa4b0);
    font-size: 12px;
    font-weight: 400;
    line-height: 125%; /* 15px */
  }
`;

const ButtonBoxStep4 = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const BoxTag = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
  }
`;

const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 8px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.32px;
  border-radius: 24px;
  cursor: pointer;
  color: var(--default-text-color, #333);
  background: var(--default-bg-color, #e0e0e0);
  transition:
    background-color 0.3s,
    color 0.3s;

  // Hover state
  &:hover {
    color: var(--hover-text-color, #fff);
    background: var(--hover-bg-color, #b0c4de);
  }

  // Selected state
  &.activeGroup {
    color: var(--active-text-color, #fff);
    background: var(--active-bg-color, #00539a);
  }

  &.smartcash {
    color: var(--smartcash-text-color, #021033);
    background: var(--smartcash-bg-color, #fef600);
  }

  &.unimoney {
    color: var(--unimoney-text-color, #dfe8fd);
    background: var(--unimoney-bg-color, #01c0b0);
  }
`;

const Tags = styled.div`
  display: flex;
  gap: 8px;
`;

export {
  Modal,
  ModalContent,
  CloseButton,
  HeaderBox,
  Title,
  SelectBox,
  ContentBox,
  ButtonBox,
  ProgressBarContainer,
  ProgressFill,
  AddNewAccount,
  LoadingFileBox,
  LoadingButton,
  LoadingTitle,
  FilesLength,
  LoadingFiles,
  Line,
  RequestSuccessfulBox,
  InfoContact,
  ErrorMessage,
  BoxChecks,
  StyledDatePicker,
  StyledInput,
  DataPickerBox,
  DateBox,
  ButtonBoxStep4,
  BoxTag,
  Tag,
  Tags,
};
