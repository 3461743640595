import {
  TitleBox,
  Table,
  TableHeader,
  TableCell,
  TableCellHeader,
  Backdrop,
  Modal,
  ModalHeader,
  ModalActions,
  Button,
} from 'components/OffersBusinessList/CompareOffers.style';
import React, { useState } from 'react';
import { InfoOfferProps } from './CompareOffers.interface';
import { BreadCrumbsBox } from 'components/RequestDetailsDraft/RequestDetails.style';
import { NewButton } from 'components/common/Button';
import axios from 'axios';
import { ErrorModal } from 'components/common/ErrorModal';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'contexts/AppContext';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/cabinet/creditor/request/offers/`;

const InfoOffer: React.FC<InfoOfferProps> = ({
  offer1,
  requestId,
  requestNumber,
  requestedAmount,
  requestPeriod,
  requestRate,
  closeCompareMode,
  fetchData,
}) => {
  const navigate = useNavigate();
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [error, setError] = useState('');
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const { userDataLight } = useAppContext();

  const isUserRole =
    userDataLight?.roles.includes('ADMIN') ||
    userDataLight?.roles.includes('MANAGER') ||
    userDataLight?.roles.includes('USER');

  const handleReject = () => {
    setRejectModalOpen(true);
  };

  const rejectUrl = `${BASE_URL}reject/${offer1.id}`;

  const confirmReject = () => {
    setRejectModalOpen(false);
    axios
      .get(rejectUrl, {
        withCredentials: true,
      })
      .then((response) => {
        closeCompareMode();
        fetchData();
      })
      .catch((error) => {
        console.error('Error rejecting the offer', error);
        setError('Ошибка. Пожалуйста, попробуйте еще раз.'); // Set error message
        setErrorDialogOpen(true);
      });
  };

  const acceptOffer = (offerId: any) => {
    const acceptUrl = `${BASE_URL}accept/${offerId}`;
    axios
      .get(acceptUrl, { withCredentials: true })
      .then((response) => {
        navigate(`/contracts/${response.data.contract}`);
      })
      .catch((error) => {
        console.error('Error accepting the offer', error);
        setError('Ошибка. Пожалуйста, попробуйте еще раз.'); // Set error message
        setErrorDialogOpen(true);
      });
  };

  const formatNumber = (num: any) => {
    const number = parseFloat(num.replace(',', '.'));

    if (isNaN(number)) {
      return 'Неверное значение';
    }

    return Number.isInteger(number) ? number : number.toFixed(1);
  };

  return (
    <div>
      <BreadCrumbsBox>
        <p>
          <a href='/applications'>...</a>
        </p>
        <p>{'>'}</p>
        <p>
          <a href={`/req/${requestId}`}>Заявка {requestNumber}</a>
        </p>
        <p>{'>'}</p>
        <p>
          <span onClick={closeCompareMode}>Предложения инвесторов</span>
        </p>
        <p>{'>'}</p>
        <p className='select'>Предложение от инвестора</p>
      </BreadCrumbsBox>
      <TitleBox>
        <h2>Предложение от инвестора</h2>
        <span onClick={closeCompareMode}>К другим предложениям</span>
      </TitleBox>
      <Table>
        <thead>
          <tr>
            <TableHeader></TableHeader>
            <TableHeader>Ваша заявка</TableHeader>
            <TableHeader>{offer1.organizationName}</TableHeader>
          </tr>
        </thead>
        <tbody className='blue'>
          <tr>
            <TableCellHeader>Сумма займа:</TableCellHeader>
            <TableCell>{requestedAmount.toLocaleString('RU-KZ')} тенге</TableCell>
            <TableCell>{offer1.offerSum} тенге</TableCell>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <TableCellHeader>Срок финансирования:</TableCellHeader>
            <TableCell>{requestPeriod} дней</TableCell>
            <TableCell>{offer1.offerPeriod}</TableCell>
          </tr>
        </tbody>
        <tbody className='blue'>
          <tr>
            <TableCellHeader>Срок начала финансирования:</TableCellHeader>
            <TableCell>—</TableCell>
            <TableCell>{offer1.offerTransfer}</TableCell>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <TableCellHeader>Ставка вознаграждения:</TableCellHeader>
            <TableCell>{requestRate} %</TableCell>
            <TableCell>{offer1.offerRate}</TableCell>
          </tr>
        </tbody>
        <tbody className='blue'>
          <tr>
            <TableCellHeader>Пеня за день:</TableCellHeader>
            <TableCell>—</TableCell>
            <TableCell>{offer1.offerPenaltyRate}</TableCell>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <TableCellHeader>Сумма переплаты:</TableCellHeader>
            <TableCell>—</TableCell>
            <TableCell>{formatNumber(offer1.overpaymentSum)}</TableCell>
          </tr>
        </tbody>
        <tbody className='blue'>
          <tr>
            <TableCellHeader>Дополнительные условия:</TableCellHeader>
            <TableCell>—</TableCell>
            <TableCell>{offer1.offerExtra}</TableCell>
          </tr>
        </tbody>
        {isUserRole && (
          <tbody>
            <tr>
              <TableCellHeader></TableCellHeader>
              <TableCell></TableCell>
              <TableCell>
                {offer1.status === 'Принят' ? (
                  <p style={{ color: 'green' }}>Принят</p>
                ) : offer1.status !== 'Отменен заёмщиком' &&
                  offer1.status !== 'Отменён инвестором' ? (
                  <div>
                    <NewButton onClick={() => acceptOffer(offer1.id)} text='Принять договор' />
                    <NewButton onClick={handleReject} text='Отказать' className='red' />
                  </div>
                ) : (
                  <p style={{ color: 'red' }}>Отменена</p>
                )}
              </TableCell>
            </tr>
          </tbody>
        )}
      </Table>
      {isRejectModalOpen && (
        <Backdrop>
          <Modal>
            <ModalHeader>Вы уверены, что хотите отказаться от предложения?</ModalHeader>
            <p>Действия невозвратное, Вы не сможете вернуться к данному предложению.</p>
            <ModalActions>
              <NewButton onClick={confirmReject} text='Отказать' className='red' />
              <Button className='cancel' onClick={() => setRejectModalOpen(false)}>
                Отменить
              </Button>
            </ModalActions>
          </Modal>
        </Backdrop>
      )}
      {errorDialogOpen && (
        <ErrorModal
          headerError='Ошибка'
          textError={error}
          buttonClose={t('loginAndRegister.buttonClose')}
          onClick={() => setErrorDialogOpen(false)}
        />
      )}
    </div>
  );
};

export default InfoOffer;
