import React, { useEffect, useState } from 'react';
import {
  Container,
  ValueText,
  Indicator,
  ScaleLine,
  ScaleContainer,
  BoxScale,
  Value,
  Header,
  MainInfoContent,
  BoxSimple,
  BoxBool,
  BoxRecommended,
} from './InfoEGZNew.style';
import { CustomerConcentrationProps, Indicators, ScoringInfoProps } from './InfoEGZNew.interface';
import {
  ExpandableSection,
  MainInfoBox,
  MainInfoTitle,
  ArrowIcon,
} from 'components/ApplicationInvestor/ApplicationInvestorNew.style';
import CalculateIcon from 'assets/img/CalculateIcon.svg';
import IconArrow from 'assets/img/IconArrow.svg';
import IconArrowDown from 'assets/img/IconArrowDown.svg';
import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

const CustomerConcentration: React.FC<CustomerConcentrationProps> = ({
  min,
  max,
  value,
  header,
}) => {
  const percentage = ((value - min) / (max - min)) * 100;
  let color = '#29E584';
  if (percentage > 75) color = '#FA4D56';
  else if (percentage > 50) color = '#FFC700';
  else if (percentage > 25) color = '#FFC700';

  return (
    <BoxScale>
      <Header>{header}</Header>
      <ScaleContainer>
        <span>{min}</span>
        <ScaleLine>
          <Indicator percentage={percentage} color={color} />
          <ValueText percentage={percentage} color={color}>
            {value}
          </ValueText>
        </ScaleLine>
        <span>{max}</span>
        <Value color={color}>{value}</Value>
      </ScaleContainer>
    </BoxScale>
  );
};

const InfoEGZNew: React.FC<ScoringInfoProps> = ({ id, num, closeModal }) => {
  const [isMainInfoExpanded, setIsMainInfoExpanded] = useState(true);
  const [indicators, setIndicators] = useState<Indicators[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}cabinet/investor/request/creditor/indicators/${id}`,
          { withCredentials: true },
        );
        setIndicators(response.data.indicators);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const renderIndicator = (indicator: Indicators) => {
    switch (indicator.code) {
      case 'EGZ_GROWTH_COEFFICIENT_YEAR':
      case 'EGZ_CONCENTRATION_QUARTER':
      case 'EGZ_CONCENTRATION_CUSTOMER':
      case 'EGZ_CONCENTRATION_MONTH':
        return (
          <CustomerConcentration
            key={indicator.code}
            header={indicator.name}
            min={indicator.minValue}
            max={indicator.maxValue}
            value={indicator.value}
          />
        );
      case 'EGZ_RECOMMEND_SUM_FINANCE':
        return (
          <BoxRecommended key={indicator.code}>
            <span>{indicator.name}</span>
            <span>
              {indicator?.value.toLocaleString('ru-KZ', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </BoxRecommended>
        );
      default:
        if (indicator.type === 'b') {
          const color = indicator.boolValue ? '#2BB774' : '#FA4D56';
          return (
            <BoxBool key={indicator.code} color={color}>
              <span>{indicator.name}</span>
            </BoxBool>
          );
        }
        return (
          <BoxSimple key={indicator.code}>
            <span>{indicator.name}</span>
            <span>
              {indicator?.value.toLocaleString('ru-KZ', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </BoxSimple>
        );
    }
  };

  return (
    <Container>
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsMainInfoExpanded(!isMainInfoExpanded)}>
          <img src={CalculateIcon} alt='icon' />
          Количественные показатели
          <ArrowIcon src={isMainInfoExpanded ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isMainInfoExpanded && (
          <MainInfoBox>
            <MainInfoContent>
              {loading ? (
                <p>Loading...</p>
              ) : indicators.length === 0 ? (
                <p style={{ color: 'red' }}>Данные отсутствуют</p>
              ) : (
                <>
                  {indicators
                    .filter((indicator) =>
                      [
                        'EGZ_GROWTH_COEFFICIENT_YEAR',
                        'EGZ_CONCENTRATION_QUARTER',
                        'EGZ_CONCENTRATION_CUSTOMER',
                        'EGZ_CONCENTRATION_MONTH',
                      ].includes(indicator.code),
                    )
                    .map((indicator) => renderIndicator(indicator))}
                  {indicators
                    .filter((indicator) => indicator.code === 'EGZ_RECOMMEND_SUM_FINANCE')
                    .map((indicator) => renderIndicator(indicator))}
                  {indicators
                    .filter(
                      (indicator) =>
                        ![
                          'EGZ_GROWTH_COEFFICIENT_YEAR',
                          'EGZ_CONCENTRATION_QUARTER',
                          'EGZ_CONCENTRATION_CUSTOMER',
                          'EGZ_CONCENTRATION_MONTH',
                          'EGZ_RECOMMEND_SUM_FINANCE',
                        ].includes(indicator.code),
                    )
                    .map((indicator) => renderIndicator(indicator))}
                </>
              )}
            </MainInfoContent>
          </MainInfoBox>
        )}
      </ExpandableSection>
    </Container>
  );
};

export default InfoEGZNew;
