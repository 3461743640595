const WHITE = '#FFFFFF';
const TEAL = '#01AEB7';
const TEAL_HOVER = '#19d3dd';
const GOLDEN = '#F8B801';
const CHARCOAL = '#292D32';
const GRAY = '#8F91A0';
const LIGHT_GRAY = '#F3F4F6';
const BORDER_GRAY = '#e2e2e2';
const BUTTON_DISABLED = '#ccc';
const SOFT_GRAY = '#eaeaea';
const INPUT_BORDER = '#DCDFE5';
const WARNING = '#FB4753';

const PRIMARY_50 = '#dfe8fd';
const PRIMARY_100 = '#ccdafd';
const PRIMARY_200 = '#a5bdfb';
const PRIMARY_300 = '#5784f7';
const PRIMARY_400 = '#0f62fe';
const PRIMARY_500 = '#0a43d4';
const PRIMARY_600 = '#08329e';
const PRIMARY_700 = '#021033';

const NEUTRAL_50 = '#fefefe';
const NEUTRAL_100 = '#f4f4f4';
const NEUTRAL_200 = '#d8d8d8';
const NEUTRAL_300 = '#bcbcbc';
const NEUTRAL_400 = '#a0a0a0';
const NEUTRAL_500 = '#848484';
const NEUTRAL_600 = '#686868';
const NEUTRAL_700 = '#4c4c4c';
const NEUTRAL_800 = '#303030';
const NEUTRAL_900 = '#141414';
const NEUTRAL_950 = '#060606';

const FUNCTIONAL_POSITIVE = '#29e584';
const FUNCTIONAL_NEGATIVE = '#fa4d56';
const FUNCTIONAL_NEGATIVE_DEEP = '#ca2830';

const CHARTS_BASE_10 = '#ffc700';
const CHARTS_BASE_11 = '#fef600';
const CHARTS_BASE_01 = '#007ea6';
const CHARTS_BASE_02 = '#279e82';
const CHARTS_BASE_03 = '#2bb774';
const CHARTS_BASE_04 = '#01c0b0';
const CHARTS_BASE_05 = '#a35bff';
const CHARTS_BASE_06 = '#bf6cd8';
const CHARTS_BASE_07 = '#6e9bff';
const CHARTS_BASE_08 = '#ff985e';
const CHARTS_BASE_09 = '#37ff56';

const colors = {
  WHITE,
  TEAL,
  TEAL_HOVER,
  GOLDEN,
  CHARCOAL,
  GRAY,
  LIGHT_GRAY,
  BORDER_GRAY,
  BUTTON_DISABLED,
  SOFT_GRAY,
  INPUT_BORDER,
  WARNING,
  PRIMARY_50,
  PRIMARY_100,
  PRIMARY_200,
  PRIMARY_300,
  PRIMARY_400,
  PRIMARY_500,
  PRIMARY_600,
  PRIMARY_700,
  NEUTRAL_50,
  NEUTRAL_100,
  NEUTRAL_200,
  NEUTRAL_300,
  NEUTRAL_400,
  NEUTRAL_500,
  NEUTRAL_600,
  NEUTRAL_700,
  NEUTRAL_800,
  NEUTRAL_900,
  NEUTRAL_950,
  FUNCTIONAL_POSITIVE,
  FUNCTIONAL_NEGATIVE,
  FUNCTIONAL_NEGATIVE_DEEP,
  CHARTS_BASE_10,
  CHARTS_BASE_11,
  CHARTS_BASE_01,
  CHARTS_BASE_02,
  CHARTS_BASE_03,
  CHARTS_BASE_04,
  CHARTS_BASE_05,
  CHARTS_BASE_06,
  CHARTS_BASE_07,
  CHARTS_BASE_08,
  CHARTS_BASE_09,
};

export default colors;
