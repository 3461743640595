import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Container,
  BoxHeader,
  ControlBox,
  Title,
  Table,
  TableHeader,
  TableRowHeader,
  TableRow,
  TableCell,
  StatusCircle,
  StyledLink,
  FilterBox,
  ActionFilterBox,
  BoxInputDescription,
  BoxInput,
  DatePickerWrapper,
  StyledDatePicker,
  BoxInfoInput,
  CalendarIcon,
  BoxDropDown,
  ButtonsBox,
} from '../ApplicationBusinessList/ApplicationBusinessList.style';
import { Pagination } from 'components/Pagination';
import { InputSearch } from 'components/common/Input/InputSearch';
import { useNavigate } from 'react-router-dom';
import { fetchTableInvestorList, Filters } from 'components/api/applicationInvestorListApi';
import { TagsWhiteLabel } from 'components/TagWhiteLabels';
import { default as IconTune } from 'assets/img/IconTune.svg';
import { default as CalendarIcons } from 'assets/img/CalendarIcon.svg';
import { InputNew } from 'components/common/Input/InputNew/InputNew';
import { Checkbox } from 'components/common/Checkbox';
import SelectBox from 'components/common/SelectBox/SelectBox';
import { NewButton } from 'components/common/Button';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { useAppContext } from 'contexts/AppContext';

interface PageChangeEventArgs {
  selected: number;
}

interface CodeClassMap {
  [key: string]: string;
}

interface WhiteLabel {
  code: string;
  name: string;
}

interface TableDataInvestor {
  id: string;
  num: string;
  businessName: string;
  requestedAmount: string;
  approvedAmount: string;
  product: string;
  status: string;
  whiteLabels: { text: string; className: string }[];
}

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

export const ApplicationInvestorList = () => {
  const navigate = useNavigate();
  const { userDataLight } = useAppContext();

  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');

  const [tableData, setTableData] = useState<TableDataInvestor[]>([]);
  const [paginationInfo, setPaginationInfo] = useState({
    totalPages: 0,
    totalElements: 0,
    currentPage: 0,
  });

  const [isFilterBoxOpen, setFilterBoxOpen] = useState(false);

  const [applicationDateFrom, setApplicationDateFrom] = useState<Date | null>(null);
  const [applicationDateTo, setApplicationDateTo] = useState<Date | null>(null);
  const [financingPeriodFrom, setFinancingPeriodFrom] = useState('');
  const [financingPeriodTo, setFinancingPeriodTo] = useState('');
  const [requestedAmountFrom, setRequestedAmountFrom] = useState('');
  const [requestedAmountTo, setRequestedAmountTo] = useState('');
  const [entryThresholdFrom, setEntryThresholdFrom] = useState('');
  const [entryThresholdTo, setEntryThresholdTo] = useState('');
  const [interestRateFrom, setInterestRateFrom] = useState('');
  const [interestRateTo, setInterestRateTo] = useState('');
  const [selectedProduct, setSelectedProduct] = useState<{ code: string; name: string } | null>(
    null,
  );
  const [selectedWhiteLabels, setSelectedWhiteLabels] = useState<{ code: string; name: string }[]>(
    [],
  );
  const [statuses, setStatuses] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState<{ code: string; name: string }[]>([]);
  const [currentFilters, setCurrentFilters] = useState<Filters>({});
  const [inputHasContent, setInputHasContent] = useState({
    dealNumber: false,
    borrower: false,
    requestedAmountFrom: false,
    requestedAmountTo: false,
    entryThresholdFrom: false,
    entryThresholdTo: false,
    interestRateFrom: false,
    interestRateTo: false,
    financingPeriodFrom: false,
    financingPeriodTo: false,
  });

  const [sortConfig, setSortConfig] = useState<{ field: string; direction: 'asc' | 'desc' | null }>(
    {
      field: '',
      direction: null,
    },
  );

  useEffect(() => {
    if (isFilterBoxOpen) {
      const fetchStatuses = async () => {
        try {
          const response = await axios.get(
            `${BASE_URL}dictionary/get?name=request_statuses_investor`,
            {
              withCredentials: true,
            },
          );
          setStatuses(response.data);
        } catch (error) {
          console.error('Ошибка загрузки статусов:', error);
        }
      };

      // Вызов функции
      fetchStatuses();
    }
  }, [isFilterBoxOpen]);

  const applyFilters = async () => {
    const statusCodes = selectedStatuses.map((status) => status.code);
    const whiteLabelsCodes = selectedWhiteLabels.map((status) => status.code);

    const filters: Filters = {
      requestNum: searchValue,
      dateCreateStart: applicationDateFrom?.toISOString().split('T')[0] || undefined,
      dateCreateEnd: applicationDateTo?.toISOString().split('T')[0] || undefined,
      whiteLabels: whiteLabelsCodes,
      products: selectedProduct ? [selectedProduct.code] : [], // Updated to use product code
      statuses: statusCodes,
      minSum: requestedAmountFrom ? parseFloat(requestedAmountFrom) : undefined,
      maxSum: requestedAmountTo ? parseFloat(requestedAmountTo) : undefined,
      minAvailSum: entryThresholdFrom ? parseFloat(entryThresholdFrom) : undefined,
      maxAvailSum: entryThresholdTo ? parseFloat(entryThresholdTo) : undefined,
      minRate: interestRateFrom,
      maxRate: interestRateTo,
      minPeriod: financingPeriodFrom,
      maxPeriod: financingPeriodTo,
    };

    setCurrentFilters(filters);

    try {
      const data = await fetchTableInvestorList(currentPage, filters, sortConfig); // Передаем объект filters
      setTableData(transformData(data.content)); // Обновить данные таблицы
      setPaginationInfo({
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        currentPage: data.number,
      });
    } catch (error) {
      console.error('Ошибка применения фильтров:', error);
    }
  };

  const transformData = (data: any[]): TableDataInvestor[] => {
    const getCodeToClassname = (code: string): string => {
      const codeClassMap: CodeClassMap = {
        OMARKET: 'smartcash',
        ALL: 'all',
      };
      return codeClassMap[code] || code.toLowerCase();
    };

    return data.map((item) => ({
      id: item.id,
      num: item.num,
      businessName: item.businessName,
      requestedAmount:
        item.requestedAmount.toLocaleString('ru-KZ', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) || 'null',
      approvedAmount:
        item.approvedAmount.toLocaleString('ru-KZ', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) || '-',
      product: item.productName,
      status: item.statusName,
      whiteLabels: item.whiteLabels.map((label: WhiteLabel) => ({
        text: label.name,
        className: getCodeToClassname(label.code),
      })),
    }));
  };

  const handleSort = (field: string) => {
    setSortConfig((prevSortConfig) => {
      // Если поле то же, что и текущее, меняем направление
      if (prevSortConfig.field === field) {
        return {
          field,
          direction: prevSortConfig.direction === 'asc' ? 'desc' : 'asc',
        };
      }
      // Если поле новое, сортировка будет по возрастанию
      return { field, direction: 'asc' };
    });
  };

  const fetchData = async (page = 0, filters: Filters = currentFilters) => {
    try {
      const data = await fetchTableInvestorList(page, filters, sortConfig);
      setTableData(transformData(data.content));
      setPaginationInfo({
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        currentPage: data.number,
      });
    } catch (error) {
      console.error('Ошибка загрузки данных:', error);
    }
  };
  useEffect(() => {
    fetchData(currentPage, currentFilters);
  }, [currentPage, currentFilters, sortConfig]);

  const clearFilters = () => {
    setSearchValue('');
    setApplicationDateFrom(null);
    setApplicationDateTo(null);
    setSelectedWhiteLabels([]);
    setSelectedProduct(null);
    setSelectedStatuses([]);
    setRequestedAmountFrom('');
    setRequestedAmountTo('');
    setEntryThresholdFrom('');
    setEntryThresholdTo('');
    setInterestRateFrom('');
    setInterestRateTo('');
    setFinancingPeriodFrom('');
    setFinancingPeriodTo('');
    setSortConfig({ field: '', direction: null }); // Сброс сортировки
    setCurrentFilters({});
    setCurrentPage(0); // Сбрасываем страницу на 0
    fetchData(0, {}); // Загружаем данные для 0-й страницы без фильтров
  };

  const handlePageChange = useCallback(
    ({ selected }: PageChangeEventArgs) => {
      fetchData(selected, currentFilters);
      setCurrentPage(selected);
    },
    [currentFilters, sortConfig],
  );

  const filteredData = useMemo(() => {
    return searchValue
      ? tableData.filter((row) => row.num?.toLowerCase().includes(searchValue.toLowerCase()))
      : tableData;
  }, [searchValue, tableData]);

  const formatAmount = (amount: number | string) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  const toggleFilterBox = () => {
    setFilterBoxOpen((prev) => !prev);
  };

  const handleInputChange = (field: string, value: string) => {
    setInputHasContent((prev) => ({
      ...prev,
      [field]: value !== '',
    }));
  };

  return (
    <Container>
      <Title>Сделки на финансирование</Title>
      <BoxHeader>
        <ControlBox>
          <InputSearch
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder='Поиск Сделок'
            name='searchQuery'
          />
          <FilterBox onClick={toggleFilterBox} isActive={isFilterBoxOpen}>
            <img src={IconTune} alt='icon' />
          </FilterBox>
        </ControlBox>
      </BoxHeader>
      {isFilterBoxOpen && (
        <ActionFilterBox>
          <BoxInputDescription>
            <BoxInput>
              <p>Дата заявки</p>
              <BoxInfoInput>
                <span>С</span>
                <DatePickerWrapper>
                  <StyledDatePicker
                    selected={applicationDateFrom}
                    dateFormat='dd/MM/yyyy'
                    onChange={(date: Date | null) => setApplicationDateFrom(date)}
                    placeholderText='Выберите дату'
                  />
                  <CalendarIcon src={CalendarIcons} alt='calendar icon' />
                </DatePickerWrapper>
                <span>По</span>
                <DatePickerWrapper>
                  <StyledDatePicker
                    selected={applicationDateTo}
                    dateFormat='dd/MM/yyyy'
                    onChange={(date: Date | null) => setApplicationDateTo(date)}
                    placeholderText='Выберите дату'
                  />
                  <CalendarIcon src={CalendarIcons} alt='calendar icon' />
                </DatePickerWrapper>
              </BoxInfoInput>
            </BoxInput>
            <BoxInput>
              <p>Срок финансирования (в днях)</p>
              <BoxInfoInput>
                <span>От</span>
                <InputNew
                  placeholder='1'
                  value={financingPeriodFrom}
                  onChange={(e) => {
                    handleInputChange('financingPeriodFrom', e.target.value);
                    setFinancingPeriodFrom(e.target.value);
                  }}
                  maxWidth='50px'
                />
                <span>До</span>
                <InputNew
                  placeholder='30'
                  value={financingPeriodTo}
                  onChange={(e) => {
                    handleInputChange('financingPeriodTo', e.target.value);
                    setFinancingPeriodTo(e.target.value);
                  }}
                  maxWidth='50px'
                />
              </BoxInfoInput>
            </BoxInput>
          </BoxInputDescription>
          <BoxInputDescription>
            <BoxInput>
              <p>Запрашиваемая сумма</p>
              <BoxInfoInput>
                <span>От</span>
                <InputNew
                  placeholder='2 000 000'
                  value={requestedAmountFrom}
                  onChange={(e) => {
                    handleInputChange('requestedAmountFrom', e.target.value);
                    setRequestedAmountFrom(e.target.value);
                  }}
                  maxWidth='100px'
                />
                <span>До</span>
                <InputNew
                  placeholder='7 000 000'
                  value={requestedAmountTo}
                  onChange={(e) => {
                    handleInputChange('requestedAmountTo', e.target.value);
                    setRequestedAmountTo(e.target.value);
                  }}
                  maxWidth='100px'
                />
              </BoxInfoInput>
            </BoxInput>
            <BoxInput>
              <p>Минимальный порог входа</p>
              <BoxInfoInput>
                <span>От</span>
                <InputNew
                  placeholder='100 000'
                  value={entryThresholdFrom}
                  onChange={(e) => {
                    handleInputChange('entryThresholdFrom', e.target.value);
                    setEntryThresholdFrom(e.target.value);
                  }}
                  maxWidth='100px'
                />
                <span>До</span>
                <InputNew
                  placeholder='200 000'
                  value={entryThresholdTo}
                  onChange={(e) => {
                    handleInputChange('entryThresholdTo', e.target.value);
                    setEntryThresholdTo(e.target.value);
                  }}
                  maxWidth='100px'
                />
              </BoxInfoInput>
            </BoxInput>
            <BoxInput>
              <p>Процентная ставка</p>
              <BoxInfoInput>
                <span>От</span>
                <InputNew
                  placeholder='7%'
                  value={interestRateFrom}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*\.?\d{0,2}$/.test(value)) {
                      handleInputChange('interestRateFrom', value);
                      setInterestRateFrom(value);
                    }
                  }}
                  maxWidth='50px'
                />
                <span>До</span>
                <InputNew
                  placeholder='20%'
                  value={interestRateTo}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*\.?\d{0,2}$/.test(value)) {
                      handleInputChange('interestRateTo', value);
                      setInterestRateTo(value);
                    }
                  }}
                  maxWidth='50px'
                />
              </BoxInfoInput>
            </BoxInput>
          </BoxInputDescription>

          <BoxDropDown>
            <BoxDropDown>
              <SelectBox
                label='Продукт'
                options={[
                  { id: 'LOAN', name: 'Заемное финансирование' },
                  { id: 'TENDER', name: 'Тендерное финансирование' },
                ]}
                selectedOptions={selectedProduct ? [selectedProduct.name] : []}
                onOptionSelect={(option) =>
                  setSelectedProduct(
                    selectedProduct?.code === option.id
                      ? null
                      : { code: option.id, name: option.name },
                  )
                }
              />
            </BoxDropDown>

            <BoxDropDown>
              <SelectBox
                label='Группы'
                options={userDataLight.whiteLabel.map((label: any) => ({
                  id: label.code,
                  name: label.name,
                }))}
                selectedOptions={selectedWhiteLabels.map((status) => status.name)}
                onOptionSelect={(option) =>
                  setSelectedWhiteLabels((prev) =>
                    prev.find((item) => item.code === option.id)
                      ? prev.filter((item) => item.code !== option.id)
                      : [...prev, { code: option.id, name: option.name }],
                  )
                }
              />
            </BoxDropDown>
            <BoxDropDown>
              <SelectBox
                label='Статус'
                options={statuses.map((status: any) => ({
                  id: status.code,
                  name: status.name,
                }))}
                selectedOptions={selectedStatuses.map((status) => status.name)}
                onOptionSelect={(option) =>
                  setSelectedStatuses((prev) =>
                    prev.find((item) => item.code === option.id)
                      ? prev.filter((item) => item.code !== option.id)
                      : [...prev, { code: option.id, name: option.name }],
                  )
                }
              />
            </BoxDropDown>
          </BoxDropDown>
          <ButtonsBox>
            <NewButton text='Сбросить все фильтры' className='white small' onClick={clearFilters} />
            <NewButton text='Применить фильтры' className='small' onClick={applyFilters} />
          </ButtonsBox>
        </ActionFilterBox>
      )}
      <Table>
        <thead>
          <TableRowHeader>
            <TableHeader
              isSorted={sortConfig.field === 'num'}
              direction={sortConfig.field === 'num' ? sortConfig.direction : null}
              onClick={() => handleSort('num')}>
              Номер сделки
            </TableHeader>
            <TableHeader>Заемщик </TableHeader>
            <TableHeader
              isSorted={sortConfig.field === 'requestedAmount'}
              direction={sortConfig.field === 'requestedAmount' ? sortConfig.direction : null}
              onClick={() => handleSort('requestedAmount')}>
              Запрашиваемая сумма
            </TableHeader>
            <TableHeader
              isSorted={sortConfig.field === 'acceptedOffersSum'}
              direction={sortConfig.field === 'acceptedOffersSum' ? sortConfig.direction : null}
              onClick={() => handleSort('acceptedOffersSum')}>
              Одобренная сумма
            </TableHeader>{' '}
            <TableHeader>Группы</TableHeader>
            <TableHeader>Продукт</TableHeader>
            <TableHeader>Статус</TableHeader>
          </TableRowHeader>
        </thead>
        <tbody>
          {filteredData.map((row) => (
            <TableRow key={row.id} onClick={() => navigate(`/request/${row.id}`)}>
              <TableCell>
                <StyledLink to={`/request/${row.id}`}>{row.num}</StyledLink>
              </TableCell>
              <TableCell>{row.businessName}</TableCell>
              <TableCell className='right'>{formatAmount(row.requestedAmount)} ₸</TableCell>
              <TableCell className='right'>{formatAmount(row.approvedAmount)} ₸</TableCell>
              <TableCell>
                <div>
                  {row.whiteLabels.map((label) => (
                    <TagsWhiteLabel
                      key={label.text}
                      text={label.text}
                      className={label.className}
                    />
                  ))}
                </div>
              </TableCell>
              <TableCell>{row.product}</TableCell>
              <TableCell>
                <StatusCircle $status={row.status} />
                {row.status}
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
      <Pagination
        pageCount={Math.max(paginationInfo.totalPages, 1)}
        currentPage={Math.min(currentPage, paginationInfo.totalPages - 1)}
        onPageChange={handlePageChange}
      />
    </Container>
  );
};
