import styled, { css } from 'styled-components';

export const DraftBox = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--functional-negative, #fa4d56);
  background: var(--neutral-50, #fefefe);

  h4 {
    color: var(--neutral-800, var(--neutral-800, #303030));
    font-family: 'IBM Plex Sans';
    font-size: 18px;
    font-weight: 600;
    line-height: 125%;
  }
  p {
    color: var(--neutral-600, var(--neutral-600, #686868));
    font-family: 'IBM Plex Sans';
    font-size: 18px;
    font-weight: 500;
    line-height: 130%;
  }
`;

export const DraftButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 14px;
`;

export const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
  label {
    color: var(--neutral-600, #686868);
    font-size: 18px;
    font-weight: 600;
    line-height: 125%;
  }
`;

export const DropdownHeader = styled.div<{ isOpen: boolean; $hasContent: boolean }>`
  margin-top: 5px;
  padding: 12px 16px;
  border: 1px solid var(--Control, #9aa4b0);
  border-radius: 6px;
  background: var(--White, #fff);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--Control, #9aa4b0);
  font-size: 14px;
  line-height: 125%;
  font-weight: 400;

  ${({ $hasContent }) =>
    $hasContent &&
    css`
      color: var(--neutral-800, #303030);
      border: 1px solid var(--neutral-800, #303030);
    `}
`;

export const DropdownListContainer = styled.div`
  position: absolute;
  width: 100%;
  border: 1px solid var(--Control, #9aa4b0);
  border-radius: 6px;
  background: var(--White, #fff);
  z-index: 1000;
  margin-top: 4px;
  overflow-x: scroll;
  max-height: 110px;
`;

export const DropdownListItem = styled.div`
  padding: 12px;
  cursor: pointer;
  border-bottom: 1px solid var(--neutral-300, #bcbcbc);
  font-size: 14px;
  line-height: 125%;
  font-weight: 400;
  &:hover {
    background: var(--Light, #f5f5f5);
  }
`;

export const DropdownArrow = styled.div<{ isOpen: boolean }>`
  display: inline-block;
  margin-left: 8px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: ${({ isOpen }) => (isOpen ? 'none' : '6px solid #000')};
  border-bottom: ${({ isOpen }) => (isOpen ? '6px solid #000' : 'none')};
`;

export const ErrorTitle = styled.p`
  color: #fa4d56;
  font-size: 12px;
`;

export const AcceptNewOffersNo = styled.p`
  color: #fa4d56;
  font-size: 16px;
`;

export const MainDecorContent = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
`;

export const MainDecorLogo = styled.img`
  max-width: 156px;
  height: 156px;
  border-radius: 8px;
  width: 100%;
`;

export const MainDecorInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: var(--Hover-Gray-60, #5e5e5e);
  font-family: 'IBM Plex Sans';
  font-size: 18px;
  font-weight: 600;
  line-height: 135%;
  p {
    color: var(--neutral-800, #303030);
  }
  div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const InfoDescription = styled.span`
  max-width: 500px;
  overflow: hidden;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 10; /* Number of lines to show */
  -webkit-box-orient: vertical;
`;

export const MainDecorTitle = styled.p`
  color: var(--neutral-800, var(--neutral-800, #303030));
  font-family: 'IBM Plex Sans';
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
`;

export const DocLinkBox = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--neutral-800, #303030);
  font-family: 'IBM Plex Sans';
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;

  a {
    color: blue; /* Traditional link color */
    text-decoration: underline; /* Adds the underline */
    cursor: pointer; /* Changes the cursor to a pointer on hover */
  }
`;
