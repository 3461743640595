import React, { useState } from 'react';
import { Container } from './PkbStyle';
import * as api from 'components/api/investor-settings.api';
import { PkbResponseDetails } from './response/PkbResponseInterface';
import { PkbResponse } from './response/PkbResponse';
import { Button } from '../../common/Button';

export const Pkb = () => {
  const [inputNumber, setInputNumber] = useState(0);
  const [responseModal, setResponseModal] = useState<PkbResponseDetails | null>(null);

  const getAnswerPkb = () => {
    if (inputNumber) {
      api.getRequestPkb(inputNumber).then((response) => {
        setResponseModal(response.data);
      });
    }
  };

  return (
    <Container>
      <div style={{ textAlign: 'center' }}>
        <div style={{ padding: '15px' }}>
          <label htmlFor='inputNumber' style={{ paddingRight: '10px' }}>
            Введите номер запроса
          </label>
          <input
            style={{ width: '50px' }}
            type='text'
            id='inputNumber'
            placeholder={'Номер запроса'}
            value={inputNumber}
            onChange={(e) => setInputNumber(Number(e.target.value))}
          />
        </div>
        <Button text={'Получить'} onClick={getAnswerPkb} />
        {/* <PkbResponse status={responseModal?.status ?? ''} enterprise={responseModal?.enterprise} /> */}
      </div>
    </Container>
  );
};
