import React, { useState } from 'react';
import { PaymentCalendar } from '../ContractBusiness.interface';
import { extractMonth, getFormattedDate, groupByDate, sortCalendar } from '../tools';
import {
  Table,
  ModalClose,
  ModalHeader,
  ModalWrapper,
  TableRow,
  TableCell,
  HeaderCell,
  TableBody,
  TableHeader,
  ButtonContainer,
} from './PenaltyCalculator.style';
import { InputTextType } from '../../common/Input/InputText/InputText';
import { Input } from '../../common/Input/InputText/InputText.style';
import { default as IconPlus } from 'assets/img/IconPlus.svg';
import { Checkbox } from '../../common/Checkbox';
import { NewButton } from '../../common/Button';
import { IPaidPart, IPenaltyCalcItem, IPenaltyForm } from './PenaltyCalculator.interface';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (calculateItems: IPenaltyCalcItem[]) => void;
  contractNumber: string;
  calendar: PaymentCalendar[];
}

export const PenaltyCalculatorForm: React.FC<Props> = (props) => {
  const { isOpen, onClose, onSubmit, contractNumber, calendar } = props;

  const initPenalties = () => {
    return sortCalendar(groupByDate(calendar)).map((item) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return {
        needCalculation: true,
        mayCalculate: new Date(item.paymentDate).getTime() < today.getTime(),
        paymentDate: new Date(item.paymentDate),
        paymentSum: item.paymentSum,
        hasPaidParts: false,
        paidParts: [{ sum: item.paymentSum, paidDate: new Date(item.paymentDate) }],
      };
    });
  };
  const [penalties, setPenalties] = useState<IPenaltyForm[]>(initPenalties());

  const formatNumberWithSpaces = (number: any) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  const removeSpacesFromNumber = (formattedNumber: any) => {
    return formattedNumber.replace(/\s/g, '');
  };

  const updatePenalty = (index: number, newPenalty: IPenaltyForm) => {
    setPenalties((prevPenalties) => {
      return prevPenalties.map((penalty, i) => {
        if (i === index) return newPenalty;
        return penalty;
      });
    });
  };

  const handleNeedCalcChecked = (pIndex: number, penalty: IPenaltyForm) => {
    updatePenalty(pIndex, { ...penalty, needCalculation: !penalty.needCalculation });
  };

  const handlePaidPartChecked = (pIndex: number, penalty: IPenaltyForm) => {
    updatePenalty(pIndex, { ...penalty, hasPaidParts: !penalty.hasPaidParts });
  };

  const handleSumChange = (
    pIndex: number,
    penalty: IPenaltyForm,
    ppIndex: number,
    value: string,
  ) => {
    const cleanedValue = removeSpacesFromNumber(value);
    const updatedPaidParts = penalty.paidParts.map((item: IPaidPart, index: number) => {
      if (index === ppIndex) return { ...item, sum: Number(cleanedValue) };
      return item;
    });
    updatePenalty(pIndex, { ...penalty, paidParts: updatedPaidParts });
  };

  const handleDateChange = (
    pIndex: number,
    penalty: IPenaltyForm,
    ppIndex: number,
    value: string,
  ) => {
    const updatedPaidParts = penalty.paidParts.map((item: IPaidPart, index: number) => {
      if (index === ppIndex) return { ...item, paidDate: new Date(value) };
      return item;
    });
    updatePenalty(pIndex, { ...penalty, paidParts: updatedPaidParts });
  };

  const handleAddClick = (index: number, penalty: IPenaltyForm) => {
    if (penalty.hasPaidParts) {
      updatePenalty(index, {
        ...penalty,
        paidParts: [...penalty.paidParts, { sum: 0, paidDate: new Date() }],
      });
    }
  };

  const handleCalculateClick = () => {
    const calcItems = penalties
      .filter((item) => item.needCalculation)
      .map((item) => {
        const paidParts: IPaidPart[] = item.hasPaidParts
          ? item.paidParts.filter((ppItem) => ppItem.sum && ppItem.sum > 0)
          : [];
        return {
          paymentDate: new Date(item.paymentDate),
          paymentSum: item.paymentSum,
          paidParts: paidParts,
        };
      });
    onSubmit(calcItems);
  };

  return (
    <>
      {isOpen && (
        <ModalWrapper>
          <ModalHeader>
            <h2>Калькулятор пени по договору {contractNumber}</h2>
            <ModalClose onClick={onClose} />
          </ModalHeader>
          <Table>
            <TableHeader>
              <tr>
                <HeaderCell>Месяц выплаты</HeaderCell>
                <HeaderCell>Рассчитывать?</HeaderCell>
                <HeaderCell>Был ли платеж?</HeaderCell>
                <HeaderCell>Сумма выплаты</HeaderCell>
                <HeaderCell>Дата выплаты</HeaderCell>
                <HeaderCell></HeaderCell>
              </tr>
            </TableHeader>
            <TableBody>
              {penalties.map((pItem, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {new Date(pItem.paymentDate).getFullYear()} {extractMonth(pItem.paymentDate)}
                  </TableCell>
                  <TableCell>
                    {pItem.mayCalculate && (
                      <Checkbox
                        checked={pItem.needCalculation}
                        onChange={() => handleNeedCalcChecked(index, pItem)}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {pItem.mayCalculate && (
                      <Checkbox
                        checked={pItem.hasPaidParts}
                        onChange={() => handlePaidPartChecked(index, pItem)}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {pItem.paidParts.map((paidPart, ppIndex) => (
                      <Input
                        value={formatNumberWithSpaces(paidPart.sum)}
                        type={InputTextType.TEXT}
                        style={{ width: '100%' }}
                        onChange={(e) => handleSumChange(index, pItem, ppIndex, e.target.value)}
                        disabled={!pItem.hasPaidParts}
                        $readOnly={!pItem.hasPaidParts}
                        key={ppIndex}
                      />
                    ))}
                  </TableCell>
                  <TableCell>
                    {pItem.paidParts.map((paidPart, ppIndex) => (
                      <Input
                        type='date'
                        value={getFormattedDate(paidPart.paidDate)}
                        style={{ width: '100%' }}
                        key={ppIndex}
                        disabled={!pItem.hasPaidParts}
                        $readOnly={!pItem.hasPaidParts}
                        onChange={(e) => handleDateChange(index, pItem, ppIndex, e.target.value)}
                      />
                    ))}
                  </TableCell>
                  <TableCell>
                    <img
                      src={IconPlus}
                      alt='add_payment'
                      onClick={() => handleAddClick(index, pItem)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <ButtonContainer>
            <NewButton text='Расчитать пеню' onClick={handleCalculateClick} />
          </ButtonContainer>
        </ModalWrapper>
      )}
    </>
  );
};
