import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
`;

export const MainInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px;
  border-radius: 0px 0px 8px 8px;
  background: var(--neutral-50, #fefefe);
  div {
    width: 100%;
    display: flex;
    gap: 20px;
  }
`;

export const MainInfo = styled.div`
  display: flex;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--neutral-800, #303030);
  background: #fff;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  .red {
    color: #fa4d56;
  }
  .green {
    color: #2bb774;
  }
  p {
    color: var(--neutral-800, #303030);
    font-family: 'Jost';
    font-size: 18px;
    font-weight: 600;
    line-height: 130%; /* 24.12px */
  }
`;

const ListScoring = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-top: 20px;
  width: 100%;
  border-radius: 8px;
  background: var(--neutral-50, #fefefe);
  padding: 24px;
  @media (max-width: 1512px) {
    flex-direction: column;
  }
`;

const CardScoring = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  h3 {
    font-family: 'IBM Plex Sans';
    font-size: 16px;
    color: var(--Text-text-primary, #161616);
    font-weight: 600;
    line-height: 22px;
  }
`;

const CardInfo = styled.div`
  display: flex;
  padding: 32px;
  min-width: 461px;
  height: 268px;
  align-items: center;
  gap: 54px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--neutral-50, #fefefe);
  @media (max-width: 1512px) {
    justify-content: space-between;
    gap: 0px;
  }
`;

const CardDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  div {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  p {
    color: #5e5e5e;
    font-size: 14px;
    font-weight: 400;
    line-height: 125%;
  }
`;

const Circle = styled.span<{ color: string }>`
  height: 20px;
  width: 20px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  display: inline-block;
`;

const ChartContainer = styled.div`
  position: relative;
  width: 202px;
  height: 202px;
`;

const CenterText = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h4 {
    color: #0d0d0d;

    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
  }
  p {
    color: #5e5e5e;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
  }
`;

export { ListScoring, CardScoring, CardInfo, CardDescription, Circle, ChartContainer, CenterText };
