import { useState } from 'react';
import axios from 'axios';

export const useModalState = (defaultValue: boolean = false) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultValue || false);

  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  return {
    isOpen,
    setIsOpen,
    toggle,
  };
};

export const useFileDownload = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const BASE_URL = `${process.env.REACT_APP_BASE_URL}/`;

  const downloadFile = async (fileId: string) => {
    try {
      setLoading(true);
      setError(null);

      const response = await axios.get(`${BASE_URL}api/v1/ui/file/${fileId}`, {
        responseType: 'blob',
        withCredentials: true,
      });

      if (response) {
        const file = new Blob([response.data]);
        const contentDisposition = response.headers['content-disposition'];

        let filename = 'document';
        if (contentDisposition) {
          const match = contentDisposition.match(/filename\*?=["']?(?:UTF-8'')?([^"';\n]+)/);
          console.log(match);
          if (match) filename = decodeURIComponent(match[1]);
        }

        const a = document.createElement('a');
        a.href = URL.createObjectURL(file);
        a.target = '_blank';
        a.download = filename;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } catch (error: any) {
      setError(error.message);
      console.error('Error downloading file:', error);
    } finally {
      setLoading(false);
    }
  };

  return { downloadFile, loading, error };
};
