import { PaymentCalendar } from './ContractBusiness.interface';

export interface ModifiedPaymentCalendar {
  paymentDate: string;
  paymentSum: number;
  status?: any;
  id?: any;
}

export const groupByDate = (calendar: PaymentCalendar[]) => {
  const groupedByDate: { [key: string]: number } = {};

  calendar.forEach((payment) => {
    const { paymentDate, paymentSum } = payment;
    // Используйте только дату без времени для группировки
    const dateKey = paymentDate.split('T')[0];
    if (groupedByDate[dateKey]) {
      groupedByDate[dateKey] += paymentSum;
    } else {
      groupedByDate[dateKey] = paymentSum;
    }
  });

  return Object.entries(groupedByDate).map(([date, sum]) => ({
    paymentDate: date,
    paymentSum: sum,
  }));
};

export const sortCalendar = (calendar: ModifiedPaymentCalendar[]) => {
  return calendar.sort((a, b) => {
    return new Date(a.paymentDate).getTime() - new Date(b.paymentDate).getTime();
  });
};

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const extractMonthStr = (dateString: string) => {
  const options: Intl.DateTimeFormatOptions = { month: 'long' };
  const month = new Date(dateString).toLocaleDateString('ru-RU', options);
  return capitalizeFirstLetter(month);
};

export const extractMonth = (date: Date) => {
  const month = date.toLocaleDateString('ru-RU', { month: 'long' });
  return capitalizeFirstLetter(month);
};

export const getFormattedDate = (date: Date) => {
  if (!isNaN(date.getTime())) return date.toISOString().substring(0, 10);
};

export const getDayWord = (days: number) => {
  if (days % 100 >= 11 && days % 100 <= 14) {
    return 'дней';
  }

  switch (days % 10) {
    case 1:
      return 'день';
    case 2:
    case 3:
    case 4:
      return 'дня';
    default:
      return 'дней';
  }
};

export function extractMonthPay(dateString: string): string {
  const date = new Date(dateString);
  const monthNames = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ];
  const day = date.getDate();
  const month = monthNames[date.getMonth()];

  return `${day} ${month}`;
}

export const spacedNumber = (num: number | string): string => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};
