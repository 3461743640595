import React from 'react';
import { ProgressIndicatorContainer, Step } from './ProgressIndicator.style';

interface ProgressIndicatorProps {
  steps: string[];
  currentStep: number;
}

export const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({ steps, currentStep }) => {
  return (
    <ProgressIndicatorContainer>
      {steps.map((step, index) => (
        <Step key={index} active={index <= currentStep} />
      ))}
    </ProgressIndicatorContainer>
  );
};
