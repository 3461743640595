import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, ContentBox, TitleBox, DataBox, MainInfoContent } from './InfoCreditStory.style';
import {
  ExpandableSection,
  MainInfoTitle,
  MainInfoBox,
  ArrowIcon,
} from 'components/ApplicationInvestor/ApplicationInvestorNew.style';
import IconWorkHistory from 'assets/img/IconWorkHistory.svg';
import IconArrow from 'assets/img/IconArrow.svg';
import IconArrowDown from 'assets/img/IconArrowDown.svg';

export interface InfoCreditProps {
  num: string;
  id: string | number;
}

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

const InfoCreditStory: React.FC<InfoCreditProps> = ({ id, num }) => {
  const [creditHistory, setCreditHistory] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isMainInfoExpanded, setIsMainInfoExpanded] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}cabinet/investor/request/creditor/credit_history/${id}`,
          { withCredentials: true },
        );
        setCreditHistory(response.data.getReportResultDTO.cigResultDTO.resultDTO.rootDTO);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching credit history data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const {
    footerDTO: { blockList2, gamblingTrue, gamblingFalse } = {} as any,
    numberOfQueriesDTO = {} as any,
    summaryInformationDTO: {
      existingContractsDTO: { subjectRoleDTOList = [] } = {} as any,
    } = {} as any,
    publicSourcesDTO: { qamqorAlimonyDTO = {} as any } = {} as any,
  } = creditHistory || {};

  const { statusDTO } = qamqorAlimonyDTO || {};

  const borrowerContracts = subjectRoleDTOList
    ? subjectRoleDTOList.find((role: any) => role.type === 'Borrower')
    : {};

  return (
    <Container>
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsMainInfoExpanded(!isMainInfoExpanded)}>
          <img src={IconWorkHistory} alt='icon' />
          Кредитная история
          <ArrowIcon src={isMainInfoExpanded ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isMainInfoExpanded && (
          <MainInfoBox>
            <MainInfoContent>
              <ContentBox>
                <TitleBox>Ограничение доступа</TitleBox>
                <DataBox>
                  Субъект не найден в списке лиц, ограничивавших доступ к своему кредитному отчету
                </DataBox>
              </ContentBox>
              <ContentBox>
                <TitleBox>Игорный бизнес</TitleBox>
                <DataBox>
                  У субъекта присутствуют признаки лица, активно вовлеченного в игорный бизнес
                </DataBox>
              </ContentBox>
              <ContentBox>
                <TitleBox>Количество произведенных участниками ПКБ запросов</TitleBox>
                <DataBox>
                  {numberOfQueriesDTO.title ? numberOfQueriesDTO.value : 'Данные отсутствуют'}
                </DataBox>
              </ContentBox>
              <ContentBox>
                <TitleBox>Алиментщики</TitleBox>
                <DataBox>{statusDTO?.title ? ` ${statusDTO.value}` : 'Данные отсутствуют'}</DataBox>
              </ContentBox>
              <ContentBox>
                <TitleBox>Действующие договора (просроченные)</TitleBox>
                <DataBox>
                  {borrowerContracts?.totalDebtOverdue?.title
                    ? `${borrowerContracts.totalDebtOverdue.title}: ${borrowerContracts.totalDebtOverdue.value}`
                    : 'Данные отсутствуют'}
                </DataBox>
              </ContentBox>
            </MainInfoContent>
          </MainInfoBox>
        )}
      </ExpandableSection>
    </Container>
  );
};

export default InfoCreditStory;
