import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TableContainer = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;
`;

const FixedTableHeader = styled.th`
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
`;

const FixedTableCell = styled.td`
  position: sticky;
  left: 0;
  background-color: #e1fdff;
  z-index: 1;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border-radius: 8px;
  margin-bottom: 32px;

  tbody.blue {
    background-color: #e1fdff;
  }

  thead th {
    padding: 10px;
    border: 1px solid #e3e3e3;
    background: white;
    z-index: 2;
  }

  td,
  th {
    padding: 10px;
    border: 1px solid #e3e3e3;
    text-align: center;
  }

  th {
    background: #f1f1f1;
  }
`;

const TableHeader = styled.th`
  padding: 60px;
  border: 1px solid #e3e3e3;
  color: #0f1419;
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  line-height: 125%;
`;

const TableRowHeader = styled.tr`
  border: none;
`;

const TableCell = styled.td`
  padding: 16px 1px;
  border: 1px solid #e3e3e3;
  font-size: 14px;
  font-weight: 400;
  line-height: 125%;
  text-align: center;
  div {
    display: flex;
    flex-direction: column;
    max-width: 264px;
    width: 100%;
    gap: 8px;
    margin: 0 auto;
    padding: 10px;
  }
`;

const TableCellButton = styled.td`
  padding: 16px 1px;
  border: 1px solid #e3e3e3;
  font-size: 16px;
  font-weight: 400;
  line-height: 125%;
  text-align: center;
  display: flex;
`;

const TableCellHeader = styled.td`
  padding: 16px 24px;
  border: 1px solid #e3e3e3;
  font-size: 16px;
  font-weight: 600;
  line-height: 125%;
`;

const TitleBox = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  h2 {
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
  }
  span {
    color: var(--Primary, #01aeb7);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const Modal = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 460px;
  gap: 20px;
  p {
    font-size: 16px;
  }
`;

const ModalHeader = styled.h2`
  font-size: 16px;
  color: var(--Black-Main, #292d32);
  font-weight: 600;
  line-height: 20px;
`;

const ModalActions = styled.div`
  display: flex;
  margin-top: 20px;
`;

const Button = styled.button`
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;

  &:focus {
    outline: none;
  }

  &.cancel {
    background-color: #ccc;
    color: #333;
  }
`;

const BoxCheck = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
`;

export {
  TitleBox,
  Table,
  TableHeader,
  TableRowHeader,
  TableCell,
  TableCellHeader,
  Backdrop,
  Modal,
  ModalHeader,
  ModalActions,
  Button,
  BoxCheck,
  TableContainer,
  FixedTableHeader,
  FixedTableCell,
  TableCellButton,
  Container,
};
