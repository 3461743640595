import React, { useEffect, useState } from 'react';
import {
  Container,
  Title,
  Table,
  TableHeader,
  TableRowHeader,
  TableRow,
  TableCell,
  ButtonSmallEdit,
} from './TemplateAdminListStyle';
import { TemplateDetails } from '../../AdminSettingsInterface';
import { default as IconThreeDots } from 'assets/img/IconThreeDots.svg';
import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/administration/templates/list`;

export const TemplateAdminList = () => {
  const [selectedRow, setSelectedRow] = useState<TemplateDetails | null>(null);
  const [tableData, setTableData] = useState<TemplateDetails[]>([]);

  useEffect(() => {
    axios
      .get(BASE_URL, {
        withCredentials: true,
      })
      .then((response) => {
        setTableData(response.data);
      })
      .catch((error) => {
        console.error('Ошибка загрузки данных:', error);
      });
  }, []);

  return (
    <Container>
      <Title>Список шаблонов</Title>
      <Table>
        <thead>
          <TableRowHeader>
            <TableHeader>Название</TableHeader>
            <TableHeader>Код</TableHeader>
            <TableHeader>Тип</TableHeader>
            <TableHeader></TableHeader>
          </TableRowHeader>
        </thead>
        <tbody>
          {tableData.map((row) => (
            <TableRow key={row.id} $isSelected={row.id === selectedRow?.id}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.code}</TableCell>
              <TableCell>{row.type}</TableCell>
              <TableCell>
                <ButtonSmallEdit>
                  <img src={IconThreeDots} alt='icon-three' />
                </ButtonSmallEdit>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
      {/*<Pagination*/}
      {/*  pageCount={Math.ceil(filteredData.length / rowsPerPage)}*/}
      {/*  currentPage={currentPage}*/}
      {/*  onPageChange={handlePageChange}*/}
      {/*/>*/}
      {/*{isCreateModalOpen && <CreateApplication closeModal={closeModal} />}*/}
    </Container>
  );
};
