import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  HeaderBox,
  BreadCrumbsBox,
  TitleBox,
  BoxContent,
  SwitcherBox,
  Box,
  TitleButtons,
} from './ContractBusiness.style';
import { InfoContract } from './InfoContract';
import { DocumentContract } from './DocumentContract';
import { ContractSchedule } from './ContractSchedule';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { ContractData } from './ContractBusiness.interface';
import { useAppContext } from 'contexts/AppContext';
import { NewButton } from 'components/common/Button/NewButton';
import { default as IconPlusSlim } from 'assets/img/IconPlusSlim.svg';
import { default as IconCalc } from 'assets/img/IconCalc.svg';
import { default as IconMail } from 'assets/img/IconMail.svg';
import { AVR } from './AVR';
import { useModalState } from '../../utils/hooks';
import { PenaltyCalculatorForm } from './PenaltyCalculatorModal/PenaltyCalculatorForm';
import {
  IPenaltyCalcItem,
  IPenaltyCalcResponse,
} from './PenaltyCalculatorModal/PenaltyCalculator.interface';
import { getCalculations } from './PenaltyCalculatorModal/PenaltyCalculator.api';
import { PenaltyCalculatorResult } from './PenaltyCalculatorModal/PenaltyCalculatorResult';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

export const ContractBusiness = () => {
  const { userDataLight } = useAppContext();
  const { id } = useParams();
  const [active, setActive] = useState('contract');
  const [contractData, setContractData] = useState<ContractData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { isOpen: calcFormIsOpen, toggle: calcFormToggle } = useModalState();
  const { isOpen: calcResultIsOpen, toggle: calcResultToggle } = useModalState();
  const [penaltyCalcData, setPenaltyCalcData] = useState<IPenaltyCalcResponse>();

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const fetchData = async () => {
    if (!userDataLight) return;

    const endpoint =
      userDataLight.organizationRole === 'INVESTOR'
        ? `${BASE_URL}/api/v1/cabinet/investor/contract/get/`
        : `${BASE_URL}/api/v1/cabinet/creditor/contract/get/`;

    try {
      const response = await axios.get(`${endpoint}${id}`, {
        withCredentials: true,
      });
      setContractData(response.data);
      setLoading(false);
    } catch (err) {
      setError('An error occurred while fetching data');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setSelectedFile(file);
    if (file) {
      uploadDocument(file);
    }
  };

  const uploadDocument = async (selectedFile: File) => {
    if (!contractData?.id) return;

    const formData = new FormData();
    formData.append('contract', contractData.id.toString());
    formData.append('file', selectedFile);

    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/cabinet/investor/contract/upload`,
        formData,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      console.log('Загрузка успешна', response.data);
      fetchData();
    } catch (error) {
      console.error('Ошибка при загрузке файла', error);
    }
  };

  const handleClaimLetterClick = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/cabinet/investor/contract/claim_letter?contractId=${contractData?.id}`,
        {
          responseType: 'blob',
          withCredentials: true,
        },
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Претензионное письмо.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      console.error('Error downloading claim letter:', err);
    }
  };

  const handleCalculatePenalty = (calculateItems: IPenaltyCalcItem[]) => {
    if (contractData) {
      const endpoint = userDataLight.organizationRole === 'INVESTOR' ? 'investor' : 'creditor';
      getCalculations(calculateItems, contractData.id, endpoint).then((res) => {
        setPenaltyCalcData(res as IPenaltyCalcResponse);
        calcFormToggle();
        calcResultToggle();
      });
    }
  };

  const handleCalcPenaltyBackClick = () => {
    calcResultToggle();
    calcFormToggle();
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Container>
      {contractData && (
        <>
          <PenaltyCalculatorForm
            isOpen={calcFormIsOpen}
            onClose={calcFormToggle}
            onSubmit={handleCalculatePenalty}
            contractNumber={contractData.contractNum}
            calendar={contractData.paymentCalendar}
          />
          <PenaltyCalculatorResult
            isOpen={calcResultIsOpen}
            onClose={calcResultToggle}
            contractNumber={contractData.contractNum}
            data={penaltyCalcData}
            handleBackClick={handleCalcPenaltyBackClick}
            handleClaimLetterClick={handleClaimLetterClick}
          />
        </>
      )}
      <HeaderBox>
        <BreadCrumbsBox>
          <p>
            <a href='/contracts'>Мои договоры</a>
          </p>{' '}
          <p>{'>'}</p>
          <p>Подписание договора</p>
        </BreadCrumbsBox>
        <TitleBox>
          <div>
            <h2>
              Договор с {userDataLight.organizationRole === 'INVESTOR' ? 'бизнесом' : 'инвестором'}{' '}
            </h2>
            <h2>
              <Link
                style={{ color: '#5784f7' }}
                to={
                  userDataLight.organizationRole === 'INVESTOR'
                    ? `/request/${contractData?.requestInfo.id}`
                    : `/req/${contractData?.requestInfo.id}`
                }>
                заявка {contractData?.requestInfo.num}
              </Link>
            </h2>
          </div>
          <TitleButtons>
            {userDataLight.organizationRole === 'INVESTOR' &&
              !contractData?.investorSigned &&
              !contractData?.businessSigned && (
                <>
                  <input
                    ref={fileInputRef}
                    type='file'
                    accept='application/pdf'
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                  <NewButton
                    text='Загрузить договор'
                    icon={IconPlusSlim}
                    onClick={handleUploadClick}
                  />
                </>
              )}
            <NewButton text='Расчитать пеню' icon={IconCalc} onClick={calcFormToggle} />
            {userDataLight.organizationRole === 'INVESTOR' && (
              <NewButton
                text='Претензионное письмо'
                icon={IconMail}
                onClick={handleClaimLetterClick}
              />
            )}
          </TitleButtons>
        </TitleBox>
      </HeaderBox>
      <Box>
        <BoxContent>
          <SwitcherBox>
            <div className={active === 'info' ? 'active' : ''} onClick={() => setActive('info')}>
              Реквизиты
            </div>
            <div
              className={active === 'contract' ? 'active' : ''}
              onClick={() => setActive('contract')}>
              Договор
            </div>
            <div
              className={active === 'schedule' ? 'active' : ''}
              onClick={() => setActive('schedule')}>
              График погашения
            </div>
            <div className={active === 'avr' ? 'active' : ''} onClick={() => setActive('avr')}>
              АВР
            </div>
          </SwitcherBox>
          <div>
            {active === 'info' && contractData && (
              <InfoContract
                contractSum={contractData.contractSum}
                period={contractData.period}
                ceoNameBusiness={contractData.businessInfo.ceoName}
                iinBusiness={contractData.businessInfo.iinBin}
                nameBusiness={contractData.businessInfo.name}
                addressBusiness={contractData.businessInfo.address}
                phoneBusiness={contractData.businessInfo.phone}
                emailBusiness={contractData.businessInfo.email}
                ibanBusiness={contractData.businessAccountInfo.iban}
                ceoNameInvestor={contractData.investorInfo.ceoName}
                iinInvestor={contractData.investorInfo.iinBin}
                nameInvestor={contractData.investorInfo.name}
                addressInvestor={contractData.investorInfo.address}
                phoneInvestor={contractData.investorInfo.phone}
                emailInvestor={contractData.investorInfo.email}
                ibanInvestor={contractData.investorAccountInfo?.iban ?? ''}
              />
            )}
            {active === 'contract' && contractData && (
              <DocumentContract
                idContract={contractData.id}
                fileUid={contractData.fileUid}
                fileHash={contractData.fileHash}
                signingDtEnd={contractData.signingDtEnd}
                investorName={contractData.investorInfo.name}
                businessName={contractData.businessInfo.name}
                investorSigned={contractData.investorSigned}
                businessSigned={contractData.businessSigned}
                status={contractData.status}
                contractNum={contractData.contractNum}
                onFetchData={fetchData}
              />
            )}
            {active === 'schedule' && contractData && (
              <ContractSchedule
                calendar={contractData.paymentCalendar}
                status={contractData.status}
                ibanInvestor={contractData.investorAccountInfo?.iban ?? ''}
                ibanBusiness={contractData.businessAccountInfo.iban}
                bankInvestor={contractData.investorAccountInfo?.bankName}
                bankBusiness={contractData.businessAccountInfo.bankName}
              />
            )}
            {active === 'avr' && contractData && <AVR idContract={contractData.id} />}
          </div>
        </BoxContent>
      </Box>
    </Container>
  );
};
