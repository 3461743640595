import React, { useEffect, useState } from 'react';
import {
  Container,
  Table,
  TableHeader,
  TableRowHeader,
  TableRow,
  TableCell,
  ButtonGenerateCode,
  TelegramIcon,
  ButtonAddCode,
} from './UserTelegramStyle';
import IconDeleteRed from 'assets/img/IconDeleteRed.svg';
import IconTelegram from '../../../assets/img/IconTelegram.svg';
import { ContactBox } from '../UserCabinet.style';
import { InputText } from '../../common/Input/InputText';
import * as api from './user-telegram.api';

interface UserTelegramDetails {
  id: number;
  chatId: string;
  code: string;
  phone: string;
  userName: string;
}

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/`;

const UserTelegram: React.FC = () => {
  const [newTelegramAccount, setNewTelegramAccount] = useState('');
  const [userTelegramList, setUserTelegramList] = useState<UserTelegramDetails[]>([]);

  console.log(userTelegramList);
  useEffect(() => {
    updateUserTable();
  }, []);

  const deleteAcc = async (id: number) => {
    await api.deleteAcc(id);
    await updateUserTable();
  };

  const updateUserTable = async () => {
    await api.updateUserTelegramList().then((response) => {
      setUserTelegramList(response.data);
    });
  };

  const clickAddAcc = async () => {
    await api.addTelegramAcc(newTelegramAccount);
    await updateUserTable();
  };

  const openTelegramBot = () => {
    const telegramBotUrl = 'https://t.me/moneylink_kz_bot';
    window.open(telegramBotUrl, '_blank');
  };

  return (
    <>
      <Container>
        <h3>Telegram-bot</h3>
        <strong>Добавить аккаунт для Telegram бота:</strong>
        <ContactBox>
          <InputText
            value={newTelegramAccount}
            onChange={(e) => setNewTelegramAccount(e.target.value)}
            placeholder='Добавить аккаунт'
          />
          <ButtonAddCode onClick={clickAddAcc}>➕</ButtonAddCode>
        </ContactBox>
        <strong>Список аккаунтов телеграм:</strong>
        <Table>
          <thead>
            <TableRowHeader>
              <TableHeader>№</TableHeader>
              <TableHeader>Аккаунт</TableHeader>
              <TableHeader>Удалить</TableHeader>
            </TableRowHeader>
          </thead>
          <tbody>
            {userTelegramList?.map((row, index) => (
              <TableRow key={row.chatId}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>@{row.userName}</TableCell>
                <TableCell>
                  <img src={IconDeleteRed} onClick={() => deleteAcc(row.id)} />
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
        <ButtonGenerateCode onClick={openTelegramBot}>
          <TelegramIcon src={IconTelegram} alt='Telegram Icon' />
          Перейти в телеграм бот
        </ButtonGenerateCode>
      </Container>
    </>
  );
};

export default UserTelegram;
