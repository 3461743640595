import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
  background: #f4f4f4;
  gap: 24px;
  height: 100vw;
`;

const HeaderBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BreadCrumbsBox = styled.div`
  display: flex;
  gap: 16px;
  text-align: center;
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  p {
    text-decoration: none;
    color: var(--primary-400, #0f62fe);
    a {
      text-decoration: none;
      color: #5f5f5f;
    }
  }
  span {
    cursor: pointer;
    color: #5f5f5f;
  }
`;

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
  }
`;

const BoxContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;
`;

const SwitcherBox = styled.div<{ activeSection?: string }>`
  display: flex;
  width: 100%;
  div {
    cursor: pointer;
    color: var(--Primary, #5784f7);
    border: 1px solid #5784f7;
    padding: 14px;
    font-family: 'IBM Plex Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.16px;
    border-radius: 2px;
    align-items: center;
    display: flex;
    width: 100%;
    &:not(:last-child) {
      border-right: none;
    }
    &.active {
      color: var(--White, #fff);
      background: var(--Primary, #5784f7);
    }
  }
`;

const Box = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;

const TitleButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export { Container, HeaderBox, TitleBox, BoxContent, SwitcherBox, Box, TitleButtons };
