import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Container, Title, Card } from './SentOffersList.style';
import { Offer, SentOffersListProps } from './SentOffersList.interface';
import { useAppContext } from 'contexts/AppContext';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

export const SentOffersList: React.FC<SentOffersListProps> = ({ ids, num }) => {
  const { userDataLight } = useAppContext();
  const [offers, setOffers] = useState<Offer[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        setIsLoading(true); // Start loading
        const endpoint =
          userDataLight?.organizationRole === 'BUSINESS'
            ? `cabinet/creditor/request/offers/${ids}`
            : `cabinet/investor/request/offer/request/${ids}`;
        const response = await axios.get(`${BASE_URL}${endpoint}`, { withCredentials: true });
        setOffers(response.data);
        setIsLoading(false); // Stop loading after data is fetched
      } catch (error) {
        console.error('Failed to fetch offers', error);
        setIsLoading(false); // Stop loading if an error occurs
      }
    };

    fetchOffers();
  }, [ids, userDataLight]);

  const getCardStyle = (status: string) => {
    if (status.includes('REJECT')) {
      return {
        border: '1px solid var(--functional-negative, #FA4D56)',
        background: '#FFEFF0',
      };
    } else if (status.includes('ACTIVE')) {
      return {
        border: '1px solid var(--neutral-400, #A0A0A0)',
        background: 'var(--neutral-200, #D8D8D8)',
      };
    } else if (status.includes('ACCEPT')) {
      return {
        border: '1px solid var(--functional-positive, #29E584)',
        background: '#C6FFC8',
      };
    }
  };

  const handleCardClick = (offerId: number) => {
    if (userDataLight?.organizationRole !== 'BUSINESS') {
      navigate(`/suggestions/${offerId}`);
    }
  };

  return (
    <Container>
      <Title>Мои предложения по сделке {num}</Title>
      {isLoading ? (
        <p>Loading ....</p>
      ) : offers.length === 0 ? (
        <p>Предложений нет</p>
      ) : (
        offers.map((offer) => (
          <Card
            key={offer.id}
            style={getCardStyle(offer.status)}
            onClick={() => handleCardClick(offer.id)}>
            <div>
              <p>Сумма</p>
              {offer.offerSum.toLocaleString()}
            </div>
            <div>
              <p>Ставка</p>
              {offer.offerRate} %
            </div>
            <div>
              <p>Срок</p>
              {offer.offerPeriod} д
            </div>
          </Card>
        ))
      )}
    </Container>
  );
};
