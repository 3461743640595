import {
  Title,
  BoxHeader,
  ControlBox,
  Container,
  Table,
  TableRowHeader,
  TableHeader,
  TableRow,
  TableCell,
  StatusCircle,
} from 'components/ApplicationBusinessList/ApplicationBusinessList.style';
import React, { useEffect, useMemo, useState } from 'react';
import { InputSearch } from 'components/common/Input/InputSearch';
import { Pagination } from 'components/Pagination';
import { default as IconRedTimer } from 'assets/img/IconRedTimer.svg';
import { default as IconBlackTimer } from 'assets/img/IconBlackTimer.svg';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { SuggestionsInterface } from './SuggestionsInvestorList.interface';

interface PageChangeEventArgs {
  selected: number;
}

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/`;

const SuggestionsInvestorList = () => {
  const [searchValue, setSearchValue] = useState('');
  const [tableData, setTableData] = useState<SuggestionsInterface[]>([]);
  const rowsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationInfo, setPaginationInfo] = useState({
    totalPages: 0,
    totalElements: 0,
    currentPage: 0,
  });

  const navigate = useNavigate();

  const transformedData = (data: any[]): SuggestionsInterface[] => {
    return data.map((item) => ({
      id: item.id,
      request: item.request,
      requestNum: item.requestNum,
      requestDtEnd: item.requestDtEnd,
      offerSum: item.offerSum.toLocaleString('ru-KZ', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      offerTransfer: item.offerTransfer,
      offerPeriod: item.offerPeriod,
      offerRate: item.offerRate.toLocaleString('ru-KZ', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      offerPenaltyRate: item.offerPenaltyRate.toLocaleString('ru-KZ', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      extraInfo: item.extraInfo,
      statusName: item.statusName || 'Не указан',
      overpaymentSum: item.overpaymentSum.toLocaleString('ru-KZ', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      offerExtra: item.offerExtra || '',
      status: item.status || null,
      requestInfo: item.requestInfo || null,
    }));
  };

  const fetchData = (page = 0) => {
    console.log('fetchData called for page:', page);

    axios
      .get(`${BASE_URL}api/v1/cabinet/investor/request/offer?page=${page}`, {
        withCredentials: true,
      })
      .then((response) => {
        setTableData(transformedData(response.data.content));
        setPaginationInfo({
          totalPages: Math.max(response.data.totalPages, 1),
          totalElements: response.data.totalElements,
          currentPage: response.data.number,
        });
      })
      .catch((error) => {
        console.error('Ошибка загрузки данных:', error);
      });
  };

  useEffect(() => {
    console.log('useEffect with empty deps array called');
    fetchData();
  }, []);

  const handlePageChange = ({ selected }: PageChangeEventArgs) => {
    fetchData(selected);
    setCurrentPage(selected);
  };

  const offset = currentPage * rowsPerPage;

  const filteredData = useMemo(() => {
    if (searchValue) {
      return tableData.filter((row) => row.requestNum.toString().includes(searchValue));
    }
    return tableData;
  }, [searchValue, tableData]);

  const calculateRemainingTime = (endDateString: string) => {
    const endDate = new Date(endDateString);
    const now = new Date();
    const timeDiff = endDate.getTime() - now.getTime();

    if (timeDiff <= 0) {
      return { text: 'Время истекло', expired: true };
    }

    const daysRemaining = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    if (daysRemaining >= 1) {
      return { text: `${daysRemaining} ${daysRemaining === 1 ? 'день' : 'д'}`, expired: false };
    }

    const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

    return { text: `${hours}:${minutes}`, expired: false };
  };

  return (
    <Container>
      <Title>Мои предложения</Title>
      <BoxHeader>
        <ControlBox>
          <InputSearch
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder='Поиск Сделок'
            name='searchQuery'
          />
        </ControlBox>
      </BoxHeader>
      <Table>
        <thead>
          <TableRowHeader>
            <TableHeader>Номер сделки</TableHeader>
            <TableHeader>Cумма займа</TableHeader>
            <TableHeader>Срок финансирования</TableHeader>
            <TableHeader>Начало финансирования</TableHeader>
            <TableHeader>Месячная ставка</TableHeader>
            <TableHeader>Пеня за день</TableHeader>
            <TableHeader>Доход</TableHeader>
            <TableHeader>Срок завершения</TableHeader>
            <TableHeader>Статус предложения</TableHeader>
          </TableRowHeader>
        </thead>
        <tbody>
          {filteredData.map((row) => {
            const { text: remainingTime, expired } = calculateRemainingTime(row.requestDtEnd);
            const isLessThanADay = remainingTime.includes(':');

            return (
              <TableRow key={row.id} onClick={() => navigate(`/suggestions/${row.id}`)}>
                {' '}
                <TableCell>
                  <Link
                    style={{ color: '#5784F7', textDecoration: 'none' }}
                    to={`/request/${row.request}`}>
                    {row.requestNum}
                  </Link>
                </TableCell>
                <TableCell className='right'>{row.offerSum} ₸</TableCell>
                <TableCell className='right'>{row.offerPeriod}</TableCell>
                <TableCell className='right'>{row.offerTransfer}</TableCell>
                <TableCell className='right'>{row.offerRate} %</TableCell>
                <TableCell className='right'>{row.offerPenaltyRate} %</TableCell>
                <TableCell className='right'>{row.overpaymentSum} ₸</TableCell>
                <TableCell style={{ color: expired ? 'red' : 'inherit' }}>
                  <div>
                    <img src={expired ? IconRedTimer : IconBlackTimer} alt='Timer Icon' />
                    {remainingTime}
                  </div>
                </TableCell>
                <TableCell>
                  <StatusCircle $status={row.statusName || ''} />
                  {row.statusName}
                </TableCell>
              </TableRow>
            );
          })}
        </tbody>
      </Table>
      <Pagination
        pageCount={Math.max(paginationInfo.totalPages, 1)}
        currentPage={Math.min(currentPage, paginationInfo.totalPages - 1)}
        onPageChange={handlePageChange}
      />
    </Container>
  );
};

export default SuggestionsInvestorList;
