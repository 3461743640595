import { BreadCrumbsBox } from 'components/RequestDetailsDraft/RequestDetails.style';
import React, { useEffect, useMemo, useState } from 'react';
import { TitleBox } from './OffersBusinessList.style';
import {
  ActionBox,
  IconWrapper,
  StatusCircle,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TableRowHeader,
} from 'components/ApplicationBusinessList/ApplicationBusinessList.style';
import { Checkbox } from 'components/common/Checkbox';
import { default as IconVerticalPoints } from 'assets/img/IconVerticalPoints.svg';
import axios from 'axios';
import { TableData, Request, OffersBusinessListProps } from './OffersBusinessList.interface';
import { Pagination } from 'components/Pagination';
import { formatDate } from 'utils/formatDate';
import CompareOffers from './CompareOffers';
import InfoOffer from './InfoOffer';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/cabinet/creditor/request/offers/`;

export const OffersBusinessList: React.FC<OffersBusinessListProps> = ({
  requestNumber,
  requestId,
  requestedAmount,
  requestPeriod,
  requestRate,
}) => {
  const [selectedRows, setSelectedRows] = useState<Record<number, boolean>>({});
  const rowsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [activeMenuRowId, setActiveMenuRowId] = useState<number | null>(null);
  const [isCompareMode, setIsCompareMode] = useState(false);
  const [openOfferId, setOpenOfferId] = useState<number | null>(null);

  const fetchData = () => {
    axios
      .get<Request[]>(`${BASE_URL}${requestId}`, {
        withCredentials: true,
      })
      .then((response) => {
        const transformedData: TableData[] = response.data
          .sort((a: Request, b: Request) => b.id - a.id)
          .map((item: Request) => ({
            id: item.id,
            offerPeriod: `${item.offerPeriod} дней`,
            offerSum: item.offerSum.toLocaleString('ru-KZ', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
            dtCreate: formatDate(item.dtCreate),
            offerRate: `${item.offerRate.toLocaleString('ru-KZ', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} %`,
            offerPenaltyRate: `${item.offerPenaltyRate.toLocaleString('ru-KZ', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} %`,
            overpaymentSum: item.overpaymentSum.toLocaleString('ru-KZ', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
            organizationName: item.organizationName,
            offerTransfer: `${item.offerTransfer} дней`,
            offerExtra: item.offerExtra,
            organizationType: item.organizationType,
            approvedAmount: item.approvedAmount
              ? item.approvedAmount.toLocaleString('ru-KZ')
              : 'null',
            status: item.statusName,
          }));

        setTableData(transformedData);
      })
      .catch((error) => {
        console.error('Ошибка загрузки данных:', error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleOpenOffer = (id: number) => {
    setOpenOfferId(id);
  };

  const offset = currentPage * rowsPerPage;
  const currentTableData = tableData.slice(offset, offset + rowsPerPage);

  const handleHeaderCheckbox = () => {
    const allCurrentRowsSelected = currentTableData.every((row) => selectedRows[row.id]);

    if (allCurrentRowsSelected) {
      const newSelectedRows = { ...selectedRows };
      currentTableData.forEach((row) => {
        delete newSelectedRows[row.id];
      });
      setSelectedRows(newSelectedRows);
    } else {
      const newSelectedRows: Record<number, boolean> = { ...selectedRows };
      currentTableData.forEach((row) => {
        newSelectedRows[row.id] = true;
      });
      setSelectedRows(newSelectedRows);
    }
  };

  const handleRowCheckbox = (id: number) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = !prevSelectedRows[id];
      if (!isSelected && id === activeMenuRowId) {
        // Если чекбокс убран и это активная строка меню, закрываем меню
        setActiveMenuRowId(null);
      }
      return { ...prevSelectedRows, [id]: isSelected };
    });
  };

  const toggleMenuForRow = (id: number) => {
    if (activeMenuRowId === id) {
      setActiveMenuRowId(null);
    } else {
      setActiveMenuRowId(id);
    }
  };

  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
  };

  const selectedRowCount = useMemo(() => {
    return Object.values(selectedRows).filter(Boolean).length;
  }, [selectedRows]);

  const handleOpenSelectedOffer = () => {
    const selectedIds = Object.entries(selectedRows).filter(([_, isSelected]) => isSelected);
    if (selectedIds.length === 1) {
      const [selectedId] = selectedIds[0];
      setOpenOfferId(Number(selectedId));
    }
  };

  const actionBasedOnSelection = useMemo(() => {
    if (selectedRowCount === 1) {
      return <li onClick={handleOpenSelectedOffer}>Открыть</li>;
    } else if (selectedRowCount >= 1) {
      return <li onClick={() => setIsCompareMode(true)}>Сравнить</li>;
    }
    return null;
  }, [selectedRowCount, handleOpenSelectedOffer]);

  const selectedOffers = tableData.filter((row) => selectedRows[row.id]);

  const closeCompareMode = () => {
    setIsCompareMode(false);
  };

  const renderInfoOffer = () => {
    if (openOfferId !== null) {
      const selectedOffer = tableData.find((row) => row.id === openOfferId);
      if (selectedOffer) {
        return (
          <InfoOffer
            offer1={selectedOffer}
            requestId={requestId}
            requestNumber={requestNumber}
            requestPeriod={requestPeriod}
            requestedAmount={requestedAmount}
            requestRate={requestRate}
            closeCompareMode={() => setOpenOfferId(null)}
            fetchData={() => fetchData()}
          />
        );
      }
    }
    return null;
  };

  return (
    <div>
      {isCompareMode && selectedOffers.length > 1 ? (
        <CompareOffers
          offers={selectedOffers}
          requestId={requestId}
          requestNumber={requestNumber}
          requestPeriod={requestPeriod}
          requestedAmount={requestedAmount}
          requestRate={requestRate}
          closeCompareMode={closeCompareMode}
          fetchData={() => fetchData()}
        />
      ) : (
        renderInfoOffer() || (
          <>
            <BreadCrumbsBox>
              <p>
                <a href='/applications'>Заявки</a>
              </p>
              <p>{'>'}</p>
              <p>
                <a href={`/req/${requestId}`}>Заявка {requestNumber}</a>
              </p>
              <p>{'>'}</p>
              <p className='select'>Предложения инвесторов</p>
            </BreadCrumbsBox>
            <TitleBox>
              <h2>
                Предложения инвесторов по
                <a href={`/req/${requestId}`}> заявке {requestNumber}</a>
              </h2>{' '}
            </TitleBox>
            <Table>
              <thead>
                <TableRowHeader>
                  <TableHeader>
                    <Checkbox
                      checked={currentTableData.every((row) => selectedRows[row.id])}
                      onChange={handleHeaderCheckbox}
                    />
                  </TableHeader>
                  <TableHeader>Инвестор</TableHeader>
                  <TableHeader>Сумма займа</TableHeader>
                  <TableHeader>Срок финансирования</TableHeader>
                  <TableHeader>Начало финансирования</TableHeader>
                  <TableHeader>Месячная ставка (%)</TableHeader>
                  <TableHeader>Пеня за день (%)</TableHeader>
                  <TableHeader>Сумма переплаты</TableHeader>
                  <TableHeader>Статус</TableHeader>
                  <TableHeader></TableHeader>
                </TableRowHeader>
              </thead>
              <tbody>
                {currentTableData.map((row) => (
                  <TableRow
                    key={row.id}
                    $isSelected={!!selectedRows[row.id]}
                    onClick={() => handleOpenOffer(row.id)}>
                    <TableCell onClick={(e) => e.stopPropagation()}>
                      <Checkbox
                        checked={!!selectedRows[row.id]}
                        onChange={() => handleRowCheckbox(row.id)}
                      />
                    </TableCell>
                    <TableCell>{row.organizationName}</TableCell>
                    <TableCell className='right'>{row.offerSum} ₸</TableCell>
                    <TableCell>{row.offerPeriod}</TableCell>
                    <TableCell>{row.offerTransfer}</TableCell>
                    <TableCell className='right'>{row.offerRate}</TableCell>
                    <TableCell className='right'>{row.offerPenaltyRate}</TableCell>
                    <TableCell className='right'>{row.overpaymentSum} ₸</TableCell>
                    <TableCell>
                      <StatusCircle $status={row.status} />
                      {row.status}
                    </TableCell>
                    <TableCell onClick={(e) => e.stopPropagation()}>
                      <IconWrapper
                        onClick={() => toggleMenuForRow(row.id)}
                        style={{ pointerEvents: selectedRows[row.id] ? 'auto' : 'none' }}>
                        {!!selectedRows[row.id] && <img src={IconVerticalPoints} alt='Icon' />}
                        {activeMenuRowId === row.id && (
                          <ActionBox>{actionBasedOnSelection}</ActionBox>
                        )}
                      </IconWrapper>
                    </TableCell>
                  </TableRow>
                ))}
              </tbody>
            </Table>
            <Pagination
              pageCount={Math.ceil(tableData.length / rowsPerPage)}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </>
        )
      )}
    </div>
  );
};
