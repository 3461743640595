import React, { useEffect, useState } from 'react';
import { InputText } from 'components/common/Input/InputText';
import { KBE, MyAccount } from './types';
import * as api from 'components/api/bankAccount.api';
import { ContactBox } from 'components/OrganizationCabinet/OrganizationCabinet.style';
import { ContentBox, ModalBackground, ModalContent, OrganizationInfo } from './BankAccount.style';
import { SelectNew } from 'components/common/Input/SelectNew';
import { NewButton } from 'components/common/Button';
import IconDelete from 'assets/img/IconDelete.svg';
import { Button, ModalActions } from '../OffersBusinessList/CompareOffers.style';
import { returnBill } from 'components/api/bankAccount.api';

export const BankAccount = () => {
  const [myAccounts, setMyAccounts] = useState<MyAccount[]>([]);
  const [kbes, setKBEs] = useState<KBE[]>([]);
  const [selectedKBE, setSelectedKBE] = useState<number | null>(null);
  const [accountNumber, setAccountNumber] = useState<string>('');
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isLoadingKBE, setIsLoading] = useState(false);
  const [showDeletedModal, setShowDeletedModal] = useState(false);

  useEffect(() => {
    api.getKBEs().then((response) => setKBEs(response.data));
    api.getMyAccounts().then((response) => setMyAccounts(response.data));
  }, []);

  const kbeOptions = kbes.map((kbe) => ({
    value: kbe.id.toString(),
    label: kbe.name,
  }));

  const deleteAccount = (id: number) => {
    api.deleteAccount(id).then(() => {
      setMyAccounts((prevAccounts) => prevAccounts.filter((account) => account.id !== id));
    });
  };

  const addAccount = async () => {
    try {
      const response = await api.addAccount(selectedKBE, accountNumber);
      setMyAccounts([...myAccounts, response.data]);
    } catch (error: any) {
      if (error.response.data.detail == 'IBAN_ALREADY_DELETED') {
        setShowDeletedModal(true);
      }
    }
  };
  const isFormComplete = selectedKBE !== null && accountNumber.length === 20;

  const toggleSelect = () => {
    setIsSelectOpen(!isSelectOpen);
  };

  const handleReturnBill = async () => {
    try {
      await returnBill(accountNumber);
      const updatedAccounts = await api.getMyAccounts();
      setMyAccounts(updatedAccounts.data);
      setShowDeletedModal(false);
    } catch (error) {
      console.error('Ошибка при возврате счета:', error);
    }
  };

  return (
    <>
      <ContentBox>
        <h3>Мои счета:</h3>
        {myAccounts.map((account) => (
          <li key={account.id}>
            {account.iban}
            <img
              src={IconDelete}
              alt='Удалить'
              onClick={() => deleteAccount(account.id)}
              style={{ marginLeft: '8px', cursor: 'pointer' }}
            />
          </li>
        ))}
        <OrganizationInfo className='col'>
          <strong>Добавить счет:</strong>
          <ContactBox>
            <InputText
              placeholder='20 значный IBAN'
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
            />
          </ContactBox>
        </OrganizationInfo>
        <OrganizationInfo className='col'>
          <strong>Выбрать КБе:</strong>
          <ContactBox>
            <SelectNew
              options={kbeOptions}
              onOptionChange={(label) => {
                const matchedKBE = kbes.find((kbe) => kbe.name === label);
                if (matchedKBE) {
                  setSelectedKBE(matchedKBE.id);
                }
              }}
              selectedValue={
                selectedKBE ? kbes.find((kbe) => kbe.id === selectedKBE)?.name ?? null : null
              }
              isOpen={isSelectOpen}
              onToggleOpen={toggleSelect}
            />
          </ContactBox>
        </OrganizationInfo>
        <NewButton
          className='white'
          text='Добавить счет'
          onClick={addAccount}
          disabled={!isFormComplete}
        />
        {showDeletedModal && (
          <ModalBackground>
            <ModalContent>
              <h2>Вы действительно хотите вернуть ранее удаленный счет?</h2>
              <ModalActions>
                <Button onClick={handleReturnBill}>Да</Button>
                <Button onClick={() => setShowDeletedModal(false)}>Нет</Button>
              </ModalActions>
            </ModalContent>
          </ModalBackground>
        )}
      </ContentBox>
    </>
  );
};
