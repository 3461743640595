import { styled } from 'styled-components';

const ButtonStyle = styled.button`
  border-radius: 62px;
  background: #01aeb7;
  border: none;
  padding: 16px 28px;
  cursor: pointer;
  align-self: center;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &:hover {
    background: #19d3dd;
  }
  &.white {
    border: 1px solid #292d32;
    color: #292d32;
    background: none;
    &:hover {
      border: 1px solid var(--light-grey, #eaeaea);
    }
  }
  &.yellow {
    border: none;
    background: #f8b801;
    color: #fff;
    padding: 16px 38px;
    &:hover {
      background: #ffce41;
    }
  }
`;

export { ButtonStyle };
