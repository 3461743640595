import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Container,
  BoxHeader,
  ControlBox,
  ButtonBox,
  Title,
  Table,
  TableHeader,
  TableRowHeader,
  TableRow,
  TableCell,
  StatusCircle,
  StyledLink,
  FilterBox,
  ActionFilterBox,
  BoxInputDescription,
  BoxInfoInput,
  StyledDatePicker,
  DatePickerWrapper,
  CalendarIcon,
  BoxDropDown,
  ButtonsBox,
  BoxInput,
} from './ApplicationBusinessList.style';
import { NewButton } from 'components/common/Button';
import { Pagination } from 'components/Pagination';
import { InputSearch } from 'components/common/Input/InputSearch';
import { useNavigate } from 'react-router-dom';
import { TableData } from './ApplicationBusinessList.interface';
import { fetchTableBusinessList, Filters } from 'components/api/applicationBusinessListApi';
import { default as IconTune } from 'assets/img/IconTune.svg';
import 'react-datepicker/dist/react-datepicker.css';
import { default as CalendarIcons } from 'assets/img/CalendarIcon.svg';
import { Checkbox } from 'components/common/Checkbox';
import { useAppContext } from 'contexts/AppContext';
import axios from 'axios';
import { InputNew } from 'components/common/Input/InputNew/InputNew';
import SelectBox from 'components/common/SelectBox/SelectBox';

interface PageChangeEventArgs {
  selected: number;
}

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

export const ApplicationBusinessList = () => {
  const navigate = useNavigate();
  const { userDataLight } = useAppContext();

  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');

  const [tableData, setTableData] = useState<TableData[]>([]);
  const [paginationInfo, setPaginationInfo] = useState({
    totalPages: 0,
    totalElements: 0,
    currentPage: 0,
  });
  const [isFilterBoxOpen, setFilterBoxOpen] = useState(false);

  const [applicationDateFrom, setApplicationDateFrom] = useState<Date | null>(null);
  const [applicationDateTo, setApplicationDateTo] = useState<Date | null>(null);
  const [financingPeriodFrom, setFinancingPeriodFrom] = useState('');
  const [financingPeriodTo, setFinancingPeriodTo] = useState('');
  const [requestedAmountFrom, setRequestedAmountFrom] = useState('');
  const [requestedAmountTo, setRequestedAmountTo] = useState('');
  const [entryThresholdFrom, setEntryThresholdFrom] = useState('');
  const [entryThresholdTo, setEntryThresholdTo] = useState('');
  const [interestRateFrom, setInterestRateFrom] = useState('');
  const [interestRateTo, setInterestRateTo] = useState('');
  const [isReceivingOffers, setIsReceivingOffers] = useState(false);
  const [hasInvestorOffers, setHasInvestorOffers] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<{ code: string; name: string } | null>(
    null,
  );
  const [selectedWhiteLabels, setSelectedWhiteLabels] = useState<{ code: string; name: string }[]>(
    [],
  );
  const [statuses, setStatuses] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState<{ code: string; name: string }[]>([]);
  const [currentFilters, setCurrentFilters] = useState<Filters>({});
  const [inputHasContent, setInputHasContent] = useState({
    dealNumber: false,
    borrower: false,
    requestedAmountFrom: false,
    requestedAmountTo: false,
    entryThresholdFrom: false,
    entryThresholdTo: false,
    interestRateFrom: false,
    interestRateTo: false,
    financingPeriodFrom: false,
    financingPeriodTo: false,
  });

  const [sortConfig, setSortConfig] = useState<{ field: string; direction: 'asc' | 'desc' | null }>(
    {
      field: '',
      direction: null,
    },
  );

  useEffect(() => {
    if (isFilterBoxOpen) {
      const fetchStatuses = async () => {
        try {
          const response = await axios.get(`${BASE_URL}dictionary/get?name=request_statuses`, {
            withCredentials: true,
          });
          setStatuses(response.data);
        } catch (error) {
          console.error('Ошибка загрузки статусов:', error);
        }
      };

      // Вызов функции
      fetchStatuses();
    }
  }, [isFilterBoxOpen]);

  const applyFilters = async () => {
    const statusCodes = selectedStatuses.map((status) => status.code);
    const whiteLabelsCodes = selectedWhiteLabels.map((status) => status.code);

    const filters: Filters = {
      requestNum: searchValue,
      dateCreateStart: applicationDateFrom?.toISOString().split('T')[0] || undefined,
      dateCreateEnd: applicationDateTo?.toISOString().split('T')[0] || undefined,
      whiteLabels: whiteLabelsCodes,
      products: selectedProduct ? [selectedProduct.code] : [], // Updated to use product code
      statuses: statusCodes,
      minSum: requestedAmountFrom ? parseFloat(requestedAmountFrom) : undefined,
      maxSum: requestedAmountTo ? parseFloat(requestedAmountTo) : undefined,
      minAvailSum: entryThresholdFrom ? parseFloat(entryThresholdFrom) : undefined,
      maxAvailSum: entryThresholdTo ? parseFloat(entryThresholdTo) : undefined,
      minRate: interestRateFrom,
      maxRate: interestRateTo,
      minPeriod: financingPeriodFrom,
      maxPeriod: financingPeriodTo,
      isReceivingOffers: isReceivingOffers ? true : undefined,
      hasInvestorOffers: hasInvestorOffers ? true : undefined,
    };

    setCurrentFilters(filters);

    try {
      const data = await fetchTableBusinessList(currentPage, filters, sortConfig); // Передаем объект filters и sortConfig
      setTableData(transformedData(data.content)); // Обновить данные таблицы
      setPaginationInfo({
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        currentPage: data.number,
      });
    } catch (error) {
      console.error('Ошибка применения фильтров:', error);
    }
  };

  const clearFilters = () => {
    setSearchValue('');
    setApplicationDateFrom(null);
    setApplicationDateTo(null);
    setSelectedWhiteLabels([]);
    setSelectedProduct(null);
    setSelectedStatuses([]);
    setRequestedAmountFrom('');
    setRequestedAmountTo('');
    setEntryThresholdFrom('');
    setEntryThresholdTo('');
    setInterestRateFrom('');
    setInterestRateTo('');
    setFinancingPeriodFrom('');
    setFinancingPeriodTo('');
    setIsReceivingOffers(false);
    setHasInvestorOffers(false);

    setSortConfig({ field: '', direction: null }); // Сброс сортировки
    setCurrentFilters({});
    setCurrentPage(0); // Сбрасываем страницу на 0
    fetchTableData(0, {}); // Загружаем данные для 0-й страницы без фильтров
  };

  const handleInputChange = (field: string, value: string) => {
    setInputHasContent((prev) => ({
      ...prev,
      [field]: value !== '',
    }));
  };

  const toggleFilterBox = () => {
    setFilterBoxOpen((prev) => !prev);
  };

  const handleSort = (field: string) => {
    setSortConfig((prevSortConfig) => {
      // Если поле то же, что и текущее, меняем направление
      if (prevSortConfig.field === field) {
        return {
          field,
          direction: prevSortConfig.direction === 'asc' ? 'desc' : 'asc',
        };
      }
      // Если поле новое, сортировка будет по возрастанию
      return { field, direction: 'asc' };
    });
  };

  const transformedData = (data: any[]) => {
    return data.map((item) => ({
      num: item.num,
      id: item.id,
      product: item.productName,
      requestedAmount: item.requestedAmount
        ? item.requestedAmount.toLocaleString('ru-KZ', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : 'null',
      approvedAmount: item.approvedAmount
        ? item.approvedAmount.toLocaleString('ru-KZ', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : '-',
      status: item.statusName,
    }));
  };

  const fetchTableData = async (page = 0, filters: Filters = currentFilters) => {
    try {
      const data = await fetchTableBusinessList(page, filters, sortConfig); // Передаем объект sortConfig
      setTableData(transformedData(data.content));
      setPaginationInfo({
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        currentPage: data.number,
      });
    } catch (error) {
      console.error('Ошибка загрузки данных:', error);
    }
  };

  useEffect(() => {
    fetchTableData(currentPage, currentFilters); // Обновляем данные каждый раз при изменении сортировки или фильтров
  }, [currentPage, currentFilters, sortConfig]); // Добавили зависимость sortConfig

  const handlePageChange = useCallback(
    ({ selected }: PageChangeEventArgs) => {
      fetchTableData(selected, currentFilters);
      setCurrentPage(selected);
    },
    [currentFilters, sortConfig],
  );

  const filteredData = useMemo(() => {
    return searchValue
      ? tableData.filter((row) => row.num?.toLowerCase().includes(searchValue.toLowerCase()))
      : tableData;
  }, [searchValue, tableData]);

  return (
    <Container>
      <Title>Сделки на финансирование</Title>
      <BoxHeader>
        <ControlBox>
          <InputSearch
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder='Поиск Сделок'
            name='searchQuery'
          />
          <FilterBox onClick={toggleFilterBox} isActive={isFilterBoxOpen}>
            <img src={IconTune} alt='icon' />
          </FilterBox>
        </ControlBox>
        <ButtonBox>
          <NewButton
            text='Создать сделку'
            className='small'
            onClick={() => navigate('/create-application')}
          />
        </ButtonBox>
      </BoxHeader>
      {isFilterBoxOpen && (
        <ActionFilterBox>
          <BoxInputDescription>
            <BoxInput>
              <p>Дата заявки</p>
              <BoxInfoInput>
                <span>С</span>
                <DatePickerWrapper>
                  <StyledDatePicker
                    selected={applicationDateFrom}
                    dateFormat='dd/MM/yyyy'
                    onChange={(date: Date | null) => setApplicationDateFrom(date)}
                    placeholderText='Выберите дату'
                  />
                  <CalendarIcon src={CalendarIcons} alt='calendar icon' />
                </DatePickerWrapper>
                <span>По</span>
                <DatePickerWrapper>
                  <StyledDatePicker
                    dateFormat='dd/MM/yyyy'
                    selected={applicationDateTo}
                    onChange={(date: Date | null) => setApplicationDateTo(date)}
                    placeholderText='Выберите дату'
                  />
                  <CalendarIcon src={CalendarIcons} alt='calendar icon' />
                </DatePickerWrapper>
              </BoxInfoInput>
            </BoxInput>
            <BoxInput>
              <p>Срок финансирования (в днях)</p>
              <BoxInfoInput>
                <span>От</span>
                <InputNew
                  placeholder='1'
                  value={financingPeriodFrom}
                  onChange={(e) => {
                    handleInputChange('financingPeriodFrom', e.target.value);
                    setFinancingPeriodFrom(e.target.value);
                  }}
                  maxWidth='50px'
                />
                <span>До</span>
                <InputNew
                  placeholder='30'
                  value={financingPeriodTo}
                  onChange={(e) => {
                    handleInputChange('financingPeriodTo', e.target.value);
                    setFinancingPeriodTo(e.target.value);
                  }}
                  maxWidth='50px'
                />
              </BoxInfoInput>
            </BoxInput>
            <BoxInput>
              <p>Минимальный порог входа</p>
              <BoxInfoInput>
                <span>От</span>
                <InputNew
                  placeholder='100 000'
                  value={entryThresholdFrom}
                  onChange={(e) => {
                    handleInputChange('entryThresholdFrom', e.target.value);
                    setEntryThresholdFrom(e.target.value);
                  }}
                  maxWidth='100px'
                />
                <span>До</span>
                <InputNew
                  placeholder='200 000'
                  value={entryThresholdTo}
                  onChange={(e) => {
                    handleInputChange('entryThresholdTo', e.target.value);
                    setEntryThresholdTo(e.target.value);
                  }}
                  maxWidth='100px'
                />
              </BoxInfoInput>
            </BoxInput>
          </BoxInputDescription>
          <BoxInputDescription>
            <BoxInput>
              <p>Запрашиваемая сумма</p>
              <BoxInfoInput>
                <span>От</span>
                <InputNew
                  placeholder='2 000 000'
                  value={requestedAmountFrom}
                  onChange={(e) => {
                    handleInputChange('requestedAmountFrom', e.target.value);
                    setRequestedAmountFrom(e.target.value);
                  }}
                  maxWidth='100px'
                />
                <span>До</span>
                <InputNew
                  placeholder='7 000 000'
                  value={requestedAmountTo}
                  onChange={(e) => {
                    handleInputChange('requestedAmountTo', e.target.value);
                    setRequestedAmountTo(e.target.value);
                  }}
                  maxWidth='100px'
                />
              </BoxInfoInput>
            </BoxInput>

            <BoxInput>
              <p>Доп. фильтры</p>
              <BoxInfoInput>
                <Checkbox
                  checked={isReceivingOffers}
                  onChange={() => setIsReceivingOffers((prev) => !prev)}
                />
                <span>Ведется прием предложений</span>
              </BoxInfoInput>
              <BoxInfoInput>
                <Checkbox
                  checked={hasInvestorOffers}
                  onChange={() => setHasInvestorOffers((prev) => !prev)}
                />
                <span>Есть предложения инвесторов</span>
              </BoxInfoInput>
            </BoxInput>
            <BoxInput>
              <p>Процентная ставка</p>
              <BoxInfoInput>
                <span>От</span>
                <InputNew
                  placeholder='7%'
                  value={interestRateFrom}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*\.?\d{0,2}$/.test(value)) {
                      handleInputChange('interestRateFrom', value);
                      setInterestRateFrom(value);
                    }
                  }}
                  maxWidth='50px'
                />
                <span>До</span>
                <InputNew
                  placeholder='20%'
                  value={interestRateTo}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*\.?\d{0,2}$/.test(value)) {
                      handleInputChange('interestRateTo', value);
                      setInterestRateTo(value);
                    }
                  }}
                  maxWidth='50px'
                />
              </BoxInfoInput>
            </BoxInput>
          </BoxInputDescription>

          <BoxDropDown>
            <BoxDropDown>
              <SelectBox
                label='Продукт'
                options={[
                  { id: 'LOAN', name: 'Заемное финансирование' },
                  { id: 'TENDER', name: 'Тендерное финансирование' },
                ]}
                selectedOptions={selectedProduct ? [selectedProduct.name] : []}
                onOptionSelect={(option) =>
                  setSelectedProduct(
                    selectedProduct?.code === option.id
                      ? null
                      : { code: option.id, name: option.name },
                  )
                }
              />
            </BoxDropDown>
            <BoxDropDown>
              <SelectBox
                label='Группы'
                options={userDataLight.whiteLabel.map((label: any) => ({
                  id: label.code,
                  name: label.name,
                }))}
                selectedOptions={selectedWhiteLabels.map((status) => status.name)}
                onOptionSelect={(option) =>
                  setSelectedWhiteLabels((prev) =>
                    prev.find((item) => item.code === option.id)
                      ? prev.filter((item) => item.code !== option.id)
                      : [...prev, { code: option.id, name: option.name }],
                  )
                }
              />
            </BoxDropDown>
            <BoxDropDown>
              <SelectBox
                label='Статус'
                options={statuses.map((status: any) => ({
                  id: status.code,
                  name: status.name,
                }))}
                selectedOptions={selectedStatuses.map((status) => status.name)}
                onOptionSelect={(option) =>
                  setSelectedStatuses((prev) =>
                    prev.find((item) => item.code === option.id)
                      ? prev.filter((item) => item.code !== option.id)
                      : [...prev, { code: option.id, name: option.name }],
                  )
                }
              />
            </BoxDropDown>
          </BoxDropDown>
          <ButtonsBox>
            <NewButton text='Сбросить все фильтры' className='white small' onClick={clearFilters} />
            <NewButton text='Применить фильтры' className='small' onClick={applyFilters} />
          </ButtonsBox>
        </ActionFilterBox>
      )}
      <Table>
        <thead>
          <TableRowHeader>
            <TableHeader
              isSorted={sortConfig.field === 'num'}
              direction={sortConfig.field === 'num' ? sortConfig.direction : null}
              onClick={() => handleSort('num')}>
              Номер сделки
            </TableHeader>

            <TableHeader
              isSorted={sortConfig.field === 'requestedAmount'}
              direction={sortConfig.field === 'requestedAmount' ? sortConfig.direction : null}
              onClick={() => handleSort('requestedAmount')}>
              Запрашиваемая сумма
            </TableHeader>

            <TableHeader
              isSorted={sortConfig.field === 'acceptedOffersSum'}
              direction={sortConfig.field === 'acceptedOffersSum' ? sortConfig.direction : null}
              onClick={() => handleSort('acceptedOffersSum')}>
              Одобренная сумма
            </TableHeader>

            <TableHeader>Продукт</TableHeader>
            <TableHeader>Статус</TableHeader>
          </TableRowHeader>
        </thead>
        <tbody>
          {filteredData.map((row) => (
            <TableRow key={row.id} onClick={() => navigate(`/req/${row.id}`)}>
              <TableCell>
                <StyledLink to={`/req/${row.id}`}>{row.num}</StyledLink>
              </TableCell>
              <TableCell className='right'>{row.requestedAmount} ₸</TableCell>
              <TableCell className='right'>{row.approvedAmount} ₸</TableCell>
              <TableCell>{row.product}</TableCell>
              <TableCell>
                <StatusCircle $status={row.status} />
                {row.status}
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
      <Pagination
        pageCount={paginationInfo.totalPages}
        currentPage={paginationInfo.currentPage}
        onPageChange={handlePageChange}
      />
    </Container>
  );
};
