import React from 'react';
import { BoxSelect } from './SelectNew.style';
import ArrowIcon from 'assets/img/ArrowIcon.svg';

interface Option {
  value: string;
  label: string;
}

interface SelectProps {
  options: Option[];
  onOptionChange: (value: string) => void;
  selectedValue: string | null;
  isOpen: boolean;
  onToggleOpen: () => void;
}

export const SelectNew: React.FC<SelectProps> = ({
  options,
  onOptionChange,
  selectedValue,
  isOpen,
  onToggleOpen,
}) => {
  return (
    <BoxSelect className={isOpen ? 'open' : ''}>
      <div className='dropdown-header' onClick={onToggleOpen}>
        {selectedValue || 'Выберите...'}
        <img
          src={ArrowIcon}
          width={32}
          height={32}
          alt='Arrow down'
          className={isOpen ? 'arrow-up' : 'arrow-down'}
        />
      </div>
      {isOpen && (
        <div className='options'>
          {options.map((option) => (
            <div
              key={option.value}
              className='option'
              onClick={() => {
                onOptionChange(option.label);
                onToggleOpen(); // изменение состояния открытия/закрытия
              }}>
              {option.label}
            </div>
          ))}
        </div>
      )}
    </BoxSelect>
  );
};
