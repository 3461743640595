import styled from 'styled-components';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

const ErrorDialog = styled.div`
  display: flex;
  align-items: center;
  width: 400px;
  padding: 32px;
  background-color: #fefefe;
  border-radius: 8px;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  img {
    width: 88px;
    height: 88px;
    margin-bottom: 16px;
  }
  h2 {
    color: #000;
    font-family: 'IBM Plex Sans';
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
  }
  p {
    color: var(--fordevs-gray-900, #242527);
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }
  button {
    margin-top: 16px;
  }
`;

export { ModalBackground, ErrorDialog };
