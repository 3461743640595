import React from 'react';
import { InputBox, Input, ErrorMessage } from './InputText.style';

export enum InputTextType {
  TEXT = 'text',
  PHONE = 'tel',
  EMAIL = 'email',
  NUMBER = 'number',
}

interface InputTextProps {
  value: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string | null;
  type?: string;
  placeholder?: string;
  readOnly?: boolean;
  title?: string;
  className?: string;
  disabled?: boolean;
}

export const InputText = ({
  type = InputTextType.TEXT,
  placeholder,
  value,
  onChange,
  error,
  readOnly,
  title,
  className,
  disabled,
}: InputTextProps) => {
  const hasError = Boolean(error);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (type === InputTextType.NUMBER && !/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <InputBox>
        {title && <p>{title}</p>}
        <Input
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          $readOnly={readOnly} // This maps to the styled-component prop
          $hasError={hasError}
          $hasContent={!!value}
          onKeyPress={handleKeyPress}
          className={className}
          disabled={disabled}
        />
        {hasError && <ErrorMessage>{error}</ErrorMessage>}
      </InputBox>
    </>
  );
};
