import React, { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import {
  Container,
  Box,
  LogoImg,
  BoxContent,
  TextBox,
  Line,
  ModalContainer,
  StatusBox,
  StatusCard,
  RefreshStatus,
  LeftBlock,
  RightBlock,
  OferBox,
  Pdf,
} from './KycNew.style';
import Logo from 'assets/img/Logo.svg';
import IdCardKz from 'assets/img/IdCardKz.png';
import IdCardKzFront from 'assets/img/IdCardKzFront.png';
import IconUpdate from 'assets/img/IconUpdate.svg';
import IconCheck from 'assets/img/IconCheck.svg';
import IconCheckMiss from 'assets/img/IconCheckMiss.svg';
import IconCheckDone from 'assets/img/IconCheckDone.svg';
import IconInfo from 'assets/img/iconI.png';
import { NewButton } from 'components/common/Button';
import { InputText } from 'components/common/Input/InputText';
import Llive from './LIive';
import { offerSign } from '../TestStand/signtest/offerSign';
import { useAppContext } from 'contexts/AppContext';
import axios from 'axios';
import {
  FAQContent,
  FAQIcon,
  FAQSection,
  FAQTitle,
  StyledFAQItem,
} from 'components/EnterSite/ModalRegistration.style';
import { ProgressIndicator } from 'components/ProgressIndicator';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { ErrorModal } from 'components/common/ErrorModal';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

const faqData = [
  {
    title: 'Почему требуется верифицировать личность',
    content: (
      <>
        <div>
          <span>
            Верификация личности необходима для обеспечения безопасности и подтверждения
            легитимности действий на платформе.
          </span>
          <p>
            Это позволяет удостовериться, что пользователь, использующий ЭЦП, действительно тот, за
            кого себя выдает, и что он имеет право на совершение конкретных действий
          </p>
        </div>
      </>
    ),
  },
];

const faqDataSms = [
  {
    title: 'Как найти код в приложении Egov',
    content: (
      <>
        <div>
          <span>Откройте приложение Egov;</span>
          <p>
            <br />· Перейдите в раздел «Цифровые документы»;
            <br />· Найдите удостоверения личности - нажмите «Открыть»;
            <br />· Нажмите «Открыть доступ»;
            <br />· 6-значный код будет расположен сразу под QR-кодом.
          </p>
        </div>
      </>
    ),
  },
  {
    title: 'Как найти код в приложении Kaspi.kz / Halyk',
    content: (
      <>
        <div>
          <span>Откройте приложение банка</span>
          <p>
            · Откройте приложение банка;
            <br />· Перейдите в раздел «Госуслуги»;
            <br />· Перейдите в раздел «Цифровые документы»;
            <br />· Нажмите «Предъявить документ»;
            <br />· Ваш 6-значный код будет расположен сразу под QR-кодом.
          </p>
        </div>
      </>
    ),
  },
];

type FAQItemProps = {
  index: number;
  isOpen: boolean;
  onClick: (index: number) => void;
  data: { title: string; content: JSX.Element }[];
};

type ArrowIconProps = {
  rotated: boolean;
};

const FAQItem: React.FC<FAQItemProps> = ({ index, isOpen, onClick, data }) => {
  const { title, content } = data[index];

  return (
    <StyledFAQItem>
      <FAQTitle onClick={() => onClick(index)}>
        <div>
          <img src={IconInfo} alt='iconInfo' />
          {title}
        </div>
        <FAQIcon>
          <ArrowIcon rotated={isOpen} />
        </FAQIcon>
      </FAQTitle>
      {isOpen && <FAQContent>{content}</FAQContent>}
    </StyledFAQItem>
  );
};

const ArrowIcon: React.FC<ArrowIconProps> = ({ rotated }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='17'
    height='16'
    viewBox='0 0 17 16'
    fill='none'
    style={{
      transform: rotated ? 'rotate(180deg)' : 'rotate(0deg)',
      transition: 'transform 0.3s ease',
    }}>
    <path
      d='M4.5 6L8.5 10L12.5 6'
      stroke='black'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const KycNew = () => {
  const { userDataLight } = useAppContext();
  const [currentPage, setCurrentPage] = useState('ofertaSign');
  const [code, setCode] = useState('');
  const [kycStatus, setKycStatus] = useState({
    liveness: null,
    document: null,
    faceMatching: null,
    validityDocument: null,
    compareToEDS: null,
    offerSigned: null,
  });
  const [error, setError] = useState<string | null>(null);
  const [faqOpen, setFaqOpen] = useState<number | null>(null);
  const [documentContent, setDocumentContent] = useState('');
  const [docId, setDocId] = useState('');
  const [fileHash, setFileHash] = useState('');

  useEffect(() => {
    const allStatusesTrue = Object.values(kycStatus).every((status) => status === true);
    if (allStatusesTrue) {
      window.location.href = '/applications'; // Используем window.location.href для перезагрузки страницы
    }
  }, [kycStatus]);

  const fetchKycStatus = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/v1/kyc/status`, {
        withCredentials: true,
      });
      setKycStatus(response.data);
    } catch (error) {
      console.error('Failed to fetch KYC status:', error);
      setError('Failed to fetch KYC status. Please try again.');
    }
  };

  const resetKycStatus = async () => {
    try {
      await axios.get(`${BASE_URL}/api/v1/kyc/reset`, {
        withCredentials: true,
      });
      window.location.reload(); // Обновление страницы
    } catch (error) {
      console.error('Ошибка при сбросе статуса KYC:', error);
      setError('Ошибка при сбросе статуса KYC. Please try again.');
    }
  };

  const fetchDocument = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/public/api/v1/ui/doc/public/offer`, {
        withCredentials: true,
        responseType: 'json', // Receive the response as JSON
      });
      const docId = response.data.docId;
      const fileHash = response.data.hashOffer;
      setDocId(docId); // Save to state
      setFileHash(fileHash); // Save to state
      const base64String = response.data.offer; // Extract the base64 string from the response
      const binaryString = window.atob(base64String);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      const blob = new Blob([bytes], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(blob);
      setDocumentContent(fileURL);
    } catch (error) {
      console.error('Failed to fetch document:', error);
      setError('Failed to fetch document. Please try again.');
    }
  };

  useEffect(() => {
    if (currentPage === 'ofertaSign') {
      fetchDocument();
    }
    if (userDataLight?.status === 'KYC_IN_PROCESS' || userDataLight?.status === 'KYC_FAIL') {
      setCurrentPage('status');
    }
    fetchKycStatus();
  }, [userDataLight, currentPage]);

  const handleNextVerilive = () => {
    setCurrentPage('veriLive');
  };

  const handleSignClick = async () => {
    const documentType = 'PUBLIC_OFFER_REGISTRATION';
    const endpoint = `${BASE_URL}/api/v1/ui/user/sign/public/offer`;
    try {
      const response = await offerSign(
        fileHash,
        endpoint,
        docId,
        userDataLight.iin,
        userDataLight.bin,
        documentType,
      );
      if (response.status === 200) {
        handleFirstStep(); // Call handleFirstStep after a successful response
      }
    } catch (error) {
      console.error('Ошибка при подписании:', error);
      setError('Ошибка при подписании. Please try again.');
    }
  };

  const handleFirstStep = () => {
    setCurrentPage('firstStep');
  };

  const handleNextStatus = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/v1/kyc/id/${code}`, {
        withCredentials: true,
      });
      console.log(response.data);
      setCurrentPage('status');
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        setError('Невалидный код');
      } else {
        console.error('Failed to send code:', error);
        setError('Failed to send code. Please try again.');
      }
    }
  };

  const onCompletion = () => {
    setCurrentPage('nextStepIdentifier');
  };

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    const input = e.target.value;
    if (/^\d{0,6}$/.test(input)) {
      setCode(input);
    }
  };

  const handleFAQClick = (index: number) => {
    setFaqOpen(faqOpen === index ? null : index);
  };

  const steps = ['ofertaSign', 'firstStep', 'veriLive', 'nextStepIdentifier', 'status']; // List of steps for the progress indicator
  const currentStepIndex = steps.indexOf(currentPage);

  const closeModal = () => {
    setError(null);
  };

  return (
    <Container>
      <Box>
        <LogoImg src={Logo} alt='MoneyLink Logo' />
        <ProgressIndicator steps={steps} currentStep={currentStepIndex} />
        {currentPage === 'ofertaSign' && (
          <OferBox>
            {documentContent && (
              <Pdf>
                <Worker
                  workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
                  <Viewer fileUrl={documentContent} />
                </Worker>
              </Pdf>
            )}
            <NewButton text='Подписать с ЭЦП' onClick={handleSignClick} />
          </OferBox>
        )}
        {currentPage === 'nextStepIdentifier' && (
          <BoxContent>
            <LeftBlock>
              <TextBox>
                <h3>Предоставьте код</h3>
                <p>Предоставьте 6-значный код из приложений Egov и Kaspi.kz/Halyk.</p>
              </TextBox>
              <InputText
                placeholder='6-значный код'
                type='text'
                value={code}
                onChange={handleCodeChange}
                error={error}
              />
              <NewButton text='Далее' onClick={handleNextStatus} disabled={code.length !== 6} />
            </LeftBlock>
            <RightBlock>
              <FAQSection>
                {faqDataSms.map((_, index) => (
                  <FAQItem
                    key={index}
                    index={index}
                    isOpen={faqOpen === index}
                    onClick={handleFAQClick}
                    data={faqDataSms}
                  />
                ))}
              </FAQSection>
            </RightBlock>
          </BoxContent>
        )}
        {currentPage === 'veriLive' && (
          <ModalContainer>
            <Llive onCompletion={onCompletion} />
          </ModalContainer>
        )}
        {currentPage === 'status' && (
          <StatusBox>
            <StatusCard>
              Верификация живости
              <img
                src={
                  kycStatus.liveness === true
                    ? IconCheckDone
                    : kycStatus.liveness === false
                      ? IconCheckMiss
                      : IconCheck
                }
                alt='icon'
              />
            </StatusCard>
            <StatusCard>
              Проверка удостоверения личности
              <img
                src={
                  kycStatus.document === true
                    ? IconCheckDone
                    : kycStatus.document === false
                      ? IconCheckMiss
                      : IconCheck
                }
                alt='icon'
              />
            </StatusCard>
            <StatusCard>
              Сравнение фото с документом
              <img
                src={
                  kycStatus.faceMatching === true
                    ? IconCheckDone
                    : kycStatus.faceMatching === false
                      ? IconCheckMiss
                      : IconCheck
                }
                alt='icon'
              />
            </StatusCard>
            <StatusCard>
              Проверка валидности документа
              <img
                src={
                  kycStatus.validityDocument === true
                    ? IconCheckDone
                    : kycStatus.validityDocument === false
                      ? IconCheckMiss
                      : IconCheck
                }
                alt='icon'
              />
            </StatusCard>
            <StatusCard>
              Сравнение документов с ЭЦП
              <img
                src={
                  kycStatus.compareToEDS === true
                    ? IconCheckDone
                    : kycStatus.compareToEDS === false
                      ? IconCheckMiss
                      : IconCheck
                }
                alt='icon'
              />
            </StatusCard>
            <StatusCard>
              Подписание Оферты
              <img
                src={
                  kycStatus.offerSigned === true
                    ? IconCheckDone
                    : kycStatus.offerSigned === false
                      ? IconCheckMiss
                      : IconCheck
                }
                alt='icon'
              />
            </StatusCard>
            <p>
              Процедура проверки обычно занимает не более 24 часов. Уведомление о прохождении
              верификации придет Вам на электронную почту
            </p>
            {Object.values(kycStatus).some((status) => status === false) ? (
              <RefreshStatus onClick={resetKycStatus}>Пройти повторную верификацию</RefreshStatus>
            ) : (
              <NewButton text='Обновить статусы' icon={IconUpdate} onClick={fetchKycStatus} />
            )}
          </StatusBox>
        )}
        {currentPage === 'firstStep' && (
          <BoxContent>
            <LeftBlock>
              <TextBox>
                <h3>Верификация личности</h3>
                <p>
                  Для использования функций платформы MoneyLink необходимо пройти процедуру
                  верификации личности. Весь процесс верификации занимает не более 1 минуты вашего
                  времени.
                </p>
              </TextBox>
              <Line />
              <TextBox>
                <h3>Проверка удостоверения личности</h3>
                <p>
                  · Пожалуйста, удостоверьтесь, что срок действия вашего документа не истек.
                  <br />· Следуйте инструкциям, отображаемым на экране.
                </p>
                <div>
                  <img src={IdCardKz} alt='id-card' />
                  <img src={IdCardKzFront} alt='id-card-front' />
                </div>
              </TextBox>
              <NewButton text='Далее' onClick={handleNextVerilive} />
            </LeftBlock>
            <RightBlock>
              <FAQSection>
                {faqData.map((_, index) => (
                  <FAQItem
                    key={index}
                    index={index}
                    isOpen={faqOpen === index}
                    onClick={handleFAQClick}
                    data={faqData}
                  />
                ))}
              </FAQSection>
            </RightBlock>
          </BoxContent>
        )}
      </Box>
      {error && (
        <ErrorModal
          headerError='Ошибка'
          textError={error}
          buttonClose='Закрыть'
          onClick={closeModal}
        />
      )}
    </Container>
  );
};

export default KycNew;
