import React from 'react';
import { Box, Title } from './InfoContract.style';

interface InfoContractProps {
  contractSum: number;
  ceoNameBusiness: string;
  nameBusiness: string;
  addressBusiness: string;
  phoneBusiness: string | number;
  emailBusiness: string | number;
  iinBusiness: string;
  ibanBusiness: string;
  ceoNameInvestor: string;
  nameInvestor: string;
  addressInvestor: string;
  phoneInvestor: string | number;
  emailInvestor: string | number;
  iinInvestor: string;
  ibanInvestor: string;
  period: string;
}

export const InfoContract: React.FC<InfoContractProps> = ({
  contractSum,
  ceoNameBusiness,
  iinBusiness,
  nameBusiness,
  addressBusiness,
  phoneBusiness,
  emailBusiness,
  ibanBusiness,
  ceoNameInvestor,
  nameInvestor,
  addressInvestor,
  phoneInvestor,
  emailInvestor,
  iinInvestor,
  ibanInvestor,
  period,
}) => {
  return (
    <Box>
      <div>
        <Title>Атрибуты договора</Title>
        <p>
          <strong>Сумма займа:</strong> {contractSum.toLocaleString('RU-KZ')} тенге
        </p>
        <p>
          <strong>Срок займа</strong> {period} дней
        </p>
      </div>
      <div>
        <Title>Реквизиты заемщика</Title>
        <p>
          <strong>ФИО:</strong> {ceoNameBusiness}
        </p>
        <p>
          <strong>ИИН:</strong> {iinBusiness}
        </p>
        <p>
          <strong>Организация:</strong> {nameBusiness}
        </p>
        <p>
          <strong>Адресс:</strong> {addressBusiness}
        </p>
        <p>
          <strong>Email:</strong> {emailBusiness}
        </p>
        <p>
          <strong>Телефон:</strong> {phoneBusiness}
        </p>
        <p>
          <strong>Номер счета:</strong> {ibanBusiness}
        </p>
      </div>
      <div>
        <Title>Реквизиты Инвестора</Title>
        <p>
          <strong>ФИО:</strong> {ceoNameInvestor}
        </p>
        <p>
          <strong>ИИН:</strong> {iinInvestor}
        </p>
        <p>
          <strong>Организация:</strong> {nameInvestor}
        </p>
        <p>
          <strong>Адресс:</strong> {addressInvestor}
        </p>
        <p>
          <strong>Email:</strong> {emailInvestor}
        </p>
        <p>
          <strong>Телефон:</strong> {phoneInvestor}
        </p>
        <p>
          <strong>Номер счета:</strong> {ibanInvestor}
        </p>
      </div>
    </Box>
  );
};
