import { styled } from 'styled-components';

type StatusCircleProps = {
  status: 'Активна' | 'Откланена' | 'На рассмотрение' | 'Просрочена' | string;
};

const Container = styled.div`
  padding: 0 62px;
  display: flex;
  flex-direction: column;
`;

const BoxHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 40px;
  gap: 40px;
  justify-content: space-between;
`;

const ControlBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  max-width: 700px;
`;

const FilterBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  max-height: 46px;
`;

const Title = styled.h1`
  color: #292929;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 106%;
  padding-bottom: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 22.78125px 48.825px 0 rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  margin-bottom: 32px;
`;

const TableHeader = styled.th`
  padding: 10px;
  text-align: left;
  background: #f6f8fa;
  color: #0f1419;
  font-style: normal;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  align-self: stretch;
`;

const TableRowHeader = styled.tr`
  border: none;
`;

const TableRow = styled.tr<{ $isSelected?: boolean }>`
  border-top: 1px solid #c7eaec;
  background: ${(props) => (props.$isSelected ? '#EDFEFF' : 'transparent')};
`;

const TableCell = styled.td`
  padding: 16px 10px;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  position: relative;
`;

const StatusCircle = styled.span<StatusCircleProps>`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;

  background-color: ${(props) => {
    switch (props.status) {
      case 'Активна':
        return '#89E141';
      case 'Откланена':
        return '#FF3838';
      case 'На рассмотрение':
        return '#FFD54E';
      case 'Просрочена':
        return ' #FF9C41';
      default:
        return 'gray';
    }
  }};
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ActionBox = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  gap: 10px;
  min-width: 220px;
  background: var(--White, #fff);
  top: 90%;
  right: 50px;
  z-index: 999;
  border-radius: 8px;
  background: var(--White, #fff);
  box-shadow: 0 8px 16px 0 rgba(30, 34, 72, 0.16);

  li {
    color: var(--black-500-ink, #1e2248);
    font-size: 16px;
    font-style: normal;
    line-height: 24px; /* 150% */
    padding: 8px 16px;

    &:hover {
      background: var(--Primary-Highlight, #def5f6);
    }
  }
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 50%;
  height: 80%;
  overflow-y: auto;
  z-index: 1000;
`;

const ModalWrapperSmall = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  max-height: 50%;
  overflow-y: auto;
  z-index: 1000;
`;

const BoxInput = styled.div`
  display: flex;
  align-items: center;
  padding-right: 20px;
  padding-bottom: 20px;
`;

const BoxInputTitle = styled.span`
  font-size: 16px;
  width: 200px;
  margin-right: 10px;
`;

const BigBoxInputTitle = styled.span`
  font-size: 20px;
  font-style: italic;
`;

const Box = styled.div`
  background-color: #fff;
  max-width: 400px;
  margin: 0 auto; /* Horizontally center the box */
  padding-right: 10px;
  text-align: center;
`;

const TextDiv = styled.div`
  width: 70%;
  font-size: 20px;
`;

const ButtonEdit = styled.button`
  border-radius: 62px;
  background: #01aeb7;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  align-self: center;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &:hover {
    background: #19d3dd;
  }
  &.white {
    border: 1px solid #292d32;
    color: #292d32;
    background: none;
    &:hover {
      border: 1px solid var(--light-grey, #eaeaea);
    }
  }
  &.yellow {
    border: none;
    background: #f8b801;
    color: #fff;
    padding: 16px 38px;
    &:hover {
      background: #ffce41;
    }
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  appearance: none; /* Убираем стандартные стрелки в браузерах */
  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const DraggableItem = styled.div<{ isDragging: boolean }>`
  background-color: ${({ isDragging }) => (isDragging ? '#e0f7fa' : '#ffffff')};
  box-shadow: ${({ isDragging }) =>
    isDragging ? '0px 4px 12px rgba(0, 0, 0, 0.1)' : '0px 2px 6px rgba(0, 0, 0, 0.1)'};
  cursor: grab;
  transition:
    background-color 0.2s,
    box-shadow 0.2s;
`;

const RemoveButton = styled.button`
  color: black;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #d32f2f;
  }
`;

export {
  TextDiv,
  ModalWrapperSmall,
  BoxInput,
  BoxInputTitle,
  BigBoxInputTitle,
  Box,
  Container,
  Title,
  Table,
  TableHeader,
  TableRowHeader,
  TableRow,
  TableCell,
  BoxHeader,
  ControlBox,
  ButtonBox,
  FilterBox,
  StatusCircle,
  IconWrapper,
  ActionBox,
  ModalWrapper,
  ButtonEdit,
  SelectWrapper,
  StyledSelect,
  DraggableItem,
  RemoveButton,
};
