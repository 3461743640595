import {
  TitleBox,
  Table,
  TableHeader,
  TableCell,
  TableCellHeader,
  Backdrop,
  Modal,
  ModalHeader,
  ModalActions,
  Button,
  TableContainer,
  FixedTableHeader,
  FixedTableCell,
  Container,
} from './CompareOffers.style';
import { BreadCrumbsBox } from 'components/RequestDetailsDraft/RequestDetails.style';
import { NewButton } from 'components/common/Button';
import React, { useState } from 'react';
import { CompareOffersProps } from './CompareOffers.interface';
import axios from 'axios';
import { ErrorModal } from 'components/common/ErrorModal';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'contexts/AppContext';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/cabinet/creditor/request/offers/`;

const CompareOffers: React.FC<CompareOffersProps> = ({
  offers,
  requestId,
  requestNumber,
  requestedAmount,
  requestPeriod,
  requestRate,
  closeCompareMode,
  fetchData,
}) => {
  const navigate = useNavigate();
  const { userDataLight } = useAppContext();

  const isUserRole =
    userDataLight?.roles.includes('ADMIN') ||
    userDataLight?.roles.includes('MANAGER') ||
    userDataLight?.roles.includes('USER');
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [activeOfferId, setActiveOfferId] = useState(null);
  const [error, setError] = useState('');
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  const openRejectModal = (offerId: any) => {
    setActiveOfferId(offerId);
    setRejectModalOpen(true);
  };

  const closeRejectModal = () => {
    setRejectModalOpen(false);
  };

  const rejectOffer = () => {
    if (!activeOfferId) return;

    const rejectUrl = `${BASE_URL}reject/${activeOfferId}`;
    axios
      .get(rejectUrl, { withCredentials: true })
      .then((response) => {
        closeCompareMode();
        fetchData();
      })
      .catch((error) => {
        setError('Ошибка. Пожалуйста, попробуйте еще раз.'); // Set error message
        setErrorDialogOpen(true);
        console.error('Error rejecting the offer', error);
      })
      .finally(() => {
        closeRejectModal();
      });
  };

  const acceptOffer = (offerId: any) => {
    if (!offerId) return;

    const acceptUrl = `${BASE_URL}accept/${offerId}`;
    axios
      .get(acceptUrl, { withCredentials: true })
      .then((response) => {
        navigate(`/contracts/${response.data.contract}`);
      })
      .catch((error) => {
        setError('Ошибка. Пожалуйста, попробуйте еще раз.'); // Set error message
        setErrorDialogOpen(true);
        console.error('Error accepting the offer', error);
      });
  };

  const renderActionButtons = (offerId: any, offerStatus: any) => {
    if (!isUserRole) return null; // Hide buttons if not a MANAGER
    if (offerStatus === 'Принят') {
      return <p style={{ color: 'green' }}>Принят</p>;
    } else if (offerStatus !== 'Отменен заёмщиком' && offerStatus !== 'Отменён инвестором') {
      return (
        <div>
          <NewButton onClick={() => acceptOffer(offerId)} text='Подписать договор' />
          <NewButton onClick={() => openRejectModal(offerId)} text='Отказать' className='red' />
        </div>
      );
    } else {
      return <p style={{ color: 'red' }}>Отменена</p>;
    }
  };

  const formatNumber = (num: any) => {
    const number = parseFloat(num.replace(',', '.'));

    if (isNaN(number)) {
      return 'Неверное значение';
    }

    return Number.isInteger(number) ? number : number.toFixed(1);
  };

  return (
    <Container>
      <BreadCrumbsBox>
        <p>
          <a href='/applications'>...</a>
        </p>
        <p>{'>'}</p>
        <p>
          <a href={`/req/${requestId}`}>Заявка {requestNumber}</a>
        </p>
        <p>{'>'}</p>
        <p>
          <span onClick={closeCompareMode}>Предложения инвесторов</span>
        </p>
        <p>{'>'}</p>
        <p className='select'>Сравнить предложения</p>
      </BreadCrumbsBox>
      <TitleBox>
        <h2>Сравнить предложения</h2>
        <span onClick={closeCompareMode}>К другим предложениям</span>
      </TitleBox>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <TableHeader></TableHeader>
              <FixedTableHeader>Ваша заявка</FixedTableHeader>
              {offers.map((offer) => (
                <TableHeader key={offer.id}>{offer.organizationName}</TableHeader>
              ))}
            </tr>
          </thead>
          <tbody className='blue'>
            <tr>
              <TableCellHeader>Сумма займа:</TableCellHeader>
              <FixedTableCell>{requestedAmount.toLocaleString('ru-KZ')} тенге</FixedTableCell>
              {offers.map((offer) => (
                <TableCell key={offer.id}>{offer.offerSum} тенге</TableCell>
              ))}
            </tr>
          </tbody>
          <tbody>
            <tr>
              <TableCellHeader>Срок финансирования:</TableCellHeader>
              <FixedTableCell>{requestPeriod} дней</FixedTableCell>
              {offers.map((offer) => (
                <TableCell key={offer.id}>{offer.offerPeriod}</TableCell>
              ))}
            </tr>
          </tbody>
          <tbody className='blue'>
            <tr>
              <TableCellHeader>Срок начала финансирования:</TableCellHeader>
              <FixedTableCell>—</FixedTableCell>
              {offers.map((offer) => (
                <TableCell key={offer.id}>{offer.offerTransfer} </TableCell>
              ))}
            </tr>
          </tbody>
          <tbody>
            <tr>
              <TableCellHeader>Ставка вознаграждения:</TableCellHeader>
              <FixedTableCell>{requestRate} %</FixedTableCell>
              {offers.map((offer) => (
                <TableCell key={offer.id}>{offer.offerRate}</TableCell>
              ))}
            </tr>
          </tbody>
          <tbody className='blue'>
            <tr>
              <TableCellHeader>Пеня за день:</TableCellHeader>
              <FixedTableCell>—</FixedTableCell>
              {offers.map((offer) => (
                <TableCell key={offer.id}>{offer.offerPenaltyRate}</TableCell>
              ))}
            </tr>
          </tbody>
          <tbody>
            <tr>
              <TableCellHeader>Сумма переплаты:</TableCellHeader>
              <FixedTableCell>—</FixedTableCell>
              {offers.map((offer) => (
                <TableCell key={offer.id}>{formatNumber(offer.overpaymentSum)} тенге</TableCell>
              ))}
            </tr>
          </tbody>
          <tbody className='blue'>
            <tr>
              <TableCellHeader>Дополнительные условия:</TableCellHeader>
              <FixedTableCell>—</FixedTableCell>
              {offers.map((offer) => (
                <TableCell key={offer.id}>{offer.offerExtra}</TableCell>
              ))}
            </tr>
          </tbody>
          <tbody>
            <tr>
              <TableCellHeader></TableCellHeader>
              <FixedTableCell>—</FixedTableCell>
              {offers.map((offer) => (
                <TableCell key={offer.id}>{renderActionButtons(offer.id, offer.status)}</TableCell>
              ))}
            </tr>
          </tbody>
        </Table>
      </TableContainer>
      {isRejectModalOpen && (
        <Backdrop>
          <Modal>
            <ModalHeader>Подтвердить действие?</ModalHeader>
            <p>Вы действительно хотите отказаться от предложения?</p>
            <ModalActions>
              <NewButton onClick={rejectOffer} text='Отказать' className='red' />
              <Button className='cancel' onClick={closeRejectModal}>
                Отменить
              </Button>
            </ModalActions>
          </Modal>
        </Backdrop>
      )}
      {errorDialogOpen && (
        <ErrorModal
          headerError='Ошибка'
          textError={error}
          buttonClose={t('loginAndRegister.buttonClose')}
          onClick={() => setErrorDialogOpen(false)}
        />
      )}
    </Container>
  );
};

export default CompareOffers;
