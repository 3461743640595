import styled, { css } from 'styled-components';
import colors from 'global_styling/colors';

interface TextAreaProps {
  $hasError?: boolean;
  $hasContent?: boolean;
  $readOnly?: boolean;
}

const StyledTextArea = styled.textarea<TextAreaProps>`
  width: 100%;
  padding: 12px 16px;
  border-radius: 6px;
  background: var(--neutral-100, #f4f4f4);
  color: var(--neutral-400, #a0a0a0);
  border: 1px solid var(--Control, #f4f4f4);
  font-size: 14px;
  line-height: 125%;
  font-weight: 400;
  resize: none;
  font-family: 'IBM Plex Sans';
  &.maxApplication {
    height: 144px;
    vertical-align: top;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.16px;
    white-space: pre-wrap;
  }

  ${({ $hasContent }) =>
    $hasContent &&
    css`
      color: var(--neutral-800, #303030);
      border: 1px solid var(--neutral-800, #f4f4f4);
    `}

  ${({ $readOnly }) =>
    $readOnly &&
    css`
      color: var(--neutral-600, #686868);
      border: 1px solid var(--neutral-400, #f4f4f4);
      background: var(--neutral-200, #d8d8d8);
      cursor: not-allowed;
    `}

  &:focus {
    outline: none;
    border-color: #5784f7;
    color: #292d32;
  }

  ${({ $hasError }) =>
    $hasError &&
    css`
      border: 1px solid ${colors.WARNING};
    `}
`;

const ErrorMessage = styled.span`
  color: ${colors.WARNING};
  font-size: 12px;
  font-weight: 400;
`;

const TextAreaBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  padding: 4px 0px;
  p {
    color: var(--neutral-800, var(--neutral-800, #303030));
    font-family: 'IBM Plex Sans';
    font-size: 12px;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.32px;
  }
`;

export { TextAreaBox, StyledTextArea, ErrorMessage };
