import colors from 'global_styling/colors';
import styled from 'styled-components';
import { ReactComponent as LogoNotifications } from 'assets/img/LogoNotifications.svg';
import { NavLink } from 'react-router-dom';

const HeaderMenu = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 16px 42px;
  background: #fff;
  justify-content: flex-end;
  border-bottom: 1px solid #e4e4e4;
  max-height: 76px;
`;

const HeaderNav = styled.div`
  display: flex;
  gap: 8px;
`;

const IconNotifications = styled(LogoNotifications)`
  width: 50px;
  height: 50px;
  cursor: pointer;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
  .active {
    color: #5784f7;
    path {
      stroke: #5784f7;
    }
  }
  :hover {
    color: #5784f7;
    path {
      stroke: #5784f7;
    }
  }
`;

const UserInitialsCircle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #faebec;
  color: #a62b35;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.75px;
`;

const NotificationBadge = styled.span`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #f8b801;
  color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
`;

const IconWithBadge = styled.div`
  position: relative;
`;

export {
  HeaderMenu,
  HeaderNav,
  IconNotifications,
  UserInitialsCircle,
  StyledNavLink,
  NotificationBadge,
  IconWithBadge,
};
