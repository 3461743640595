import React, { useEffect, useState } from 'react';
import { Container, Box } from './ProductStyle';
import { useTranslation } from 'react-i18next';
import { default as IconPlus } from 'assets/img/IconPlus.svg';
import { ProductBase } from './ProductInterface';
import * as api from 'components/api/investor-settings.api';

export const Product = () => {
  const { t } = useTranslation();
  const [allProducts, setAllProducts] = useState<ProductBase[]>([]);
  const [myProducts, setMyProducts] = useState<ProductBase[]>([]);

  useEffect(() => {
    api.getListProducts().then((response) => setAllProducts(response.data));
    api.getUiProducts().then((response) => setMyProducts(response.data));
  }, []);

  const handleAddProduct = (productCode: string) => {
    api.addProduct(productCode).then((response) => setMyProducts([...myProducts, response.data]));
    api.getUiProducts().then((response) => setMyProducts(response.data));
  };

  return (
    <Container>
      <Box>
        <p>{t('product.title')}</p>
        {allProducts.map((product) => (
          <div key={product.code} style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '10px' }}>{product.name}</span>
            <button onClick={() => handleAddProduct(product.code)}>
              <img src={IconPlus} alt={'IconPlus'} />
            </button>
          </div>
        ))}
      </Box>
      <Box>
        <p>{t('product.myProducts')}</p>
        {myProducts.map((product) => (
          <div key={product.code}>
            <span>{product.name}</span>
          </div>
        ))}
      </Box>
    </Container>
  );
};
