import styled from 'styled-components';
import { Button } from 'components/common/Button';

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  justify-content: center;
  margin-top: 10px;
`;

const Box = styled.div`
  flex: 1;
  background-color: #fff;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  p {
    text-align: center;
    margin-bottom: 20px;
    font-size: 22px;
  }
`;

const ServiceButton = styled.button<{ $isActive: boolean }>`
  border: none;
  width: 100%;
  text-align: left;
  background-color: ${({ $isActive }) => ($isActive ? '#F0EBE8' : '#fff')};
  font-size: 16px;
  padding: 10px 40px;
`;

const BoxInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
`;

const BoxInputTitle = styled.span`
  font-size: 16px;
  width: 100%;
`;

const BoxButton = styled(Button)`
  margin-top: 20px;
`;

const AccordionContainer = styled.div``;

const AccordionItem = styled.div`
  border: 1px solid #ccc;
  margin-bottom: 10px;
`;

interface AccordionHeaderProps {
  isOpen?: boolean;
}

const AccordionHeader = styled.div<AccordionHeaderProps>`
  position: relative;
  background-color: #f0f0f0;
  padding: 10px;
  cursor: pointer;

  &:after {
    content: '▼';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    transition: transform 0.3s ease;
  }

  ${({ isOpen }) =>
    isOpen &&
    `
    &:after {
      transform: translateY(-50%) rotate(180deg);
    }
  `}
`;

interface AccordionContentProps {
  isOpen: boolean;
}

const AccordionContent = styled.div<AccordionContentProps>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  padding: 10px;
`;

export {
  Container,
  Box,
  ServiceButton,
  BoxInput,
  BoxInputTitle,
  BoxButton,
  AccordionContainer,
  AccordionItem,
  AccordionHeader,
  AccordionContent,
};
