import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  BoxHeader,
  ControlBox,
  Title,
  Table,
  TableHeader,
  TableRowHeader,
  TableRow,
  TableCell,
  StatusCircle,
  StyledLink,
  FilterBox,
} from '../ApplicationBusinessList/ApplicationBusinessList.style';
import { Pagination } from 'components/Pagination';
import { InputSearch } from 'components/common/Input/InputSearch';
import { default as IconRedTimer } from 'assets/img/IconRedTimer.svg';
import { default as IconTune } from 'assets/img/IconTune.svg';
import { default as IconBlackTimer } from 'assets/img/IconBlackTimer.svg';
import { useAppContext } from 'contexts/AppContext';
import { PageChangeEventArgs, TableDataDocuments } from './DocumentsList.interface';

export const DocumentsList = () => {
  const navigate = useNavigate();
  const { userDataLight } = useAppContext();

  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [tableData, setTableData] = useState<TableDataDocuments[]>([]);
  const [paginationInfo, setPaginationInfo] = useState({
    totalPages: 0,
    totalElements: 0,
    currentPage: 0,
  });
  const [endpoint, setEndpoint] = useState<string | null>(null);

  const [isFilterBoxOpen, setFilterBoxOpen] = useState(false);

  const transformData = (data: any[]) => {
    return data.map((item) => ({
      id: item.id,
      contractNum: item.contractNum,
      contractSum:
        item.contractSum.toLocaleString('ru-KZ', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) || 'null',
      product: item.productName,
      status: item.statusName,
      investorName: item.investorName,
      endDate: item.endDate,
      investorSigned: item.investorSigned,
      businessSigned: item.businessSigned,
      signingDtEnd: item.signingDtEnd,
      businessName: item.businessName,
    }));
  };

  const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

  const fetchData = (page = 0) => {
    if (!endpoint) return;

    axios
      .get(`${endpoint}${page}`, {
        withCredentials: true,
      })
      .then((response) => {
        setTableData(transformData(response.data.content));
        setPaginationInfo({
          totalPages: response.data.totalPages,
          totalElements: response.data.totalElements,
          currentPage: response.data.number,
        });
      })
      .catch((error) => {
        console.error('Ошибка загрузки данных:', error);
      });
  };

  useEffect(() => {
    if (!userDataLight) return;

    const newEndpoint =
      userDataLight.organizationRole === 'INVESTOR'
        ? `${BASE_URL}/api/v1/cabinet/investor/contract?page=`
        : `${BASE_URL}/api/v1/cabinet/creditor/contract?page=`;
    setEndpoint(newEndpoint);
  }, [userDataLight]);

  useEffect(() => {
    fetchData();
  }, [endpoint]);

  const handlePageChange = ({ selected }: PageChangeEventArgs) => {
    fetchData(selected);
    setCurrentPage(selected);
  };

  const filteredData = useMemo(() => {
    if (searchValue) {
      return tableData.filter((row) =>
        row.contractNum?.toLowerCase().includes(searchValue.toLowerCase()),
      );
    }
    return tableData;
  }, [searchValue, tableData]);

  const calculateRemainingTime = (signingDtEnd: string) => {
    const endDate = new Date(signingDtEnd);
    const now = new Date();
    const timeDiff = endDate.getTime() - now.getTime();

    if (timeDiff <= 0) {
      return { text: 'Время истекло', expired: true };
    }

    const daysRemaining = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    if (daysRemaining >= 1) {
      return { text: `${daysRemaining} ${daysRemaining === 1 ? 'день' : 'д'}`, expired: false };
    }

    const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

    return { text: `${hours}:${minutes}`, expired: false };
  };

  const toggleFilterBox = () => {
    setFilterBoxOpen((prev) => !prev);
  };

  return (
    <Container>
      <Title>Договоры</Title>
      <BoxHeader>
        <ControlBox>
          <InputSearch
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder='Поиск договоров'
            name='searchQuery'
          />
          <FilterBox onClick={toggleFilterBox} isActive={isFilterBoxOpen}>
            <img src={IconTune} alt='icon' />
          </FilterBox>
        </ControlBox>
      </BoxHeader>
      <Table>
        <thead>
          <TableRowHeader>
            <TableHeader>Номер договора</TableHeader>
            <TableHeader>
              {userDataLight.organizationRole === 'INVESTOR' ? 'Заемщик' : 'Инвестор'}
            </TableHeader>
            <TableHeader>Сумма займа</TableHeader>
            <TableHeader>Продукт</TableHeader>
            <TableHeader>Статус</TableHeader>
            <TableHeader>Время подписания</TableHeader>
          </TableRowHeader>
        </thead>
        <tbody>
          {filteredData.map((row) => {
            const { text: remainingTime, expired } = calculateRemainingTime(row.signingDtEnd);

            return (
              <TableRow key={row.id} onClick={() => navigate(`/contracts/${row.id}`)}>
                <TableCell>
                  <StyledLink to={`/contracts/${row.id}`}>{row.contractNum}</StyledLink>
                </TableCell>
                <TableCell>
                  {userDataLight.organizationRole === 'INVESTOR'
                    ? row.businessName
                    : row.investorName}
                </TableCell>
                <TableCell className='right'>{row.contractSum} ₸</TableCell>
                <TableCell>{row.product}</TableCell>
                <TableCell>
                  {row.businessSigned && (
                    <div>
                      <StatusCircle $status={row.status} />
                      {row.status}
                    </div>
                  )}
                  {!row.businessSigned && (
                    <div>
                      <StatusCircle $status={row.status} />
                      {row.status}
                    </div>
                  )}
                </TableCell>
                <TableCell style={{ color: expired ? 'red' : 'inherit' }}>
                  <div>
                    <img src={expired ? IconRedTimer : IconBlackTimer} alt='Timer Icon' />
                    {remainingTime}
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </tbody>
      </Table>
      <Pagination
        pageCount={paginationInfo.totalPages}
        currentPage={paginationInfo.currentPage}
        onPageChange={handlePageChange}
      />
    </Container>
  );
};
