import React from 'react';
import { TextAreaBox, StyledTextArea, ErrorMessage } from './TextArea.style';

interface TextAreaProps {
  value: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  error?: string | null;
  placeholder?: string;
  readOnly?: boolean;
  title?: string;
  className?: string;
  disabled?: boolean;
}

export const TextArea = ({
  placeholder,
  value,
  onChange,
  error,
  readOnly,
  title,
  className,
  disabled,
}: TextAreaProps) => {
  const hasError = Boolean(error);

  return (
    <>
      <TextAreaBox>
        {title && <p>{title}</p>}
        <StyledTextArea
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          $readOnly={readOnly}
          $hasError={hasError}
          $hasContent={!!value}
          className={className}
          disabled={disabled}
        />
        {hasError && <ErrorMessage>{error}</ErrorMessage>}
      </TextAreaBox>
    </>
  );
};
