import React, { useState } from 'react';
import { ModalWrapper, Title, Box, BoxInput, BoxInputTitle } from './DocumentGroupAddModalStyle';
import { Button } from 'components/common/Button';
import { DocumentGroupDetails } from '../../AdminSettingsInterface';
import * as api from '../../admin-settings.api';
import { Input } from '../../../common/Input/InputText/InputText.style';

interface DocumentGroupProps {
  onClose: () => void;
}

export const DocumentGroupAddModal: React.FC<DocumentGroupProps> = ({ onClose }) => {
  const [isModal, setIsModal] = useState(true);
  const [editingData, setEditingData] = useState<DocumentGroupDetails | null>(null);
  const [selectedFile, setSelectedFile] = useState<File>();

  const closeModal = () => {
    setIsModal(false);
    onClose();
  };

  const handleSaveClick = () => {
    if (editingData && selectedFile) {
      api
        .addDocumentGroupApi(editingData, selectedFile)
        .then(() => {
          // Обработка успешного сохранения
        })
        .catch((error: any) => {
          console.error('Error saving document group:', error);
        });
    }
    closeModal();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0] as File);
    }
  };

  return (
    <>
      {isModal && (
        <ModalWrapper>
          <div className='modal'>
            <Title>Добавить документ</Title>
            <div className='modal-content'>
              <form>
                <BoxInput>
                  <BoxInputTitle>Название документа:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData?.name}
                    onChange={(e) => setEditingData({ ...editingData, name: e.target.value })}
                  />
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>Код документа:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData?.code}
                    onChange={(e) => setEditingData({ ...editingData, code: e.target.value })}
                  />
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>Версия документа:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData?.version}
                    onChange={(e) =>
                      setEditingData({
                        ...editingData,
                        version: parseFloat(e.target.value) || 0,
                      })
                    }
                  />
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>Дата валидации:</BoxInputTitle>
                  <Input
                    type='date' // Update input type to 'date'
                    value={editingData?.validDate ? editingData.validDate.toLocaleDateString() : ''}
                    onChange={(e) => {
                      const selectedDate = e.target.value;
                      setEditingData((prevData) => ({
                        ...prevData,
                        validDate: selectedDate ? new Date(selectedDate) : undefined,
                      }));
                    }}
                  />
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>Требует подписи:</BoxInputTitle>
                  <input
                    type='checkbox'
                    checked={editingData?.sign}
                    onChange={(e) =>
                      setEditingData({
                        ...editingData,
                        sign: e.target.checked,
                      })
                    }
                  />
                </BoxInput>
                <BoxInput>
                  <Input type='file' onChange={handleFileChange} />
                </BoxInput>
                <Box>
                  <Button onClick={handleSaveClick} text={'Сохранить'} />
                  <Button onClick={closeModal} text={'Закрыть'} />
                </Box>
              </form>
            </div>
          </div>
        </ModalWrapper>
      )}
    </>
  );
};
