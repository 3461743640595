import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
`;

export const MainInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ContentBox = styled.div`
  padding: 8px 12px;
  border-radius: 8px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--neutral-800, #303030);
  background: #fff;
`;

export const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--neutral-800, #303030);
  font-family: 'IBM Plex Sans';
  font-size: 18px;
  font-weight: 600;
  line-height: 130%; /* 23.4px */
`;

export const DataBox = styled.p`
  margin: 10px 0 0 0;
  color: var(--neutral-950, #060606);
  font-family: 'IBM Plex Sans';
  font-size: 18px;
  font-weight: 600;
  line-height: 125%; /* 22.5px */
`;
