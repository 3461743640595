/*******  Interface of document  *******/
export interface DocumentGroupDetails {
  id?: number;
  code?: string;
  name?: string;
  version?: number;
  startDate?: Date;
  validDate?: Date;
  sign?: boolean;
}
/**************************************/

/*******  Interface of module  *******/
export interface TextProps {
  text_ru: string;
  text_kk: string;
  text_en: string;
}

export interface ModuleDetails {
  id: number;
  name: TextProps;
  path: string;
  status: string;
}

export interface ModuleAddDetails {
  text_ru?: string;
  text_kk?: string;
  text_en?: string;
  path?: string;
  status?: string;
}
/**************************************/

/*******  Interface of organization  *******/
export enum OrganizationType {
  SL = 'ИП',
  LLC = 'ТОО',
}

export interface OrganizationDetails {
  id: number;
  iinbin: string;
  name: string;
  address: string;
  email: string;
  phone: string;
  // oked: Oked;
  ceoName: string;
  locked: boolean;
  organizationRole: string;
  organizationType: OrganizationType;
  whiteLabels: WhiteLabel[];
}

export interface WhiteLabel {
  baseUrl: string;
  description: string | null;
  fee: string | null;
  id: string;
  name: Dictionary;
  status: Status;
}

export interface Dictionary {
  text_ru: string;
  text_kk: string;
  text_en: string;
}

export interface Status {
  code: string;
}

export interface OrganizationEditDetails {
  iinbin?: string;
  name?: string;
  address?: string;
  email?: string;
  phone?: string;
  // oked: Oked;
  ceoName?: string;
  locked?: boolean;
  organizationRole?: string;
  organizationType?: OrganizationType;
  whiteLabels?: string[];
}
/**************************************/

/*******  Interface of user  *******/
export interface UserDetails {
  id: number;
  login: string;
  iin: string;
  bin: string;
  email: string;
  phone: string;
  fio: string;
  organization: string;
  roles: string[];
  dtCreate: Date;
}

export interface UserEditDetails {
  login?: string;
  iin?: string;
  bin?: string;
  email?: string;
  phone?: string;
  fio?: string;
  organization?: string;
  roles?: string[];
  dtCreate?: Date;
}
/**************************************/

/*******  Interface of template  *******/
export interface TemplateDetails {
  id: number;
  name: string;
  code: string;
  type: string;
}
