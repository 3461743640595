import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  BreadCrumbsBox,
  HeaderBox,
  TagRisk,
  TagTime,
  Timer,
  SwitcherBox,
  Tab,
  ContainerContent,
  LeftBlock,
  RightBlock,
  InfoBoxRight,
  InfoBoxRightCard,
  ButtonBox,
  ContainerMain,
  ExpandableSection,
  MainInfoBox,
  MainInfoItem,
  MainInfoTitle,
  MainInfoContent,
  ArrowIcon,
} from './ApplicationInvestorNew.style';
import { RequestData } from 'components/RequestDetailsDraft/RequestDetailsDraft.interface';
import IconRedTimer from 'assets/img/IconRedTimer.svg';
import CircleWhite from 'assets/img/CircleWhite.svg';
import IconHelpHouse from 'assets/img/IconHelpHouse.svg';
import IconArrow from 'assets/img/IconArrow.svg';
import IconArrowDown from 'assets/img/IconArrowDown.svg';
import IconDecor from 'assets/img/IconDecor.svg';
import { NewButton } from 'components/common/Button';
import { SentOffersList } from './SentOffersList';
import { TagsWhiteLabel } from 'components/TagWhiteLabels';
import ScoringInfoNew from 'components/ScoringInfoInvestor/ScoringInfoInvestorNew';
import InfoCreditStory from 'components/InfoCreditStory/InfoCreditStoryNew';
import { InfoBusinessNew } from 'components/InfoBusiness/InfoBusinessNew';
import { ApplicationDocumentsList } from 'components/ApplicationInvestor/ApplicationDocumentsList';
import { PKBInfo } from 'components/PKBInfo';
import {
  MainDecorContent,
  MainDecorInfoBox,
  MainDecorLogo,
  MainDecorTitle,
} from 'components/RequestDetailsNew/RequestDetailsNew.style';
import { ApproveModal } from './ApproveModal';
import InfoEGZNew from 'components/InfoEGZNew/InfoEGZNew';

export const ApplicationInvestorNew = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [requestData, setRequestData] = useState<RequestData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [remainingTime, setRemainingTime] = useState('00:00:00');
  const [activeTab, setActiveTab] = useState('mainInfo');
  const [isMainInfoExpanded, setIsMainInfoExpanded] = useState(true);
  const [isMainInfoDecor, setIsMainInfoDecor] = useState(true);
  const [logoSrc, setLogoSrc] = useState<string | null>(null); // State to store the logo image source
  const [isModalOpen, setModalOpen] = useState(false);

  const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

  const fetchRequestDetails = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}cabinet/investor/request/get/${id}`, {
        withCredentials: true,
      });
      const data = response.data;
      setRequestData(data);
      if (response.data.logoFileId) {
        const logoBlob = await fetchLogo(response.data.logoFileId);
        if (logoBlob) {
          setLogoSrc(URL.createObjectURL(logoBlob));
        }
      }
    } catch (err) {
      if (err instanceof Error) {
        setError('Ошибка загрузки данных: ' + err.message);
      } else {
        setError('Ошибка загрузки данных: ' + String(err));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLogo = async (logoFileId: string) => {
    try {
      const response = await axios.get(`${BASE_URL}/ui/file/${logoFileId}`, {
        responseType: 'blob',
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching logo:', error);
      setError('Error fetching logo');
    }
  };

  const calculateTimeLeft = useCallback(() => {
    if (!requestData?.requestDtEnd) {
      return '00:00:00';
    }

    const endTime = new Date(requestData.requestDtEnd).getTime();
    const now = new Date().getTime();
    const difference = endTime - now;

    if (difference > 0) {
      const hours = Math.floor(difference / (1000 * 60 * 60))
        .toString()
        .padStart(2, '0');
      const minutes = Math.floor((difference / (1000 * 60)) % 60)
        .toString()
        .padStart(2, '0');
      const seconds = Math.floor((difference / 1000) % 60)
        .toString()
        .padStart(2, '0');
      return `${hours}:${minutes}:${seconds}`;
    }

    return '00:00:00';
  }, [requestData]);

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [calculateTimeLeft]);

  useEffect(() => {
    fetchRequestDetails();
  }, [id]);

  const getWhiteLabelTag = () => {
    if (requestData && requestData.whiteLabels && requestData.whiteLabels.length > 0) {
      const whiteLabel = requestData.whiteLabels[0];
      switch (whiteLabel.code) {
        case 'OMARKET':
          return <TagsWhiteLabel text={whiteLabel.name} className='smartcash' />;
        case 'ALL':
          return <TagsWhiteLabel text={whiteLabel.name} className='all' />;
        default:
          return null;
      }
    }
    return <TagsWhiteLabel text='All' />;
  };

  const handleDecline = async () => {
    try {
      await axios.get(`${BASE_URL}cabinet/investor/request/decline/${id}`, {
        withCredentials: true,
      });
      navigate('/applications');
    } catch (err) {
      if (err instanceof Error) {
        setError('Ошибка при отклонении: ' + err.message);
      } else {
        setError('Ошибка при отклонении: ' + String(err));
      }
    }
  };

  const closeModal = () => {
    setModalOpen(!isModalOpen);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!requestData) {
    return <div>Данные не найдены.</div>;
  }

  return (
    <Container>
      <BreadCrumbsBox>
        <p>
          <a href='/applications'>Сделки</a>
        </p>
        <span>{'>'}</span>
        <p>Сделка {requestData.num}</p>
      </BreadCrumbsBox>
      <HeaderBox>
        <div className='leftContent'>
          <h2>Сделка {requestData.num}</h2>
          {getWhiteLabelTag()}
          <TagRisk>
            <img src={CircleWhite} alt='circle' />
            {requestData.statusName}
          </TagRisk>
          <TagTime>
            <img src={IconRedTimer} alt='timer' />
            <Timer>{remainingTime}</Timer>
          </TagTime>
        </div>
        <a href='/suggestions'>
          <NewButton text='К предложениям' />
        </a>
      </HeaderBox>
      <SwitcherBox>
        <Tab active={activeTab === 'mainInfo'} onClick={() => setActiveTab('mainInfo')}>
          Основная информация
        </Tab>
        <Tab active={activeTab === 'borrowerData'} onClick={() => setActiveTab('borrowerData')}>
          Данные о заемщике
        </Tab>
        <Tab active={activeTab === 'scoring'} onClick={() => setActiveTab('scoring')}>
          Скоринг
        </Tab>
        <Tab active={activeTab === 'creditHistory'} onClick={() => setActiveTab('creditHistory')}>
          Кредитная история
        </Tab>
        <Tab active={activeTab === 'dataEgz'} onClick={() => setActiveTab('dataEgz')}>
          Данные ЭГЗ
        </Tab>
        <Tab
          active={activeTab === 'analyticsBusiness'}
          onClick={() => setActiveTab('analyticsBusiness')}>
          История займов
        </Tab>
        <Tab active={activeTab === 'documents'} onClick={() => setActiveTab('documents')}>
          Документы
        </Tab>
      </SwitcherBox>
      <ContainerContent>
        <LeftBlock>
          {activeTab === 'mainInfo' && (
            <ContainerMain>
              <ExpandableSection>
                <MainInfoTitle onClick={() => setIsMainInfoExpanded(!isMainInfoExpanded)}>
                  <img src={IconHelpHouse} alt='icon' />
                  Общая информация
                  <ArrowIcon src={isMainInfoExpanded ? IconArrowDown : IconArrow} alt='arrow' />
                </MainInfoTitle>
                {isMainInfoExpanded && (
                  <MainInfoBox>
                    <MainInfoContent>
                      <MainInfoItem>
                        <span>Заёмщик</span> {requestData.businessName}
                      </MainInfoItem>
                      <MainInfoItem>
                        <span>Продукт</span> {requestData.productName}
                      </MainInfoItem>
                      <MainInfoItem>
                        <span>ИИН/БИН Заёмщика</span> {requestData.organization.iinbin}
                      </MainInfoItem>
                      <MainInfoItem>
                        <span>Длительность финансирования</span> {requestData.requestPeriod} дней
                      </MainInfoItem>
                      <MainInfoItem>
                        <span>Запрошенная сумма</span>{' '}
                        {requestData.requestedAmount.toLocaleString()} ₸
                      </MainInfoItem>
                      <MainInfoItem>
                        <span>№ Счета</span> {requestData.accountInfo.iban}
                      </MainInfoItem>
                      <MainInfoItem>
                        <span>Минимальный порог входа</span>{' '}
                        {requestData?.requestedMinAmount?.toLocaleString() || 'N/A'} ₸
                      </MainInfoItem>
                      <MainInfoItem>
                        <span>Процентная ставка</span> {requestData.requestRate} %
                      </MainInfoItem>
                      {requestData?.product === 'LOAN' && (
                        <>
                          <MainInfoItem>
                            <span>Вид залога</span>{' '}
                            {requestData?.collateral?.name?.text_ru
                              ? requestData.collateral.name.text_ru
                              : 'Нет залога'}
                          </MainInfoItem>
                        </>
                      )}
                      {requestData?.product === 'TENDER' && (
                        <>
                          <MainInfoItem>
                            <span>Сумма контракта</span>{' '}
                            {requestData.contracts[0]?.sumWithNds.toLocaleString() || 'N/A'} ₸
                          </MainInfoItem>
                          <MainInfoItem>
                            <span>№ Контракта</span> {requestData.contracts[0]?.num || 'N/A'}
                          </MainInfoItem>
                          <MainInfoItem>
                            <span>Название контракта</span>{' '}
                            {requestData.contracts[0]?.customerName || 'N/A'}
                          </MainInfoItem>
                        </>
                      )}
                    </MainInfoContent>
                  </MainInfoBox>
                )}
              </ExpandableSection>
              <ExpandableSection>
                <MainInfoTitle onClick={() => setIsMainInfoDecor(!isMainInfoDecor)}>
                  <img src={IconDecor} alt='icon' />
                  Оформление
                  <ArrowIcon src={isMainInfoDecor ? IconArrowDown : IconArrow} alt='arrow' />
                </MainInfoTitle>
                {isMainInfoDecor && (
                  <MainInfoBox>
                    <MainDecorContent>
                      {logoSrc && <MainDecorLogo src={logoSrc} alt='logo' />}
                      <MainDecorInfoBox>
                        <MainDecorTitle>{requestData?.loanPurposeProject}</MainDecorTitle>
                        {requestData?.funding_goals?.name?.text_ru ? (
                          <div>
                            <p>Цель займа:</p>
                            {requestData.funding_goals.name.text_ru}
                          </div>
                        ) : (
                          <div>
                            <p>Цель займа:</p>
                            Исполнение тендера
                          </div>
                        )}

                        <div>
                          <p>Индустрия:</p>
                          {requestData?.industry?.name?.text_ru}
                        </div>
                        <div>
                          <p>Описание:</p>
                          {requestData?.loanPurposeProjectDesc}
                        </div>
                      </MainDecorInfoBox>
                    </MainDecorContent>
                  </MainInfoBox>
                )}
              </ExpandableSection>
            </ContainerMain>
          )}
          {activeTab === 'scoring' && <ScoringInfoNew id={requestData.id} num={requestData.num} />}
          {activeTab === 'creditHistory' && (
            <InfoCreditStory id={requestData.id} num={requestData.num} />
          )}
          {activeTab === 'analyticsBusiness' && (
            <InfoBusinessNew num={requestData.num} iinbin={requestData.organization.iinbin} />
          )}
          {activeTab === 'documents' && (
            <ApplicationDocumentsList files={requestData.files} docLink={requestData?.docLink} />
          )}
          {activeTab === 'dataEgz' && <InfoEGZNew id={requestData.id} num={requestData.num} />}
          {activeTab === 'borrowerData' && <PKBInfo num={requestData.num} id={requestData.id} />}
        </LeftBlock>
        <RightBlock>
          <InfoBoxRight>
            <InfoBoxRightCard>
              <p>Принято предложений на сумму:</p>
              <p>{requestData?.approvedAmount?.toLocaleString() || 'N/A'} ₸</p>
            </InfoBoxRightCard>
            <InfoBoxRightCard>
              <p>Подписано договоров на сумму:</p>
              <p>{requestData?.signedContractSum?.toLocaleString() || 'N/A'} ₸</p>
            </InfoBoxRightCard>
            <InfoBoxRightCard>
              <p>Осталось собрать по сделке:</p>
              <p>{requestData?.remainingSum?.toLocaleString() || 'N/A'} ₸</p>
            </InfoBoxRightCard>
          </InfoBoxRight>
          {requestData?.status !== '11' && (
            <ButtonBox>
              {requestData?.declinable !== false && (
                <NewButton text='Отклонить' className='red' onClick={handleDecline} />
              )}
              <NewButton text='Сделать предложение' onClick={() => setModalOpen(true)} />
            </ButtonBox>
          )}

          <SentOffersList ids={id} num={requestData.num} />
        </RightBlock>
      </ContainerContent>
      {isModalOpen && (
        <ApproveModal
          closeModal={closeModal}
          amount={requestData?.requestedAmount || 0}
          period={requestData?.requestPeriod || 0}
        />
      )}
    </Container>
  );
};
