import React from 'react';
import { IPenaltyCalcResponse } from './PenaltyCalculator.interface';
import {
  ModalWrapper,
  ModalClose,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  HeaderCell,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  CenteredTableCell,
} from './PenaltyCalculator.style';
import { NewButton } from '../../common/Button';
import { default as ArrowLeft } from 'assets/img/ArrowLeft.svg';
import { getDayWord, spacedNumber } from '../tools';
import { useAppContext } from '../../../contexts/AppContext';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  contractNumber: string;
  data: IPenaltyCalcResponse | undefined;
  handleBackClick: () => void;
  handleClaimLetterClick: () => void;
}

export const PenaltyCalculatorResult: React.FC<Props> = (props) => {
  const { isOpen, onClose, contractNumber, data, handleBackClick, handleClaimLetterClick } = props;
  const { userDataLight } = useAppContext();

  return (
    <>
      {isOpen && data && (
        <ModalWrapper>
          <ModalHeader>
            <h2>Расчет пени по договору {contractNumber}</h2>
            <ModalClose onClick={onClose} />
          </ModalHeader>
          <ModalBody>
            <Table>
              <TableHeader>
                <tr>
                  <HeaderCell>Дата ожидаемой оплаты</HeaderCell>
                  <HeaderCell>Дата фактической оплаты</HeaderCell>
                  <HeaderCell>Дней просрочки</HeaderCell>
                  <HeaderCell>Просроченная сумма</HeaderCell>
                  <HeaderCell>Пеня за указанный срок</HeaderCell>
                  <HeaderCell>Процент, по которому рассчитана пеня</HeaderCell>
                </tr>
              </TableHeader>
              <TableBody>
                {data.calculations.map((item, index) => (
                  <TableRow key={index}>
                    <CenteredTableCell>
                      {new Date(item.expectedPayDate).toLocaleDateString('ru-RU')}
                    </CenteredTableCell>
                    <CenteredTableCell>
                      {new Date(item.actualPayDate).toLocaleDateString('ru-RU')}
                    </CenteredTableCell>
                    <CenteredTableCell>
                      {item.days} {getDayWord(item.days)}
                    </CenteredTableCell>
                    <CenteredTableCell>{spacedNumber(item.overdueAmount)} тг.</CenteredTableCell>
                    <CenteredTableCell>{spacedNumber(item.penalty)} тг.</CenteredTableCell>
                    <CenteredTableCell>{item.penaltyPercentage} %</CenteredTableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <CenteredTableCell>Итого</CenteredTableCell>
                  <CenteredTableCell></CenteredTableCell>
                  <CenteredTableCell></CenteredTableCell>
                  <CenteredTableCell></CenteredTableCell>
                  <CenteredTableCell></CenteredTableCell>
                  <CenteredTableCell>{spacedNumber(data.totalSum)} тг</CenteredTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <NewButton text='' icon={ArrowLeft} onClick={handleBackClick} />
            {userDataLight.organizationRole === 'INVESTOR' ? (
              <NewButton text='Претензионное письмо' onClick={handleClaimLetterClick} />
            ) : (
              <NewButton text='Внести платежи' onClick={() => console.log('Внести платежи')} />
            )}
          </ModalFooter>
        </ModalWrapper>
      )}
    </>
  );
};
