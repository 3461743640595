import React from 'react';
import { ButtonStyle } from './Button.style';

interface ButtonProps {
  text: string;
  className?: string;
  onClick?: () => void;
}

export const Button = ({ text, className, onClick }: ButtonProps) => {
  return (
    <ButtonStyle className={className} onClick={onClick}>
      {text}
    </ButtonStyle>
  );
};
