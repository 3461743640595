import React, { useState } from 'react';
import {
  AccordionContainer,
  AccordionContent,
  AccordionHeader,
  AccordionItem,
} from './AdminSettings.style';
import { DocumentGroup } from './Table/DocumentGroup/DocumentGroup';
import { ModuleList } from './Table/ModuleList/ModuleList';
import { OrganizationList } from './Table/OrganizationList/OrganizationList';
import { UserList } from './Table/UserList/UserList';
import { TemplateAdminList } from './Table/TemplateAdminList/TemplateAdminList';

export const AdminSettings = () => {
  const [documentsGroupOpen, setDocumentGroupOpen] = useState(false);
  const [moduleOpen, setModuleOpen] = useState(false);
  const [organizationOpen, setOrganizationOpen] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const [templateOpen, setTemplateOpen] = useState(false);
  const [allSectionsOpen, setAllSectionsOpen] = useState(false);

  const toggleTemplateGroup = () => {
    setTemplateOpen(!templateOpen);
    setAllSectionsOpen(moduleOpen && organizationOpen && userOpen && documentsGroupOpen);
  };

  const toggleDocumentsGroup = () => {
    setDocumentGroupOpen(!documentsGroupOpen);
    setAllSectionsOpen(moduleOpen && organizationOpen && userOpen && templateOpen);
  };

  const toggleModule = () => {
    setModuleOpen(!moduleOpen);
    setAllSectionsOpen(documentsGroupOpen && organizationOpen && userOpen && templateOpen);
  };

  const toggleOrganization = () => {
    setOrganizationOpen(!organizationOpen);
    setAllSectionsOpen(documentsGroupOpen && moduleOpen && userOpen && templateOpen);
  };

  const toggleUser = () => {
    setUserOpen(!userOpen);
    setAllSectionsOpen(documentsGroupOpen && moduleOpen && organizationOpen && templateOpen);
  };

  return (
    <AccordionContainer style={{ overflowY: 'scroll', maxHeight: '90%' }}>
      <AccordionItem>
        <AccordionHeader onClick={toggleTemplateGroup}>Список шаблонов</AccordionHeader>
        <AccordionContent isOpen={templateOpen}>
          <TemplateAdminList />
        </AccordionContent>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader onClick={toggleDocumentsGroup}>
          Список документов платформы
        </AccordionHeader>
        <AccordionContent isOpen={documentsGroupOpen}>
          <DocumentGroup />
        </AccordionContent>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader onClick={toggleModule}>Список модулей платформы</AccordionHeader>
        <AccordionContent isOpen={moduleOpen}>
          <ModuleList />
        </AccordionContent>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader onClick={toggleOrganization}>Список организаций</AccordionHeader>
        <AccordionContent isOpen={organizationOpen}>
          <OrganizationList />
        </AccordionContent>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader onClick={toggleUser}>Список пользователей</AccordionHeader>
        <AccordionContent isOpen={userOpen}>
          <UserList />
        </AccordionContent>
      </AccordionItem>
    </AccordionContainer>
  );
};
