import { styled } from 'styled-components';

type StatusCircleProps = {
  status: 'Активна' | 'Откланена' | 'На рассмотрение' | 'Просрочена' | string;
};

const Container = styled.div`
  padding: 0 62px;
  display: flex;
  flex-direction: column;
`;

const BoxHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 40px;
  gap: 40px;
  justify-content: space-between;
`;

const ControlBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  max-width: 700px;
`;

const FilterBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  max-height: 46px;
`;

const Title = styled.h1`
  color: #292929;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 106%;
  padding-bottom: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 22.78125px 48.825px 0 rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  margin-bottom: 32px;
`;

const TableHeader = styled.th`
  padding: 10px;
  text-align: left;
  background: #f6f8fa;
  color: #0f1419;
  font-style: normal;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  align-self: stretch;
`;

const TableRowHeader = styled.tr`
  border: none;
`;

const TableRow = styled.tr<{ $isSelected?: boolean }>`
  border-top: 1px solid #c7eaec;
  background: ${(props) => (props.$isSelected ? '#EDFEFF' : 'transparent')};
`;

const TableCell = styled.td`
  padding: 16px 10px;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  position: relative;
`;

const StatusCircle = styled.span<StatusCircleProps>`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;

  background-color: ${(props) => {
    switch (props.status) {
      case 'Активна':
        return '#89E141';
      case 'Откланена':
        return '#FF3838';
      case 'На рассмотрение':
        return '#FFD54E';
      case 'Просрочена':
        return ' #FF9C41';
      default:
        return 'gray';
    }
  }};
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  z-index: 1000;
`;

const BoxInput = styled.div`
  display: flex;
  align-items: center;
  padding-right: 20px;
  padding-bottom: 20px;
`;

const BoxInputTitle = styled.span`
  font-size: 16px;
  width: 200px;
  margin-right: 10px;
`;

const BigBoxInputTitle = styled.span`
  font-size: 20px;
  font-style: italic;
`;

const Box = styled.div`
  background-color: #fff;
  max-width: 400px;
  margin: 0 auto; /* Horizontally center the box */
  padding-right: 10px;
  text-align: center;
`;

export {
  BoxInput,
  BoxInputTitle,
  BigBoxInputTitle,
  Box,
  Container,
  Title,
  Table,
  TableHeader,
  TableRowHeader,
  TableRow,
  TableCell,
  BoxHeader,
  ControlBox,
  ButtonBox,
  FilterBox,
  StatusCircle,
  IconWrapper,
  ModalWrapper,
};
