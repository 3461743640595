import React, { useEffect, useState } from 'react';
import {
  ModalWrapper,
  Title,
  Box,
  BoxInput,
  BoxInputTitle,
  TextDiv,
  ButtonEdit,
} from './EditModalStyle';
import { Button } from 'components/common/Button';
import { UserDetails, UserEditDetails } from '../../AdminSettingsInterface';
import * as api from '../../admin-settings.api';
import { Input } from '../../../common/Input/InputText/InputText.style';
import { EditRole } from './EditRole/EditRole';

interface EditUserProps {
  userDetails: UserDetails | null;
  onClose: () => void;
  id: number;
  onUpdateUser: (updatedUser: UserDetails) => void;
}

export const EditUserModal: React.FC<EditUserProps> = ({
  userDetails,
  onClose,
  id,
  onUpdateUser,
}) => {
  const [isModal, setIsModal] = useState(true);
  const [editingData, setEditingData] = useState<UserEditDetails | null>(null); // Updated to accept null
  const [editRole, setEditRole] = useState(false);

  useEffect(() => {
    setEditingData(userDetails);
  }, [userDetails]);
  const closeModal = () => {
    setIsModal(false);
    onClose();
  };

  const handleSaveClick = () => {
    if (editingData) {
      const changes: Partial<UserEditDetails> = {};

      if (editingData.login !== userDetails?.login) {
        changes.login = editingData.login;
      }

      if (editingData.iin !== userDetails?.iin) {
        changes.iin = editingData.iin;
      }
      if (editingData.bin !== userDetails?.bin) {
        changes.bin = editingData.bin;
      }

      if (editingData.email !== userDetails?.email) {
        changes.email = editingData.email;
      }
      if (editingData.fio !== userDetails?.fio) {
        changes.fio = editingData.fio;
      }

      if (editingData.phone !== userDetails?.phone) {
        changes.phone = editingData.phone;
      }

      if (editingData.phone !== userDetails?.phone) {
        changes.phone = editingData.phone;
      }
      if (editingData.roles !== userDetails?.roles) {
        changes.roles = editingData.roles;
      }
      api
        .editUser(id, changes)
        .then((response) => {
          onUpdateUser(response?.data);
        })
        .catch((error: any) => {
          console.error('Error editing of user:', error);
        });
      closeModal();
    }
  };

  const handleOpenEditRole = () => {
    setEditRole(true);
  };

  const handleSelectRole = (selectedRole: string) => {
    setEditingData({ roles: [selectedRole] });
  };
  return (
    <>
      {isModal && (
        <ModalWrapper>
          <div className='modal'>
            <Title>Редактирование пользователя</Title>
            <div className='modal-content'>
              <form>
                <BoxInput>
                  <BoxInputTitle>Логин:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData?.login}
                    onChange={(e) => setEditingData({ ...editingData, login: e.target.value })}
                  />
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>ИИН:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData?.iin}
                    onChange={(e) => setEditingData({ ...editingData, iin: e.target.value })}
                  />
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>БИН:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData?.bin}
                    onChange={(e) => setEditingData({ ...editingData, bin: e.target.value })}
                  />
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>Email:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData?.email}
                    onChange={(e) => setEditingData({ ...editingData, email: e.target.value })}
                  />
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>Телефон:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData?.phone}
                    onChange={(e) => setEditingData({ ...editingData, phone: e.target.value })}
                  />
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>ФИО:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData?.fio}
                    onChange={(e) => setEditingData({ ...editingData, fio: e.target.value })}
                  />
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>Роли:</BoxInputTitle>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}>
                    <TextDiv>{editingData?.roles && editingData.roles.join(', ')}</TextDiv>
                    <ButtonEdit type='button' onClick={handleOpenEditRole}>
                      Изменить роль
                    </ButtonEdit>
                  </div>
                </BoxInput>
                {editRole && (
                  <div style={{ position: 'absolute', zIndex: 9999 }}>
                    <EditRole roles={editingData?.roles} onSelectRole={handleSelectRole} />
                  </div>
                )}
                <Box>
                  <Button onClick={handleSaveClick} text={'Сохранить'} />
                  <Button onClick={closeModal} text={'Закрыть'} />
                </Box>
              </form>
            </div>
          </div>
        </ModalWrapper>
      )}
    </>
  );
};
