import styled from 'styled-components';

const TitleBox = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
  }
`;

const Content = styled.div`
  display: flex;
  border-radius: 4px;
  background: #f6f8fa;
  padding: 20px;
  gap: 40px;
  flex-wrap: wrap;
`;

const Card = styled.div`
  flex-basis: calc(50% - 20px); /* Three cards with some space between them */
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: #fff;
  padding: 20px;
  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.06);
  h3 {
    color: #0d0d0d;
    font-size: 32px;
    font-weight: 600;
    line-height: 125%;
    margin-bottom: 50px;
  }

  .right {
    display: flex;
    gap: 20px;
    align-items: center;
    p {
      color: #292929;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
    }
  }
`;

const Info = styled.p`
  color: var(--grayscale-600, #757575);
  font-size: 14px;
  font-weight: 400;
  line-height: 126%;
  max-width: 180px;
`;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
`;

export { Content, TitleBox, Card, Info, Box };
