import React, { useState, useEffect } from 'react';
import {
  CustomButton,
  Container,
  LeftBlock,
  RightBlock,
  LogoImg,
  LogoBox,
  WelcomeText,
  Divider,
  DividerBox,
  LinkText,
  SubText,
  ContentLeft,
  FAQSection,
  StyledFAQItem,
  FAQTitle,
  FAQContent,
  FAQIcon,
  StyledLink,
} from './EnterSite.style';
import { default as Logo } from 'assets/img/Logo.svg';
import { default as IconInfo } from 'assets/img/iconI.png';
import { default as KeyECP } from 'assets/img/KeyECP.webp';
import { useTranslation } from 'react-i18next';
import { DefaultNCALayerError, NCALayerNotEnabledError } from 'components/ncaLayer/ncaLayerError';
import { signAuth, signX509 } from 'components/ncaLayer/DigitalSignatureLib';
import { sendSign } from 'components/api/authorization.api';
import { ErrorModal } from 'components/common/ErrorModal';
import { ISignBody } from 'components/api/registration.interface';
import { getCertFromSign } from 'components/api/registration.api';
import ModalRegistration from './ModalRegistration';
import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/`;

type EnterButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
};

type ArrowIconProps = {
  rotated: boolean;
};

type FAQItemProps = {
  index: number;
  isOpen: boolean;
  onClick: (index: number) => void;
};

const faqData = [
  {
    title: 'Подготовьте  ЭЦП',
    content: (
      <>
        <div>
          <span>Файл эцп на носителе</span>
          <div>
            {' '}
            <img src={KeyECP} alt='exampleKey' />
          </div>
          <p>
            Первый необходим для регистрации , второй для подписи, например подписи договоров на
            платформе
          </p>
        </div>
        <div>
          <span>Почему необходимо ЭЦП для регистрации ?</span>
          <p>
            ЭЦП обеспечивает прозрачность и доверие между участниками платформы, позволяя проводить
            проверку и аутентификацию пользователей. Также он гарантирует юридическую значимость
            сделок, делая их такими же законными, как и договоры на бумаге
          </p>
        </div>
      </>
    ),
  },
  {
    title: 'Как мне получить ЭЦП если у меня его нет?',
    content: (
      <>
        <div>
          <span>Получение ЭЦП удаленно</span>
          <div>
            <StyledLink
              href='https://www.gov.kz/services/4362?lang=ru'
              target='_blank'
              rel='noopener noreferrer'>
              https://www.gov.kz/services/4362?lang=ru
            </StyledLink>
          </div>
        </div>
      </>
    ),
  },
  {
    title: 'Где скачать NCAlayer?',
    content: (
      <>
        <div>
          <div>
            <StyledLink
              href='https://pki.gov.kz/en/ncalayer-2/'
              target='_blank'
              rel='noopener noreferrer'>
              https://pki.gov.kz/en/ncalayer-2/
            </StyledLink>
          </div>
        </div>
      </>
    ),
  },
];

const KeyIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <g clipPath='url(#clip0_1747_24719)'>
      <path
        d='M4.33325 18.2295C4.33325 18.6107 4.4847 18.9764 4.75429 19.246C5.02387 19.5155 5.3895 19.667 5.77075 19.667C6.152 19.667 6.51763 19.5155 6.78722 19.246C7.0568 18.9764 7.20825 18.6107 7.20825 18.2295C7.20825 17.8482 7.0568 17.4826 6.78722 17.213C6.51763 16.9434 6.152 16.792 5.77075 16.792C5.3895 16.792 5.02387 16.9434 4.75429 17.213C4.4847 17.4826 4.33325 17.8482 4.33325 18.2295Z'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.1459 0.979492L10.7715 10.3549C9.3497 9.61914 7.71164 9.41615 6.15324 9.78254C4.59484 10.1489 3.21884 11.0606 2.2738 12.3528C1.32877 13.6449 0.876995 15.2325 1.0001 16.8287C1.1232 18.4248 1.81306 19.9243 2.94506 21.0563C4.07706 22.1883 5.57657 22.8782 7.17272 23.0013C8.76888 23.1244 10.3564 22.6726 11.6486 21.7276C12.9408 20.7825 13.8524 19.4065 14.2188 17.8481C14.5852 16.2897 14.3822 14.6517 13.6465 13.2299L16.7918 10.0837H18.2293V8.64616L18.7084 8.16699H20.1459V6.72949L20.6251 6.25033H22.0626V4.81283L23.0209 3.85449V0.979492H20.1459Z'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1747_24719'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

const ArrowIcon: React.FC<ArrowIconProps> = ({ rotated }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='17'
    height='16'
    viewBox='0 0 17 16'
    fill='none'
    style={{
      transform: rotated ? 'rotate(180deg)' : 'rotate(0deg)',
      transition: 'transform 0.3s ease',
    }}>
    <path
      d='M4.5 6L8.5 10L12.5 6'
      stroke='black'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const FAQItem: React.FC<FAQItemProps> = ({ index, isOpen, onClick }) => {
  const { title, content } = faqData[index];

  return (
    <StyledFAQItem>
      <FAQTitle onClick={() => onClick(index)}>
        <div>
          <img src={IconInfo} alt='iconInfo' />
          {title}
        </div>
        <FAQIcon>
          <ArrowIcon rotated={isOpen} />
        </FAQIcon>
      </FAQTitle>
      {isOpen && <FAQContent>{content}</FAQContent>}
    </StyledFAQItem>
  );
};

const EnterButton = ({ children, onClick }: EnterButtonProps) => (
  <CustomButton onClick={onClick}>
    <KeyIcon />
    {children}
  </CustomButton>
);

export const EnterSite = () => {
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorDialogText, setErrorDialogText] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState<ISignBody | null>(null);
  const [signXml, setSignXml] = useState<string>('');
  const [urlToken, setUrlToken] = useState<string | null>(null);

  const { t } = useTranslation();

  const [faqOpen, setFaqOpen] = useState<number | null>(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const refToken = urlParams.get('ref');
    setUrlToken(refToken);
  }, []);

  const handleFAQClick = (index: number) => {
    setFaqOpen(faqOpen === index ? null : index);
  };

  const handleErrorCallback = (error: any) => {
    console.error('Error object:', error);
    if (error !== DefaultNCALayerError && error !== NCALayerNotEnabledError) {
      setErrorDialogText(t('loginAndRegister.errorNcaLayer'));
      setErrorDialogOpen(true);
    }
  };

  const handleAuthX509 = async () => {
    try {
      signX509('<root></root>', {
        format: 'x509',
        data_signed_callback: async (x509Data: string) => {
          try {
            const { data } = await sendSign(x509Data);
            await axios.get(`${BASE_URL}api/v1/ui`, { withCredentials: true });

            // Перенаправление на оригинальный URL после успешной аутентификации
            const urlParams = new URLSearchParams(window.location.search);
            const redirectTo = urlParams.get('redirectTo');
            window.location.href = redirectTo ? decodeURIComponent(redirectTo) : '/applications';
          } catch (error: any) {
            if (error.response) {
              if (error.response.status === 406) {
                setErrorDialogText(
                  'Вы обновили сертификат. Пожалуйста, пройдите регистрацию повторно',
                );
              } else if (error.response.status === 401) {
                console.error(error);
                setErrorDialogText(
                  'Проблема с аутентификацией. Пожалуйста, проверьте введенные данные и повторите попытку.',
                );
              } else if (error.response.status === 404) {
                setErrorDialogText(
                  'Сертификат не найден. Пожалуйста, зарегистрируйтесь в системе.',
                );
              } else {
                setErrorDialogText('Произошла ошибка на сервере.');
              }
            } else {
              console.error('Unexpected error:', error);
              setErrorDialogText('Произошла неизвестная ошибка.');
            }
            setErrorDialogOpen(true);
          }
        },
        error_callback: handleErrorCallback,
      });
    } catch (e) {
      handleErrorCallback(e);
    }
  };

  const handleSignedData = async (xml: string) => {
    try {
      const response = await getCertFromSign(xml);
      setSignXml(xml);
      setData(response.data);
      setShowModal(true);
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === 400) {
          setErrorDialogText('Вы уже зарегистрированы на платформе');
        } else if (error.response.status === 403) {
          setErrorDialogText(t('loginAndRegister.errorConnect'));
        } else {
          setErrorDialogText(t('loginAndRegister.errorConnect'));
        }
      } else {
        setErrorDialogText('Неизвестная ошибка');
      }
      setErrorDialogOpen(true);
    }
  };

  const handleSignXML = async () => {
    signAuth('<root></root>', {
      data_signed_callback: handleSignedData,
      error_callback: handleErrorCallback,
    });
  };

  return (
    <Container>
      <LeftBlock>
        <ContentLeft>
          <LogoBox>
            <LogoImg src={Logo} alt='MoneyLink Logo' />
          </LogoBox>
          <WelcomeText>Добро пожаловать на MoneyLink</WelcomeText>
          <SubText>Выгодное инвестирование с доходностью выше, чем по вкладам</SubText>
          <EnterButton onClick={handleAuthX509}>Войти по ЭЦП</EnterButton>
          <DividerBox>
            <Divider />
            Или
            <Divider />
          </DividerBox>
          <LinkText onClick={handleSignXML}>Создать аккаунт с использованием ЭЦП</LinkText>
        </ContentLeft>
      </LeftBlock>
      <RightBlock>
        <FAQSection>
          {faqData.map((_, index) => (
            <FAQItem
              key={index}
              index={index}
              isOpen={faqOpen === index}
              onClick={handleFAQClick}
            />
          ))}
        </FAQSection>
      </RightBlock>

      {errorDialogOpen && (
        <ErrorModal
          headerError={t('loginAndRegister.errorTitle')}
          textError={errorDialogText}
          buttonClose={t('loginAndRegister.buttonClose')}
          onClick={() => setErrorDialogOpen(false)}
        />
      )}
      {data && showModal && (
        <ModalRegistration
          urlToken={urlToken}
          token={data.token}
          organization={data.organization}
          onClose={() => setShowModal(false)}
          user={data.user}
          xml={signXml}
        />
      )}
    </Container>
  );
};
