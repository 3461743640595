import React from 'react';
import { Tag } from './TagsWhiteLabel.style';

interface TagsWhiteLabelProps {
  text: string;
  className?: string;
}

export const TagsWhiteLabel = ({ text, className }: TagsWhiteLabelProps) => {
  return <Tag className={className}>{text}</Tag>;
};
