import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 30px;
  width: 100%;
  height: 100%;
`;

const HeaderBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const BreadCrumbsBox = styled.div`
  display: flex;
  gap: 16px;
  color: #5f5f5f;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  p a {
    color: inherit; /* Задает цвет текста, идентичный текущему */
    text-decoration: none;
  }
  span {
    cursor: pointer;
  }
  .select {
    border-bottom: 1px solid #01aeb7;
  }
`;

const TitleBox = styled.div`
  display: flex;
  margin-top: 40px;
  gap: 40px;
  h2 {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
  }
`;
const TagRisk = styled.div`
  display: inline-flex;
  background: var(--Disable, #dee2e6);
  border-radius: 500px;
  padding: 13px 60px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  color: var(--Black-Main, #292d32);

  text-overflow: ellipsis;
  font-family: Jost;
  font-size: 18px;
  font-weight: 500;
`;

const BoxContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 4px;
  background: #f6f8fa;
  gap: 32px;
  padding: 40px;
  max-height: 1300px;
  margin-bottom: 40px;
  input {
    max-width: 540px;
  }
`;

const BoxInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 540px;
`;

const Title = styled.p`
  color: #0d0d0d;
  font-family: Jost;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
`;

const Box = styled.div`
  display: flex;
  gap: 20px;
`;

const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 380px;
  height: 100%;
  margin-bottom: 40px;
  gap: 32px;
`;

const DocumentsBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px;
  gap: 20px;
  border-radius: 4px;
  background: #f6f8fa;

  h3 {
    color: #333;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
    li {
      color: var(--Control, #9aa4b0);
      font-size: 14px;
      font-weight: 400;
      text-decoration-line: underline;

      cursor: pointer;
      &:hover {
        color: #07bec8;
      }
    }
  }
`;

const ButtonBox = styled.div`
  display: flex;
  margin-top: 32px;
  gap: 12px;
`;

const FilesLength = styled.div`
  color: var(--Primary, #01aeb7);
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  justify-content: space-between;
  img {
    cursor: pointer;
  }
`;

const TitleDoc = styled.h4`
  color: var(--Control, #9aa4b0);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;

const BoxDoc = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LoadingButton = styled.label`
  border-radius: 6px;
  background: var(--white-100, #fff);
  border: 1px solid var(--Disable, #dee2e6);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--Primary, #01aeb7);
  font-size: 14px;
  font-weight: 600;
  padding: 20px 12px;
  cursor: pointer;
  gap: 8px;
  &:hover {
  }
`;

export {
  Container,
  HeaderBox,
  BreadCrumbsBox,
  TitleBox,
  TagRisk,
  BoxContent,
  Box,
  DocumentsBox,
  RightBox,
  ButtonBox,
  Title,
  FilesLength,
  TitleDoc,
  BoxDoc,
  BoxInput,
  LoadingButton,
};
