import React, { useState, DragEvent, useEffect } from 'react';
import axios from 'axios';
import {
  ApplicationDocumentsListProps,
  ApplicationFile,
} from './ApplicationDocumentsList.interface';
import IconDocuments from 'assets/img/IconDocuments.svg';
import IconArrow from 'assets/img/IconArrow.svg';
import IconArrowDown from 'assets/img/IconArrowDown.svg';
import { default as GroupIconDecline } from 'assets/img/GroupIconDecline.svg';
import { default as GroupIconEdit } from 'assets/img/GroupIconEdit.svg';

import {
  Container,
  MainInfoBox,
  FileItem,
  FileLink,
  DeleteIcon,
  UploadBox,
  FileUploadArea,
  FileUploadInput,
  WarningMessage,
  DocumentsBox,
  ButtonBox,
  EditIcon,
} from './ApplicationDocumentsList.style';
import {
  ExpandableSection,
  MainInfoTitle,
  ArrowIcon,
  MainInfoItemText,
} from 'components/ApplicationInvestor/ApplicationInvestorNew.style';
import { DocLinkBox } from '../RequestDetailsNew.style';
import { WarningModal } from 'components/common/WarningModal';
import { useFileDownload } from 'utils/hooks';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes

export const ApplicationDocumentsList: React.FC<ApplicationDocumentsListProps> = ({
  files,
  isEditing,
  onFilesChange,
  setIsUploading,
  isUploading,
  resetFiles,
  onDeleteFile,
  docLink,
}) => {
  const [isMainInfoExpanded, setIsMainInfoExpanded] = useState(true);
  const [isMainInfoExpandedBank, setIsMainInfoExpandedBank] = useState(true);
  const [uploadedFiles, setUploadedFiles] = useState<ApplicationFile[]>(files ?? []);
  const [warningMessage, setWarningMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [externalLink, setExternalLink] = useState<string | null>(null);
  const { downloadFile } = useFileDownload();

  useEffect(() => {
    setUploadedFiles(files);
  }, [files]);

  const handleDeleteFile = (fileId: string) => {
    onDeleteFile(fileId); // Call parent handler
  };

  const handleFileUpload = async (file: File) => {
    if (file.size > MAX_FILE_SIZE) {
      setWarningMessage('File size exceeds the 5MB limit.');
      return;
    }

    setWarningMessage('');
    const formData = new FormData();
    formData.append('file', file);

    try {
      setIsUploading(true);
      const response = await axios.post(`${BASE_URL}ui/file`, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const newFile: ApplicationFile = { file: response.data.uid, code: null, title: null };
      const updatedFiles = [...uploadedFiles, newFile];
      setUploadedFiles(updatedFiles);
      onFilesChange(updatedFiles); // Update parent component
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const onDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      handleFileUpload(event.dataTransfer.files[0]);
      event.dataTransfer.clearData();
    }
  };

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (resetFiles) {
      setUploadedFiles(files);
    }
  }, [resetFiles, files]);

  const handleDocLinkClick = () => {
    setExternalLink(docLink);
    setIsModalOpen(true);
  };

  const handleModalConfirm = () => {
    if (externalLink) {
      window.open(externalLink, '_blank');
    }
    setIsModalOpen(false);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setExternalLink(null);
  };

  return (
    <Container>
      {/* General Documents Section */}
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsMainInfoExpanded(!isMainInfoExpanded)}>
          <img src={IconDocuments} alt='icon' />
          Другие документы
          <ArrowIcon src={isMainInfoExpanded ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isMainInfoExpanded && (
          <MainInfoBox>
            {docLink && docLink !== '' && (
              <DocLinkBox>
                <MainInfoItemText>Ссылка на гугл папку</MainInfoItemText>
                <a onClick={handleDocLinkClick} style={{ cursor: 'pointer' }}>
                  {docLink}
                </a>
              </DocLinkBox>
            )}
            {uploadedFiles.length > 0 ? (
              uploadedFiles
                .filter((file) => file.code === null) // Show files where code is null (general documents)
                .map((file) => (
                  <FileItem key={file.file}>
                    <FileLink onClick={() => downloadFile(file.file)}>
                      <img src={IconDocuments} alt='download icon' />
                      {file.title}
                    </FileLink>
                    <ButtonBox>
                      <EditIcon>
                        <img src={GroupIconEdit} alt='delete icon' />
                      </EditIcon>
                      <DeleteIcon onClick={() => handleDeleteFile(file.file)}>
                        <img src={GroupIconDecline} alt='delete icon' />
                      </DeleteIcon>
                    </ButtonBox>
                  </FileItem>
                ))
            ) : (
              <div>Документы отсутствуют</div>
            )}
          </MainInfoBox>
        )}
      </ExpandableSection>

      {/* Bank Statement Documents Section */}
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsMainInfoExpandedBank(!isMainInfoExpandedBank)}>
          <img src={IconDocuments} alt='icon' />
          Документы банковской выписки
          <ArrowIcon src={isMainInfoExpandedBank ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isMainInfoExpandedBank && (
          <MainInfoBox>
            {uploadedFiles
              .filter((file) => file.code === 'BANK_STATEMENT')
              .map((file) => (
                <FileItem key={file.file}>
                  <FileLink onClick={() => downloadFile(file.file)}>
                    <img src={IconDocuments} alt='download icon' />
                    {file.title}
                  </FileLink>
                  {file.status && <span>{file.status.name.text_ru}</span>}
                  <ButtonBox>
                    <EditIcon>
                      <img src={GroupIconEdit} alt='delete icon' />
                    </EditIcon>
                    <DeleteIcon onClick={() => handleDeleteFile(file.file)}>
                      <img src={GroupIconDecline} alt='delete icon' />
                    </DeleteIcon>
                  </ButtonBox>
                </FileItem>
              ))}
            {uploadedFiles.filter((file) => file.code === 'BANK_STATEMENT').length === 0 && (
              <div>Документы отсутствуют</div>
            )}
          </MainInfoBox>
        )}
      </ExpandableSection>

      {isModalOpen && (
        <WarningModal
          headerWarning='Предупреждение'
          textWarning='Вы переходите на внешнюю ссылку'
          buttonClose='Согласен'
          onClick={handleModalConfirm}
          onClose={handleModalClose}
        />
      )}
    </Container>
  );
};
