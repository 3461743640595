import { styled } from 'styled-components';
import colors from 'global_styling/colors';

interface BoxSelectProps {
  isDisabled?: boolean;
}

const BoxSelect = styled.div<BoxSelectProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;

  input {
    border: none;
    width: 100%;
    padding-left: 30px;
    color: var(--Text-Input, #292d32);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    &:focus {
      outline: none; /* Убираем стандартный фокус */
      border: none;
      color: #292d32;
    }
  }

  .dropdown-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    background: var(--White, #fff);
    border: 1px solid #b3bbc4;
    color: var(--Control, #9aa4b0);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    margin-bottom: 10px;
    gap: 20px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    ${(props) => props.isDisabled && `border: 1px solid red;`}
    ${(props) => props.isDisabled && `cursor:default;`}
  }
  &.open .dropdown-header {
    border: 1px solid #5784f7;
    color: #292d32;
  }

  .arrow-down,
  .arrow-up {
    width: 10px;
    height: 10px;
    transition: transform 0.3s ease;
  }

  .arrow-up {
    transform: rotate(180deg);
  }

  .options {
    display: none;
    flex-direction: column;
    position: absolute;
    width: 100%;
    top: 100%;
    background: var(--White, #fff);
    z-index: 2;
    border-radius: 8px;
    color: var(--Black-Main, #292d32);
    box-shadow: 0px 8px 16px 0px rgba(30, 34, 72, 0.16);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
    max-height: 130px;
    overflow-y: auto;
  }

  .option {
    padding: 10px 24px;
    cursor: pointer;
    &:hover {
      background: var(--Primary-Highlight, #def5f8);
    }
  }

  &.open .options {
    display: flex;
  }

  .options::-webkit-scrollbar {
    width: 4px; /* ширина для вертикального скроллбара */
    height: 4px; /* высота для горизонтального скроллбара */
  }

  .options::-webkit-scrollbar-button {
    display: none; /* скройте стрелки */
  }

  .options::-webkit-scrollbar-thumb {
    background: ${colors.CHARCOAL}; /* цвет ползунка */
  }

  .options::-webkit-scrollbar-thumb:hover {
    background: ${colors.TEAL}; /* цвет ползунка при наведении */
  }

  .options::-webkit-scrollbar-track {
    background: ${colors.SOFT_GRAY};
    border-radius: 4px;
  }

  .options::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

const InputBox = styled.div`
  display: flex;
  align-items: center; /* Выравнивание элементов по центру по вертикали */
  position: relative;
  width: 100%;

  .search-icon {
    position: absolute;
    left: 0px; /* Отступ слева для иконки поиска */
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

  input {
    width: 100%;
    border-radius: 6px;

    &:focus {
      outline: none;
      border-color: #01a5ad;
    }

    &:disabled {
      background-color: #fff;
    }
  }
`;

const SpinnerLoading = styled.div`
  position: absolute;
  right: 46%; /* Отступ справа для спиннера */
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 2px solid #01a5ad;
  animation: spin 1s linear infinite;
  cursor: none;
  @keyframes spin {
    0% {
      transform: translateY(-50%) rotate(0deg);
    }
    100% {
      transform: translateY(-50%) rotate(360deg);
    }
  }
`;

export { BoxSelect, InputBox, SpinnerLoading };
