import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/`;

const axiosInstanceJSON = axios.create({
  baseURL: BASE_URL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const sendSign = (x509: string) => {
  return axiosInstanceJSON.post('signin', { x509 });
};
