import React, { useEffect, useState } from 'react';
import {
  Container,
  Title,
  Table,
  TableHeader,
  TableRowHeader,
  TableRow,
  TableCell,
  ButtonSmallBlock,
  ButtonSmallEdit,
} from './OrganizationListStyle';
import { OrganizationDetails } from '../../AdminSettingsInterface';
import { EditOrgModal } from '../../Modal/editModal/EditOrgModal';
import { default as IconThreeDots } from 'assets/img/IconThreeDots.svg';
import { default as IconLock } from 'assets/img/IconLock.svg';
import { BlockOrgModal } from '../../Modal/blockModal/BlockOrgModal';
import * as api from '../../admin-settings.api';

export const OrganizationList = () => {
  const [selectedRow, setSelectedRow] = useState<OrganizationDetails | null>(null);
  const [tableData, setTableData] = useState<OrganizationDetails[]>([]);
  const [editOrgModal, setEditOrgModal] = useState(false);
  const [blockModal, setBlockModal] = useState(false);

  const handleEditClick = (row: OrganizationDetails) => {
    setEditOrgModal(true);
    setSelectedRow(row);
  };

  const handleBlockClick = (row: OrganizationDetails) => {
    setSelectedRow(row);
    setBlockModal(true);
  };

  const handleUpdateOrganization = (organizationDetails: OrganizationDetails) => {
    const updatedData = tableData.map((organization) =>
      organization.id === organizationDetails.id ? organizationDetails : organization,
    );
    setTableData(updatedData);
    setEditOrgModal(false);

    api
      .userList()
      .then((response) => {
        setTableData(response.data);
      })
      .catch((error) => {
        console.error('Ошибка обновления данных:', error);
      });
  };

  useEffect(() => {
    api.organizationList().then((response) => {
      setTableData(response.data);
    });
  }, []);

  const getRoleLabel = (role: string): string => {
    switch (role) {
      case 'BUSINESS':
        return 'Бизнес';
      case 'INVESTOR':
        return 'Инвестор';
      case 'AGENT':
        return 'Агент';
      default:
        return '';
    }
  };

  const organizationTypeMap: Record<string, string> = {
    SL: 'ИП',
    LLC: 'ТОО',
  };

  return (
    <Container>
      <Title>Список организаций</Title>
      <Table>
        <thead>
          <TableRowHeader>
            <TableHeader>Название</TableHeader>
            <TableHeader>ИИН/БИН</TableHeader>
            <TableHeader>Телефон</TableHeader>
            <TableHeader>Руководитель</TableHeader>
            <TableHeader>Роль компании</TableHeader>
            <TableHeader>Тип компании</TableHeader>
            <TableHeader>Заблокирован</TableHeader>
            <TableHeader>Редактирование</TableHeader>
            <TableHeader>Блокировка</TableHeader>
            <TableHeader></TableHeader>
          </TableRowHeader>
        </thead>
        <tbody>
          {tableData.map((row) => (
            <TableRow key={row.id} $isSelected={row.id === selectedRow?.id}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.iinbin}</TableCell>
              <TableCell>{row.phone}</TableCell>
              <TableCell>{row.ceoName}</TableCell>
              <TableCell>{getRoleLabel(row.organizationRole)}</TableCell>
              <TableCell>{organizationTypeMap[row.organizationType] || 'Не указано'}</TableCell>
              <TableCell>
                {row.whiteLabels && row.whiteLabels.length > 0 ? (
                  row.whiteLabels.map((whiteLabel, index) => (
                    <div key={index}>
                      <a href={whiteLabel.baseUrl} target='_blank' rel='noopener noreferrer'>
                        {whiteLabel.name.text_ru}
                      </a>
                    </div>
                  ))
                ) : (
                  <span>Нет данных</span>
                )}
              </TableCell>
              <TableCell>{row.locked ? 'Да' : 'Нет'}</TableCell>
              <TableCell>
                <ButtonSmallEdit onClick={() => handleEditClick(row)}>
                  <img src={IconThreeDots} alt='icon-three' />
                </ButtonSmallEdit>
              </TableCell>
              <TableCell>
                <ButtonSmallBlock onClick={() => handleBlockClick(row)}>
                  <img src={IconLock} alt='icon-lock' />
                </ButtonSmallBlock>
              </TableCell>
              {editOrgModal && (
                <EditOrgModal
                  organizationEditDetails={selectedRow}
                  onClose={() => setEditOrgModal(false)}
                  id={selectedRow?.id || 0}
                  onUpdateOrganization={handleUpdateOrganization}
                />
              )}
              {blockModal && (
                <BlockOrgModal
                  onClose={() => setBlockModal(false)}
                  id={selectedRow?.id || 0}
                  name={selectedRow?.name || ''}
                />
              )}
            </TableRow>
          ))}
        </tbody>
      </Table>
      {/*<Pagination*/}
      {/*  pageCount={Math.ceil(filteredData.length / rowsPerPage)}*/}
      {/*  currentPage={currentPage}*/}
      {/*  onPageChange={handlePageChange}*/}
      {/*/>*/}
      {/*{isCreateModalOpen && <CreateApplication closeModal={closeModal} />}*/}
    </Container>
  );
};
