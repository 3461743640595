import { styled } from 'styled-components';
import colors from 'global_styling/colors';
import Banner from 'assets/img/BannerRegisterModal.webp';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  margin: 0 auto;
  position: absolute;
  color: ${colors.CHARCOAL};
  width: 100%;
  min-height: 100vh;
  background-image: url(${Banner});
  background-size: cover;
  background-position: center;
  backdrop-filter: blur(100px); /* Adds a blur effect to the background */
`;

const BoxContent = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  justify-content: center;
  margin-top: 24px;
`;

const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 548px;
  width: 100%;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.9); /* White background with some transparency */
  min-height: 580px;
  justify-content: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Adds a shadow for better visibility */
`;

const RightBlock = styled.div`
  flex: 1;
  max-width: 548px;
  position: relative;
  display: flex;
`;

const LogoImg = styled.img`
  width: 148px;
  height: 40px;
`;

const BoxInput = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 548px;
  width: 100%;
`;

const Title = styled.h1`
  color: var(--Black-Main, #292d32);
  font-size: 20px;
  font-weight: 600;
  line-height: 106%;
  margin-bottom: 6px;
  margin-top: 6px;
`;

const Text = styled.div`
  color: #5f5f5f;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  gap: 10px;
  a {
    color: ${colors.TEAL};
  }
  p {
    color: #0d0d0d;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  gap: 8px;
  input {
    padding: 10px;
  }
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })``;

const Button = styled.button`
  padding: 16px 28px;
  color: white;
  background: var(--Primary, #5784f7);
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0px 4px 6.7px 0px rgba(87, 132, 247, 0.12);
  transition: background 0.3s ease;
  width: 100%;
  &:hover {
    background: var(--primary-400, #0f62fe);
  }

  &:disabled {
    background: var(--Button-button-secondary, #393939);
    cursor: not-allowed;
    &:hover {
      background: var(--neutral-500, #848484);
    }
  }
`;

const BoxSelect = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  select {
    padding: 4px;
    border-radius: 6px;
  }
`;

const BoxRadioInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 10px 0px;
`;

const BoxCheck = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    margin-left: 10px;
    color: var(--Control, #9aa4b0);
    font-size: 14px;
    font-weight: 400;
    line-height: 125%;
  }
  a {
    color: var(--Primary, #5784f7);
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 125%;
    margin-left: 4px;
  }
`;

const FAQSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 8px;
  width: 100%;
  max-width: 406px;
`;

const StyledFAQItem = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px 0px rgba(7, 17, 43, 0.07);
  overflow: hidden;
  :hover {
    background: var(--primary-50, #dfe8fd);
  }
`;

const FAQTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  cursor: pointer;
  color: var(--neutral-900, #141414);
  font-family: Jost;
  font-size: 20px;
  font-weight: 600;
  line-height: 125%; /* 23.193px */
  div {
    display: flex;
    gap: 20px;
    align-items: center;
    img {
      width: 16px;
      height: 16px;
    }
  }
`;

const FAQIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FAQContent = styled.div`
  padding: 0px 24px 24px 24px;
  background-color: #fff;
  display: flex;
  gap: 20px;
  flex-direction: column;
  p {
    margin-top: 6px;
    color: #5e5e5e;
    font-size: 16px;
    font-weight: 500;
    line-height: 125%; /* 23.193px */
  }
  span {
    color: #0d0d0d;
    font-size: 20px;
    font-weight: 500;
    line-height: 130%; /* 19.2px */
  }
`;

export {
  Container,
  Title,
  Text,
  CheckboxContainer,
  Checkbox,
  Button,
  BoxSelect,
  BoxRadioInput,
  LeftBlock,
  BoxInput,
  BoxCheck,
  LogoImg,
  RightBlock,
  BoxContent,
  FAQSection,
  StyledFAQItem,
  FAQTitle,
  FAQContent,
  FAQIcon,
};
