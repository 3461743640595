import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { default as IconRedTimer } from 'assets/img/IconRedTimer.svg';
import { default as IconBlackTimer } from 'assets/img/IconBlackTimer.svg';
import { NewButton } from 'components/common/Button';
import {
  Box,
  Wrapper,
  BoxTimer,
  RightBox,
  InfoBox,
  BoxCheck,
  BoxHeader,
  StatusSigned,
  NoAccessConnection,
  ButtonContainer,
} from './DocumentContract.style';
import { Checkbox } from 'components/common/Checkbox';
import { sign } from '../TestStand/signtest/ncalayer';
import { useParams } from 'react-router-dom';
import { useAppContext } from 'contexts/AppContext';

interface ContractScheduleProps {
  fileUid: string;
  signingDtEnd: string;
  investorName: string;
  businessName: string;
  investorSigned: boolean;
  businessSigned: boolean;
  fileHash: string;
  status: string;
  idContract: string | number;
  onFetchData: () => void;
  contractNum: string;
}

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;
const BASE_URL_PUBLIC = `${process.env.REACT_APP_BASE_URL}/public/api/v1/`;

export const DocumentContract: React.FC<ContractScheduleProps> = ({
  fileUid,
  signingDtEnd,
  investorName,
  investorSigned,
  businessSigned,
  businessName,
  fileHash,
  status,
  idContract,
  onFetchData,
  contractNum,
}) => {
  const { userDataLight } = useAppContext();

  const isUserRole =
    userDataLight?.roles.includes('ADMIN') || userDataLight?.roles.includes('MANAGER');
  const [documentUrl, setDocumentUrl] = useState<string>('');
  const [isAgreed, setIsAgreed] = useState(false);
  const [loadingReceipt, setLoadingReceipt] = useState(false);
  const { id } = useParams();

  const isInvestorAndSigned = userDataLight?.organizationRole === 'INVESTOR' && investorSigned;

  const isBusinessAndSigned = userDataLight?.organizationRole === 'BUSINESS' && businessSigned;

  const isSigned = investorSigned && businessSigned && status === 'SIGNED';

  useEffect(() => {
    axios
      .get(`${BASE_URL}ui/file/${fileUid}`, {
        responseType: 'blob',
        withCredentials: true,
      })
      .then((response) => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        setDocumentUrl(fileURL);
      })
      .catch((error) => {
        console.error('Ошибка при получении документа', error);
      });
  }, [fileUid]);

  const handleCheckboxChange = () => {
    setIsAgreed(!isAgreed);
  };

  const calculateRemainingTime = (endDateString: any) => {
    const endDate = new Date(endDateString);
    const now = new Date();
    const timeDiff = endDate.getTime() - now.getTime();

    if (timeDiff <= 0) {
      return 'Время истекло';
    }

    const daysRemaining = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    if (daysRemaining >= 1) {
      return `${daysRemaining} ${daysRemaining === 1 ? 'день' : 'д'}`;
    }

    const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours}:${minutes}`;
  };

  const remainingTime = calculateRemainingTime(signingDtEnd);
  const isLessThanADay = remainingTime.includes('истекло');

  const handleSignClick = async () => {
    const endpoint =
      userDataLight && userDataLight.organizationRole === 'INVESTOR'
        ? `${BASE_URL}cabinet/investor/contract/sign`
        : `${BASE_URL}cabinet/creditor/contract/sign`;

    try {
      await sign(fileHash, endpoint, parseInt(id as string));
      await onFetchData();
    } catch (error) {
      console.error('Ошибка при подписании:', error);
    }
  };

  const handleRefreshContract = async () => {
    const basePath =
      userDataLight && userDataLight.organizationRole === 'INVESTOR'
        ? `${BASE_URL}/cabinet/investor/contract/generate/`
        : `${BASE_URL}/cabinet/creditor/contract/generate/`;

    const refreshUrl = `${basePath}${idContract}`;

    try {
      const response = await axios.get(refreshUrl, {
        withCredentials: true,
      });

      onFetchData();
    } catch (error) {
      console.error('Ошибка при обновлении договора:', error);
    }
  };

  const handleRejectClick = async () => {
    const rejectEndpoint =
      userDataLight && userDataLight.organizationRole === 'INVESTOR'
        ? `${BASE_URL}cabinet/investor/contract/reject/${id}`
        : `${BASE_URL}cabinet/creditor/contract/reject/${id}`;

    try {
      const response = await axios.get(rejectEndpoint, {
        withCredentials: true,
      });
      onFetchData();
      console.log('Ответ на отклонение договора:', response);
    } catch (error) {
      console.error('Ошибка при отклонении договора:', error);
    }
  };

  const isContractActive = () => {
    return status !== 'REJECT_CREDITOR' && status !== 'REJECT_BUSINESS';
  };

  const renderContractStatus = () => {
    const timeHasExpired = remainingTime === 'Время истекло';
    if (status === 'REJECT_CREDITOR') {
      return (
        <InfoBox>
          <StatusSigned>Договор отклонен заемщиком</StatusSigned>
        </InfoBox>
      );
    } else if (status === 'REJECT_BUSINESS') {
      return <StatusSigned>Договор отклонен Инвестором</StatusSigned>;
    } else {
      return (
        <>
          <InfoBox>
            <div>
              <p>{investorName}</p>
              <span style={investorSigned ? { color: 'green' } : {}}>
                {investorSigned ? 'Подписан' : 'Изучает договор'}
              </span>
            </div>
          </InfoBox>
          <InfoBox>
            <div>
              <p>{businessName}</p>
              <span style={businessSigned ? { color: 'green' } : {}}>
                {businessSigned ? 'Подписан' : 'Изучает договор'}
              </span>
            </div>
          </InfoBox>
          {isUserRole && !isInvestorAndSigned && !isBusinessAndSigned && !timeHasExpired && (
            <InfoBox>
              <NewButton
                text='Подписать'
                onClick={isAgreed ? handleSignClick : undefined}
                disabled={!isAgreed}
              />
              <NewButton text='Отклонить' className='reject' onClick={handleRejectClick} />
            </InfoBox>
          )}
          {!isUserRole && (
            <NoAccessConnection>Подписать может только руководитель компании</NoAccessConnection>
          )}
          {!isInvestorAndSigned && !isBusinessAndSigned && !timeHasExpired && (
            <BoxCheck>
              <Checkbox checked={isAgreed} onChange={handleCheckboxChange} />
              <p>С договором ознакомлен(-а)</p>
            </BoxCheck>
          )}
        </>
      );
    }
  };
  const generateQR = async () => {
    const link = 'https://moneylink.kz/';
    const apiUrl =
      userDataLight && userDataLight.organizationRole === 'INVESTOR'
        ? `${BASE_URL}cabinet/investor/contract/generate/qr?contractNumber=${contractNum}&link=${link}`
        : `${BASE_URL}cabinet/creditor/contract/generate/qr?contractNumber=${contractNum}&link=${link}`;
    await downloadPdf(apiUrl, 'Подписанный договор');
  };

  const generateReceipt = async () => {
    setLoadingReceipt(true);
    const apiUrl = `${BASE_URL_PUBLIC}ui/doc/receipt/generate?contractNumber=${contractNum}`;
    try {
      await downloadPdf(apiUrl, 'Квитанция');
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoadingReceipt(false);
    }
  };

  const downloadPdf = async (apiUrl: any, name: string) => {
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        responseType: 'arraybuffer',
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = name;

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <Box>
      {isContractActive() && (
        <Wrapper>
          <BoxHeader>
            <h3>Посмотреть договор</h3>
            <ButtonContainer>
              {userDataLight?.organizationRole !== 'BUSINESS' && (
                <NewButton
                  text='Обновить договор'
                  onClick={handleRefreshContract}
                  disabled={isInvestorAndSigned || isBusinessAndSigned}
                />
              )}
              {isSigned && <NewButton onClick={generateQR} text='Подписанный договор' />}
              {isSigned && (
                <NewButton onClick={generateReceipt} text='Квитанция' loading={loadingReceipt} />
              )}
            </ButtonContainer>
            <BoxTimer>
              <img
                src={isLessThanADay ? IconRedTimer : IconBlackTimer}
                alt='Timer Icon'
                style={{ marginRight: '5px' }}
              />
              <span style={{ color: isLessThanADay ? 'red' : 'inherit' }}>{remainingTime}</span>
            </BoxTimer>
          </BoxHeader>
          <iframe
            src={documentUrl}
            allow={'fullscreen'}
            width='100%'
            height='830px'
            style={{ border: 'none' }}
          />
        </Wrapper>
      )}
      <RightBox>{renderContractStatus()}</RightBox>
    </Box>
  );
};

export default DocumentContract;
