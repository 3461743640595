import $ from 'jquery';
import { useState } from 'react';
import axios from 'axios';
var SOCKET_URL = 'wss://127.0.0.1:13579/';
var response = '';

async function sign(signature, url, contractId) {
  await request(signature, url, contractId);
  return response.toString();
}

function connect() {
  if (connect.webSocket && connect.webSocket.readyState < 2) {
    console.log(
      'reusing the socket connection [state = ' +
        connect.webSocket.readyState +
        ']: ' +
        connect.webSocket.url,
    );
    return Promise.resolve(connect.webSocket);
  }

  return new Promise(function (resolve, reject) {
    const socketUrlWithCredentials = SOCKET_URL + '?withCredentials=true';
    connect.webSocket = new WebSocket(socketUrlWithCredentials);

    connect.webSocket.onopen = function () {
      console.log(
        'socket connection is opened [state = ' +
          connect.webSocket.readyState +
          ']: ' +
          connect.webSocket.url,
      );
      resolve(connect.webSocket);
    };

    connect.webSocket.onerror = function (err) {
      unblockScreen();
      console.error('socket connection error : ', err);
      reject(err);
    };

    connect.webSocket.onclose = function (event) {
      if (event.wasClean) {
        console.error('socket connection is closed ');
      } else {
        console.log('Connection error');
        openDialog();
      }
      console.log('Code: ' + event.code + ' Reason: ' + event.reason);
    };
  });
}

export async function request(signature, url, contractId) {
  // blockScreen();

  var selectedStorages = [];
  var storageCheckboxes = document.querySelectorAll('input[name=storage-check]:checked');
  for (var i = 0; i < storageCheckboxes.length; i++) {
    selectedStorages.push(storageCheckboxes[i].value);
  }

  //тип подписи cms или xml
  var signatureType = 'cms';

  //Данные для подписи
  var dataToSign = '';

  //Декодирование из Base64 (decode - false по умолчанию. Если true, то data будет декодировано из base64)
  var decode = false;

  //Встраивание значение в cms (encapsulate - true по умолчанию, встраивает значение в cms. Если false, то будет detached)
  var encapsulate = true;

  //Хеширование данных
  //digested - false по умолчанию. Если true, то входящие данные уже захешированы. Пока не работает для ГОСТ Р. 3410-2015.
  //Не рекомендуется использовать без необходимости
  var digested = false;

  //Язык интерфейса. При отсутствии параметра по умолчанию используется казахский язык. Может быть 'ru' или 'kz'.
  var localeRadio = 'ru';

  //Пока пустой объект. Если указан, то будет добавлена метка времени сервиса tsp.pki.gov.kz
  var tsaProfile = null;
  // if (document.getElementById("tsaProfile").checked) {
  //     tsaProfile = {};
  // }
  //Выбор ключа(ей) с сертификатом с указанными параметрами. В окне будут отображаться все сертификаты, но только соответствующие параметрам будут активны для выбора.
  // var extKeyUsageOidString = document.getElementById("extKeyUsageOids").value;
  // var extKeyUsageOids = extKeyUsageOidString ? extKeyUsageOidString.split(",") : [];

  // var caCertsString = document.getElementById("caCerts").value;
  // var caCerts = caCertsString ? caCertsString.split(",") : null;
  // if (document.getElementById("buildChain").checked) {
  //     caCert = caCertsString ? caCertsString.split(",") : null;
  // } else {
  //     caCerts = [];
  // }

  var signInfo = {
    module: 'kz.gov.pki.knca.basics',
    method: 'sign',
    args: {
      allowedStorages: selectedStorages,
      format: signatureType,
      data: signature,
      signingParams: {
        decode: decode,
        encapsulate: encapsulate,
        digested: digested,
        tsaProfile: tsaProfile,
      },
      signerParams: {
        extKeyUsageOids: [],
        chain: [],
      },
      locale: localeRadio,
    },
  };

  if (selectedStorages.length == 0) {
    delete signInfo.args.allowedStorages;
  }

  return connect()
    .then((webSocket) => {
      webSocket.send(JSON.stringify(signInfo));

      return new Promise((resolve, reject) => {
        webSocket.onmessage = async ({ data }) => {
          response = JSON.parse(data);
          if (response != null) {
            var responseStatus = response['status'];
            if (responseStatus === true) {
              var responseBody = response['body'];
              if (responseBody != null) {
                // unblockScreen();
                if (Object.prototype.hasOwnProperty.call(responseBody, 'result')) {
                  response = responseBody.result;
                  try {
                    await axios.post(
                      url,
                      {
                        signature: response,
                        contract: contractId,
                      },
                      {
                        withCredentials: true,
                      },
                    );
                    console.error('Sign is successfully send');
                  } catch (error) {
                    console.error('Error in Axios request:', error);
                    throw error;
                  }
                }
              }
            } else if (responseStatus === false) {
              // unblockScreen();
              var responseCode = response['code'];
              alert(responseCode);
            }
          }
          resolve(response);
        };
      });
    })
    .catch(function (err) {
      unblockScreen();
    });
}

/* global jQuery */
function blockScreen() {
  jQuery.blockUI({
    message:
      '<img src="js/loading.gif" /><br/>NCALayer-дің жауабын күте тұрыңыз<br/>Подождите, выполняется операция в NCALayer...',
    css: {
      border: 'none',
      padding: '15px',
      backgroundColor: '#000',
      '-webkit-border-radius': '10px',
      '-moz-border-radius': '10px',
      opacity: 0.5,
      color: '#fff',
    },
  });
}

function unblockScreen() {
  jQuery.unblockUI();
}

function openDialog() {
  if (
    confirm(
      'NCALayer-ге қосылғанда қате шықты. NCALayer-ды қайта қосып, ОК-ді басыңыз\nОшибка при подключении к NCALayer. Запустите NCALayer и нажмите ОК',
    ) === true
  ) {
    location.reload();
  }
}

export { sign };
