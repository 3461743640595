const heartbeat_msg = '--heartbeat--';
const missed_heartbeats_limit = 3;
let errorReported = false;

let helpers = {
  sign_workaround: {
    WebSocket: {},
    files: {},
    form_sign_helper: {},
  },
};

helpers.sign_workaround.WebSocket.clientsAr = [];

helpers.sign_workaround.WebSocket.WebSocketWrapper = function () {
  let thisws = this;
  this.ws = new WebSocket('wss://127.0.0.1:13579/');
  this.status = 1;
  this.missed_heartbeats = 0;
  this.hello_message = true;

  this.ws.onmessage = function (event) {
    if (thisws.hello_message) {
      thisws.hello_message = false;
      return;
    } else if (event.data === heartbeat_msg) {
      thisws.pong();
      return;
    }
    let data = JSON.parse(event.data);
    if (data.errorCode != null && data.errorCode !== 'NONE') {
      if (typeof thisws.onerror === 'function') {
        thisws.onerror(data.errorCode);
        thisws.close();
      }
    } else {
      if (typeof thisws.onmessage === 'function') {
        thisws.onmessage(data);
        thisws.close();
      }
    }
  };

  this.ws.onerror = function (event) {
    console.error('Ошибка подключения к WebSocket серверу:', event);
    if (typeof thisws.onerror === 'function') {
      thisws.onerror(999);
    }
  };

  this.ping = function () {
    try {
      thisws.missed_heartbeats++;
      if (thisws.missed_heartbeats >= missed_heartbeats_limit)
        throw new Error('Too many missed heartbeats.');
      thisws.ws.send(heartbeat_msg);
    } catch (e) {
      clearInterval(thisws.ping_interval);
      thisws.ws.close();
      if (typeof thisws.onerror === 'function') {
        thisws.onerror(998);
      }
    }
  };

  this.pong = function () {
    thisws.missed_heartbeats = 0;
  };

  this.ws.onopen = function () {
    //this.ping_interval = setInterval(thisws.ping,2000);
  };

  this.close = function () {
    this.onmessage = null;
    helpers.sign_workaround.WebSocket.setClient(this);
  };

  this.send = function (data, onmessage) {
    if (typeof onmessage !== 'function') throw new Error('InvalidArgumentException: onmessage');
    this.onmessage = onmessage;
    let delay_counter = 0;
    let delay_send = function (wsw) {
      delay_counter++;
      if (delay_counter > 20) {
        if (typeof wsw.onerror === 'function') {
          wsw.onerror(997);
        }
        return;
      }
      if (wsw.ws.readyState === 0 || wsw.hello_message) {
        setTimeout(delay_send, 250, wsw);
      } else if (wsw.ws.readyState === 1) {
        thisws.hello_message = false;
        wsw.ws.send(JSON.stringify(data));
      } else {
        if (typeof wsw.onerror === 'function') {
          wsw.onerror({ message: 'Соединение сброшено.' });
        }
      }
    };
    if (thisws.ws.readyState === 1) {
      thisws.hello_message = false;
      thisws.ws.send(JSON.stringify(data));
    } else {
      delay_send(this);
    }
  };
};

helpers.sign_workaround.WebSocket.setClient = function (client) {
  this.clientsAr.push(client);
};

helpers.sign_workaround.WebSocket.getClient = function () {
  let lastClient = this.clientsAr.pop();
  if (lastClient !== undefined) {
    if (lastClient.ws.readyState === 2 || lastClient.ws.readyState === 3)
      return helpers.sign_workaround.WebSocket.getClient();
    return lastClient;
  }
  return new helpers.sign_workaround.WebSocket.WebSocketWrapper();
};

helpers.sign_workaround.form_sign_helper.sign_raw = function (
  settings,
  type,
  data,
  signType = 'SIGNATURE',
) {
  errorReported = false;
  if (settings.cert_type == null || settings.cert_type === '') {
    settings.cert_type = 'GY';
  }
  if (typeof settings.data_signed_callback != 'function') {
    console.log('Обязательная функция возврата не была определена');
    alert('Возникла ошибка. Обратитесь к администратору системы.');
    return;
  }

  let message = {
    module: 'kz.gov.pki.knca.commonUtils',
    type: type,
    method: 'signXml',
    args: ['PKCS12', signType, data, '', ''],
  };
  if (settings.source != null) {
    message.source = settings.source;
  } else {
    message.source = 'local';
  }
  let ws = helpers.sign_workaround.WebSocket.getClient();
  ws.onerror = function (error_id) {
    if (!errorReported) {
      settings.error_callback(
        helpers.sign_workaround.form_sign_helper.get_error_text(error_id),
        'error',
      );
      errorReported = true;
    }
  };
  ws.send(message, function (result) {
    settings.data_signed_callback(result.responseObject);
  });
};

helpers.sign_workaround.form_sign_helper.start_check_status = function () {
  if (this.checkStatusIntervalID == null) {
    this.checkStatusIntervalID = setInterval(this.check_status_tmp, 10000);
  }
};

helpers.sign_workaround.form_sign_helper.sign_xml = function (xml, settings) {
  helpers.sign_workaround.form_sign_helper.sign_raw(settings, 'xml', xml, 'SIGNATURE');
};

helpers.sign_workaround.form_sign_helper.sign_auth = function (xml, settings) {
  helpers.sign_workaround.form_sign_helper.sign_raw(settings, 'xml', xml, 'AUTH');
};

helpers.sign_workaround.form_sign_helper.sign_x509 = function (x509Data, settings) {
  helpers.sign_workaround.form_sign_helper.sign_raw(settings, 'x509', x509Data, 'AUTH');
};

helpers.sign_workaround.form_sign_helper.get_error_text = function (error_id) {
  switch (error_id) {
    default:
      return 'Неизвестная ошибка';
  }
};

export const signRsa = helpers.sign_workaround.form_sign_helper.sign_xml;
export const signAuth = helpers.sign_workaround.form_sign_helper.sign_auth;
export const signX509 = helpers.sign_workaround.form_sign_helper.sign_x509;
