import React, { useEffect, useState } from 'react';
import {
  Container,
  BoxHeader,
  ButtonBox,
  Title,
  Table,
  TableHeader,
  TableRowHeader,
  TableRow,
  TableCell,
} from './DocumentGroupStyle';
import { NewButton } from 'components/common/Button';
import axios from 'axios';
import { DocumentGroupDetails } from '../../AdminSettingsInterface';
import { DocumentGroupAddModal } from '../../Modal/documentGroupAddModal/DocumentGroupAddModal';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/administration/documents/list`;

export const DocumentGroup = () => {
  // const rowsPerPage = 10;
  // const [currentPage, setCurrentPage] = useState(0);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [tableData, setTableData] = useState<DocumentGroupDetails[]>([]);
  useEffect(() => {
    axios
      .get(BASE_URL, {
        withCredentials: true,
      })
      .then((response) => {
        setTableData(response.data);
      })
      .catch((error) => {
        console.error('Ошибка загрузки данных:', error);
      });
  }, []);

  return (
    <Container>
      <Title>Список документов платформы</Title>
      <BoxHeader>
        <ButtonBox>
          <NewButton
            text='Добавить документ'
            className='small'
            onClick={() => setIsAddModalOpen(true)}
          />
        </ButtonBox>
      </BoxHeader>
      <Table>
        <thead>
          <TableRowHeader>
            <TableHeader>Название документа</TableHeader>
            <TableHeader>Код документа</TableHeader>
            <TableHeader>Версия документа</TableHeader>
            <TableHeader>Дата создания</TableHeader>
            <TableHeader>Дата валидации</TableHeader>
            <TableHeader>Требует подписи</TableHeader>
            <TableHeader></TableHeader>
          </TableRowHeader>
        </thead>
        <tbody>
          {tableData.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.code}</TableCell>
              <TableCell>{row.version}</TableCell>
              <TableCell>
                {row.startDate instanceof Date ? row.startDate.toISOString() : 'Invalid Date'}
              </TableCell>
              <TableCell>
                {row.validDate instanceof Date ? row.validDate.toISOString() : 'Invalid Date'}
              </TableCell>
              <TableCell>{row.sign}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
      {isAddModalOpen && <DocumentGroupAddModal onClose={() => setIsAddModalOpen(false)} />}
      {/*<Pagination*/}
      {/*  pageCount={Math.ceil(filteredData.length / rowsPerPage)}*/}
      {/*  currentPage={currentPage}*/}
      {/*  onPageChange={handlePageChange}*/}
      {/*/>*/}
      {/*{isCreateModalOpen && <CreateApplication closeModal={closeModal} />}*/}
    </Container>
  );
};
