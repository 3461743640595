import React, { useState } from 'react';
import {
  Box,
  Container,
  Content,
  ExpandableSection,
  MainInfoTitle,
  MainInfoBox,
  ArrowIcon,
  BoxSimple,
} from '../PkbStyle';
import { EnterpriseDetails, SourceDetails } from './PkbResponseInterface';
import IconDocuments from 'assets/img/IconDocuments.svg';
import IconArrowDown from 'assets/img/IconArrowDown.svg';
import IconArrow from 'assets/img/IconArrow.svg';

interface PkbResponseProps {
  status?: string;
  enterprise?: EnterpriseDetails;
  sources?: SourceDetails[];
}

interface OpenReportsState {
  [key: string]: boolean;
}

export const PkbResponseNew: React.FC<PkbResponseProps> = ({ status, enterprise, sources }) => {
  const [license, setLicense] = useState(false);
  const [statGov, setStatGov] = useState(false);
  const [gbdulAffilAace, setGbdulAffilAace] = useState(false);
  const [orgLeaderAfillFace, setOrgLeaderAfillFace] = useState(false);
  const [gbdrnInfo, setGbdrnInfo] = useState(false);
  const [mvd02Report, setMvd02Report] = useState(false);
  const [semiReport, setSemiReport] = useState(false);
  const [vsReport, setVsReport] = useState(false);
  const [openReports, setOpenReports] = useState<OpenReportsState>({});

  const toggleReport = (key: string) => {
    setOpenReports((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const renderReportSection = (
    title: string,
    isOpen: boolean,
    toggle: () => void,
    content: JSX.Element,
  ) => (
    <ExpandableSection>
      <MainInfoTitle onClick={toggle}>
        <img src={IconDocuments} alt='icon' />
        {title}
        <ArrowIcon src={isOpen ? IconArrowDown : IconArrow} alt='arrow' />
      </MainInfoTitle>
      {isOpen && <MainInfoBox>{content}</MainInfoBox>}
    </ExpandableSection>
  );

  return (
    <Container>
      <div>
        {enterprise?.license &&
          enterprise.license.length > 0 &&
          renderReportSection(
            'Лицензирование и сертификации',
            license,
            () => setLicense(!license),
            <Content isOpen={license}>
              {enterprise.license.map((licence, index) => (
                <div key={index}>
                  <div>
                    <h2>Лицензия {index + 1}</h2>
                    <label>Дата выдачи лицензии: {licence.issueDate}</label>
                    <label>Код типа лицензиара: {licence.licensiar.code}</label>
                    <label>Наименование на русском языке: {licence.licensiar.nameRu}</label>
                  </div>
                  <div>
                    <b>Статус</b>
                    <label>Код статуса лицензии: {licence.status.code}</label>
                  </div>
                </div>
              ))}
            </Content>,
          )}

        {enterprise?.stat_gov &&
          ((Object.keys(enterprise.stat_gov.OrgSize || {}).length > 0 &&
            enterprise.stat_gov.OrgSize !== null) ||
            (Object.keys(enterprise.stat_gov.ActivityAttr || {}).length > 0 &&
              enterprise.stat_gov.ActivityAttr !== null) ||
            (Object.keys(enterprise.stat_gov.AddressFact || {}).length > 0 &&
              enterprise.stat_gov.AddressFact !== null)) &&
          renderReportSection(
            'Сведения Бюро национальной статистики Агентства по стратегическому планированию',
            statGov,
            () => setStatGov(!statGov),
            <Content isOpen={statGov}>
              {Object.keys(enterprise?.stat_gov?.OrgSize || {}).length > 0 && (
                <>
                  <BoxSimple>
                    <span>Код размерности организации:</span>
                    {enterprise?.stat_gov.OrgSize.Code}
                  </BoxSimple>
                  <BoxSimple>{enterprise?.stat_gov.OrgSize.NameRu}</BoxSimple>
                </>
              )}
            </Content>,
          )}

        {enterprise?.gbdul_affil_face &&
          (enterprise.gbdul_affil_face.founders_count_ul != null ||
            enterprise.gbdul_affil_face.founders_count_fl != null) &&
          renderReportSection(
            'Сведения об учредителях и аффилированных лица',
            gbdulAffilAace,
            () => setGbdulAffilAace(!gbdulAffilAace),
            <Content isOpen={gbdulAffilAace}>
              <Box>
                {enterprise.gbdul_affil_face.founders_count_ul != null && (
                  <label>
                    Общее количество учредителей ЮЛ: {enterprise.gbdul_affil_face.founders_count_ul}
                  </label>
                )}
                {enterprise.gbdul_affil_face.founders_count_fl != null && (
                  <label>
                    Общее количество учредителей ФЛ: {enterprise.gbdul_affil_face.founders_count_fl}
                  </label>
                )}
              </Box>
            </Content>,
          )}

        {Object.keys(enterprise?.organization_leader || {}).length > 0 &&
          renderReportSection(
            'Исполнительный орган',
            orgLeaderAfillFace,
            () => setOrgLeaderAfillFace(!orgLeaderAfillFace),
            <Content isOpen={orgLeaderAfillFace}>
              <>
                <BoxSimple>
                  <span>Фамилия:</span>
                  {enterprise?.organization_leader?.SurName}
                </BoxSimple>
                <BoxSimple>
                  <span>Имя:</span> {enterprise?.organization_leader?.Name}
                </BoxSimple>
                <BoxSimple>
                  <span>Отчество:</span>
                  {enterprise?.organization_leader?.MiddleName}
                </BoxSimple>
              </>
            </Content>,
          )}

        {enterprise?.gbdrn_info?.by_location &&
          enterprise.gbdrn_info.by_location.length > 0 &&
          renderReportSection(
            'Сведения о недвижимом имуществе',
            gbdrnInfo,
            () => setGbdrnInfo(!gbdrnInfo),
            <Content isOpen={gbdrnInfo}>
              {enterprise.gbdrn_info.by_location.map((location, index) => (
                <div key={index}>
                  <label>Местоположение: {location.location}</label>
                  {location.objects?.map((object, objIndex) => (
                    <Box key={objIndex}>
                      <label>Тип объекта: {object.object_type}</label>
                    </Box>
                  ))}
                </div>
              ))}
            </Content>,
          )}

        {Object.keys(enterprise?.vs_report || {}).length > 0 &&
          renderReportSection(
            'Судебные разбирательства',
            vsReport,
            () => setVsReport(!vsReport),
            <Content isOpen={vsReport}>
              <>
                <BoxSimple>
                  <span>в качестве истца:</span>
                  {enterprise?.vs_report.num_applicant}
                </BoxSimple>
                <BoxSimple>
                  <span>в качестве ответчика:</span>
                  {enterprise?.vs_report.num_defendant}
                </BoxSimple>
              </>
            </Content>,
          )}

        {enterprise?.mvd02_report?.vehicle &&
          enterprise.mvd02_report.vehicle.length > 0 &&
          renderReportSection(
            'МВД, сведения о движимом имуществе',
            mvd02Report,
            () => setMvd02Report(!mvd02Report),
            <Content isOpen={mvd02Report}>
              {enterprise.mvd02_report.vehicle.map((vehic, index) => (
                <Box key={index}>
                  <BoxSimple>
                    <span>Марка, модель, модификация ТС:</span>
                    {vehic.model}
                  </BoxSimple>
                  <BoxSimple>
                    <span>Год выпуска ТС:</span>
                    {vehic.release_year}
                  </BoxSimple>
                  <BoxSimple>
                    <span>Тип обременения:</span>
                    {vehic.encumbrance_type}
                  </BoxSimple>
                </Box>
              ))}
            </Content>,
          )}

        {enterprise?.sem01_report?.contracts &&
          enterprise.sem01_report.contracts.length > 0 &&
          renderReportSection(
            'Обязательства перед нефинансовыми организациями',
            semiReport,
            () => setSemiReport(!semiReport),
            <Content isOpen={semiReport}>
              {enterprise.sem01_report.contracts.map((contract, index) => (
                <Box key={index}>
                  <BoxSimple>
                    <span>Название организации:</span>
                    {contract.source_name}
                  </BoxSimple>
                  <BoxSimple>
                    <span>Сумма задолженности:</span>
                    {contract.debt_remains}
                  </BoxSimple>
                </Box>
              ))}
            </Content>,
          )}

        {sources?.map((source, index) => (
          <React.Fragment key={index}>
            {source.name?.ru &&
              source.infos &&
              renderReportSection(
                source.name.ru,
                openReports[index.toString()],
                () => toggleReport(index.toString()),
                <Content isOpen={openReports[index.toString()]}>
                  {source.infos?.map((infoGroup, groupIndex) => (
                    <Box key={groupIndex}>
                      {infoGroup.ru &&
                        infoGroup.ru.map((info, infoIndex) => (
                          <div key={infoIndex}>
                            <BoxSimple>
                              <span>{info.title}:</span>
                              {info.value}
                            </BoxSimple>
                          </div>
                        ))}
                    </Box>
                  ))}
                </Content>,
              )}
          </React.Fragment>
        ))}
      </div>
    </Container>
  );
};
