import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/`;

export const getBankAccounts = () =>
  axios.get(`${BASE_URL}v1/ui/accounts/banks`, {
    withCredentials: true,
  });

export const getKBEs = () =>
  axios.get(`${BASE_URL}v1/dictionary/get?name=kbe`, {
    withCredentials: true,
  });

export const getMyAccounts = () =>
  axios.get(`${BASE_URL}v1/ui/accounts`, {
    withCredentials: true,
  });

export const deleteAccount = (id: number) =>
  axios.delete(`${BASE_URL}v1/ui/accounts/delete/${id}`, {
    withCredentials: true,
  });

export const addAccount = (kbe: number | null, iban: string) =>
  axios.post(
    `${BASE_URL}v1/ui/accounts/add`,
    {
      kbe,
      iban,
    },
    {
      withCredentials: true,
    },
  );

export const returnBill = (iban: string) => {
  return axios.get(`${BASE_URL}v1/ui/accounts/return/bill`, {
    params: { iban },
    withCredentials: true,
  });
};
