import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/cabinet/creditor/request/add`;

export const submitLoanRequest = async (requestBody: any) => {
  try {
    const response = await axios.post(BASE_URL, requestBody, { withCredentials: true });
    return response.data;
  } catch (error) {
    console.error('Error sending loan request:', error);
    throw error;
  }
};
