import { css, styled } from 'styled-components';
import { InputMask } from '@react-input/mask';

interface InputProps {
  $hasError?: boolean;
  $hasContent?: boolean;
  $readOnly?: boolean;
}

const OrganizationContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 24px;
  flex: 1 0 0;
  background: var(--neutral-50, #fefefe);
`;

const Block = styled.div`
  display: flex;
  display: flex;
  padding: 0px 8px;
  justify-content: space-between;
  align-self: stretch;
  width: 100%;
  flex-wrap: wrap; /* Разрешаем перенос элементов, если они не помещаются */
  div {
    gap: 16px;
  }
`;

const HeaderOrganization = styled.h1`
  color: #0d0d0d;
  font-size: 24px;
  font-weight: 600;
  line-height: 106%; /* 25.44px */
`;

const OrganizationInfo = styled.div`
  font-size: 14px;
  color: #0d0d0d;
  font-weight: 600;
  line-height: 125%;
  align-items: center;
  display: flex;
  gap: 10px;
  width: 100%;
  max-width: 460px;
  p {
    color: #5f5f5f;
    font-weight: 400;
  }
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 4px;
  .col {
    flex-direction: column;
    align-items: baseline;
    gap: 0px;
  }
  button {
    width: 100%;
    max-width: 460px;
  }
`;

const ContactBox = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  max-width: 700px;
  button {
    height: 36px;
    width: 36px;
  }
`;

const Input = styled(InputMask)<InputProps>`
  margin-top: 10px;
  width: 100%;
  padding: 12px 16px;
  border-radius: 6px;
  background: var(--White, #fff);
  border: 1px solid var(--Control, #9aa4b0);
  color: var(--Control, #9aa4b0);
  font-size: 14px;
  line-height: 125%;
  font-weight: 400;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  & {
    -moz-appearance: textfield;
  }

  ${({ $hasContent }) =>
    $hasContent &&
    css`
      color: var(--neutral-800, #303030);
      border: 1px solid var(--neutral-800, #303030);
    `}

  ${({ $readOnly }) =>
    $readOnly &&
    css`
      color: var(--neutral-600, #686868);
      border: 1px solid var(--neutral-400, #a0a0a0);
      background: var(--neutral-200, #d8d8d8);
    `}

  &:focus {
    outline: none;
    border: 1px solid #5784f7;
    color: #292d32;
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap; /* Включаем перенос тегов */
  max-width: 100%; /* Ограничиваем ширину контейнера */
`;

export {
  OrganizationContainer,
  HeaderOrganization,
  ContentBox,
  ContactBox,
  OrganizationInfo,
  Input,
  Block,
};
