import React, { useState } from 'react';
import { ButtonStyle, ModalWrapper, Title } from './BlockModalStyle';
import * as api from '../../admin-settings.api';

interface EditOrgProps {
  onClose: () => void;
  name: string;
  id: number;
}

export const BlockUserModal: React.FC<EditOrgProps> = ({ onClose, name, id }) => {
  console.log(id);
  const [isModal, setIsModal] = useState(true);
  const closeModal = () => {
    setIsModal(false);
    onClose();
  };
  const handleSaveClick = () => {
    api
      .blockUser(id, true)
      .then(() => {
        console.log('This function is not empty.');
      })
      .catch((error: any) => {
        console.error('Error block of user:', error);
      });
    closeModal();
  };

  return (
    <>
      {isModal && (
        <ModalWrapper>
          <div className='modal'>
            <Title>{`Вы действительно хотите заблокировать ${name}?`}</Title>
            <div style={{ textAlign: 'center' }}>
              <ButtonStyle onClick={handleSaveClick}>Да</ButtonStyle>
              <ButtonStyle onClick={closeModal}>Нет</ButtonStyle>
            </div>
          </div>
        </ModalWrapper>
      )}
    </>
  );
};
