import React, { useState } from 'react';
import ArrowIcon from 'assets/img/ArrowIcon.svg';
import { BoxSelect, InputBox, SpinnerLoading } from './SelectSearch.style';

interface Option {
  value: string;
  label: string;
}

interface SelectSearchProps {
  options: Option[];
  onOptionChange: (selectedOption: Option) => void;
  selectedValue: string | null;
  isOpen: boolean;
  onToggleOpen: () => void;
  isLoading?: boolean;
  isDisabled?: boolean; // Новый пропс
}

const SearchIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='search-icon'
    width='24'
    height='25'
    viewBox='0 0 24 25'
    fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.0491 15.9633C12.7873 16.9274 11.2105 17.5 9.5 17.5C5.35786 17.5 2 14.1421 2 10C2 5.85786 5.35786 2.5 9.5 2.5C13.6421 2.5 17 5.85786 17 10C17 11.7105 16.4274 13.2873 15.4633 14.5491L22.0055 21.0913L20.5913 22.5055L14.0491 15.9633ZM15 10C15 13.0376 12.5376 15.5 9.5 15.5C6.46243 15.5 4 13.0376 4 10C4 6.96243 6.46243 4.5 9.5 4.5C12.5376 4.5 15 6.96243 15 10Z'
      fill='#9AA4B0'
    />
  </svg>
);

export const SelectSearch: React.FC<SelectSearchProps> = ({
  options,
  onOptionChange,
  selectedValue,
  isOpen,
  onToggleOpen,
  isLoading,
  isDisabled,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const placeholderText = isDisabled ? 'Данные отсутствуют' : selectedValue || 'Поиск...';

  return (
    <BoxSelect className={isOpen ? 'open' : ''} isDisabled={isDisabled}>
      <div className='dropdown-header' onClick={() => !isLoading && !isDisabled && onToggleOpen()}>
        <InputBox>
          {SearchIcon}
          <input
            type='text'
            placeholder={placeholderText}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            disabled={isLoading || isDisabled}
          />
          {isLoading && <SpinnerLoading />}
        </InputBox>

        {!isLoading && !isDisabled && (
          <img
            src={ArrowIcon}
            width={32}
            height={32}
            alt='Arrow down'
            className={isOpen ? 'arrow-up' : 'arrow-down'}
          />
        )}
      </div>
      {isOpen && (
        <div className='options'>
          {options.length === 0 ? (
            <div className='empty'>Список пуст</div>
          ) : (
            options
              .filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase()))
              .map((option) => (
                <div
                  key={option.value}
                  className='option'
                  onClick={() => {
                    onOptionChange(option);
                    setSearchTerm('');
                    onToggleOpen();
                  }}>
                  {option.label}
                </div>
              ))
          )}
        </div>
      )}
    </BoxSelect>
  );
};
