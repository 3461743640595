import React from 'react';
import { ActiveStepIcon, CompletedStepIcon } from './Icons';
import { StepContainer, Step, Line, StepText } from './StepIndicator.style';

interface StepIndicatorProps {
  currentStep: number;
}

const StepIndicator: React.FC<StepIndicatorProps> = ({ currentStep }) => {
  const steps = ['Вид продукта', 'Основная информация', 'Оформление'];

  return (
    <StepContainer>
      {steps.map((step, index) => (
        <Step key={index}>
          {index < currentStep ? (
            <CompletedStepIcon />
          ) : index === currentStep ? (
            <ActiveStepIcon />
          ) : (
            <div style={{ width: '18px', height: '18px' }} />
          )}
          <StepText $active={index === currentStep} $completed={index < currentStep}>
            {step}
          </StepText>
          <Line $active={index <= currentStep} $isLast={index === steps.length - 1} />
        </Step>
      ))}
    </StepContainer>
  );
};

export default StepIndicator;
