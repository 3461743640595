import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
`;

export const InfoItem = styled.div`
  margin-bottom: 10px;
`;

export const InfoTitle = styled.span`
  color: var(--neutral-800, var(--neutral-800, #303030));
  font-family: 'IBM Plex Sans';
  font-size: 18px;
  font-weight: 600;
  line-height: 125%;
  display: block;
`;

export const InfoValue = styled.span`
  color: var(--charts-base-03, var(--charts-base-03, #686868));
  font-family: 'IBM Plex Sans';
  font-size: 18px;
  font-weight: 500;
  line-height: 44px; /* 244.444% */
  display: block;
`;

export const ErrorText = styled.div`
  color: red;
`;
