import styled from 'styled-components';

const CardContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 16px;
  min-width: 398px;
  width: 100%;
  height: 206px;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(7, 17, 43, 0.07);
  cursor: pointer;
  &:hover {
    background: var(--primary-300, #5784f7);

    h2,
    p {
      color: var(--neutral-50, #fefefe);
    }
  }
`;

const CardTitle = styled.h2`
  color: var(--neutral-700, var(--neutral-700, #4c4c4c));
  font-family: 'IBM Plex Sans';
  font-size: 24px;
  font-weight: 500;
`;

const CardDescription = styled.p`
  color: var(--neutral-700, var(--neutral-700, #4c4c4c));
  font-family: 'IBM Plex Sans';
  font-size: 12px;
  font-weight: 500;
`;

export { CardContainer, Card, CardTitle, CardDescription };
