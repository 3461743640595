import React, { useEffect, useState } from 'react';
import {
  AccordionContainer,
  AccordionItem,
  AccordionHeader,
  AccordionContent,
} from './InvestorSettings.style';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { ProductSettings } from './productSettings/ProductSettings';
import { Tarif } from './tarif/Tarif';
import { Product } from './product/Product';
import { Pkb } from './pkb/Pkb';

const services = ['Факторинг', 'Лизинг', 'Ипотека'];

type ServiceType = 'Факторинг' | 'Лизинг' | 'Ипотека';

type ServiceResponse = {
  id: number;
  name: {
    text_ru: string;
    text_kk: string;
    text_en: string;
  };
  path: string;
  status: string;
};

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/ui/organization`;

export const InvestorSettings = () => {
  const { t } = useTranslation();
  const [services, setServices] = useState<ServiceType[]>([]);
  const [selectedService, setSelectedService] = useState<ServiceType | ''>('');
  const [maxTerms, setMaxTerms] = useState<Record<ServiceType, string>>({
    Факторинг: '',
    Лизинг: '',
    Ипотека: '',
  });

  useEffect(() => {
    axios
      .get(`${BASE_URL}`, {
        withCredentials: true, // Добавлено это поле
      })
      .then((response) => {
        // Извлечем имя организации и обновим состояние services
        const fetchedName = response.data.name;
        setServices([fetchedName]); // В зависимости от того, что должно храниться в services, это можно обновить
      })
      .catch((error) => {
        console.error('There was an error fetching the services', error);
      });
  }, []);

  const handleMaxTermChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    service: ServiceType,
  ) => {
    setMaxTerms((prevState) => ({
      ...prevState,
      [service]: event.target.value,
    }));
  };

  const [productOpen, setProduct] = useState(false);
  const [productSettingsOpen, setProductSettingsOpen] = useState(false);
  const [tarifOpen, setTarifOpen] = useState(false);
  const [pkbOpen, setPkbOpen] = useState(false);
  const [allSectionsOpen, setAllSectionsOpen] = useState(false);

  const toggleProduct = () => {
    setProduct(!productOpen);
    setAllSectionsOpen(productSettingsOpen && tarifOpen && pkbOpen);
  };

  const toggleProductSettings = () => {
    setProductSettingsOpen(!productSettingsOpen);
    setAllSectionsOpen(productOpen && tarifOpen && pkbOpen);
  };

  const toggleTarif = () => {
    setTarifOpen(!tarifOpen);
    setAllSectionsOpen(productSettingsOpen && tarifOpen && pkbOpen);
  };

  const togglePkb = () => {
    setPkbOpen(!pkbOpen);
    setAllSectionsOpen(productSettingsOpen && tarifOpen && productOpen);
  };

  return (
    <AccordionContainer style={{ overflowY: 'scroll', maxHeight: '90%' }}>
      <AccordionItem>
        <AccordionHeader onClick={toggleProduct}>{t('product.accordion')}</AccordionHeader>
        <AccordionContent isOpen={productOpen}>
          <Product />
          {/*    <Container>*/}
          {/*    <Box>*/}
          {/*        <p>{t('investorSetting.financialService')}</p>*/}
          {/*        {services.map((service) => (*/}
          {/*            <ServiceButton*/}
          {/*                key={service}*/}
          {/*                $isActive={selectedService === service}*/}
          {/*                onClick={() => setSelectedService(service as ServiceType)}>*/}
          {/*                {service}*/}
          {/*            </ServiceButton>*/}
          {/*        ))}*/}
          {/*    </Box>*/}

          {/*    <Box>*/}
          {/*        <p>{t('investorSetting.mainSetting')}</p>*/}
          {/*        {selectedService && (*/}
          {/*            <>*/}
          {/*                <BoxInput>*/}
          {/*                    <BoxInputTitle>Максимальный срок:</BoxInputTitle>*/}
          {/*                    <InputText*/}
          {/*                        placeholder='Введите значение'*/}
          {/*                        type='text'*/}
          {/*                        value={maxTerms[selectedService]}*/}
          {/*                        onChange={(e) => handleMaxTermChange(e, selectedService)}*/}
          {/*                    />*/}
          {/*                </BoxInput>*/}
          {/*                <BoxButton text='Сохранить' className='yellow'/>*/}
          {/*            </>*/}
          {/*        )}*/}
          {/*    </Box>*/}
          {/*</Container>*/}
        </AccordionContent>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader onClick={toggleProductSettings}>
          {t('productSetting.accordion')}
        </AccordionHeader>
        <AccordionContent isOpen={productSettingsOpen}>
          <ProductSettings />
        </AccordionContent>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader onClick={toggleTarif}>{t('tarif.accordion')}</AccordionHeader>
        <AccordionContent isOpen={tarifOpen}>
          <Tarif />
        </AccordionContent>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader onClick={togglePkb}>{t('ПКБ')}</AccordionHeader>
        <AccordionContent isOpen={pkbOpen}>
          <Pkb />
        </AccordionContent>
      </AccordionItem>
    </AccordionContainer>
  );
};
