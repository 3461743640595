import React, { useState, useEffect } from 'react';
import { BoxInput, BoxInputTitle, Box } from './ProductModalStyle';
import styled from 'styled-components';
import { Button } from 'components/common/Button';
import { Input } from '../../common/Input/InputText/InputText.style';
import { ProductSettingDetails } from '../../InvestorSettings/productSettings/ProductSettingInterface';
import * as api from '../../api/investor-settings.api';

interface ProductModalProps {
  productDetails: ProductSettingDetails;
  onClose: () => void;
  fetchProductDetails: (productCode: string) => void;
}

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  z-index: 1000;
`;

const ProductModal: React.FC<ProductModalProps> = ({
  productDetails,
  onClose,
  fetchProductDetails,
}) => {
  const [isModal, setIsModal] = useState(true);
  const [editingData, setEditingData] = useState<ProductSettingDetails | null>(null);

  useEffect(() => {
    setEditingData(productDetails);
  }, [productDetails]);

  const closeModal = () => {
    setIsModal(false);
    onClose();
  };

  const handleSaveClick = () => {
    if (editingData) {
      api
        .postInvestorAttr(editingData)
        .then(() => {
          if (productDetails.product) {
            fetchProductDetails(productDetails.product);
          }
        })
        .catch((error) => {
          console.error('Error saving product settings:', error);
        });
    }
    closeModal();
  };

  return (
    <>
      {isModal && (
        <ModalWrapper>
          <div className='modal'>
            <div className='modal-content'>
              <form>
                <BoxInput>
                  <BoxInputTitle>Product:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData ? editingData.product : ''}
                    onChange={(e) => setEditingData({ ...editingData, product: e.target.value })}
                  />
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>Use scoring:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData ? editingData?.useScoring?.toString() : ''}
                    onChange={(e) =>
                      setEditingData({ ...editingData, useScoring: e.target.checked })
                    }
                  />
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>Total limit:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData ? editingData?.totalLimit : ''}
                    onChange={(e) =>
                      setEditingData({
                        ...editingData,
                        totalLimit: parseFloat(e.target.value) || 0,
                      })
                    }
                  />
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>Min sum:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData ? editingData?.minSum : ''}
                    onChange={(e) =>
                      setEditingData({
                        ...editingData,
                        minSum: parseFloat(e.target.value) || 0,
                      })
                    }
                  />
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>Max sum:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData ? editingData?.maxSum : ''}
                    onChange={(e) =>
                      setEditingData({
                        ...editingData,
                        maxSum: parseFloat(e.target.value) || 0,
                      })
                    }
                  />
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>Borrower limit:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData ? editingData?.borrowerLimit : ''}
                    onChange={(e) =>
                      setEditingData({
                        ...editingData,
                        borrowerLimit: parseFloat(e.target.value) || 0,
                      })
                    }
                  />
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>Degree of risk:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData ? editingData?.degreeOfRisk : ''}
                    onChange={(e) =>
                      setEditingData({
                        ...editingData,
                        degreeOfRisk: parseFloat(e.target.value) || 0,
                      })
                    }
                  />
                </BoxInput>

                <BoxInput>
                  <BoxInputTitle>Min term:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData ? editingData?.minTerm : ''}
                    onChange={(e) =>
                      setEditingData({
                        ...editingData,
                        minTerm: parseFloat(e.target.value) || 0,
                      })
                    }
                  />
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>Max term:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData ? editingData?.maxTerm : ''}
                    onChange={(e) =>
                      setEditingData({
                        ...editingData,
                        maxTerm: parseFloat(e.target.value) || 0,
                      })
                    }
                  />
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>Transactions:</BoxInputTitle>
                  <Input
                    type='text'
                    value={editingData ? editingData?.transactions : ''}
                    onChange={(e) =>
                      setEditingData({
                        ...editingData,
                        transactions: parseFloat(e.target.value) || 0,
                      })
                    }
                  />
                </BoxInput>
                <Box>
                  <Button onClick={handleSaveClick} text={'Сохранить'} />
                  <Button onClick={closeModal} text={'Закрыть'} />
                </Box>
              </form>
            </div>
          </div>
        </ModalWrapper>
      )}
    </>
  );
};

export default ProductModal;
