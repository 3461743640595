import React from 'react';
import { InputBox, Input, ErrorMessage } from './InputSearch.style';

interface InputSearchProps {
  value: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  placeholder?: string;
  name?: string;
}

const SearchIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='search-icon'
    width='19'
    height='19'
    viewBox='0 0 19 19'
    fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.0491 15.9633C12.7873 16.9274 11.2105 17.5 9.5 17.5C5.35786 17.5 2 14.1421 2 10C2 5.85786 5.35786 2.5 9.5 2.5C13.6421 2.5 17 5.85786 17 10C17 11.7105 16.4274 13.2873 15.4633 14.5491L22.0055 21.0913L20.5913 22.5055L14.0491 15.9633ZM15 10C15 13.0376 12.5376 15.5 9.5 15.5C6.46243 15.5 4 13.0376 4 10C4 6.96243 6.46243 4.5 9.5 4.5C12.5376 4.5 15 6.96243 15 10Z'
      fill='#9AA4B0'
    />
  </svg>
);

export const InputSearch = ({ placeholder, value, onChange, error, name }: InputSearchProps) => {
  const hasError = Boolean(error);
  return (
    <>
      <InputBox>
        {SearchIcon}
        <Input
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          $hasError={hasError}
          name={name}
        />
        {hasError && <ErrorMessage>{error}</ErrorMessage>}
      </InputBox>
    </>
  );
};
