import styled from 'styled-components';

interface StepProps {
  active: boolean;
}

const ProgressIndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const Step = styled.div<StepProps>`
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 999px;
  border: 1px solid var(--neutral-400, #a0a0a0);
  background-color: ${(props) => (props.active ? '#5784F7' : '#F9FAFB')};
  border-color: ${(props) => (props.active ? '#5784F7' : '#A0A0A0')};
`;

export { ProgressIndicatorContainer, Step };
