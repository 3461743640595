import React, { useEffect, useState } from 'react';
import { TarifCreateDetails } from '../../InvestorSettings/tarif/TarifInterface';
import { Input } from '../../common/Input/InputText/InputText.style';
import { BoxInput, BoxInputTitle, Box } from './TarifModalStyle';
import styled from 'styled-components';
import { Button } from 'components/common/Button';
import { useTranslation } from 'react-i18next';
import { Activity, Category, ProductProps } from '../../InvestorSettings/InvestorSerringsInterface';
import * as api from '../../api/investor-settings.api';

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  z-index: 1000;
`;

interface TarifCreateModalProps {
  onClose: () => void;
}

const TarifCreateModal: React.FC<TarifCreateModalProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const [isModal, setIsModal] = useState(true);
  const [createData, setCreateData] = useState<TarifCreateDetails | null>(null);
  const [activityList, setActivityList] = useState<Activity[]>([]);
  const [categoryList, setCategoryList] = useState<Category[]>([]);
  const [productList, setProductList] = useState<ProductProps[]>([]);

  const closeModal = () => {
    setIsModal(false);
    onClose();
  };

  const handleSaveClick = () => {
    if (createData) {
      api
        .addInvestorTarif(createData)
        .then((response) => {
          console.log('Product details have been successfully updated', response.data);
        })
        .catch((error) => {
          console.error('There was an error updating product details', error);
        });
    }
    closeModal();
  };

  useEffect(() => {
    api.getListActivity().then((response) => setActivityList(response.data));
    api.getListCategory().then((response) => setCategoryList(response.data));
    api.getListProducts().then((response) => setProductList(response.data));
  }, []);

  return (
    <>
      {isModal && (
        <ModalWrapper>
          <div className='modal'>
            <div className='modal-content'>
              <form>
                <BoxInput>
                  <BoxInputTitle>{t('tarif.activityName')}</BoxInputTitle>
                  <select
                    value={createData ? createData.activity : ''}
                    onChange={(e) => setCreateData({ ...createData, activity: e.target.value })}>
                    <option value=''>Select Activity</option>
                    {activityList.map((activity) => (
                      <option key={activity.code} value={activity.code}>
                        {activity.name}
                      </option>
                    ))}
                  </select>
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>{t('tarif.categoryName')}</BoxInputTitle>
                  <select
                    value={createData ? createData.category : ''}
                    onChange={(e) => setCreateData({ ...createData, category: e.target.value })}>
                    <option value=''>Select Activity</option>
                    {categoryList.map((category) => (
                      <option key={category.code} value={category.code}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </BoxInput>
                <BoxInput>
                  <BoxInputTitle>{t('tarif.minSum')}</BoxInputTitle>
                  <Input
                    type='text'
                    value={createData ? createData?.experience : ''}
                    onChange={(e) =>
                      setCreateData({
                        ...createData,
                        experience: parseFloat(e.target.value) || 0,
                      })
                    }
                  />
                </BoxInput>

                <BoxInput>
                  <BoxInputTitle>{t('tarif.productName')}</BoxInputTitle>
                  <select
                    value={createData ? createData.product : ''}
                    onChange={(e) => setCreateData({ ...createData, product: e.target.value })}>
                    <option value=''>Select Activity</option>
                    {productList.map((product) => (
                      <option key={product.code} value={product.code}>
                        {product.name}
                      </option>
                    ))}
                  </select>
                </BoxInput>
                <Box>
                  <Button onClick={handleSaveClick} text={'Сохранить'} />
                  <Button onClick={closeModal} text={'Закрыть'} />
                </Box>
              </form>
            </div>
          </div>
        </ModalWrapper>
      )}
    </>
  );
};

export default TarifCreateModal;
