import { IPenaltyCalcItem } from './PenaltyCalculator.interface';
import { axiosInstanceJSON } from './axios-instance';

export const getCalculations = async (
  body: IPenaltyCalcItem[],
  contractId: number,
  endpoint: string,
) => {
  return axiosInstanceJSON
    .post(`/cabinet/${endpoint}/penalty_calculator/calculate?contractId=${contractId}`, body)
    .then((res) => res.data);
};
