export const formatDate = (date: string | number | Date) => {
  const d = new Date(date);
  const day = ('0' + d.getDate()).slice(-2);
  const month = ('0' + (d.getMonth() + 1)).slice(-2);
  const year = d.getFullYear();
  return day + '.' + month + '.' + year;
};

export const getRemainingTime = (endTime: string | number | Date) => {
  const now = new Date().getTime();
  const end = new Date(endTime).getTime();
  const timeDiff = end - now;

  if (timeDiff <= 0) {
    return 'Время истекло';
  }

  const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  if (days >= 1) {
    return `${days} дней`;
  } else {
    const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  }
};
