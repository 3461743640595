import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AppContextProps, AppProviderProps, UserDataLightType } from './AppContext.interface';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/`;

export const AppContext = createContext<AppContextProps | null>(null);

export const useAppContext = (): AppContextProps => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within AppProvider');
  }
  return context;
};

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [userDataLight, setUserDataLight] = useState<UserDataLightType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResponse = await axios.get(`${BASE_URL}api/v1/ui/user/lite`, {
          withCredentials: true,
        });
        setUserDataLight(userResponse.data);
      } catch (error) {
        console.error('Failed to fetch data', error);
        if (axios.isAxiosError(error) && error.response) {
          if (error.response.status === 401) {
            // Сохраняем текущий путь и добавляем его как параметр запроса
            const currentPath = window.location.pathname + window.location.search;
            navigate(`/login?redirectTo=${encodeURIComponent(currentPath)}`);
          }
        } else {
          console.error('Ошибка при получении данных:', error);
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [navigate]);

  if (isLoading) {
    return <div></div>;
  }

  return <AppContext.Provider value={{ userDataLight, isLoading }}>{children}</AppContext.Provider>;
};
