import styled from 'styled-components';

interface LineProps {
  $active: boolean;
  $isLast: boolean;
}

interface StepTextProps {
  $active: boolean;
  $completed: boolean;
}

const StepContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  /* position: relative; */
  padding: 16px;
  padding-left: 32px;
  background: #fff;
  max-width: 180px;
  height: 206px;
`;

const Step = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
`;

const Line = styled.div<LineProps>`
  position: absolute;
  left: -10px;
  top: 2px;
  height: ${(props) => (props.$isLast ? '0' : '100px')};
  width: 2px;
  background-color: ${(props) => (props.$active ? '#4589FF' : '#393939')};
`;

const StepText = styled.div<StepTextProps>`
  margin-left: 10px;
  color: ${(props) => (props.$active || props.$completed ? '#000' : '#A0A0A0')};
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.16px;
  max-width: 62px;
`;

export { StepContainer, Step, Line, StepText };
