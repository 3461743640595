// EditRole.jsx
import React, { useEffect, useState } from 'react';
import { ModalWrapperSmall, Title } from '../EditModalStyle';
import * as api from '../../../admin-settings.api';

interface LocalText {
  text_ru: string;
}
interface Role {
  id: number;
  code: string;
  name: LocalText;
}

interface EditRoleProps {
  roles?: string[];
  onSelectRole: (selectedRole: string) => void;
}

export const EditRole: React.FC<EditRoleProps> = ({ roles: initialRoles = [], onSelectRole }) => {
  const [roles, setRoles] = useState<Role[]>(
    initialRoles instanceof Function ? initialRoles() : initialRoles,
  );
  const [selectedRole, setSelectedRole] = useState<string>(''); // State to store the selected role
  const [isModalOpen, setIsModalOpen] = useState(true);

  const fetchData = () => {
    api
      .roleList()
      .then((response) => {
        const rolesData = response.data || []; // Ensure response.data is an array
        setRoles(rolesData.map((role: Role) => role));
      })
      .catch((error) => {
        console.error('Ошибка обновления данных:', error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedRole(event.target.value);
  };

  const handleSubmit = () => {
    onSelectRole(selectedRole);
    setIsModalOpen(false);
  };
  return (
    <>
      {isModalOpen && (
        <ModalWrapperSmall>
          <Title>Роли пользователя</Title>
          <div>
            <select value={selectedRole} onChange={handleSelectChange}>
              <option value='' disabled>
                Выбрать роль
              </option>
              {roles.map((item: Role) => (
                <option key={item.id} value={item.code}>
                  {item.name && typeof item.name === 'object' ? item.name.text_ru : ''}
                </option>
              ))}
            </select>
            <button type='button' onClick={handleSubmit}>
              Добавить
            </button>
          </div>
        </ModalWrapperSmall>
      )}
    </>
  );
};
