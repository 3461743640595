import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  HeaderBox,
  BreadCrumbsBox,
} from 'components/RequestDetailsDraft/RequestDetails.style';
import { TitleBox, ContentBox } from 'components/InfoEGZ/InfoEGZ.style';
import { NewButton } from 'components/common/Button';

export interface InfoCreditProps {
  closeModal?: () => void;
  num: string;
  id: string | number;
}

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

const InfoCreditStory: React.FC<InfoCreditProps> = ({ id, num, closeModal }) => {
  const [creditHistory, setCreditHistory] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}cabinet/investor/request/creditor/credit_history/${id}`,
          { withCredentials: true },
        );
        setCreditHistory(response.data.getReportResultDTO.cigResultDTO.resultDTO.rootDTO);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching credit history data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const isDataAvailable =
    creditHistory &&
    (creditHistory.footerDTO ||
      creditHistory.numberOfQueriesDTO ||
      (creditHistory.summaryInformationDTO &&
        creditHistory.summaryInformationDTO.existingContractsDTO) ||
      creditHistory.publicSourcesDTO);

  if (!isDataAvailable) {
    return (
      <Container>
        <HeaderBox>
          <BreadCrumbsBox>
            <p>
              <a href='/applications'>Заявки</a>
            </p>
            <p>{'>'}</p>
            <span onClick={closeModal}>Заявка {num}</span>
            <p>{'>'}</p>
            <p className='select'>Кредитная история</p>
          </BreadCrumbsBox>
          <TitleBox>
            <h2>Кредитная история</h2>
            <NewButton onClick={closeModal} text='Назад к заявке' className='white' />
          </TitleBox>
        </HeaderBox>
        <ContentBox>
          <p>Данные отсутствуют</p>
        </ContentBox>
      </Container>
    );
  }

  const {
    footerDTO: { blockList2, gamblingTrue, gamblingFalse } = {} as any,
    numberOfQueriesDTO = {} as any,
    summaryInformationDTO: {
      existingContractsDTO: { subjectRoleDTOList = [] } = {} as any,
    } = {} as any,
    publicSourcesDTO: { qamqorAlimonyDTO = {} as any } = {} as any,
  } = creditHistory || {};

  const { statusDTO } = qamqorAlimonyDTO || {};

  const borrowerContracts = subjectRoleDTOList
    ? subjectRoleDTOList.find((role: any) => role.type === 'Borrower')
    : {};

  return (
    <Container>
      <HeaderBox>
        <BreadCrumbsBox>
          <p>
            <a href='/applications'>Заявки</a>
          </p>
          <p>{'>'}</p>
          <span onClick={closeModal}>Заявка {num}</span>
          <p>{'>'}</p>
          <p className='select'>Кредитная история</p>
        </BreadCrumbsBox>
        <TitleBox>
          <h2>Кредитная история</h2>
          <NewButton onClick={closeModal} text='Назад к заявке' className='white' />
        </TitleBox>
      </HeaderBox>
      <ContentBox>
        <h3>Ограничение доступа</h3>
        <p>{blockList2?.stitle || 'Данные отсутствуют'}</p>
        <h3>Игорный бизнес</h3>
        <p>{gamblingTrue?.stitle || gamblingFalse?.stitle || 'Данные отсутствуют'}</p>
        <h3>Количество произведенных участниками ПКБ запросов</h3>
        <p>
          {numberOfQueriesDTO.title
            ? `${numberOfQueriesDTO.title}: ${numberOfQueriesDTO.value}`
            : 'Данные отсутствуют'}
        </p>
        <h3>Действующие договора (просроченные)</h3>
        <p>
          {borrowerContracts?.totalDebtOverdue?.title
            ? `${borrowerContracts.totalDebtOverdue.title}: ${borrowerContracts.totalDebtOverdue.value}`
            : 'Данные отсутствуют'}
        </p>
        <h3>Алиментщики</h3>
        <p>{statusDTO?.title ? `${statusDTO.title}: ${statusDTO.value}` : 'Данные отсутствуют'}</p>
      </ContentBox>
    </Container>
  );
};

export default InfoCreditStory;
