import styled from 'styled-components';
import { ReactComponent as IconClose } from 'assets/img/IconClose.svg';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  z-index: 1000;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  margin-bottom: 20px;
`;

export const ModalBody = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 12px;
  border: 1px solid #bcbcbc;
  border-radius: 4px;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
`;

export const ModalClose = styled(IconClose)`
  cursor: pointer;
  width: 28px;
  height: 28px;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 22.78125px 48.825px 0 rgba(0, 0, 0, 0.04);
  border-radius: 4px;
`;

export const TableHeader = styled.thead``;

export const TableBody = styled.tbody``;

export const HeaderCell = styled.th`
  padding: 10px;
`;

export const TableRow = styled.tr`
  border: 1px solid #ddd;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
`;

export const TableCell = styled.td`
  padding: 10px;
  max-width: 160px;
`;

export const CenteredTableCell = styled.td`
  padding: 10px;
  max-width: 160px;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

export const CalculationsBox = styled.div``;

export const TotalSumBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`;

export const Divider = styled.hr`
  border-top: 1px solid black;
  width: 100px;
`;
