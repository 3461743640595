import axios from 'axios';

type RequestPayload = {
  token: string;
  role: string;
  phone: string;
  address?: string;
  email: string;
  refId?: string | null;
};

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/public/`;

const axiosInstanceJSON = axios.create({
  baseURL: `${BASE_URL}api/v1`,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
});

//получение данных
export const getCertFromSign = (token: string) => {
  return axiosInstanceJSON.post(`registration`, { token });
};

//отправка данных данных
export const sendRequestWithSign = (payload: RequestPayload) => {
  return axiosInstanceJSON.put('registration', payload);
};
