import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
`;

export const MainInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px;
  border-radius: 0px 0px 8px 8px;
  background: var(--neutral-50, #fefefe);
`;

export const FileItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const FileLink = styled.a`
  align-items: center;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  background: var(--primary-300, #5784f7);
  align-self: stretch;
  text-decoration: none;
  color: var(--text-text-01, #f4f4f4);
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.16px;
  cursor: pointer;
  &:hover {
    background: var(--primary-700, #0f62fe);
  }
`;

export const NoDocumentsMessage = styled.div`
  color: red;
`;
