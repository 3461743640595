import React, { useEffect, useRef } from 'react';
import GaugeChart from 'react-gauge-chart';
import styled from 'styled-components';

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    p {
      color: var(--grayscale-600, #757575);
      font-size: 14px;
      font-weight: 400;
      line-height: 126%;
      max-width: 180px;
    }
    span {
      color: #000;

      font-family: Jost;
      font-size: 54px;
      font-style: normal;
      font-weight: 600;
      line-height: 51.83px;
    }
  }
`;

interface RiskGaugeProps {
  value: number;
  isPercentage?: boolean; // Добавляем опциональное свойство для отображения процента
}

const RiskGauge: React.FC<RiskGaugeProps> = ({ value, isPercentage = false }) => {
  const maxValue = 9;
  const displayValue = isPercentage ? `${Math.round(value * 100)}%` : value; // Определяем, как отображать значение

  const chartStyle = {
    width: '460px',
    height: '250px',
  };

  return (
    <Box>
      <div>
        <p>{isPercentage ? 'Процент' : 'Класс риска заявки'}</p>
        <span>{displayValue}</span>
      </div>
      <GaugeChart
        id='risk-gauge-chart'
        nrOfLevels={10}
        colors={['#00FF00', '#FF0000']}
        arcWidth={0.3}
        percent={isPercentage ? value : value / maxValue} // Преобразуем в проценты, если необходимо
        textColor={'#000000'}
        hideText={true}
        style={chartStyle}
        animate={false} // Отключение анимации стрелки
      />
    </Box>
  );
};

export default RiskGauge;
