import styled from 'styled-components';

interface CardTitleProps {
  $isActive: boolean;
}

const Container = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  flex: 1 0 0;
  background: var(--neutral-50, #fefefe);
  button {
    max-width: 200px;
    margin: 0 auto;
  }
`;

const HeaderOferts = styled.h1`
  color: #0d0d0d;
  font-size: 24px;
  font-weight: 600;
  line-height: 106%; /* 25.44px */
`;

const ContentBox = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  justify-content: center;
  @media (max-height: 790px) {
    gap: 16px;
  }
`;

const LefBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 0 0 260px;
  max-width: 260px;
  @media (max-height: 790px) {
    gap: 16px;
  }
`;

interface CardVersionProps {
  $isActive: boolean;
  $isSigned: boolean;
}

const CardVersion = styled.div<CardVersionProps>`
  display: flex;
  padding: 24px 24px;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  background: #fff;
  border: ${(props) => {
    if (props.$isActive) return '1px solid #5784F7';
    if (!props.$isSigned) return '1px solid red';
    return 'none';
  }};
  cursor: pointer;
  @media (max-height: 790px) {
    padding: 16px;
  }
`;

const CardTitle = styled.p<CardTitleProps>`
  color: ${(props) => (props.$isActive ? '#5784F7' : 'var(--Black-Main, #292D32)')};
  font-size: 20px;
  font-weight: 600;
  line-height: 106%;
  max-width: 178px;
`;

const CardIconStatus = styled.img`
  width: 16px;
  height: 16px;
`;

const RightBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  @media (max-height: 790px) {
    gap: 16px;
  }
`;

const Pdf = styled.div`
  width: 100%;
  height: 540px;
  @media (max-height: 790px) {
    height: 464px;
  }
`;

const Loader = styled.img`
  width: 100px;
  height: 100px;
  margin: auto;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 18px;
  text-align: center;
  margin: auto;
`;

export {
  Container,
  HeaderOferts,
  ContentBox,
  LefBlock,
  RightBlock,
  CardVersion,
  CardTitle,
  CardIconStatus,
  Pdf,
  Loader,
  ErrorMessage,
};
