import React from 'react';
import { useNavigate } from 'react-router-dom';
import BannerNotFound from 'assets/img/404.png';
import { NewButton } from 'components/common/Button';
import { Box, Content, Title, Description } from './NotFound404.style';

export const NotFound404 = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/applications');
  };

  return (
    <Box>
      <Content>
        <img src={BannerNotFound} alt='NotFound' />
        <div>
          <Title>Запрашиваемая страница отсутствует</Title>
          <Description>
            Возможно, она была перемещена, удалена или никогда не существовала.
          </Description>
          <NewButton text='На главную' onClick={handleGoHome} />
        </div>
      </Content>
    </Box>
  );
};
