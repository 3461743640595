import styled from 'styled-components';

const StyledPagination = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  .active {
    a {
      background: var(--Primary, #5784f7);
      color: white;
      font-weight: 600;
    }
  }

  .pagination {
    display: flex;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      width: 36px;
      height: 36px;
      cursor: pointer;
      border-radius: 4px;
      margin: 8px;
      &:hover {
        color: white;
        background: #5784f7;
        font-weight: 600;
        svg {
          fill: white;
        }
      }
    }
  }
`;

export { StyledPagination };
