import { styled } from 'styled-components';

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  min-width: 500px;
  h3 {
    color: #0d0d0d;
    font-size: 14px;
    font-weight: 600;
    line-height: 125%; /* 17.5px */
  }
  li {
    color: var(--Control, #9aa4b0);
    font-family: Jost;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    display: flex;
    align-items: center;
  }
  button {
    width: 100%;
  }
`;

const OrganizationInfo = styled.div`
  font-size: 14px;
  color: #0d0d0d;
  font-weight: 600;
  line-height: 125%;
  display: flex;
  gap: 10px;
  width: 100%;
  flex-direction: column;
  p {
    color: #5f5f5f;
    font-weight: 400;
  }
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Полупрозрачный черный фон */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Убедитесь, что модальное окно находится поверх всего */
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 100%;
  z-index: 1001;
`;

export { ContentBox, OrganizationInfo, ModalContent, ModalBackground };
