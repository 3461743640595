import React, { useState } from 'react';
import styled, { css } from 'styled-components';

interface DropdownProps {
  label: string;
  options: Array<{ id: string; name: string }>;
  selectedOptions: string[];
  onOptionSelect: (option: { id: string; name: string }) => void;
  placeholder?: string;
  disabled?: boolean;
  maxCharacters?: number; // Add maxCharacters as a prop to control the maximum characters before truncating
}

const SelectBox: React.FC<DropdownProps> = ({
  label,
  options,
  selectedOptions,
  onOptionSelect,
  placeholder = 'Выберите...',
  disabled = false,
  maxCharacters = 30, // Default max characters for truncation
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    if (!disabled) setIsDropdownOpen(!isDropdownOpen);
  };

  const getHeaderText = () => {
    if (selectedOptions.length === 0) return placeholder;

    // Create a comma-separated string of selected options
    const allSelectedText = selectedOptions.join(', ');

    // If the total character length is within the limit, return the full string
    if (allSelectedText.length <= maxCharacters) {
      return allSelectedText;
    }

    // Truncate the text and append an ellipsis if the character limit is exceeded
    return allSelectedText.slice(0, maxCharacters) + '...';
  };

  return (
    <DropdownContainer>
      <DropdownHeaderContainer>
        <label>{label}</label>
        {selectedOptions.length > 0 && (
          <SelectedCount>Выбрано {selectedOptions.length}</SelectedCount>
        )}
      </DropdownHeaderContainer>
      <DropdownHeader
        onClick={toggleDropdown}
        isOpen={isDropdownOpen}
        $hasContent={selectedOptions.length > 0}
        $isDisabled={disabled}>
        <TruncatedText>{getHeaderText()}</TruncatedText>
        <DropdownArrow isOpen={isDropdownOpen}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='17'
            viewBox='0 0 16 17'
            fill='none'>
            <rect
              width='16'
              height='16'
              transform='translate(0 0.5)'
              fill='white'
              fillOpacity='0.01'
              style={{ mixBlendMode: 'multiply' }}
            />
            <path
              d='M8 11.5008L3 6.50078L3.7 5.80078L8 10.1008L12.3 5.80078L13 6.50078L8 11.5008Z'
              fill='#A0A0A0'
            />
          </svg>
        </DropdownArrow>
      </DropdownHeader>
      {isDropdownOpen && (
        <DropdownListContainer>
          {options.map((option) => (
            <DropdownListItem
              key={option.id}
              onClick={() => onOptionSelect(option)}
              $isSelected={selectedOptions.includes(option.name)}>
              {option.name}
              {selectedOptions.includes(option.name) && (
                <CheckmarkIcon>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='12'
                    height='12'
                    viewBox='0 0 16 17'
                    fill='none'>
                    <path
                      d='M13.3327 4.5L5.99935 11.8333L2.66602 8.5'
                      stroke='#141414'
                      strokeWidth='2'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </CheckmarkIcon>
              )}
            </DropdownListItem>
          ))}
        </DropdownListContainer>
      )}
    </DropdownContainer>
  );
};

// Styled Components
const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

const DropdownHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  label {
    color: var(--neutral-800, #303030);
    font-family: 'Jost';
    font-size: 16px;
    font-weight: 500;
  }
`;

const SelectedCount = styled.span`
  color: var(--neutral-400, #a0a0a0);
  font-size: 14px;
`;

const DropdownHeader = styled.div<{
  isOpen?: boolean;
  $hasContent?: boolean;
  $isDisabled?: boolean;
}>`
  margin-top: 5px;
  padding: 8px;
  background: var(--neutral-100, #f4f4f4);
  border: 1px solid var(--Control, #f4f4f4);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--Control, #9aa4b0);
  font-size: 14px;
  line-height: 125%;
  font-weight: 400;
  ${({ $hasContent }) =>
    $hasContent &&
    css`
      color: var(--neutral-800, #303030);
    `}

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}
`;

const TruncatedText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 20px); /* Adjust this based on the arrow size */
`;

const DropdownListContainer = styled.div`
  position: absolute;
  width: 100%;
  border-radius: 6px;
  background: var(--White, #f4f4f4);
  z-index: 1000;
  margin-top: 4px;
  overflow-x: scroll;
  max-height: 170px;
`;

const DropdownListItem = styled.div<{ $isSelected?: boolean }>`
  padding: 8px;
  cursor: pointer;
  font-size: 14px;
  line-height: 125%;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--neutral-400, #a0a0a0);

  &:hover {
    background: var(--Light, #f5f5f5);
    color: var(--neutral-800, #303030);
  }

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background: var(--Light, #f5f5f5);
      color: var(--neutral-800, #303030);
    `}
`;

const CheckmarkIcon = styled.div`
  margin-left: auto;
  svg {
    fill: none;
    stroke: #141414;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
`;

const DropdownArrow = styled.div<{ isOpen: boolean }>`
  display: inline-block;
  margin-left: 8px;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')}; /* Rotate the arrow */
  transition: transform 0.2s ease-in-out; /* Add smooth animation */
`;

export default SelectBox;
