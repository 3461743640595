import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  background: var(--neutral-50, #fefefe);
  h3 {
    color: #0d0d0d;
    font-size: 24px;
    font-weight: 600;
    line-height: 106%; /* 25.44px */
  }
  strong {
    color: #0d0d0d;
    font-size: 14px;
    font-weight: 600;
    line-height: 125%; /* 17.5px */
  }
`;

const Table = styled.table`
  width: 459px;
  height: auto;
  max-height: 144px;
  overflow-y: auto;
  border-collapse: collapse;
  margin-top: 10px;
  border-radius: 4px;
  margin-bottom: 16px;
  border: 1px solid #f2f2f2;
`;

const TableHeader = styled.th`
  padding: 10px;
  text-align: left;
  background: #f6f8fa;
  color: #0f1419;
  font-style: normal;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  align-self: stretch;
`;

const TableRowHeader = styled.tr`
  border: none;
`;

const TableRow = styled.tr<{ $isSelected?: boolean }>`
  border-top: 1px solid #f6f8fa;
  background: ${(props) => (props.$isSelected ? '#EDFEFF' : 'transparent')};
`;

const TableCell = styled.td`
  padding: 16px 10px;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  position: relative;
  img {
    cursor: pointer;
  }
`;

const TelegramIcon = styled.img`
  width: 16px;
  height: 14px;
`;

const TelegramBlock = styled.div`
  border: 1px solid var(--Control, #9aa4b0);
  padding: 10px;
  align-items: center;
`;

const TelegramSearchBlock = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
`;

const TelegramLinkBlock = styled.div`
  margin-top: 10px;
  height: 25px;
  display: flex;
  align-items: center;
  background: #5dafe9;
  border-radius: 8px;
`;

const TelegramHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 20px;
  font-weight: bold;
  max-width: 220px;
  height: 40px;
  border: none;
  color: #5dafe9;
  text-align: center;
`;

const ButtonAddCode = styled.button`
  width: 35px;
  height: 35px;
`;

const ButtonGenerateCode = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: #5dafe9;
  font-size: 14px;
  font-weight: 600;
  max-width: 460px;
  padding: 12px 42px;
  background: #5dafe9;
  border: none;
  color: #fff;
  gap: 10px;
  cursor: pointer;
  &&:hover {
    opacity: 0.9;
  }
`;

export {
  Container,
  Table,
  TableHeader,
  TableRowHeader,
  TableRow,
  TableCell,
  TelegramIcon,
  TelegramBlock,
  TelegramHeader,
  TelegramSearchBlock,
  TelegramLinkBlock,
  ButtonAddCode,
  ButtonGenerateCode,
};
