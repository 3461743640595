import React, { useState } from 'react';
import {
  Box,
  Title,
  CardBox,
  Card,
  PaymentDate,
  PaymentDay,
  PaymentSum,
  Description,
  BoxPayment,
  Details,
  getPaymentDayStyles,
  getPaymentSumStyles,
  ModalBackdrop,
  ModalContent,
  CloseButton,
  Block,
  BoxInfo,
  BankInfoWrapper,
  BankInfoDetail,
  BankInfoHeader,
} from './ContractSchedule.style';
import { sortCalendar, extractMonthStr, extractMonthPay } from './tools';
import { PaymentCalendar } from './ContractBusiness.interface';
import { default as IconSchedule } from 'assets/img/IconSchedule.svg';
import IconWarning from 'assets/img/IconWarning.svg';
import IconDone from 'assets/img/IconDoneCheck.svg';
import IconError from 'assets/img/IconWarningError.svg';
import { ArrowIcon } from 'components/ApplicationInvestor/ApplicationInvestorNew.style';
import IconArrow from 'assets/img/IconArrow.svg';
import IconArrowDown from 'assets/img/IconArrowDown.svg';
import CopyableText from 'components/common/CopyableText/CopyableText';
import { NewButton } from 'components/common/Button';
import { useAppContext } from 'contexts/AppContext';
import axios from 'axios';

interface ContractScheduleProps {
  calendar: PaymentCalendar[];
  status: string;
  ibanInvestor: string;
  ibanBusiness: string;
  bankInvestor: any;
  bankBusiness: any;
}

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/`;

export const ContractSchedule: React.FC<ContractScheduleProps> = ({
  calendar,
  status,
  ibanInvestor,
  ibanBusiness,
  bankInvestor,
  bankBusiness,
}) => {
  const { userDataLight } = useAppContext();
  const [openCard, setOpenCard] = useState<number | null>(null);
  const [showBankInfo, setShowBankInfo] = useState(false);
  const [showDocuments, setShowDocuments] = useState(false);
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);

  const endpoint =
    userDataLight.organizationRole === 'INVESTOR'
      ? `api/v1/cabinet/investor/payment/calendar/`
      : `api/v1/cabinet/creditor/payment/calendar/`;

  const sortedCalendar = sortCalendar(calendar);

  const handleGenerateInvoice = async (id: any) => {
    const selectedIds = Array.from(id);
    const url = `${BASE_URL}${endpoint}payment_ordinary`;
    setIsLoadingPdf(true); // Set loading state to true
    try {
      const response = await axios.post(url, selectedIds, {
        withCredentials: true,
        responseType: 'blob',
      });

      // Create a link to download the file
      const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlBlob;
      link.setAttribute('download', 'invoice.zip'); // Set the file name with .zip extension
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.error('Error generating invoice:', error);
    } finally {
      setIsLoadingPdf(false); // Set loading state to false
    }
  };

  const handleCardClick = (id: number) => {
    setOpenCard(id === openCard ? null : id);
    setShowBankInfo(false);
    setShowDocuments(false);
  };

  const toggleBankInfo = () => {
    setShowBankInfo(!showBankInfo);
  };

  const toggleDocuments = () => {
    setShowDocuments(!showDocuments);
  };

  return (
    <Box>
      <Title>График погашения</Title>
      <CardBox>
        {sortedCalendar.map((payment, index) => {
          const paymentDate = new Date(payment.paymentDate);
          const formattedDate = extractMonthPay(payment.paymentDate);
          const paymentDayStyles = getPaymentDayStyles(payment.status);
          const paymentSumStyles = getPaymentSumStyles(payment.status);

          let icon;
          switch (payment.status) {
            case 'Просрочен':
              icon = <img src={IconError} alt='icon' />;
              break;
            case 'Оплачен':
              icon = <img src={IconDone} alt='icon' />;
              break;
            case 'Не оплачен':
            default:
              icon = <img src={IconWarning} alt='icon' />;
              break;
          }

          return (
            <React.Fragment key={index}>
              <Card onClick={() => handleCardClick(index)}>
                <PaymentDate>
                  <img src={IconSchedule} alt='icon' />
                  {extractMonthStr(payment.paymentDate)}
                </PaymentDate>
                <Description>
                  Ежемесячный платеж
                  <p>До {formattedDate}</p>
                </Description>
                <BoxPayment>
                  <PaymentDay style={paymentDayStyles}>{paymentDate.getDate()}</PaymentDay>
                  <PaymentSum style={paymentSumStyles}>
                    {payment.paymentSum.toLocaleString('ru-RU')} ₸
                  </PaymentSum>
                </BoxPayment>
                <Details>Подробнее</Details>
              </Card>
              {openCard === index && (
                <ModalBackdrop onClick={() => setOpenCard(null)}>
                  <ModalContent onClick={(e) => e.stopPropagation()}>
                    <CloseButton onClick={() => setOpenCard(null)}>&times;</CloseButton>
                    <PaymentDate>
                      <img src={IconSchedule} alt='icon' />
                      {extractMonthStr(payment.paymentDate)}
                      {icon}
                    </PaymentDate>
                    <PaymentSum style={paymentSumStyles}>
                      {payment.paymentSum.toLocaleString('ru-RU')} ₸
                    </PaymentSum>
                    <Block status={payment.status}>
                      {payment.status === 'Оплачен' ? (
                        <>
                          Оплачено {formattedDate} {icon}
                        </>
                      ) : (
                        <>
                          {payment.status === 'Просрочен'
                            ? 'Просрочено : Оплатить до'
                            : 'Оплатить до'}{' '}
                          {formattedDate} {icon}
                        </>
                      )}
                    </Block>
                    <BoxInfo>
                      <BankInfoHeader onClick={toggleBankInfo}>
                        Инфо по банку
                        <ArrowIcon src={showBankInfo ? IconArrowDown : IconArrow} alt='arrow' />
                      </BankInfoHeader>
                      {showBankInfo && (
                        <BankInfoWrapper>
                          <BankInfoDetail>
                            <CopyableText
                              text={ibanBusiness}
                              label={`Счет Бизнес: ${ibanBusiness}`}
                            />
                          </BankInfoDetail>
                          <BankInfoDetail>
                            <span>{`Банк Бизнес: ${bankBusiness}`}</span>
                          </BankInfoDetail>
                          <BankInfoDetail>
                            <CopyableText
                              text={ibanInvestor}
                              label={`Счет Инвестора: ${ibanInvestor}`}
                            />
                          </BankInfoDetail>
                          <BankInfoDetail>
                            <span>{`Банк Инвестора: ${bankInvestor}`}</span>
                          </BankInfoDetail>
                        </BankInfoWrapper>
                      )}
                    </BoxInfo>
                    <BoxInfo>
                      <BankInfoHeader onClick={toggleDocuments}>
                        Документы
                        <ArrowIcon src={showDocuments ? IconArrowDown : IconArrow} alt='arrow' />
                      </BankInfoHeader>
                      {showDocuments && (
                        <BankInfoWrapper>
                          <NewButton
                            text='Скачать счет .pdf'
                            onClick={() => handleGenerateInvoice(payment.id)}
                            loading={isLoadingPdf}
                            disabled={isLoadingPdf}
                          />
                        </BankInfoWrapper>
                      )}
                    </BoxInfo>
                  </ModalContent>
                </ModalBackdrop>
              )}
            </React.Fragment>
          );
        })}
      </CardBox>
    </Box>
  );
};
