import { CheckboxContainer, UncheckedBox } from './Checkbox.styled';

type CustomCheckboxProps = {
  checked: boolean;
  onChange: () => void;
};

export const Checkbox: React.FC<CustomCheckboxProps> = ({ checked, onChange }) => {
  return (
    <CheckboxContainer onClick={onChange}>
      {checked ? (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='14'
          height='14'
          viewBox='0 0 14 14'
          fill='none'>
          <rect x='0.5' y='0.5' width='13' height='13' rx='2.5' fill='#5784F7' stroke='#5784F7' />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M11.8703 3.6433C12.0484 3.8452 12.0423 4.16596 11.8568 4.35972L5.72252 10.7648C5.42352 11.077 4.95183 11.0786 4.65109 10.7684L2.14529 8.18355C1.95864 7.99102 1.95079 7.67031 2.12774 7.46723L2.55495 6.97696C2.73191 6.77388 3.02667 6.76533 3.21332 6.95786L5.18184 8.98845L10.7819 3.14111C10.9675 2.94735 11.2623 2.95395 11.4404 3.15586L11.8703 3.6433Z'
            fill='white'
          />
        </svg>
      ) : (
        <UncheckedBox />
      )}
    </CheckboxContainer>
  );
};
