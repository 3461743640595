import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
`;

export const BoxScale = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 80%;
`;

export const ScaleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  gap: 8px;
  span {
    color: var(--Text-text-primary, #161616);
    font-family: 'IBM Plex Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.16px;
  }
`;

export const ScaleLine = styled.div`
  flex: 1;
  height: 2px;
  background: linear-gradient(to right, #30e681, #fef40f, #f33030);
  position: relative;
`;

interface IndicatorProps {
  percentage: number;
  color: string;
}

export const Indicator = styled.div<IndicatorProps>`
  position: absolute;
  top: -10px;
  left: ${(props) => props.percentage}%;
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
`;

interface ValueTextProps {
  percentage?: number;
  color: string;
}

export const ValueText = styled.div<ValueTextProps>`
  position: absolute;
  top: 10px;
  left: ${(props) => props.percentage}%;
  color: #303030;
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.16px;
`;

export const Value = styled.p<ValueTextProps>`
  color: ${(props) => props.color};
  font-family: 'Jost';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 39px; /* 162.5% */
  margin-left: 20px;
`;

export const Header = styled.p`
  color: var(--Text-text-secondary, #525252);
  font-family: 'IBM Plex Sans';
  font-size: 16px;
  font-weight: 500;
  line-height: 16px; /* 100% */
  letter-spacing: 0.32px;
`;

export const MainInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const BoxSimple = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  border: 1px solid var(--neutral-800, #303030);
  border-radius: 8px;
  color: var(--neutral-800, #303030);
  font-family: 'Jost';
  font-size: 18px;
  font-weight: 600;
  line-height: 125%;
  margin-top: 8px;
`;

interface BoxBoolProps {
  color: string;
}

export const BoxBool = styled.div<BoxBoolProps>`
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  border: 1px solid var(--neutral-800, #303030);
  border-radius: 8px;
  font-family: 'Jost';
  font-size: 18px;
  font-weight: 600;
  line-height: 125%;
  margin-top: 8px;
  color: ${(props) => props.color};
`;

export const BoxRecommended = styled.div`
  display: flex;
  justify-content: center;
  gap: 14px;
  padding: 8px 12px;
  border: 1px solid var(--neutral-800, #303030);
  border-radius: 8px;
  color: var(--neutral-800, #303030);
  font-family: 'Jost';
  font-size: 18px;
  font-weight: 600;
  line-height: 125%;
  margin-top: 20px;
`;
