import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import axios from 'axios';
import IconStar from 'assets/img/IconStar.svg';
import IconArrow from 'assets/img/IconArrow.svg';
import IconArrowDown from 'assets/img/IconArrowDown.svg';
import {
  Container,
  MainInfoBox,
  MainInfo,
  ListScoring,
  CardScoring,
  CardInfo,
  CardDescription,
  Circle,
  ChartContainer,
  CenterText,
} from './InfoBusinessNew.style';
import {
  ApiResponseInfoBusiness,
  DonutChartProps,
  InfoBusinessProps,
} from './InfoBusiness.interface';
import {
  ExpandableSection,
  MainInfoTitle,
  ArrowIcon,
} from 'components/ApplicationInvestor/ApplicationInvestorNew.style';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

export const DonutChart: React.FC<DonutChartProps> = ({
  completed,
  inProgress,
  declined,
  notApproved,
  totalAmount,
  title,
}) => {
  const data = {
    labels: ['Исполнено', 'На исполнении', 'Отклоненные', 'Не согласовании'],
    datasets: [
      {
        data: [completed, inProgress, declined, notApproved],
        backgroundColor: ['#1FC8D1', '#2BB774', '#FA4D56', '#F8B801'],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false, // Это уберет легенду сверху
      },
      tooltip: {
        enabled: false, // Отключить всплывающие подсказки
      },
    },
    cutout: '85%', // строка с процентом для определения толщины кольца
    maintainAspectRatio: false, // Добавлено, чтобы предотвратить искажение аспектного соотношения при изменении размеров контейнера
  };

  return (
    <ChartContainer>
      <Doughnut data={data} options={options} />
      <CenterText>
        <h4>{totalAmount.toLocaleString('ru-RU')} ₸</h4>
        <p>{title}</p>
      </CenterText>
    </ChartContainer>
  );
};

export const InfoBusinessNew: React.FC<InfoBusinessProps> = ({ iinbin }) => {
  const [apiData, setApiData] = useState<ApiResponseInfoBusiness | null>(null);
  const [isMainInfoExpanded, setIsMainInfoExpanded] = useState(true);

  const formatDate = (dateStr: string): string => {
    const [year, month, day] = dateStr.split('-');
    return `${day}.${month}.${year}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}cabinet/investor/request/creditor/get?creditorId=${iinbin}`,
          {
            withCredentials: true,
          },
        );
        setApiData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  if (!apiData) {
    return <div>Loading...</div>;
  }

  const reqCurrent = apiData.current.find((item) => item.statusName === 'reqCurrent') || {
    countRequest: 0,
    sum: 0,
  };
  const contractCurrent = apiData.current.find((item) => item.statusName === 'contractCurrent') || {
    countRequest: 0,
    sum: 0,
  };
  const contractReview = apiData.current.find((item) => item.statusName === 'contractReview') || {
    countRequest: 0,
    sum: 0,
  };
  const reqRejected = apiData.current.find((item) => item.statusName === 'reqRejected') || {
    countRequest: 0,
    sum: 0,
  };
  const sumApplication = contractReview.sum + reqCurrent.sum;
  const contractDone = apiData.current.find((item) => item.statusName === 'contractDone') || {
    countRequest: 0,
    sum: 0,
  };
  const contractRejected = apiData.current.find(
    (item) => item.statusName === 'contractRejected',
  ) || { countRequest: 0, sum: 0 };
  const sumContract = contractDone.sum + contractCurrent.sum + contractReview.sum;

  return (
    <Container>
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsMainInfoExpanded(!isMainInfoExpanded)}>
          <img src={IconStar} alt='icon' />
          Кредитная история
          <ArrowIcon src={isMainInfoExpanded ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isMainInfoExpanded && (
          <MainInfoBox>
            <div>
              <MainInfo>
                <p>Дата регистрации в системе:</p>
                <p>{apiData ? formatDate(apiData.info.period) : 'Loading...'}</p>
              </MainInfo>
            </div>
            <div>
              <MainInfo>
                <p>Заявок на рассмотрении:</p>
                <p className='green'>{reqCurrent.countRequest}</p>
              </MainInfo>
              <MainInfo>
                <p>На сумму:</p>
                <p className='green'>{reqCurrent.sum.toLocaleString('ru-RU')} ₸</p>
              </MainInfo>
            </div>
            <div>
              <MainInfo>
                <p>Обязательства по договорам:</p>
                <p className='green'>{contractCurrent.countRequest}</p>
              </MainInfo>
              <MainInfo>
                <p>На сумму:</p>
                <p className='green'>{contractCurrent.sum.toLocaleString('ru-RU')} ₸</p>
              </MainInfo>
            </div>
            <div>
              <MainInfo>
                <p>Просроченных платежей:</p>
                <p className='red'>0</p>
              </MainInfo>
              <MainInfo>
                <p>На сумму:</p>
                <p className='red'>0 ₸</p>
              </MainInfo>
            </div>
            <div>
              <MainInfo>
                <p>Проблемных договоров:</p>
                <p className='red'>0</p>
              </MainInfo>
            </div>
          </MainInfoBox>
        )}
        <ListScoring>
          <CardScoring>
            <h3>Статистика по заявкам</h3>
            <CardInfo>
              <CardDescription>
                <p>Принято заявок:</p>
                <div>
                  <Circle color='#1FC8D1' />
                  {contractReview.countRequest}
                </div>
                <p>На рассмотрении:</p>
                <div>
                  <Circle color='#F8B801' />
                  {reqCurrent.countRequest}
                </div>
                <p>Отклонено/Не принятые:</p>
                <div>
                  <Circle color='#FA4D56' />
                  {reqRejected.countRequest}
                </div>
              </CardDescription>
              <DonutChart
                completed={contractReview.countRequest}
                notApproved={reqCurrent.countRequest}
                declined={reqRejected.countRequest}
                totalAmount={sumApplication}
                title='Сумма заявок'
              />
            </CardInfo>
          </CardScoring>
          <CardScoring>
            <h3>Статистика по договорам</h3>
            <CardInfo>
              <CardDescription>
                <p>Исполнено:</p>
                <div>
                  <Circle color='#1FC8D1' />
                  {contractDone.countRequest}
                </div>
                <p>На исполнении:</p>
                <div>
                  <Circle color='#2BB774' />
                  {contractCurrent.countRequest}
                </div>
                <p>Отклоненные:</p>
                <div>
                  <Circle color='#FA4D56' />
                  {contractRejected.countRequest}
                </div>
                <p>Не согласовании:</p>
                <div>
                  <Circle color='#F8B801' />
                  {contractReview.countRequest}
                </div>
              </CardDescription>
              <DonutChart
                completed={contractDone.countRequest}
                inProgress={contractCurrent.countRequest}
                declined={contractRejected.countRequest}
                notApproved={contractReview.countRequest}
                totalAmount={sumContract}
                title='Сумма договоров'
              />
            </CardInfo>
          </CardScoring>
        </ListScoring>
      </ExpandableSection>
    </Container>
  );
};
