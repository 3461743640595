import styled from 'styled-components';
const BoxInput = styled.div`
  display: flex;
  align-items: center;
  padding-right: 20px;
  padding-bottom: 20px;
`;

const BoxInputTitle = styled.span`
  font-size: 16px;
  width: 200px;
  margin-right: 10px;
`;

const Box = styled.div`
  flex: 1;
  background-color: #fff;
  max-width: 400px;
  align-items: center;
  padding-right: 10px;
`;

export { BoxInput, BoxInputTitle, Box };
