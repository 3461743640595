import React, { useState } from 'react';
import { Container, Header, ContentBox } from './NewCreateApplication.style';
import OneStep from './OneStep';
import StepIndicator from './StepIndicator';
import TwoStep from './TwoStep';
import ThreeStep from './ThreeStep';
import FourStep from './FourStep';
import { CollateralOption, Contract } from './NewCreateApplication.interface';

export const NewCreateApplication: React.FC = () => {
  const [idApplication, SetIdApplication] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedProductCode, setSelectedProductCode] = useState<string>('');
  const [sum, setSum] = useState('');
  const [days, setDays] = useState<string>('');
  const [minSum, setMinSum] = useState('');
  const [percent, setPercent] = useState('');
  const [selectAccountNum, setSelectAccountNum] = useState('');
  const [selectedGroups, setSelectedGroups] = useState<{ code: string }[]>([]);
  const [uploadedFilesOther, setUploadedFilesOther] = useState<{ name: string; uid: string }[]>([]);
  const [uploadedFilesBank, setUploadedFilesBank] = useState<
    { name: string; uid: string; code: string }[]
  >([]);
  const [selectedContract, setSelectedContract] = useState<Contract | null>(null);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [projectName, setProjectName] = useState('');
  const [targetLoan, setTargetLoan] = useState('');
  const [descriptionProject, setDescriptionProject] = useState('');
  const [selectedCollateral, setSelectedCollateral] = useState<CollateralOption | null>(null);
  const [docLink, setDocLink] = useState('');
  return (
    <Container>
      <Header>Создание заявки /</Header>
      <ContentBox>
        {currentStep === 0 && (
          <OneStep
            setCurrentStep={setCurrentStep}
            setSelectedProductCode={setSelectedProductCode}
          />
        )}
        {currentStep === 1 && (
          <TwoStep
            setCurrentStep={setCurrentStep}
            selectedProductCode={selectedProductCode}
            sum={sum}
            setSum={setSum}
            days={days}
            setDays={setDays}
            minSum={minSum}
            setMinSum={setMinSum}
            percent={percent}
            setPercent={setPercent}
            setSelectAccountNum={setSelectAccountNum}
            selectedGroups={selectedGroups}
            setSelectedGroups={setSelectedGroups}
            uploadedFilesOther={uploadedFilesOther}
            setUploadedFilesOther={setUploadedFilesOther}
            selectedContract={selectedContract}
            setSelectedContract={setSelectedContract}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            selectedCollateral={selectedCollateral}
            setSelectedCollateral={setSelectedCollateral}
            setDocLink={setDocLink}
            docLink={docLink}
            uploadedFilesBank={uploadedFilesBank}
            setUploadedFilesBank={setUploadedFilesBank}
          />
        )}
        {currentStep === 2 && (
          <ThreeStep
            setCurrentStep={setCurrentStep}
            selectedProductCode={selectedProductCode}
            sum={sum}
            days={days}
            minSum={minSum}
            percent={percent}
            selectAccountNum={selectAccountNum}
            selectedGroups={selectedGroups}
            uploadedFilesOther={uploadedFilesOther}
            selectedContract={selectedContract}
            SetIdApplication={SetIdApplication}
            projectName={projectName}
            setProjectName={setProjectName}
            targetLoan={targetLoan}
            setTargetLoan={setTargetLoan}
            descriptionProject={descriptionProject}
            setDescriptionProject={setDescriptionProject}
            selectedCollateral={selectedCollateral}
            docLink={docLink}
            uploadedFilesBank={uploadedFilesBank}
          />
        )}
        {currentStep === 3 && <FourStep idApplication={idApplication} />}
        <StepIndicator currentStep={currentStep} />
      </ContentBox>
    </Container>
  );
};
