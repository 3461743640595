import React, { useEffect, useState } from 'react';
import {
  Container,
  Title,
  Table,
  TableHeader,
  TableRowHeader,
  TableRow,
  TableCell,
  ButtonSmallBlock,
  ButtonSmallEdit,
} from './UserListStyle';
import { UserDetails } from '../../AdminSettingsInterface';
import { default as IconThreeDots } from 'assets/img/IconThreeDots.svg';
import { default as IconLock } from 'assets/img/IconLock.svg';
import { EditUserModal } from '../../Modal/editModal/EditUserModal';
import { BlockUserModal } from '../../Modal/blockModal/BlockUserModal';
import * as api from '../../admin-settings.api';

export const UserList = () => {
  const [selectedRow, setSelectedRow] = useState<UserDetails | null>(null);
  const [tableData, setTableData] = useState<UserDetails[]>([]);
  const [editUserModal, setEditUserModal] = useState(false);
  const [blockModal, setBlockModal] = useState(false);

  const handleEditClick = (row: UserDetails) => {
    setEditUserModal(true);
    setSelectedRow(row);
  };

  const handleBlockClick = (row: UserDetails) => {
    setBlockModal(true);
    setSelectedRow(row);
  };

  const handleUpdateUser = (updatedUser: UserDetails) => {
    const updatedData = tableData.map((user) => (user.id === updatedUser.id ? updatedUser : user));
    setTableData(updatedData);
    setEditUserModal(false);

    api
      .userList()
      .then((response) => {
        setTableData(response.data);
      })
      .catch((error) => {
        console.error('Ошибка обновления данных:', error);
      });
  };

  useEffect(() => {
    api.userList().then((response) => {
      setTableData(response.data);
    });
  }, []);

  return (
    <Container>
      <Title>Список пользователей</Title>
      <Table>
        <thead>
          <TableRowHeader>
            <TableHeader>ИИН</TableHeader>
            <TableHeader>БИН</TableHeader>
            <TableHeader>Почта</TableHeader>
            <TableHeader>Телефон</TableHeader>
            <TableHeader>ФИО</TableHeader>
            <TableHeader>Организация</TableHeader>
            <TableHeader>Роли</TableHeader>
            <TableHeader>Редактирование</TableHeader>
            <TableHeader>Блокировка</TableHeader>
            <TableHeader></TableHeader>
          </TableRowHeader>
        </thead>
        <tbody>
          {Array.isArray(tableData) &&
            tableData?.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row?.iin}</TableCell>
                <TableCell>{row?.bin}</TableCell>
                <TableCell>{row?.email}</TableCell>
                <TableCell>{row?.phone}</TableCell>
                <TableCell>{row?.fio}</TableCell>
                <TableCell>{row?.organization}</TableCell>
                <TableCell>{row?.roles && row.roles.join(', ')}</TableCell>
                <TableCell>
                  <ButtonSmallEdit onClick={() => handleEditClick(row)}>
                    <img src={IconThreeDots} alt='icon-three' />
                  </ButtonSmallEdit>
                </TableCell>
                <TableCell>
                  <ButtonSmallBlock onClick={() => handleBlockClick(row)}>
                    <img src={IconLock} alt='icon-lock' />
                  </ButtonSmallBlock>
                </TableCell>
                {editUserModal && (
                  <EditUserModal
                    userDetails={selectedRow}
                    onClose={() => setEditUserModal(false)}
                    id={selectedRow?.id || 0}
                    onUpdateUser={handleUpdateUser}
                  />
                )}
                {blockModal && (
                  <BlockUserModal
                    onClose={() => setBlockModal(false)}
                    name={selectedRow?.fio || ''}
                    id={selectedRow?.id || 0}
                  />
                )}
              </TableRow>
            ))}
        </tbody>
      </Table>
      {/*<Pagination*/}
      {/*  pageCount={Math.ceil(filteredData.length / rowsPerPage)}*/}
      {/*  currentPage={currentPage}*/}
      {/*  onPageChange={handlePageChange}*/}
      {/*/>*/}
      {/*{isCreateModalOpen && <CreateApplication closeModal={closeModal} />}*/}
    </Container>
  );
};
