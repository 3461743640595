import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;
const TEST_URL = `http://localhost:8081`;

export const addTelegramAcc = (newAcc: string) => {
  return axios.get(`${BASE_URL}ui/user/telegram/acc?userName=${newAcc}`, {
    withCredentials: true,
  });
};

export const deleteAcc = (id: number) => {
  return axios.delete(`${BASE_URL}ui/user/delete/${id}`, {
    withCredentials: true,
  });
};

export const updateUserTelegramList = () => {
  return axios.get(`${BASE_URL}ui/user/telegram/info`, {
    withCredentials: true,
  });
};
