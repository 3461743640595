import React from 'react';
import { ButtonStyle } from './NewButton.style';

interface ButtonProps {
  text: string;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  icon?: string;
}

export const NewButton = ({ text, className, onClick, disabled, loading, icon }: ButtonProps) => {
  return (
    <ButtonStyle
      className={`${className} ${loading ? 'loading' : ''}`}
      onClick={onClick}
      disabled={disabled}>
      {text}
      {icon && <img src={icon} alt='icon' />} {/* Отображение иконки */}
    </ButtonStyle>
  );
};
