import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import IphoneGif from 'assets/img/IphoneGif.gif';
import { offerSign } from '../TestStand/signtest/offerSign';
import { useAppContext } from 'contexts/AppContext';
import '@react-pdf-viewer/core/lib/styles/index.css';
import {
  Container,
  HeaderOferts,
  ContentBox,
  LefBlock,
  RightBlock,
  CardVersion,
  CardTitle,
  CardIconStatus,
  Pdf,
  Loader,
  ErrorMessage,
} from './OfertsVersioning.style';
import IconDoneCheck from 'assets/img/IconDoneCheck.svg';
import IconWarningError from 'assets/img/IconWarningError.svg';
import { pdfjs } from 'react-pdf';
import { NewButton } from 'components/common/Button';

// Define the Offer type
interface Offer {
  hashOffer: string;
  docId: number;
  isSigned: boolean;
  version: number;
  offer: string;
}

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

const documentType = 'PUBLIC_OFFER_CABINET';

export const OfertsVersioning: React.FC = () => {
  const { userDataLight } = useAppContext();
  const [offers, setOffers] = useState<Offer[]>([]);
  const [documentContent, setDocumentContent] = useState<string | null>(null);
  const [activeOffer, setActiveOffer] = useState<Offer | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchOffers = async () => {
    try {
      const response = await axios.get<Offer[]>(`${BASE_URL}/api/v1/ui/user/public/offer/list`, {
        withCredentials: true,
        responseType: 'json',
      });
      const sortedOffers = response.data.sort((a, b) =>
        a.isSigned === b.isSigned ? 0 : a.isSigned ? 1 : -1,
      );
      setOffers(sortedOffers);

      const firstUnsignedOffer = sortedOffers.find((offer) => !offer.isSigned);
      if (firstUnsignedOffer) {
        setActiveOffer(firstUnsignedOffer);
        handleCardClick(firstUnsignedOffer);
      } else if (sortedOffers.length > 0) {
        setActiveOffer(sortedOffers[0]);
        handleCardClick(sortedOffers[0]);
      }
      setLoading(false);
    } catch (error) {
      setError('Ошибка при загрузке данных');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOffers();
  }, []);

  const handleCardClick = (offer: Offer) => {
    const binaryString = window.atob(offer.offer);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    const blob = new Blob([bytes], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(blob);
    setDocumentContent(fileURL);
    setActiveOffer(offer);
  };

  const handleSignOffer = async () => {
    if (!activeOffer) return;
    const { hashOffer, docId } = activeOffer;
    const endpoint = `${BASE_URL}/api/v1/ui/user/sign/public/offer`;
    try {
      const response = await offerSign(
        hashOffer,
        endpoint,
        docId,
        userDataLight.iin,
        userDataLight.bin,
        documentType,
      );
      if (response.status === 200) {
        fetchOffers(); // Re-fetch offers after successful signing
      }
    } catch (error) {
      console.error('Ошибка при подписании:', error);
    }
  };

  if (loading) {
    return (
      <Container>
        <Loader src={IphoneGif} alt='Loading...' />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <ErrorMessage>{error}</ErrorMessage>
      </Container>
    );
  }

  return (
    <Container>
      <HeaderOferts>Соглашения и оферта</HeaderOferts>
      <ContentBox>
        <LefBlock>
          {offers.map((offer) => (
            <CardVersion
              key={offer.docId}
              onClick={() => handleCardClick(offer)}
              $isActive={offer.docId === activeOffer?.docId}
              $isSigned={offer.isSigned}>
              <CardTitle $isActive={offer.docId === activeOffer?.docId}>
                Публичная оферта версия {offer.version}
              </CardTitle>
              <CardIconStatus
                src={offer.isSigned ? IconDoneCheck : IconWarningError}
                alt='iconStatus'
              />
            </CardVersion>
          ))}
        </LefBlock>
        <RightBlock>
          {documentContent && (
            <Pdf>
              <Worker
                workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
                <Viewer fileUrl={documentContent} />
              </Worker>
            </Pdf>
          )}
        </RightBlock>
      </ContentBox>
      {!activeOffer?.isSigned && <NewButton text='Подписать ЭЦП' onClick={handleSignOffer} />}
    </Container>
  );
};
