import React, { useEffect, useState } from 'react';
import IconAnalytics from 'assets/img/IconAnalytics.svg';
import IconArrow from 'assets/img/IconArrow.svg';
import IconArrowDown from 'assets/img/IconArrowDown.svg';
import {
  Container,
  InfoTitle,
  InfoItem,
  InfoValue,
  ErrorText,
} from './ScoringInfoInvestorNew.style';
import axios from 'axios';
import {
  ExpandableSection,
  MainInfoBox,
  MainInfoTitle,
  MainInfoContent,
  ArrowIcon,
} from 'components/ApplicationInvestor/ApplicationInvestorNew.style';
import { ApiResponse, ScoringInfoProps } from './ScoringInfoInvestor.interface';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/`;

const ScoringInfoInvestorNew: React.FC<ScoringInfoProps> = ({ id }) => {
  const [scoringData, setScoringData] = useState<ApiResponse | null>(null);
  const [isError, setIsError] = useState(false);
  const [isMainInfoExpanded, setIsMainInfoExpanded] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<ApiResponse>(
          `${BASE_URL}api/v1/cabinet/investor/request/creditor/scoring`,
          {
            params: { request: id },
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );

        const { data } = response;

        setScoringData(data);
        setIsError(false);
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setIsError(true);
      }
    };

    fetchData();
  }, [id]);

  if (isError) {
    return <ErrorText>Данные отсутствуют</ErrorText>;
  }

  if (!scoringData) {
    return null;
  }

  const isFioIncomplete =
    !scoringData.firstName || !scoringData.lastName || !scoringData.middleName;

  return (
    <Container>
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsMainInfoExpanded(!isMainInfoExpanded)}>
          <img src={IconAnalytics} alt='icon' />
          Скоринг
          <ArrowIcon src={isMainInfoExpanded ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isMainInfoExpanded && (
          <MainInfoBox>
            <MainInfoContent>
              <InfoItem>
                <InfoTitle>ФИО</InfoTitle>
                <InfoValue>
                  {isFioIncomplete
                    ? 'Данные отсутствуют'
                    : `${scoringData.lastName} ${scoringData.firstName} ${scoringData.middleName}`}
                </InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoTitle>Вероятность дефолта</InfoTitle>
                <InfoValue>{scoringData.defaultRate}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoTitle>Является ИП</InfoTitle>
                <InfoValue>{scoringData.organizationType === 'SL' ? 'Да' : 'Нет'}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoTitle>Кредитный балл</InfoTitle>
                <InfoValue>{scoringData.ball}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoTitle>Доля некредитоспособных субъектов</InfoTitle>
                <InfoValue>{scoringData.badRate}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoTitle>Класс риска</InfoTitle>
                <InfoValue>{scoringData.riskClass}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoTitle>Дата запроса</InfoTitle>
                <InfoValue>{new Date(scoringData.dtCreate).toLocaleString()}</InfoValue>
              </InfoItem>
            </MainInfoContent>
          </MainInfoBox>
        )}
      </ExpandableSection>
    </Container>
  );
};

export default ScoringInfoInvestorNew;
