import styled from 'styled-components';

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  .leftContent {
    display: flex;
    gap: 40px;
    align-items: center;
  }

  h2 {
    font-size: 36px;
    font-weight: 600;
  }
  a {
    text-decoration: none;
  }
`;

const TagRisk = styled.div`
  display: inline-flex;
  background: rgba(137, 225, 65, 0.1);
  border: 1px solid #89e141;
  border-radius: 500px;
  padding: 13px 30px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  color: var(--Black-Main, #292d32);
  gap: 10px;
  font-family: Jost;
  font-size: 18px;
  font-weight: 500;
`;

const TagTime = styled.div`
  display: inline-flex;
  border: 1px solid #d9d9d9;
  border-radius: 500px;
  padding: 13px 30px;
  justify-content: center;
  align-items: center;
  color: var(--Error, #ff5353);
  gap: 10px;
  font-family: Jost;
  font-size: 16px;
  font-weight: 600;
`;

const Timer = styled.p`
  width: 80px;
  text-align: center;
`;

const CardContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  .lightBlue {
    background: #e1fdff;
  }
  .yellow {
    background: #fff5d4;
  }
  .purple {
    background: #d7e0ff;
  }
  .lightPurple {
    background: #f2ebff;
  }
  .lightGreen {
    background: #e7f9d9;
  }
  .green {
    background: #dcf6c6;
  }
  .peach {
    background: #fff4eb;
  }
  .lightPink {
    background: #fbf2f2;
  }
  .lightYellow {
    background: #fffbed;
  }
  .blue {
    background: #b5eef1;
  }
  .down {
    justify-content: flex-end; /* Прижимает контент к нижней части контейнера */
    position: relative;
    height: 140px;
    img {
      position: absolute;
      top: 20px;
    }
  }
`;

const Card = styled.div`
  flex-basis: calc(33.333% - 20px);
  background: #f0f0f0;
  padding: 24px;
  border-radius: 8px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 8px;
  &:hover {
    opacity: 80%;
  }
  img {
    position: relative;
    left: 80%;
  }
  cursor: pointer;
`;

const CardTitle = styled.p`
  color: #5e5e5e;
  font-size: 18px;
`;

const CardHeader = styled.p`
  color: #0d0d0d;
  font-size: 32px;
  font-weight: 600;
`;

const CardContent = styled.p`
  color: #0d0d0d;
  font-size: 22px;
  font-weight: 600;
`;

const BoxContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  max-height: 1300px;
  margin-bottom: 40px;
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export {
  TitleBox,
  TagRisk,
  TagTime,
  Timer,
  CardContainer,
  Card,
  CardTitle,
  CardHeader,
  CardContent,
  BoxContent,
  ButtonBox,
};
