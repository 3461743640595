import React, { useState, useEffect } from 'react';
import { IconNotifications } from './Header.style';
import axios from 'axios';
import IphoneGif from 'assets/img/IphoneGif.gif';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import {
  Dropdown,
  NotificationItem,
  NotificationHeader,
  NotificationClose,
  NotificationBox,
  NotificationTime,
  LoadingIndicator,
  IconDot,
  NotificationInfo,
  NotificationLinkItem,
} from './NotificationsDropdown.style';
import { useAppContext } from 'contexts/AppContext';
import { Notification } from './NotificationsDropdown.interface';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;

export const NotificationsDropdown = () => {
  const { userDataLight } = useAppContext();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchNotifications = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}ui/user/notification`, {
        withCredentials: true,
      });
      if (Array.isArray(response.data)) {
        let sortedNotifications = response.data.sort(
          (a, b) => new Date(b.dtCreate).getTime() - new Date(a.dtCreate).getTime(),
        );

        // Modify the link based on the organization role
        sortedNotifications = sortedNotifications.map((notification) => {
          if (
            userDataLight.organizationRole === 'BUSINESS' &&
            notification.link &&
            notification.link.startsWith('/request/')
          ) {
            return {
              ...notification,
              link: notification.link.replace('/request/', '/req/'),
            };
          }
          return notification;
        });

        setNotifications(sortedNotifications);
      } else {
        console.error('Unexpected data format:', response.data);
        setNotifications([]);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
      setNotifications([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isOpen) {
      fetchNotifications();
    }
  }, [isOpen]);

  return (
    <>
      <IconNotifications onClick={() => setIsOpen(!isOpen)} />
      {isOpen && (
        <Dropdown>
          <NotificationHeader>
            Уведомления
            <NotificationClose onClick={() => setIsOpen(!isOpen)} />
          </NotificationHeader>
          {isLoading ? (
            <LoadingIndicator src={IphoneGif} alt='Loading...' />
          ) : notifications.length > 0 ? (
            notifications.map((notification) =>
              notification.link ? (
                <NotificationLinkItem
                  key={notification.dtCreate}
                  href={notification.link}
                  target='_blank'
                  style={{
                    background: notification.read ? 'inherit' : 'var(--Highlight-Primary, #EDFEFF)',
                  }}>
                  <NotificationBox>
                    <NotificationTime>
                      {format(new Date(notification.dtCreate), 'EEEE, HH:mm ⠀d MMMM, yyyy', {
                        locale: ru,
                      })}
                    </NotificationTime>
                    <NotificationInfo>
                      <p>{notification.template}</p>
                      <IconDot iconType={notification.iconType as 'WARNING' | 'INFO' | 'SYSTEM'} />
                    </NotificationInfo>
                  </NotificationBox>
                </NotificationLinkItem>
              ) : (
                <NotificationItem
                  key={notification.dtCreate}
                  style={{
                    background: notification.read ? 'inherit' : 'var(--Highlight-Primary, #EDFEFF)',
                  }}>
                  <NotificationBox>
                    <NotificationTime>
                      {format(new Date(notification.dtCreate), 'EEEE, HH:mm ⠀d MMMM, yyyy', {
                        locale: ru,
                      })}
                    </NotificationTime>
                    <NotificationInfo>
                      <p>{notification.template}</p>
                      <IconDot iconType={notification.iconType as 'WARNING' | 'INFO' | 'SYSTEM'} />
                    </NotificationInfo>
                  </NotificationBox>
                </NotificationItem>
              ),
            )
          ) : (
            <div>No notifications to display</div>
          )}
        </Dropdown>
      )}
    </>
  );
};
