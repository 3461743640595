import styled from 'styled-components';

const Box = styled.div`
  display: flex;
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background: var(--neutral-50, #fefefe);
  img {
    width: 88px;
    height: 88px;
  }
`;

const BoxContent = styled.div`
  display: flex;
  padding: 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    color: #000;
    text-align: center;
    font-family: 'IBM Plex Sans';
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
  }
  p {
    color: var(--fordevs-gray-900, #242527);
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 8px;
    max-width: 214px;
  }
`;

const BoxButtons = styled.div`
  display: flex;
  width: 100%;
  margin-top: 32px;
  gap: 32px;
`;

export { Box, BoxContent, BoxButtons };
