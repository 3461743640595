import styled from 'styled-components';
const BoxInput = styled.div`
  display: flex;
  align-items: center;
  padding-right: 20px;
  padding-bottom: 20px;
`;

const BoxInputTitle = styled.span`
  font-size: 16px;
  width: 200px;
  margin-right: 10px;
`;

const BigBoxInputTitle = styled.span`
  font-size: 20px;
  font-style: italic;
`;

const Box = styled.div`
  background-color: #fff;
  max-width: 400px;
  margin: 0 auto; /* Horizontally center the box */
  padding-right: 10px;
  text-align: center;
`;

export { BoxInput, BoxInputTitle, Box, BigBoxInputTitle };
