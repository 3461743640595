import styled from 'styled-components';

const HeaderBox = styled.div`
  margin-top: 30px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  span {
    color: var(--Primary, #01aeb7);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
  }
  h2 {
    font-size: 36px;
    font-weight: 600;
    line-height: 115%;
  }
  .leftBox {
    display: flex;
    align-items: center;
    gap: 80px;
  }
`;

export { HeaderBox };
