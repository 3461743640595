import styled from 'styled-components';

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`;

const Wrapper = styled.div`
  width: 68%;
  overflow-y: auto;
  border-radius: 4px;
  background: var(--neutral-50, #fefefe);
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex-shrink: 0;
  h3 {
    color: #0d0d0d;
    font-size: 28px;
    font-weight: 600;
    line-height: 125%;
  }
`;

const BoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  h3 {
    color: #0d0d0d;
    font-size: 22px;
    font-weight: 600;
    line-height: 106%; /* 25.44px */
  }
`;

const BoxTimer = styled.div`
  border-radius: 50px;
  background: var(--White, #fefefe);
  padding: 12px 12px;
  align-items: center;
  display: flex;
`;

const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;
const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: #fefefe;
  padding: 20px;
  gap: 20px;
  width: 100%;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  p {
    color: #333;
    font-size: 16px;
    font-weight: 600;
    line-height: 135%;
  }
  span {
    color: var(--Control, #686868);
    font-size: 14px;
    font-weight: 400;
    line-height: 135%;
  }
`;

const BoxCheck = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
  p {
    color: var(--Control, #686868);
    font-size: 14px;
    font-weight: 400;
    line-height: 125%;
  }
`;

const StatusSigned = styled.h3`
  color: var(--Control, #fb4753);
  font-size: 24px;
  font-weight: 400;
  line-height: 125%;
`;

const NoAccessConnection = styled.p`
  color: var(--Control, #9aa4b0);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export {
  Box,
  Wrapper,
  BoxTimer,
  RightBox,
  InfoBox,
  BoxCheck,
  BoxHeader,
  StatusSigned,
  NoAccessConnection,
  ButtonContainer,
};
