import styled from 'styled-components';

const Box = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: var(--neutral-50, #fefefe);
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
  div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    p {
      font-size: 14px;
    }
  }
`;

const Title = styled.h3`
  color: #0d0d0d;
  font-size: 24px;
  font-weight: 600;
  line-height: 125%;
`;

export { Box, Title };
